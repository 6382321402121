#support {
  position: absolute;
  top: 40px;
  right: 0;
  transform: translatex(62%);
  font-weight: bold;
  z-index: 999;
  //animation: support 4s infinite;
  transition: all 1s ease-in;
  &:hover {
    transform: translateX(0);
    //animation: support-hover .5s ease;
  }
  &:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 46px 2.5em 47px 0;
    border-color: transparent $naac-blue transparent transparent;
    content: '';
    position: absolute;
    right: 100%;
    transform: rotate(360deg);
    transition: all .2s;
  }
  .btn {
    font-size: 1.5em;
    line-height: .5em;
    border-color: #0F4788;
    background: #0F4788;
    color: #efefef;
    padding-left: 6px;
    line-height: 93px;
    height: 93px;
    padding-top: 0;
    padding-bottom: 0;
    transition: all .2s;
    i {
      padding-right: 4px;
      padding-left: 4px;
      font-size: 2em;
      line-height: 94px;
      transition: all .15s;
    }
    span {
      vertical-align: top;
      text-transform: uppercase;
      font-size: 15px;
      vertical-align: top;
      line-height: 98px;
      display: inline-block;
      transition: all .2s;
    }
  }
  @media (max-width: $screen-md-max) {
    top: 0;
    animation: none!important;
    transform: none!important;
    margin-right: -1px;
    &:before {
      border-width: 28px 1.5em 28px!important;
      margin-right: -1px;
    }
    .btn {
      line-height: 56px!important;
      height: 56px!important;

      i {
        line-height: 56px!important;
      }
      span {
        display: none!important;
      }
    }

  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    position: relative!important;
    float: right;
    margin-right: -34px;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: 2em;
    &:before {
      border-width: 20px 1em 20px!important;
      margin-right: -1px;
    }
    .btn {
      line-height: 40px !important;
      height: 40px !important;

      i {
        line-height: 38px !important;
        font-size: 1.6em;
      }
    }
  }
}

.header-fixed-shrink {
  #support {
    &:before {
      border-width: 36px 1.5em 37px 0;

    }
    .btn {
      line-height: 73px;
      height: 73px;

      i {
        line-height: 74px;
      }
      span {
        line-height: 78px;
      }
    }
  }
}

body.front {
  #support {
    animation: support 4s infinite;
    transition: all 1s ease-in;
    &:hover {
      transform: translateX(0);
      animation: support-hover .5s ease;
    }
  }
}

@media (min-width: $screen-md-max) and (max-width: 1400px) {
  .header-v6 .navbar-collapse .navbar-nav {
    margin-right: 60px;
  }
}