.page-purchase-form {
  .btn-group {
    margin-bottom: 1em;
    .btn-who {
      margin-right: 1em;
    }
    i {
      margin-right: .7em;
    }
  }
  .field-name-field-course-logo {
    margin-bottom: 2em;
    img {
      margin: auto;
    }
  }
  .field-name-field-price-table {
    padding: 0 1.5em;
  }
  .field-name-commerce-price {
    font-size: 3em;
    text-align: center;
    color: #909090;
  }
  .form-submit {
    margin-top: 0 !important;
  }

  .others {
    .commerce-add-to-cart {
      margin-top: 1.5em;
      @include make-row();
      #edit-line-item-fields {
        @include make-md-column(9);
        @media (min-width: $screen-md-min) {
          float: right !important;
        }
      }
      .form-item-quantity {
        @include make-md-column(3);
      }
      .form-submit {
        clear: both;
        display: block;
        margin-left: 15px;
      }
    }
  }
}

.pane-naac-commerce-take-resume-course {
  margin-bottom: 2em;
}