/*   
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/

/*Breadcrumbs
------------------------------------*/
/*Default Breadcrumbs*/

.breadcrumbs {
  overflow: hidden;
  padding: 10px 0 6px;
  border-bottom: solid 1px #eee;
  background: url(../images/bg/breadcrumbs.png) repeat;
  h1 {
    color: #666;
    font-size: 22px;
    margin-top: 8px;
  }
}

.breadcrumb {
  top: 10px;
  padding-right: 0;
  background: none;
  position: relative;
  a {
    color: #777;
  }
  li {
    &.active, a:hover {
      color: $primary-accent;
      text-decoration: none;
    }
  }
}

@media (max-width: 550px) {
  .breadcrumbs {
    h1.pull-left, ul.pull-right {
      width: 100%;
      text-align: center;
    }
    h1.pull-left {
      margin-bottom: 0;
    }
    .breadcrumb {
      top: 0;
      margin-bottom: 10px;
    }
  }
}

/*Breadcrumbs Dark and Light*/

.breadcrumbs {
  &.breadcrumbs-dark {
    background: #222;
    padding: 30px 0 20px;
    border-bottom: solid 3px #777;
  }
  &.breadcrumbs-light {
    background: #222;
    padding: 30px 0 20px;
    border-bottom: solid 3px #777;
    background: #f7f7f7;
    border-bottom: 1px solid #eee;
  }
  &.breadcrumbs-dark {
    h1, a {
      color: #fff;
      font-weight: 200;
    }
  }
  &.breadcrumbs-light {
    h1, a {
      color: #fff;
      font-weight: 200;
    }
    h1, a {
      color: #555;
    }
  }
  &.breadcrumbs-dark h1 {
    font-size: 24px;
  }
  &.breadcrumbs-light h1 {
    font-size: 26px;
  }
}

/*Breadcrumbs Sizes*/

.breadcrumbs-sm {
  padding: 50px 0;
}

.breadcrumbs-md {
  padding: 100px 0;
}

.breadcrumbs-lg {
  padding: 200px 0;
}

/*Breadcrumbs v1 and v3
------------------------------------*/

.breadcrumbs-v1, .breadcrumbs-v3 {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
}

/*Breadcrumbs v1
------------------------------------*/

.breadcrumbs-v1 {
  padding: 100px 0;
  position: relative;
  background: url(../img/breadcrumbs/img1.jpg) center no-repeat;
  background-size: cover;
  /*Scales the background image to be as large as possible*/
  &:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.15);
  }
  span, h1 {
    color: #fff;
    z-index: 1;
    position: relative;
  }
  span {
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 5px;
    display: inline-block;
  }
  h1 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 55px;
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .breadcrumbs-v1 {
    padding: 50px 0;
    h1 {
      font-size: 40px;
      line-height: 45px;
    }
  }
}

/*Breadcrumbs v2
------------------------------------*/

.breadcrumbs-v2 {
  text-align: center;
  position: relative;
  background: url(../img/bg/11.jpg) no-repeat center;
}

.breadcrumbs-v2-in {
  padding: 80px 0;
  position: relative;
}

.breadcrumbs-v2 h1 {
  color: #fff;
  text-transform: uppercase;
}

.breadcrumb-v2 li {
  a, &.active {
    color: #fff;
  }
  i {
    color: #fff;
    min-width: 19px;
    padding: 3px 7px;
    margin-right: 5px;
    text-align: center;
    border: 1px solid #fff;
  }
}

/*Breadcrumbs v3
------------------------------------*/

.breadcrumbs-v3 {
  z-index: 1;
  padding: 100px 0;
  position: relative;
  &.breadcrumbs-lg {
    padding: 200px 0;
  }
  &.img-v1 {
    background: url(../img/breadcrumbs/img1.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &.img-v2 {
    background: url(../img/breadcrumbs/img2.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &.img-v3 {
    background: url(../img/breadcrumbs/img3.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
  }
  h1 {
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    text-transform: uppercase;
  }
  p {
    color: #fff;
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

@media (max-width: 992px) {
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 100px 0;
  }
}

@media (max-width: 768px) {
  .breadcrumbs-v3 {
    padding: 50px 0;
    &.breadcrumbs-lg {
      padding: 50px 0;
    }
    h1 {
      font-size: 30px;
      line-height: 40px;
    }
    p {
      font-size: 18px;
    }
  }
}

/*Image Hover Effects
------------------------------------*/
/*Image Hover v1*/

.img-hover-v1 {
  span {
    display: block;
    overflow: hidden;
    position: relative;
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
    }
  }
  &:hover span:after {
    background: rgba(0, 0, 0, 0.3);
  }
  img {
    transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
  }
}

.img-hover-v2 img {
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.img-hover-v1 {
  span:after {
    transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
  }
  &:hover img {
    -transform: scale(1.1);
    -o-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
}

/*Image Hover v2*/

.img-hover-v2 {
  img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
  &:hover img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
  }
}

/*Block Grid v1
------------------------------------*/

.block-grid-v1 {
  background: #fff;
  padding: 15px 15px 5px;
  border-bottom: solid 2px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  &:hover {
    border-color: $primary-accent;
  }
  img {
    margin-bottom: 10px;
    &.block-grid-v1-img {
      width: 35px;
      height: 35px;
      margin-top: 8px;
    }
  }
  &.content-boxes-v1 img.pull-left {
    float: inherit !important;
  }
  h3 a {
    font-size: 22px;
    font-weight: 200;
    margin-right: 10px;
  }
  &.content-boxes-v1 h3 {
    margin-bottom: 0;
    a {
      color: #555;
    }
  }
  .star-vote {
    padding: 0;
    margin-bottom: 10px;
    vertical-align: middle;
    li {
      padding: 0;
      font-size: 11px;
      display: inline-block;
    }
  }
}

/*Star Vote*/

/*Additional Info*/

.block-grid-v1-add-info {
  margin: 0;
  padding-top: 15px;
  border-top: solid 1px #eee;
  li {
    padding: 0;
    margin-right: 3px;
    margin-bottom: 10px;
    a {
      font-size: 11px;
      padding: 4px 7px;
      text-decoration: none;
      border: solid 2px #eee;
      border-radius: 3px !important;
    }
  }
}

/*Block Grid v2
------------------------------------*/

.block-grid-v2 {
  padding: 0;
  li {
    list-style: none;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/
  }
}

.block-grid-v2-info {
  padding: 15px;
  text-align: center;
  background: #f9f9f9;
  border-bottom: solid 3px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: $primary-accent;
}

.block-grid-v2-info {
  h3 {
    font-size: 22px;
    font-weight: 200;
    margin-bottom: 10px;
  }
  .star-vote li {
    padding: 2px;
    font-size: 16px;
  }
}

/*Services Box v1
------------------------------------*/

.service-box-v1 {
  .service-block {
    background: #fff;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  p {
    position: relative;
    margin-bottom: 50px;
    &:after {
      content: " ";
      left: 50%;
      height: 1px;
      width: 50px;
      bottom: -15px;
      position: absolute;
      margin-left: -25px;
      background: $primary-accent;
    }
  }
  ul {
    padding: 0 40px;
  }
  li {
    margin: 8px 0;
    font-size: 15px;
    padding-top: 8px;
    border-top: dotted 1px $primary-accent;
    &:hover {
      color: $primary-accent;
    }
    &:first-child {
      padding-top: 0;
      border-top: none;
    }
  }
}

/*Service Block v1
------------------------------------*/

.service-block-v1 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  padding: 45px 20px 20px;
  border: 1px solid #f2f2f2;
  i {
    left: 50%;
    top: -30px;
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 16px;
    font-size: 30px;
    margin-left: -30px;
    text-align: center;
    position: absolute;
    background: $primary-accent;
    display: inline-block;
  }
  h3, p {
    margin-bottom: 20px;
  }
}

/*Service Block v2 (used in page_404_error2.html)
------------------------------------*/

.service-block-v2 .service-block-in {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.2);
  i {
    font-size: 40px;
  }
  h4 {
    line-height: 25px;
  }
  p {
    margin-bottom: 20px;
  }
  i, h4, p {
    color: #fff;
  }
}

/*Service Block v3 (used in profile pages)
------------------------------------*/

.service-block-v3 {
  padding: 20px;
  i {
    color: #fff;
    float: left;
    font-size: 50px;
    margin: 0 20px 20px 0;
  }
  .service-heading {
    color: #fff;
    opacity: 0.8;
    line-height: 1;
  }
  .service-in {
    small {
      color: #fff;
      opacity: 0.8;
      line-height: 1;
    }
    h4 {
      color: #fff;
    }
  }
  .counter {
    color: #fff;
  }
  .service-heading {
    font-size: 16px;
    text-transform: uppercase;
  }
  .counter {
    display: block;
    line-height: 1;
    font-size: 30px;
  }
  .progress {
    margin-bottom: 7px;
  }
  .service-in {
    small {
      font-size: 16px;
      text-transform: uppercase;
    }
    h4 {
      font-size: 16px;
      line-height: 0.8;
      margin-bottom: 0;
    }
  }
  .statistics {
    .heading-xs {
      color: #fff;
      opacity: 0.8;
    }
    small {
      color: #fff;
    }
    .progress {
      background: #bbb;
    }
    .progress-bar-light {
      background: #fff;
    }
  }
}

/*Service Innner*/

/*Statistics*/

/*Service Block v4
------------------------------------*/

.service-block-v4 {
  position: relative;
  background: #f7f7f7;
  &:after {
    top: 0;
    width: 0;
    height: 0;
    left: 50%;
    content: '';
    margin-left: -20px;
    position: absolute;
    border-style: solid;
    border-width: 25px 20px 0 20px;
    border-color: #fff transparent transparent transparent;
  }
  .service-desc {
    text-align: center;
    i {
      color: $primary-accent;
      display: block;
      font-size: 30px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }
}

/*Service Block v5
------------------------------------*/

.service-block-v5 {
  text-align: center;
  background: #f7f7f7;
  .service-inner {
    padding: 80px 40px;
    i {
      margin-bottom: 30px;
    }
    span {
      display: block;
      font-size: 18px;
      font-weight: 200;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
  }
  .service-border {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
  }
}

@media (max-width: 992px) {
  .service-block-v5 .service-border {
    border-left: none;
    border-right: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
}

/*Service Block v6
------------------------------------*/

.service-block-v6 {
  i {
    float: left;
    margin: 5px 15px 0 0;
  }
  .service-desc {
    overflow: hidden;
    h2 {
      font-size: 18px;
      margin: 0 0 10px;
      text-transform: uppercase;
    }
    a {
      text-transform: uppercase;
    }
  }
}

/*Service Block v7
------------------------------------*/

.service-block-v7 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 45px 20px 20px;
  i {
    left: 50%;
    top: -25px;
    color: #fff;
    width: 45px;
    height: 45px;
    padding: 10px;
    font-size: 25px;
    text-align: center;
    position: absolute;
    background: $primary-accent;
    margin-left: -22.5px;
    display: inline-block;
  }
  h3, p {
    margin-bottom: 20px;
  }
}

/*Service Block v8
------------------------------------*/

.service-block-v8 {
  padding: 25px;
  background: #fff;
  i {
    color: #555;
    float: left;
    font-size: 22px;
    margin: 5px 25px 0 0;
  }
  .service-block-desc {
    overflow: hidden;
    h3 {
      font-size: 18px;
      margin: 0 0 30px;
      position: relative;
      text-transform: uppercase;
      &:after {
        left: 0;
        top: 40px;
        height: 1px;
        width: 30px;
        content: " ";
        position: absolute;
        background: $primary-accent;
      }
    }
  }
}

/*Process v1
------------------------------------*/

.process-v1 {
  .process-in {
    text-align: center;
    > li i {
      z-index: 1;
      width: 30px;
      height: 30px;
      position: relative;
      text-align: center;
      background: $primary-accent;
      margin-bottom: 15px;
      display: inline-block;
      border: solid 9px #fff;
      &:after {
        top: -9px;
        left: -9px;
        right: -9px;
        bottom: -9px;
        content: " ";
        border-radius: 50%;
        position: absolute;
        border: 1px solid #bbb;
      }
    }
    .process-desc {
      padding: 0 20px;
    }
    > li h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 10px;
      text-transform: uppercase;
    }
  }
  .process-border {
    position: relative;
    &:before {
      top: 15px;
      width: 75%;
      left: 12%;
      content: " ";
      display: block;
      position: absolute;
      border-top: solid 2px #ddd;
    }
  }
}

/*Process Description*/

/*Process Border*/

@media (max-width: 768px) {
  .process-v1 .process-border:before {
    border: none;
  }
}

/*Team v1
------------------------------------*/

.team-v1 {
  ul {
    position: relative;
  }
  li {
    > .team-img {
      position: relative;
      &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: " ";
        position: absolute;
        background: transparent;
        transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
      }
    }
    &:hover {
      > .team-img:after {
        background: rgba(0, 0, 0, 0.5);
      }
      > .team-img ul {
        top: 50%;
        visibility: visible;
      }
      > p:before {
        width: 100%;
        background: $primary-accent;
      }
    }
    > .team-img ul {
      left: 0;
      top: 45%;
      z-index: 1;
      padding: 0;
      width: 100%;
      height: 36px;
      list-style: none;
      margin-top: -18px;
      text-align: center;
      visibility: hidden;
      position: absolute;
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
    > {
      .team-img {
        ul li {
          margin: 0 5px;
          text-align: center;
          display: inline-block;
        }
        li i {
          color: #eee;
          border-width: 2px;
          border-color: #eee;
          transition: all 0.1s ease-in-out;
          -o-transition: all 0.1s ease-in-out;
          -moz-transition: all 0.1s ease-in-out;
          -webkit-transition: all 0.1s ease-in-out;
          &.fa-twitter:hover {
            color: #fff;
            background: #3498db;
            border-color: #3498db;
          }
          &.fa-facebook:hover {
            color: #fff;
            background: #4765a0;
            border-color: #4765a0;
          }
          &.fa-google-plus:hover {
            color: #fff;
            background: #e74c3c;
            border-color: #e74c3c;
          }
        }
      }
      h3 {
        color: #000;
        font-size: 18px;
        line-height: 20px;
        margin: 15px 0 7px;
        text-transform: uppercase;
      }
      h4 {
        color: #999;
        font-size: 11px;
        line-height: 16px;
        text-transform: uppercase;
      }
      p {
        margin: 20px 0;
        position: relative;
        &:before {
          left: 0;
          top: -10px;
          height: 1px;
          width: 40px;
          content: " ";
          position: absolute;
          background: #bbb;
          transition: all 0.4s ease-in-out;
          -o-transition: all 0.4s ease-in-out;
          -moz-transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;
        }
      }
    }
  }
}

/*Team Image*/

/*Team Socials*/

/*Team Discription*/

/*Team v2
------------------------------------*/

.team-v2 {
  position: relative;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
  .inner-team {
    padding: 20px;
    background: #fff;
    text-align: center;
    h3 {
      margin: 0;
    }
    small {
      display: block;
      font-size: 12px;
      margin-bottom: 7px;
    }
    p {
      font-size: 13px;
    }
    hr {
      margin: 10px 0 15px;
    }
  }
}

/*Team-Social (Temporary)*/

.team-social {
  margin-bottom: 0;
  li {
    padding: 0 2px;
    a {
      font-size: 14px;
      padding: 6px 4px;
      text-align: center;
      border-radius: 50% !important;
    }
    i {
      min-width: 20px;
    }
    a {
      &.fb {
        color: #4862a3;
        border: 1px solid #4862a3;
      }
      &.tw {
        color: #159ceb;
        border: 1px solid #159ceb;
      }
      &.gp {
        color: #dc4a38;
        border: 1px solid #dc4a38;
      }
      &:hover {
        text-decoration: none;
      }
      &.fb:hover {
        color: #fff;
        background: #4862a3;
      }
      &.tw:hover {
        color: #fff;
        background: #159ceb;
      }
      &.gp:hover {
        color: #fff;
        background: #dc4a38;
      }
    }
  }
}

/*Team v3
------------------------------------*/

.team-v3 {
  .team-img {
    position: relative;
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
    }
    &:hover:after {
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
    > img {
      width: 100%;
    }
  }
  .team-hover {
    left: 0;
    top: 60%;
    z-index: 1;
    padding: 0;
    width: 100%;
    padding: 20px;
    list-style: none;
    margin-top: -90px;
    text-align: center;
    visibility: hidden;
    position: absolute;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
  }
  .team-img:hover .team-hover {
    top: 50%;
    visibility: visible;
  }
  .team-hover {
    span, small {
      color: #fff;
      display: block;
    }
    span {
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
    }
    small {
      font-size: 13px;
      font-weight: 200;
      margin-bottom: 10px;
    }
    p {
      color: #fff;
      font-weight: 200;
      margin-bottom: 20px;
    }
    .team-social-v3 i {
      color: #fff;
      width: 33px;
      height: 33px;
      padding: 9px;
      font-size: 14px;
      text-align: center;
      display: inline-block;
      border: 1px solid #fff;
      &:hover {
        color: #666;
        background: #fff;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
  }
}

/*Team Hover Gradient*/

/*Team Social*/

/*Team v4
------------------------------------*/

.team-v4 {
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 20px;
  }
  span {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  small {
    color: #999;
    display: block;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 10px;
  }
  .team-social-v4 a {
    color: #555;
    font-size: 16px;
    &:hover {
      color: $primary-accent;
      text-decoration: none;
    }
  }
}

/*Team v5
------------------------------------*/

.team-v5 {
  text-align: center;
  span {
    color: #777;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  small {
    display: block;
    color: $primary-accent;
    font-size: 13px;
    font-style: italic;
    position: relative;
    margin-bottom: 25px;
    &:after {
      left: 50%;
      top: 30px;
      height: 1px;
      width: 30px;
      content: " ";
      background: #777;
      margin-left: -15px;
      position: absolute;
    }
  }
  .team-img {
    position: relative;
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
    }
    &:hover:after {
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
    > img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .team-hover {
    left: 0;
    top: 60%;
    z-index: 1;
    padding: 0;
    width: 100%;
    list-style: none;
    margin-top: -16px;
    text-align: center;
    visibility: hidden;
    position: absolute;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
  }
  .team-img:hover .team-hover {
    top: 50%;
    visibility: visible;
  }
  .team-hover .team-social-v5 i {
    color: #fff;
    width: 33px;
    height: 33px;
    padding: 9px;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    border: 1px solid #fff;
    &:hover {
      color: #666;
      background: #fff;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
  }
}

/*Team Hover Gradient*/

/*Social Network-*/

/*Team v6
------------------------------------*/

.team-v6 {
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 20px;
  }
  span {
    color: #777;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  small {
    display: block;
    color: $primary-accent;
    font-size: 13px;
    margin-bottom: 15px;
  }
}

/*Team v7
------------------------------------*/

.team-v7 {
  .team-v7-in {
    padding: 0 50px;
  }
  .team-v7-name {
    color: #555;
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .team-v7-position {
    display: block;
    color: $primary-accent;
    font-style: normal;
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .team-v7-name {
    margin-top: 60px;
  }
  .social-icons-v1 {
    margin-bottom: 60px;
  }
  .team-v7-img {
    position: relative;
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .team-arrow-right {
    position: relative;
  }
  .team-arrow-left {
    position: relative;
    &:before {
      width: 0;
      height: 0;
      top: 20%;
      z-index: 2;
      content: " ";
      position: absolute;
      border-style: solid;
    }
  }
  .team-arrow-right:before {
    width: 0;
    height: 0;
    top: 20%;
    z-index: 2;
    content: " ";
    position: absolute;
    border-style: solid;
    right: -25px;
    border-width: 20px 0 20px 25px;
    border-color: transparent transparent transparent #fff;
  }
  .team-arrow-left:before {
    left: -25px;
    border-width: 20px 25px 20px 0;
    border-color: transparent #fff transparent transparent;
  }
}

/*Team Space*/

/*Team Image*/

/*Team Info*/

/*Team Info Media Queries*/
@media (max-width: 991px) {
  /*Team Arrows*/
  .team-v7 {
    .team-arrow-left:before, .team-arrow-right:before {
      top: auto;
      left: 70px;
      bottom: -25px;
      border-width: 25px 20px 0 20px;
      border-color: #fff transparent transparent transparent;
    }
    .team-arrow-left:before, .team-arrow-right:before {
      left: 70px;
      bottom: -25px;
      border-width: 25px 20px 0 20px;
    }
    .equal-height-column {
      height: auto !important;
    }
  }
  /*Equal Height Columns*/
}

/*Call To Action v1
------------------------------------*/

.call-action-v1 {
  p {
    margin-top: 5px;
    font-size: 18px;
    font-weight: 200;
    margin-bottom: 0;
  }
  .call-action-v1-box {
    width: 100%;
    display: table;
    padding: 30px 0;
    vertical-align: middle;
  }
  .call-action-v1-in {
    display: table-cell;
    vertical-align: middle;
    &.inner-btn {
      min-width: 250px;
      text-align: right;
    }
  }
}

@media (max-width: 992px) {
  .call-action-v1 .call-action-v1-in {
    display: block;
    text-align: center;
    &.inner-btn {
      display: block;
      text-align: center;
    }
    p {
      margin-bottom: 20px;
    }
  }
}

/*Call To Axtion v2
------------------------------------*/

.call-action-v2 {
  padding: 50px 0;
  text-align: center;
  background: url(../img/patterns/16.png);
  h2 {
    font-size: 22px;
    text-transform: uppercase;
  }
  p {
    padding: 0 100px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .call-action-v2 p {
    padding: inherit;
  }
}

/*Portfolio Box
------------------------------------*/
/*Portfolio Box*/

.portfolio-box {
  margin-bottom: 100px;
  a.fancybox {
    background: #fff;
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/
    &:after {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
    }
    &:hover:after {
      background: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 100%;
      overflow: hidden;
      transition: all 500ms ease 0s;
    }
    &:hover img {
      transform: scale(1.1) rotate(0.1deg);
    }
  }
  .portfolio-box-in {
    left: 0;
    top: 50%;
    z-index: 1;
    width: 100%;
    min-height: 50px;
    margin-top: -25px;
    visibility: hidden;
    text-align: center;
    position: absolute;
  }
  a.fancybox {
    &:hover .portfolio-box-in {
      visibility: visible;
    }
    &:after {
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
    &:hover {
      &:after, .portfolio-box-in i {
        transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
      }
    }
  }
  .portfolio-box-in i {
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 16px;
    font-size: 20px;
    text-align: center;
    background: $primary-accent;
    display: inline-block;
  }
  small.project-tag {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    color: #999;
    a {
      color: #999;
    }
    i {
      margin-right: 5px;
    }
    a:hover {
      color: $primary-accent;
    }
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .portfolio-box {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .portfolio-box {
    margin-bottom: 60px;
  }
}

/*Project Tag*/

/*Portfolio Box v1
------------------------------------*/

.portfolio-box-v1 {
  margin: 0;
  [class^="col-"] {
    padding: 0;
    overflow: hidden;
  }
  li {
    background: #333;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
    }
    &:hover:after {
      background: rgba(0, 0, 0, 0.7);
    }
    &:after, &:hover:after {
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
    img {
      width: 100%;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
    &:hover img {
      -transform: scale(1.1);
      -o-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
  }
  .portfolio-box-v1-in {
    left: 0;
    top: 50%;
    z-index: 1;
    width: 100%;
    visibility: hidden;
    min-height: 150px;
    margin-top: -75px;
    text-align: center;
    position: absolute;
    font-family: "Open Sans", Arial, sans-serif;
  }
  li:hover .portfolio-box-v1-in {
    visibility: visible;
  }
  .portfolio-box-v1-in {
    h3 {
      font-size: 22px;
      margin-top: 20px;
      position: relative;
      margin-bottom: 20px;
      text-transform: uppercase;
      &:after {
        left: 50%;
        height: 1px;
        width: 40px;
        content: " ";
        bottom: -10px;
        background: #fff;
        margin-left: -20px;
        position: absolute;
      }
    }
    p, h3 {
      color: #fff;
    }
    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
    a.btn-u {
      font-weight: normal;
      -webkit-backface-visibility: hidden;
      /*For Chrome*/
    }
  }
}

/*Portfolio Feature v2
------------------------------------*/

.portfolio-box-v2 {
  margin: 0 0 60px;
  [class^="col-"] {
    padding: 0;
    overflow: hidden;
  }
  li {
    background: #333;
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
    }
    &:hover:after {
      background: rgba(255, 255, 255, 0.6);
    }
    &:after, &:hover:after {
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
    img {
      width: 100%;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
    &:hover img {
      -transform: scale(1.1);
      -o-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
  }
  .portfolio-box-v2-in {
    left: 0;
    top: 50%;
    z-index: 1;
    width: 100%;
    visibility: hidden;
    min-height: 60px;
    margin-top: -30px;
    text-align: center;
    position: absolute;
    font-family: "Open Sans", Arial, sans-serif;
  }
  li:hover .portfolio-box-v2-in {
    visibility: visible;
  }
  .portfolio-box-v2-in i {
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 20px;
    font-size: 22px;
    cursor: pointer;
    margin-right: 5px;
    text-align: center;
    display: inline-block;
    background: rgba(114, 192, 44, 0.8);
    &:hover {
      background: $primary-accent;
    }
  }
}

/*Thumbnail v1
------------------------------------*/

.thumbnails-v1 {
  .thumbnail-img {
    margin-bottom: 12px;
  }
  h3 {
    margin-bottom: 5px;
    a {
      color: #555;
      font-size: 15px;
      text-transform: uppercase;
    }
  }
  .read-more {
    color: $primary-accent;
    font-size: 12px;
    text-transform: uppercase;
  }
}

/*Image Backgrounds v1
------------------------------------*/

.bg-image-v1 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/11.jpg) repeat fixed;
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }
}

/*Image Backgrounds v2
------------------------------------*/

.bg-image-v2 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/7.jpg) repeat fixed;
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
  }
}

/*Background Dark Cover*/

.bg-image-v2-dark {
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }
  h2, p {
    color: #eee !important;
  }
}

/*Parallax Quote
------------------------------------*/

.parallax-quote {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
  &:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
  }
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: url(../img/patterns/gridtile.png) repeat;
  }
}

/*Parallax Quote Inner*/

.parallax-quote-in {
  z-index: 1;
  padding: 0 80px;
  position: relative;
  p {
    color: #fff;
    font-size: 28px;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif;
    &:after, &:before {
      content: ' " ';
      position: absolute;
      font-family: Tahoma;
    }
    &:after {
      margin-left: 3px;
    }
    &:before {
      margin-left: -15px;
    }
  }
  small {
    color: #bbb;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .parallax-quote-in {
    padding: 0 40px;
    p {
      font-size: 20px;
    }
  }
}

/*Parallax Quote Light*/

.parallax-quote-light {
  &:after {
    background: rgba(255, 255, 255, 0.9);
  }
  .parallax-quote-in {
    background: #fff;
    padding: 30px 80px;
    border-bottom: solid 2px #ddd;
    p {
      color: #555;
      font-size: 28px;
      font-weight: 200;
    }
    small {
      color: #555;
    }
  }
}

@media (max-width: 768px) {
  .parallax-quote-light .parallax-quote-in {
    padding: 30px 40px;
    p {
      font-size: 20px;
    }
    small {
      font-weight: 200;
    }
  }
}

/*Parallax Team
------------------------------------*/

.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
  position: relative;
  .title-box-v2 {
    position: relative;
  }
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
  }
}

.parallax-team-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
}

/*Parallax Team*/

.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
  position: relative;
  .title-box-v2 {
    position: relative;
  }
}

.parallax-team-dark:before {
  background: rgba(0, 0, 0, 0.8);
}

/*Parallax Counter
------------------------------------*/

.parallax-bg {
  background: url(../img/patterns/5.png) repeat fixed;
}

/*Parallax Counter
------------------------------------*/

.parallax-counter {
  padding: 30px 0;
  background: url(../img/patterns/5.png) repeat fixed;
  .counters {
    color: #fff;
    padding: 15px 0;
  }
  h4 {
    color: #999;
    font-size: 16px;
    text-transform: uppercase;
  }
  span.counter {
    font-size: 48px;
  }
}

/*Parallax Counter v1
------------------------------------*/

.parallax-counter-v1 {
  padding: 60px 0;
  background: url(../img/patterns/5.png) repeat fixed;
  .counters {
    color: #fff;
    padding: 15px 0 10px;
    border: solid 1px #555;
  }
  h4 {
    color: #eee;
    font-size: 16px;
    text-transform: uppercase;
  }
  span.counter {
    font-size: 42px;
    line-height: 48px;
  }
}

/*Parallax Counter v2
------------------------------------*/

.parallax-counter-v2 {
  position: relative;
  padding: 80px 0 60px;
  background: url(../img/patterns/16.png) repeat fixed;
  &:after {
    top: 0;
    left: 50%;
    content: " ";
    margin-left: -25px;
    position: absolute;
    border-top: 25px solid #fff;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
  }
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.02);
  }
  .counters {
    padding: 20px 0;
    border-bottom: solid 2px #ddd;
    background: rgba(255, 255, 255, 0.9);
    h4 {
      color: #555;
      font-weight: 200;
      font-family: "Open Sans", Arial, sans-serif;
    }
    span {
      color: #555;
      font-weight: 200;
      font-family: "Open Sans", Arial, sans-serif;
      font-size: 36px;
    }
    h4 {
      font-size: 22px;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 992px) {
  .parallax-counter-v2 .counters {
    margin-bottom: 0 !important;
  }
}

/*Parallax Counter v3
------------------------------------*/

.parallax-counter-v3 {
  z-index: 1;
  height: auto;
  padding: 80px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/25.jpg) 50% 0 fixed;
  &:after {
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    content: " ";
    height: 100%;
    text-align: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }
  .features {
    width: 130px;
    height: 130px;
    padding: 25px;
    position: relative;
    text-align: center;
    display: inline-block;
    border-radius: 50% !important;
    background: rgba(255, 255, 255, 0.2);
    &:after {
      top: -9px;
      left: -9px;
      right: -9px;
      bottom: -9px;
      content: " ";
      border-radius: 50%;
      position: absolute;
      border: 1px solid #fff;
    }
    span {
      color: #fff;
      display: block;
      font-weight: 200;
      &.counter {
        font-size: 30px;
      }
      &.features-info {
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 550px) {
  .parallax-counter-v3 .features {
    width: 110px;
    height: 110px;
    padding: 22px;
    &:after {
      top: -7px;
      left: -7px;
      right: -7px;
      bottom: -7px;
    }
    span {
      &.counter {
        font-size: 20px;
      }
      &.features-info {
        font-size: 11px;
      }
    }
  }
}

/*Parallax Counter v4
------------------------------------*/

.parallax-counter-v4 {
  z-index: 1;
  height: auto;
  padding: 30px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/19.jpg) 50% 0 fixed;
  &:after {
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    content: " ";
    height: 100%;
    text-align: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }
  i {
    color: #fff;
    display: block;
    font-size: 50px;
    margin-bottom: 20px;
  }
  span.counter {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 200;
    text-transform: uppercase;
  }
}

/*Flat Background Block
------------------------------------*/

.flat-bg-block-v1 {
  padding: 40px 0;
  background: url(../img/patterns/16.png) repeat;
  .checked-list {
    &.first-child {
      float: left;
      margin-right: 100px;
    }
    li {
      color: #888;
      margin-bottom: 5px;
    }
    i {
      color: $primary-accent;
      margin-right: 5px;
    }
  }
  img.img-over {
    top: -70px;
    right: 10px;
    position: absolute;
  }
}

/*Image Over*/

@media (min-width: 991px) and (max-width: 1199px) {
  .flat-bg-block-v1 img.img-over {
    top: -18px;
  }
}

@media (max-width: 991px) {
  .flat-bg-block-v1 img.img-over {
    position: static;
  }
}

/*Flat Testimonials
------------------------------------*/

.flat-testimonials {
  padding: 50px 0;
  background-color: #333;
  .flat-testimonials-in {
    padding: 30px;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    img {
      width: 135px;
      height: 135px;
      margin: 0 auto 25px;
    }
    h3 {
      color: #fff;
      font-size: 22px;
      font-weight: 200;
    }
    span {
      display: block;
      margin-bottom: 5px;
    }
    p {
      color: #fff;
      font-weight: 200;
      position: relative;
      &:before, &:after {
        top: 5px;
        font-size: 22px;
        line-height: 10px;
        position: relative;
      }
      &:before {
        content: "“";
        padding-right: 5px;
      }
      &:after {
        content: "”";
        padding-left: 5px;
      }
    }
  }
}

/*Testimonials v4
------------------------------------*/

.testimonials-v4 {
  overflow: hidden;
  .testimonials-v4-in {
    background: #fff;
    position: relative;
    margin-bottom: 40px;
    padding: 20px 20px 20px 55px;
    -webkit-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    &:before {
      color: #bbb;
      font-size: 60px;
      content: "\201C";
      position: absolute;
      margin: -25px 0 0 -40px;
      font-family: Georgia, serif;
    }
    &:after {
      width: 0;
      height: 0;
      left: 80px;
      content: " ";
      bottom: -30px;
      position: absolute;
      border-style: solid;
      border-width: 30px 30px 0 0;
      border-color: #fff transparent transparent transparent;
      -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
    }
    p {
      color: #777;
      font-size: 14px;
      font-style: italic;
    }
  }
  img {
    float: left;
    width: 60px;
    height: auto;
    margin: 0 20px;
  }
  .testimonials-author {
    color: #555;
    display: block;
    font-size: 14px;
    overflow: hidden;
    padding-top: 8px;
    text-transform: uppercase;
    em {
      font-size: 13px;
      text-transform: capitalize;
    }
  }
}

/*Testimonials v5
------------------------------------*/

.testimonials-v5 {
  padding-top: 50px;
  padding-bottom: 50px;
  .testimonials-desc {
    span {
      font-size: 13px;
      font-weight: 200;
    }
    p {
      padding: 15px;
      position: relative;
      background: #f9f9f9;
      margin-bottom: 25px;
      &:after {
        width: 0;
        height: 0;
        left: 30px;
        color: #fff;
        bottom: -12px;
        content: " ";
        text-align: center;
        position: absolute;
        border-style: solid;
        border-width: 13px 10px 0 10px;
        border-color: #f9f9f9 transparent transparent transparent;
      }
    }
  }
  .testimonials-by {
    margin-left: 20px;
    img {
      float: left;
      width: 40px;
      height: auto;
      margin-right: 15px;
    }
    small {
      color: $primary-accent;
      font-size: 12px;
    }
    span {
      text-transform: uppercase;
    }
  }
}

/*Testimonials By*/

/*Testimonials v6
------------------------------------*/

.testimonials-v6 {
  &.testimonials-wrap {
    padding-left: 70px;
    padding-right: 70px;
  }
  .testimonials-info {
    padding: 20px;
    background: #fff;
    border-bottom: solid 3px #eee;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    &:hover {
      border-color: $primary-accent;
    }
    img {
      float: left;
      width: 80px;
      height: auto;
      margin-right: 20px;
    }
  }
  .testimonials-desc {
    overflow: hidden;
    p {
      margin-bottom: 15px;
    }
    strong, span {
      color: #555;
      display: block;
    }
  }
}

@media (max-width: 768px) {
  .testimonials-v6 .testimonials-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

/*Featured Blog
------------------------------------*/

.featured-blog {
  h2 {
    font-size: 18px;
    margin: 0 0 25px;
    line-height: 25px;
    position: relative;
    &:after {
      left: 0;
      z-index: 1;
      width: 30px;
      height: 2px;
      content: " ";
      bottom: -10px;
      position: absolute;
      background: $primary-accent;
    }
  }
  .featured-img {
    position: relative;
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
    }
    &:hover:after {
      background: rgba(0, 0, 0, 0.3);
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
    > img {
      width: 100%;
    }
    i {
      top: 60%;
      left: 50%;
      z-index: 1;
      width: 100%;
      list-style: none;
      text-align: center;
      visibility: hidden;
      position: absolute;
      margin: -17.5px 0 0 -17.5px;
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
    &:hover i {
      top: 50%;
      visibility: visible;
    }
    i {
      color: #fff;
      width: 35px;
      height: 35px;
      padding: 11px;
      font-size: 14px;
      text-align: center;
      display: inline-block;
      background: rgba(255, 255, 255, 0.4);
      &:hover {
        color: #fff;
        background: $primary-accent;
        text-decoration: none;
        transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
  }
}

/*Team Hover Gradient*/

/*Blog Comments
------------------------------------*/

.blog-comments {
  img {
    width: 80px;
    height: auto;
  }
  .comments-itself {
    background: #fff;
    padding: 20px 30px;
    position: relative;
    &:before {
      width: 0;
      height: 0;
      top: 20px;
      left: -17px;
      content: " ";
      position: absolute;
      border-style: solid;
      border-width: 15px 17px 15px 0;
      border-color: transparent #fff transparent transparent;
    }
    h3 {
      margin: 0 0 10px;
    }
    span {
      color: #999;
      float: right;
      font-size: 13px;
    }
    p {
      color: #999;
    }
  }
  &.blog-comments-reply {
    margin-left: 70px;
  }
}

/*Comments Reply*/

/*Media Queries*/
@media (max-width: 768px) {
  .blog-comments .comments-itself {
    &:before {
      display: none;
    }
    &:after {
      width: 0;
      height: 0;
      top: -17px;
      left: 20px;
      content: " ";
      position: absolute;
      border-style: solid;
      border-width: 0 15px 17px 15px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

/*Quote
------------------------------------*/

.quote-v1 {
  padding: 60px 0;
  text-align: center;
  background: url(../img/patterns/5.png) repeat;
  p {
    color: #fff;
    font-size: 22px;
    font-weight: 200;
    padding: 0 100px;
    position: relative;
    margin-bottom: 20px;
    &:before {
      color: $primary-accent;
      font-size: 60px;
      content: "\201C";
      position: absolute;
      margin: -30px 0 0 -40px;
      font-family: Georgia, serif;
    }
  }
  span {
    color: #fff;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .quote-v1 p {
    font-size: 18px;
    padding: 0 50px;
  }
}

/*Quote v2
------------------------------------*/

.quote-v2 {
  text-align: center;
  padding: 85px 10% 40px;
  p {
    color: #555;
    font-size: 16px;
    font-style: italic;
    position: relative;
    margin-bottom: 20px;
    &:before {
      left: 50%;
      top: -75px;
      font-size: 80px;
      content: "\201C";
      position: absolute;
      margin-left: -25px;
      font-family: Georgia, serif;
    }
  }
  span {
    color: #555;
    font-weight: 400;
  }
}

/*Team Social
------------------------------------*/

.social-icons-v1 i {
  color: #555;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block;
  &:hover {
    color: #fff;
    background: $primary-accent;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
  }
}

/*Box Shadows
------------------------------------*/

.shadow-wrapper {
  z-index: 1;
  position: relative;
}

/*Common Style*/

.box-shadow {
  background: #fff;
  position: relative;
  &:after, &:before {
    top: 80%;
    left: 5px;
    width: 50%;
    z-index: -1;
    content: "";
    bottom: 15px;
    max-width: 300px;
    background: #999;
    position: absolute;
  }
}

/*Effect 1*/

.shadow-effect-1 {
  box-shadow: 0 10px 6px -6px #bbb;
  -moz-box-shadow: 0 10px 6px -6px #bbb;
  -webkit-box-shadow: 0 10px 6px -6px #bbb;
}

/*Effect 2,3,4*/

.shadow-effect-2 {
  position: relative;
  &:after, &:before {
    transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    box-shadow: 0 15px 10px #999;
    -moz-box-shadow: 0 15px 10px #999;
    -webkit-box-shadow: 0 15px 10px #999;
  }
}

.shadow-effect-3:before, .shadow-effect-4:after {
  transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
  -moz-box-shadow: 0 15px 10px #999;
  -webkit-box-shadow: 0 15px 10px #999;
}

.shadow-effect-2:after, .shadow-effect-4:after {
  left: auto;
  right: 5px;
  transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -webkit-transform: rotate(3deg);
}

/*Interactive Slider v1
------------------------------------*/

.interactive-slider-v1 {
  z-index: 1;
  padding: 130px 0;
  position: relative;
  &.img-v1 {
    background: url(../img/bg/18.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &.img-v2 {
    background: url(../img/bg/19.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &.img-v3 {
    background: url(../img/bg/18.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
  }
  h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
  }
  p {
    color: #fff;
    font-size: 24px;
    font-weight: 200;
    text-transform: uppercase;
  }
}

@media (max-width: 768px) {
  .interactive-slider-v1 {
    h2 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
  }
}

/*Interactive Slider v2
------------------------------------*/

.interactive-slider-v2 {
  z-index: 1;
  padding: 200px 0;
  position: relative;
  text-align: center;
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center;
  &.img-v1 {
    background: url(../img/bg/25.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &.img-v2 {
    background: url(../img/bg/14.jpg) no-repeat;
    background-size: cover;
    background-position: center center;
  }
  &:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
  }
  h1 {
    color: #fff;
    font-size: 70px;
    font-weight: 600;
    line-height: 80px;
    text-transform: uppercase;
  }
  p {
    color: #fff;
    font-size: 24px;
    font-weight: 200;
    font-family: "Open Sans", Arial, sans-serif;
  }
}

@media (max-width: 768px) {
  .interactive-slider-v2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px;
  }
}

/*Blog Trending
------------------------------------*/

.blog-trending {
  li {
    margin-bottom: 20px;
  }
  h3 {
    font-size: 14px;
    margin: 0 0 5px;
    line-height: 17px;
  }
  small {
    color: #777;
    a {
      color: #777;
      &:hover {
        color: $primary-accent;
      }
    }
  }
}

/*Blog Latest Posts
------------------------------------*/

.blog-latest-posts {
  li {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
  }
  h3 {
    font-size: 14px;
    margin: 0 0 3px;
    line-height: 20px;
  }
  small {
    color: #777;
    a {
      color: #777;
    }
    margin-bottom: 8px;
    display: inline-block;
  }
  p {
    font-size: 12px;
  }
}

/*Blog Photostream
------------------------------------*/

.blog-photostream {
  margin-right: -5px;
  li {
    padding: 0;
    margin: 3px 5px 5px;
    position: relative;
    img {
      width: 78px;
      height: auto;
    }
  }
}

/*Newsletter
------------------------------------*/

.blog-newsletter {
  p {
    color: #555;
    margin-bottom: 15px;
  }
  .form-control {
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
}

/*Blog Post Quote
------------------------------------*/

.blog-post-quote {
  padding: 35px 60px;
  background: #fff;
  text-align: center;
  p {
    color: #555;
    font-size: 32px;
    font-weight: 200;
    line-height: 45px;
    position: relative;
    margin-bottom: 20px;
    font-family: "Open Sans", Arial, sans-serif;
    &:before, &:after {
      top: -3px;
      font-size: 30px;
      position: relative;
    }
    &:before {
      left: -10px;
      content: "\0022";
    }
    &:after {
      right: -10px;
      content: "\0022";
    }
  }
  span {
    color: #555;
    font-size: 20px;
    font-weight: 200;
  }
}

@media (max-width: 768px) {
  .blog-post-quote {
    padding: 35px 25px;
    p {
      font-size: 26px;
      line-height: 35px;
    }
  }
}

/*Blog Post Author
------------------------------------*/

.blog-author {
  padding: 20px;
  background: #fff;
  img {
    float: left;
    width: 80px;
    height: auto;
    margin-right: 20px;
  }
  .blog-author-desc {
    overflow: hidden;
    h4 {
      float: left;
      font-size: 18px;
      margin: 0 0 10px;
      text-transform: uppercase;
    }
    ul {
      float: right;
      margin-top: 6px;
    }
    p {
      color: #555;
    }
  }
}

/*Blog Comments
------------------------------------*/

.blog-comments {
  img {
    width: 80px;
    height: auto;
  }
  .comments-itself {
    background: #fff;
    padding: 20px 30px;
    position: relative;
    &:before {
      width: 0;
      height: 0;
      top: 20px;
      left: -17px;
      content: " ";
      position: absolute;
      border-style: solid;
      border-width: 15px 17px 15px 0;
      border-color: transparent #fff transparent transparent;
    }
    h3 {
      margin: 0 0 10px;
    }
    span {
      color: #555;
      float: right;
      font-size: 13px;
    }
    p {
      color: #555;
    }
  }
  &.blog-comments-reply {
    margin-left: 70px;
  }
}

/*Comments Reply*/

/*Media Queries*/
@media (max-width: 768px) {
  .blog-comments .comments-itself {
    &:before {
      display: none;
    }
    &:after {
      width: 0;
      height: 0;
      top: -17px;
      left: 20px;
      content: " ";
      position: absolute;
      border-style: solid;
      border-width: 0 15px 17px 15px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

/*News v1
------------------------------------*/

.news-section {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

.news-v1 {
  .news-v1-in {
    background: #fafafa;
  }
  h3 {
    margin-bottom: 0;
    font-weight: 200;
    line-height: 28px;
    padding: 10px 15px 15px;
    a {
      color: #555;
      &:hover {
        color: $primary-accent;
      }
    }
  }
  p {
    color: #555;
    padding: 0 15px;
    margin-bottom: 20px;
  }
  .news-v1-info {
    margin-left: 0;
    margin-top: 30px;
    overflow: hidden;
    padding: 8px 10px;
    border-top: solid 1px #eee;
    li {
      font-size: 12px;
      a:hover {
        color: $primary-accent;
        text-decoration: none;
      }
    }
  }
}

/*News v2
------------------------------------*/
/*News Badge*/

.news-v2 {
  .news-v2-badge {
    overflow: hidden;
    position: relative;
    p {
      left: 0;
      top: 20px;
      background: #fff;
      padding: 5px 15px;
      text-align: center;
      position: absolute;
    }
    span {
      color: #555;
      display: block;
      font-size: 16px;
      line-height: 16px;
    }
    small {
      color: #555;
      display: block;
      font-size: 10px;
      text-transform: uppercase;
    }
  }
  .news-v2-desc {
    padding: 20px;
    background: #fff;
    h3 {
      margin: 0 0 3px;
      font-size: 16px;
      a {
        color: #555;
        &:hover {
          color: $primary-accent;
        }
      }
    }
    small {
      color: #555;
      display: block;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    p {
      color: #555;
    }
  }
}

/*News Description*/

/*News v3
------------------------------------*/
/*News Posts*/

.news-v3 {
  background: #fff;
  .news-v3-in {
    padding: 35px 30px;
  }
  .news-v3-in-sm {
    padding: 20px;
  }
  h2 {
    font-size: 32px;
    font-weight: 200;
    margin: 0 0 20px;
    line-height: 45px;
    text-transform: uppercase;
  }
  .news-v3-in-sm h2 {
    font-size: 22px;
    margin: 0 0 15px;
    line-height: 30px;
    text-transform: inherit;
  }
  h2 a {
    color: #555;
    &:hover {
      color: $primary-accent;
      text-decoration: none;
    }
  }
  p {
    color: #555;
    margin-bottom: 20px;
  }
  .posted-info li {
    color: #555;
    font-style: italic;
  }
  .news-v3-in-sm .posted-info li {
    padding: 0;
    font-size: 12px;
    padding-left: 5px;
  }
  .posted-info li:before, .news-v3-in-sm .posted-info li:before {
    content: '/';
  }
  .posted-info li:before {
    font-size: 12px;
    line-height: 12px;
    margin-right: 13px;
  }
  .news-v3-in-sm .posted-info li:before {
    font-size: 11px;
    line-height: 11px;
    margin-right: 9px;
  }
  .posted-info li:first-child:before, .news-v3-in-sm .posted-info li:first-child:before {
    content: " ";
    margin-right: 0;
  }
  .posted-info li a {
    color: #555;
    &:hover {
      color: $primary-accent;
    }
  }
  .post-shares {
    padding-left: 0;
    list-style: none;
    margin: 40px 0 0 -5px;
  }
  .news-v3-in-sm .post-shares {
    margin: 20px 0 0 -5px;
  }
  .post-shares li {
    position: relative;
    padding: 0 10px 0 5px;
    display: inline-block;
    i {
      color: #555;
      width: 40px;
      height: 40px;
      padding: 13px;
      font-size: 16px;
      background: #eee;
      text-align: center;
      display: inline-block;
      &:hover {
        background: #ddd;
      }
    }
    span {
      top: -5px;
      right: 6px;
      color: #fff;
      width: 22px;
      height: 22px;
      padding: 4px;
      font-size: 10px;
      line-height: 10px;
      position: absolute;
      text-align: center;
      background: $primary-accent;
      border: 2px solid #fff;
      border-radius: 50% !important;
    }
  }
  .post-shares-lg li span {
    top: -10px;
    right: 0px;
    width: 29px;
    height: 29px;
    padding: 4px;
    font-size: 10px;
    line-height: 16px;
  }
}

@media (max-width: 768px) {
  .news-v3 h2 {
    font-size: 22px;
    line-height: 30px;
  }
}