//bcrumbs
@media (max-width: $screen-xs-max) {
  .breadcrumbs {
    text-align: center;
    .page-title {
      font-size: 1.2em;
      margin: 0;
    }
    .breadcrumb {
      display: none!important;
    }
    .pull-left, .pull-right {
      float: none!important;
    }
  }
  .radix-moscone-collapse {
    padding: 0 15px;
  }
  #radix-collapse {
    margin-top: -4em;
    .nav-wrapper {
      padding-left: 4em;
    }
  }
  .tab-v1 .tabs--primary {
    border-color: $naac-blue;
    margin-top: -2.5em;
    text-align: center;
    li {
      max-width: 98%;
      float: none;
      display: block;
      margin: 0 auto;
    }
  }

}

