.comment {
  clear: both;
  margin: 0;
  padding: 1em 0;

  &.odd {
    background: rgba($naac-blue, 0.1); }

  .links {
    margin-top: 1em;
    font-size: 0.8em; }

  .indented {
    padding-left: 1em; }


  .picture-col {
    float: left;
    position: relative;
    z-index: 2;
    padding: 0 1em; }

  .content-col {
    padding-right: 1em; }

  .comments-itself {
    padding: 0;
    background: transparent;

    &:after {
      display: none; }

    span {
      float: none; }

    .links {
      float: right; }

    .field-name-comment-body {
      padding-top: 1em; } } }

.comment-form {
  margin-top: 1em;

  .form-group {
    margin-bottom: 0; }

  .form-type-fivestar {
    label, .form-type-select {
      display: inline-block; } }

  .form-submit {
    margin-top: 1em; } }
