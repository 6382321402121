.page-organization {
  .pane-title {
    text-align: left;
  }
  .radix-layouts-contentfooter {
    margin-top: 2em;
  }
}

.page-organization-people {
  .view-organizational-employees {
    margin: 0;
    .actions a {
      display: inline-block;
      padding-right: 0.5em;
    }
    .views-row {
      margin: 1em 0;
      @media(max-width: 990px) {
        .employee-card {
          .col-md-9 {
            padding: 1em 0;
          }
        }
      }
      &:nth-child(2n+1) {
        clear: both;
      }
      .nav-tabs {
        margin-bottom: 0;
      }
    }
  }
}

body.page-user-naac-certification {
    .btn.apply-credit {
    position: absolute;
    top: 0;
    right: 15px;
  }
}