.quiz-report {
  background: white;
}
.q-correct {
  background: #5CB85C;
  font-weight: bold;
  color: white;
  border-radius: 1em !important;
  overflow: .6em;
  border: .2em solid #96DC96;
}
.q-wrong {
  background: $naac-red;
  font-weight: bold;
  color: white;
  border-radius: 1em !important;
  overflow: hidden;
  margin: .6em;
  border: .2em solid lighten($naac-red, 10%);
}
.quiz_score {
  border: 1px solid #ECECEC;
  margin-bottom: .8em;
  span {
    width: 50%;
    display: inline-block;
    text-align: center;
    padding: .5em;
  }
  &.pass {
    span {
      &.text {
        background: #96DC96;
        color: #5CB85C;
        text-transform: uppercase;
        font-weight: 600;
      }
      &.score {
        background: #5CB85C;
        color: white;
      }
    }
  }
  &.fail {
    span {
      &.text {
        background: #D88D8D;
        color: $naac-red;
        text-transform: uppercase;
        font-weight: 600;
      }
      &.score {
        background: $naac-red;
        color: white;
      }
    }
  }
}

/*.outline-in {
  font-size: .9em;
  .quiz_score {
    span {
      display: block;
      width: 100%;
    }
  }
  .btn {
    font-size: 14px!important;
  }
}*/