#user-login-form, #user-register-form {
  padding: 0 2em;
  .form-item-name {
    &:before {
      font-family: fontawesome;
      content: '\f007';
      line-height: 40px;
      float: left;
      width: 12%;
      border-radius: 0;
      font-size: 1.5em;
      padding: 0 .5em;
      border: 1px solid #e1e1e1;
      box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
      border-right: 0;
      color: lighten($naac-blue, 10%);
    }
    input {
      width: 88%;
      display: inline-block;
    }
  }
  .form-type-password {
    &:before {
      font-family: fontawesome;
      content: '\f023';
      line-height: 40px;
      float: left;
      width: 12%;
      border-radius: 0;
      font-size: 1.5em;
      padding: 0 .5em;
      border: 1px solid #e1e1e1;
      box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
      border-right: 0;
      color: lighten($naac-blue, 10%);
    }
    input {
      width: 88%;
      display: inline-block;
    }
  }
  .form-item-mail {
    &:before {
      font-family: fontawesome;
      content: '\f0e0';
      line-height: 40px;
      float: left;
      width: 12%;
      border-radius: 0;
      font-size: 1.5em;
      padding: 0 .5em;
      border: 1px solid #e1e1e1;
      box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
      border-right: 0;
      color: lighten($naac-blue, 10%);
    }
    input {
      width: 88%;
      display: inline-block;
    }
  }

  button {
    margin-top: 1em;
    margin-right: 1%;
    background: $naac-red;
    border: darken($naac-red, 10%);
    color: white;
    font-family: "Cabin", Helvetica, Arial, sans-serif;
    font-size: 1.3em;
    transition: all .2s ease-in;
    display: block;
    width: 100%;
    height: 2.6em;
    &:hover {
      background: darken($naac-red, 6%);
    }
  }
  &#user-register-form {
    .form-item-name:before, .form-item-mail:before {
      color: $naac-red;
    }
    button {
      background: $naac-blue;
      border: darken($naac-blue, 10%);
      &:hover {
        background: darken($naac-blue, 6%);
      }
    }
  }



  ul.links {
    display: block;
    text-align: center;
    padding: 0;
    li {
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      list-style: none;
      display: inline-block;
      font-size: 1.1em;
      margin: 0;
      a {
        color: #999;
        transition: all .1s;
        transform: scale(1);
        padding: 1em .5em;
        margin: 0 .5em;
        &:hover {
          color: $naac-red;
          text-decoration: none;
          transform: scale(1.1);
        }
      }
      &.first {
        margin-top: 1em;
        margin-right: 1em;
      }
    }
  }
}

body.page-user-login {
  .col-md-6 {
    text-align: center;
  }
  .register {
    display: none;
  }
}