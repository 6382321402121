.page-calendar .tabs--primary {
  margin-left: 15px;
  margin-right: 15px; }

.calendar-calendar td.empty {
  color: lighten(#777, 15%); }

.calendar-calendar td a {
  transition: color 0.2s;
  &:hover {
    color: $naac-blue; } }

.page-calendar .breadcrumbs {
  padding: 0; }

.view-calendar {
  .view-header .pager {
    margin: 0;
    a {
      transition: background-color 0.2s, color 0.2s;
      &:hover {
        background: $naac-blue;
        border-color: #dfdfdf;
        color: #fff; } } }

  @media (max-width: 500px) {
    .pager.pager {
      margin-top: 16px;
      font-size: 0; }

    .date-prev.date-prev,
    .date-next.date-next {
      position: relative;
      display: inline-block;
      left: auto;
      right: 0; } }

  @media (min-width: 501px) {
    .pager.pager {
      margin-top: 0; }

    .date-prev.date-prev {
      position: absolute;
      right: 75px; }

    .date-next.date-next {
      position: absolute; } } }


.calendar-calendar td .inner div.day a {
  color: $naac-blue;
  transition: color 0.2s;

  &:hover {
    color: $naac-red;
    text-decoration: none; } }

.calendar-calendar .month-view .full td.date-box.today,
.calendar-calendar .month-view .full tr td.single-day.today {
  border-color: $naac-blue;
  border-color: rgba($naac-blue, 0.5); }

.calendar-calendar .day-view .full td.multi-day div.dayview a {
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: $naac-blue; } }

.node-type-calendar-event {
  .tabs--primary {
    margin-left: 15px;
    margin-right: 15px; } }


.node-calendar-event {
  .picture-col {
    padding-bottom: 1em; }
  .links, .field-name-body {
    margin-top: 1em; }
  @media (min-width: 360px) {
    .picture-col {
      @include make-xs-column(4);
      padding: 0; }
    .content-col {
      @include make-xs-column(8);
      padding-right: 0; } } }

// Block
.view-id-calendar.view-display-id-panel_pane_1 {
  .date-next.date-next {
    position: absolute; } }
