.commerce-add-to-cart-confirmation {
  position: fixed;
  top: 25%;
  width: 50%;
  left: 25%;
  z-index: 999;
  margin: 0;
  padding: 0;
  @media (max-width: $screen-xs-max) {
    width: 80%;
    left: 10%;
    top: 20%;
    font-size: .8em;
  }
  .message-inner {
    border: 1.5em solid rgba(255,255,255,.5);
    border-radius: 1em!important;
    .row {
      margin: 0;
      background: #efefef;
    }
    .added-product-title {
      text-align:center;
      font-size: 2em;
      font-family:'Lato', 'Open Sans', Arial, Helvetica, sans-serif;
      padding: .8em;
    }
    .product-wrapper {
      background: #E8E8E8;
      height: 150px;
      table-layout: fixed;
      @media (max-width: $screen-xs-max) {
        height: auto;
      }
    }
    .button-wrapper {
      position: absolute;
      right: 12px;
      top: 19px;
      bottom: 19px;
      padding: 0 3em;
      @media (max-width: $screen-xs-max) {
        position: static;
        padding: 0 2em;
      }
      .vert-wrap {
        height: 100%;
        width: 100%;
        @media (max-width: $screen-xs-max) {
          display: block;
          .vert-center {
            display: block;
            margin: 2em 0;
          }
        }
      }
      .button {
        a {
          color: $btn-success-color;
        }
      }
    }
    .view {
      padding-bottom: 2em;
    }
  }
}

div.commerce_add_to_cart_confirmation_overlay {
  background: transparent url('/sites/all/modules/contrib/commerce_add_to_cart_confirmation/images/bg_add_to_cart_overlay.png') left top;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 400;
}