/*Footer-v1
------------------------------------*/

.footer-v1 {
  .footer {
    padding: 40px 0;
    background: #272727;
    p {
      color: #eee;
    }
    h2 {
      color: #eee;
      font-size: 18px;
      font-weight: 200;
    }
  }
  .link-list li {
    border-top: solid 1px #353535;
  }
  address {
    color: #eee;
  }
  .link-list {
    a, i {
      color: #eee;
    }
  }
  address {
    line-height: 23px;
  }
  .footer .map-img {
    background: url('../images/bg/map-img.png') 5px 60px no-repeat;
  }
  .copyright {
    background: #222;
    border-top: none;
    padding: 10px 0 5px;
    p {
      color: #eee;
      margin-top: 14px;
    }
    a {
      margin: 0 2px;
    }
  }
  .footer-socials {
    margin-top: 12px;
    text-align: right;
    li a {
      color: #777;
      font-size: 16px;
    }
  }
}

/*Link List*/

/*Dotted Map*/

/*Copyright*/

@media (max-width: 991px) {
  .footer-v1 {
    .copyright, .footer-socials {
      text-align: left;
    }
  }
}