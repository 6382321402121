.node-live-course, .node-live-training {
  .list-inline.sub-heading {
    margin-bottom: 1em;
    div.list-item {
      display: inline;
      font-family: "Cabin", sans-serif;
      color: #666;
      font-weight: 600;
      .date-display-range {
        display: inline;
      }
      .addtocal {
        float: none;
        display: inline;
        margin-left: 2em;
        vertical-align: bottom;
        i {
          font-size: 1em;
          color: #9FC6E8;
          font-weight: 300;
          margin: 0 .5em 0 0;
        }
      }
      i {
        margin: 0 .25em;
        font-size: 1.1em;
        color: $naac-blue;
      }
      .field {
        display: inline-block;
        margin-right: 1em;
      }
    }
  }
  .date-display-range {

  }
  .links {
    margin-top: 2em;
    margin-bottom: 1em;
  }
}