/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/

/*Reset Styles
------------------------------------*/

* {
  border-radius: 0 !important;
}

a {
  outline: 0 !important;
  &:focus, &:hover, &:active {
    outline: 0 !important;
  }
}

button {
  outline: 0 !important;
  &:hover {
    outline: 0 !important;
  }
}

a:focus {
  text-decoration: none;
}

hr {
  margin: 30px 0;
  &.hr-xs {
    margin: 10px 0;
  }
  &.hr-md {
    margin: 20px 0;
  }
  &.hr-lg {
    margin: 40px 0;
  }
}

/*Headings*/

h1 {
  font-size: 28px;
  line-height: 35px;
}

h2 {
  font-size: 24px;
  line-height: 33px;
}

h3 {
  font-size: 20px;
  line-height: 27px;
}

h4 {
  line-height: 25px;
}

h5 {
  line-height: 20px;
}

h6 {
  line-height: 18px;
}

h1, h2, h3, h4, h5, h6 {
  color: #555;
  margin-top: 5px;
  text-shadow: none;
  font-weight: normal;
}

h1 i, h2 i, h3 i, h4 i, h5 i, h6 i {
  margin-right: 5px;
}

/*Block Headline*/

.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px dotted #e4e9f0;
  h2 {
    font-size: 22px;
    margin: 0 0 -2px 0;
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid $primary-accent;
  }
  h3, h4 {
    margin: 0 0 -2px 0;
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid $primary-accent;
  }
}

.headline-md {
  margin-bottom: 15px;
  h2 {
    font-size: 21px;
  }
}

/*Heading Options*/

.heading {
  text-align: center;
  h2 {
    padding: 0 12px;
    position: relative;
    display: inline-block;
    line-height: 34px !important;
    /*For Tagline Boxes*/
    &:before, &:after {
      content: ' ';
      width: 70%;
      position: absolute;
      border-width: 1px;
      border-color: #bbb;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }
}

@media (max-width: 768px) {
  .heading h2 {
    &:before, &:after {
      width: 20%;
    }
  }
}

/*Headline v1*/

.heading-v1 h2 {
  &:before, &:after {
    top: 15px;
    height: 6px;
    border-top-style: solid;
    border-bottom-style: solid;
  }
}

/*Headline v2*/

.heading-v2 h2 {
  &:before, &:after {
    top: 15px;
    height: 6px;
    border-top-style: dashed;
    border-bottom-style: dashed;
  }
}

/*Headline v3*/

.heading-v3 h2 {
  &:before, &:after {
    top: 15px;
    height: 6px;
    border-top-style: dotted;
    border-bottom-style: dotted;
  }
}

/*Headline v4*/

.heading-v4 h2 {
  &:before, &:after {
    top: 17px;
    border-bottom-style: solid;
  }
}

/*Headline v5*/

.heading-v5 h2 {
  &:before, &:after {
    top: 17px;
    border-bottom-style: dashed;
  }
}

/*Headline v6*/

.heading-v6 h2 {
  &:before, &:after {
    top: 17px;
    border-bottom-style: dotted;
  }
}

/*Heading Titles v1*/

.title-v1 {
  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 60px;
  h1, h2 {
    color: #444;
    font-size: 28px;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif;
  }
  h1:after, h2:after {
    bottom: 0;
    left: 50%;
    height: 1px;
    width: 70px;
    content: " ";
    margin-left: -35px;
    position: absolute;
    background: $primary-accent;
  }
  p {
    font-size: 17px;
    font-weight: 200;
  }
}

/*Heading Titles v2*/

h2.title-v2 {
  color: #555;
  position: relative;
  margin-bottom: 30px;
  &:after {
    left: 0;
    width: 70px;
    height: 2px;
    content: " ";
    bottom: -10px;
    background: #555;
    position: absolute;
  }
}

h1.title-v2.title-center, h2.title-v2.title-center, h3.title-v2.title-center {
  text-align: center;
}

h1.title-v2.title-center:after, h2.title-v2.title-center:after, h3.title-v2.title-center:after {
  left: 50%;
  width: 70px;
  margin-left: -35px;
}

h1.title-light, h2.title-light, h3.title-light {
  color: #fff;
}

h2.title-light:after {
  background: #fff;
}

/*Heading Title v3*/

h1[class^="title-v3-"], h2[class^="title-v3-"], h3[class^="title-v3-"] {
  color: #555;
}

h2.title-v3-xlg {
  font-size: 28px;
  line-height: 32px;
}

h1.title-v3-lg, h2.title-v3-lg {
  font-size: 24px;
  line-height: 28px;
}

h1.title-v3-md {
  font-size: 20px;
  line-height: 24px;
}

h2 {
  &.title-v3-md {
    font-size: 20px;
    line-height: 24px;
  }
  &.title-v3-sm {
    font-size: 18px;
    line-height: 24px;
  }
}

h3 {
  &.title-v3-md {
    font-size: 18px;
    line-height: 24px;
    line-height: 22px;
  }
  &.title-v3-sm {
    font-size: 16px;
    line-height: 20px;
  }
}

h2.title-v3-xs {
  font-size: 16px;
  line-height: 22px;
}

h3.title-v3-xs {
  font-size: 14px;
  margin-bottom: 0;
}

/*Headline Center*/

.headline-center {
  text-align: center;
  position: relative;
  h2 {
    color: #555;
    font-size: 24px;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 15px;
    &:after {
      left: 50%;
      z-index: 1;
      width: 30px;
      height: 2px;
      content: " ";
      bottom: -5px;
      margin-left: -15px;
      text-align: center;
      position: absolute;
      background: $primary-accent;
    }
  }
  p {
    /*color: #999;*/
    font-size: 14px;
    /*padding: 0 150px;*/
  }
  &.headline-light {
    h2 {
      color: #fff;
    }
    p {
      color: #eee;
    }
  }
}

@media (max-width: 991px) {
  .headline-center p {
    padding: 0 50px;
  }
}

/*Headline Center v2*/

.headline-center-v2 {
  z-index: 0;
  text-align: center;
  position: relative;
  h2 {
    color: #555;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  span.bordered-icon {
    color: #fff;
    padding: 0 10px;
    font-size: 15px;
    line-height: 18px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block;
    &:before, &:after {
      top: 8px;
      height: 1px;
      content: " ";
      width: 100px;
      background: #fff;
      position: absolute;
    }
    &:before {
      left: 100%;
    }
    &:after {
      right: 100%;
    }
  }
  p {
    color: #555;
    font-size: 14px;
    padding: 0 70px;
  }
  &.headline-center-v2-dark {
    p {
      color: #666;
    }
    span.bordered-icon {
      color: #666;
      &:before, &:after {
        background: #666;
      }
    }
  }
}

/*Headline Left*/

.headline-left {
  position: relative;
  .headline-brd {
    color: #555;
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 10px;
    &:after {
      left: 1px;
      z-index: 1;
      width: 30px;
      height: 2px;
      content: " ";
      bottom: -5px;
      position: absolute;
      background: $primary-accent;
    }
  }
}

/*Headline v2
------------------------------------*/

.headline-v2 {
  display: block;
  background: #fff;
  padding: 1px 10px;
  margin: 0 0 20px 0;
  border-left: 2px solid #000;
  h2 {
    margin: 3px 0;
    font-size: 20px;
    font-weight: 200;
  }
}

/*Heading Sizes
------------------------------------*/

h2 {
  &.heading-md {
    font-size: 20px;
    line-height: 24px;
  }
  &.heading-sm {
    font-size: 18px;
    line-height: 24px;
  }
}

h3 {
  &.heading-md {
    font-size: 18px;
    line-height: 24px;
    line-height: 22px;
  }
  &.heading-sm {
    font-size: 16px;
    line-height: 20px;
  }
}

h2.heading-xs {
  font-size: 16px;
  line-height: 22px;
}

h3.heading-xs {
  font-size: 14px;
  margin-bottom: 0;
}

/*Devider
------------------------------------*/

.devider {
  &.devider-dotted {
    border-top: 2px dotted #eee;
  }
  &.devider-dashed {
    border-top: 2px dashed #eee;
  }
  &.devider-db {
    height: 5px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  &.devider-db-dashed {
    height: 5px;
    border-top: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
  }
  &.devider-db-dotted {
    height: 5px;
    border-top: 1px dotted #ddd;
    border-bottom: 1px dotted #ddd;
  }
}

/*Tables
------------------------------------*/
/*Basic Tables*/

.table thead > tr > th {
  border-bottom: none;
}

@media (max-width: 768px) {
  .table {
    th.hidden-sm, td.hidden-sm {
      display: none !important;
    }
  }
}

/*Forms
------------------------------------*/

.form-control {
  box-shadow: none;
  &:focus {
    border-color: #bbb;
    box-shadow: 0 0 2px #c9c9c9;
  }
}

/*Form Spacing*/

.form-spacing .form-control {
  margin-bottom: 15px;
}

/*Form Icons*/

.input-group-addon {
  color: #b3b3b3;
  font-size: 14px;
  background: #fff;
}

/*Carousel v1
------------------------------------*/

.carousel-v1 {
  .carousel-caption {
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7px 15px;
    background: rgba(0, 0, 0, 0.7);
    p {
      color: #fff;
      margin-bottom: 0;
    }
  }
  .carousel-arrow a {
    &.carousel-control {
      opacity: 1;
      font-size: 30px;
      height: inherit;
      width: inherit;
      background: none;
      text-shadow: none;
      position: inherit;
    }
    i {
      top: 50%;
      opacity: 0.6;
      background: #000;
      margin-top: -18px;
      padding: 2px 12px;
      position: absolute;
      &:hover {
        opacity: 0.8;
      }
    }
    &.left i {
      left: 0;
    }
    &.right i {
      right: 0;
    }
  }
}

/*Carousel v2
------------------------------------*/

.carousel-v2 .carousel-control {
  opacity: 1;
  text-shadow: none;
  &:hover {
    opacity: 1;
    text-shadow: none;
  }
  &.left, &.right {
    top: 50%;
    z-index: 5;
    color: #eee;
    width: 45px;
    height: 45px;
    font-size: 30px;
    margin-top: -22px;
    position: absolute;
    text-align: center;
    display: inline-block;
    border: 2px solid #eee;
    background: rgba(0, 0, 0, 0.1);
  }
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
  .arrow-prev {
    top: -5px;
    position: relative;
  }
  .arrow-next {
    top: -5px;
    position: relative;
    right: -2px;
  }
}

@media (min-width: 768px) {
  .carousel-indicators {
    bottom: 10px;
  }
}

/*Tabs
------------------------------------*/
/*Tabs v1*/

.tab-v1 {
  .nav-tabs {
    border: none;
    background: none;
    border-bottom: solid 2px $primary-accent;
    a {
      font-size: 14px;
      padding: 5px 15px;
    }
    > {
      .active > a {
        color: #fff;
        border: none;
        background: $primary-accent;
        &:hover, &:focus {
          color: #fff;
          border: none;
          background: $primary-accent;
        }
      }
      li > a {
        border: none;
        &:hover {
          color: #fff;
          background: $primary-accent;
        }
      }
    }
  }
  .tab-content {
    padding: 10px 0;
    img {
      margin-top: 4px;
      margin-bottom: 15px;
      &.img-tab-space {
        margin-top: 7px;
      }
    }
  }
}

/*Tabs v2*/

.tab-v2 {
  .nav-tabs {
    border-bottom: none;
    li {
      a {
        padding: 9px 16px;
        background: none;
        border: none;
      }
      &.active a {
        background: #fff;
        padding: 7px 15px 9px;
        border: solid 1px #eee;
        border-top: solid 2px $primary-accent;
        border-bottom: none !important;
      }
    }
  }
  .tab-content {
    padding: 10px 16px;
    border: solid 1px #eee;
  }
}

/*Tabs v3*/

.tab-v3 {
  .nav-pills li {
    a {
      color: #777;
      font-size: 17px;
      padding: 4px 8px;
      margin-bottom: 3px;
      background: #fafafa;
      border: solid 1px #eee;
      &:hover {
        color: #fff;
        background: $primary-accent;
        border: solid 1px #68af28;
      }
    }
    &.active a {
      color: #fff;
      background: $primary-accent;
      border: solid 1px #68af28;
    }
    i {
      width: 1.25em;
      margin-right: 5px;
      text-align: center;
      display: inline-block;
    }
  }
  .tab-content {
    padding: 15px;
    background: #fafafa;
    border: solid 1px #eee;
  }
}

/*Accordions
------------------------------------*/
/*Accordion v1*/

.acc-v1 .panel-heading {
  padding: 0;
  box-shadow: none;
  a {
    display: block;
    font-size: 14px;
    padding: 5px 15px;
    background: #fefefe;
  }
}

.acc-icon a.accordion-toggle {
  i {
    color: #555;
    margin-right: 8px;
  }
  &:hover i {
    color: #39414c;
  }
}

/*Navigation
------------------------------------*/
/*Pegination*/

.pagination {
  li a {
    color: #777;
    padding: 5px 15px;
    &:hover {
      color: #fff;
      background: #5fb611;
      border-color: #5fb611;
    }
  }
  > .active > {
    a, span, a:hover, span:hover, a:focus, span:focus {
      border-color: $primary-accent;
      background-color: $primary-accent;
    }
  }
}

/*Pagination Without Space*/

.pagination-no-space .pagination {
  margin: 0;
}

/*Pager*/

.pager {
  li > a {
    &:hover, &:focus {
      color: #fff;
      background: #5fb611;
      border-color: #5fb611;
    }
  }
  &.pager-v2 li > a {
    border: none;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  &.pager-v3 li > a {
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  &.pager-v2 li > a {
    &:hover, &:focus {
      color: #fff;
      background: $primary-accent;
    }
  }
  &.pager-v3 li > a {
    &:hover, &:focus {
      color: #fff;
      background: $primary-accent;
    }
  }
  &.pager-v2 li.page-amount, &.pager-v3 li.page-amount {
    font-size: 16px;
    font-style: italic;
  }
  &.pager-v2 li.page-amount {
    top: 7px;
    color: #777;
    position: relative;
    &:hover, &:focus {
      top: 7px;
      color: #777;
      position: relative;
    }
  }
  &.pager-v3 li.page-amount {
    top: 7px;
    color: #777;
    position: relative;
    &:hover, &:focus {
      top: 7px;
      color: #777;
      position: relative;
    }
  }
  &.pager-v2.pager-md li a, &.pager-v3.pager-md li a {
    font-size: 16px;
    padding: 8px 18px;
  }
}

/*Pager v2 and v3
------------------------------------*/

/*Pager Amount*/

/*Pager Size*/

/*Sidebar Menu
------------------------------------*/
/*Sidebar Menu v1*/

.sidebar-nav-v1 {
  li {
    padding: 0;
    a {
      display: block;
      padding: 8px 30px 8px 10px;
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      padding: 0;
      list-style: none;
      background: #f8f8f8;
    }
    &.active ul a {
      background: #f8f8f8;
    }
    ul a {
      color: #555;
      font-size: 12px;
      border-top: solid 1px #ddd;
      padding: 6px 30px 6px 17px;
    }
  }
  > li.active {
    background: #717984;
    &:hover {
      background: #717984;
      border-color: #ddd;
    }
    border-color: #ddd;
    &:focus {
      border-color: #ddd;
    }
    > a {
      color: #fff;
    }
  }
  ul li {
    &:hover a, &.active a {
      color: $primary-accent;
    }
  }
}

/*Sidebar Sub Navigation*/

/*Sidebar Badges*/

.list-group-item li > .badge {
  float: right;
}

.sidebar-nav-v1 {
  span.badge {
    margin-top: 8px;
    margin-right: 10px;
  }
  .list-toggle > span.badge {
    margin-right: 25px;
  }
  ul li span.badge {
    margin-top: 8px;
    font-size: 11px;
    padding: 3px 5px;
    margin-right: 10px;
  }
}

/*Sidebar List Toggle*/

.list-toggle {
  &:after {
    top: 7px;
    right: 10px;
    color: #777;
    font-size: 14px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
  }
  &.active:after {
    color: #fff;
    content: "\f107";
  }
}

/*Button Styles
------------------------------------*/

.btn {
  box-shadow: none;
}

.btn-u {
  border: 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  padding: 6px 13px;
  position: relative;
  background: $naac-red;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  &.btn-block {
    text-align: center;
  }
}

a.btn-u {
  /*padding: 4px 13px;*/
  /*vertical-align: middle;*/
}

.btn-u-sm, a.btn-u-sm {
  padding: 3px 12px;
}

.btn-u-lg, a.btn-u-lg {
  font-size: 18px;
  padding: 10px 25px;
}

.btn-u-xs, a.btn-u-xs {
  font-size: 12px;
  padding: 2px 12px;
  line-height: 18px;
}

/*Button Groups*/

.btn-group {
  .dropdown-menu > li > a {
    padding: 3px 13px;
  }
  > .btn-u {
    float: left;
    position: relative;
  }
}

.btn-group-vertical > .btn-u {
  float: left;
  position: relative;
}

.btn-group > .btn-u:first-child {
  margin-left: 0;
}

/*For FF Only*/
@-moz-document url-prefix() {
  .footer-subsribe .btn-u {
    padding-bottom: 4px;
  }
}


@media (max-width: 768px) {
  @-moz-document url-prefix() {
    .btn-u {
      padding-bottom: 6px;
    }
  }
}

/*Buttons Color*/

.btn-u {
  &:hover, &:focus, &:active, &.active {
    background: $naac-blue;
    color: #fff;
  }
}

.open .dropdown-toggle.btn-u {
  background: $naac-blue;
}

.btn-u-split.dropdown-toggle {
  border-left: solid 1px $naac-blue;
}

.btn-u.btn-u-blue {
  background: #3498db;
  &:hover, &:focus, &:active, &.active {
    background: #2980b9;
  }
}

.open .dropdown-toggle.btn-u.btn-u-blue {
  background: #2980b9;
}

.btn-u {
  &.btn-u-split-blue.dropdown-toggle {
    border-left: solid 1px #2980b9;
  }
  &.btn-u-red {
    background: #e74c3c;
    &:hover, &:focus, &:active, &.active {
      background: #c0392b;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-red {
  background: #c0392b;
}

.btn-u {
  &.btn-u-split-red.dropdown-toggle {
    border-left: solid 1px #c0392b;
  }
  &.btn-u-orange {
    background: #e67e22;
    &:hover, &:focus, &:active, &.active {
      background: #d35400;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-orange {
  background: #d35400;
}

.btn-u {
  &.btn-u-split-orange.dropdown-toggle {
    border-left: solid 1px #d35400;
  }
  &.btn-u-sea {
    background: #1abc9c;
    &:hover, &:focus, &:active, &.active {
      background: #16a085;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-sea {
  background: #16a085;
}

.btn-u {
  &.btn-u-split-sea.dropdown-toggle {
    border-left: solid 1px #16a085;
  }
  &.btn-u-green {
    background: #2ecc71;
    &:hover, &:focus, &:active, &.active {
      background: #27ae60;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-green {
  background: #27ae60;
}

.btn-u {
  &.btn-u-split-green.dropdown-toggle {
    border-left: solid 1px #27ae60;
  }
  &.btn-u-yellow {
    background: #f1c40f;
    &:hover, &:focus, &:active, &.active {
      background: #f39c12;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-yellow {
  background: #f39c12;
}

.btn-u {
  &.btn-u-split-yellow.dropdown-toggle {
    border-left: solid 1px #f39c12;
  }
  &.btn-u-default {
    background: #95a5a6;
    &:hover, &:focus, &:active, &.active {
      background: #7f8c8d;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-default {
  background: #7f8c8d;
}

.btn-u {
  &.btn-u-split-default.dropdown-toggle {
    border-left: solid 1px #7f8c8d;
  }
  &.btn-u-purple {
    background: #9b6bcc;
    &:hover, &:focus, &:active, &.active {
      background: #814fb5;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-purple {
  background: #814fb5;
}

.btn-u {
  &.btn-u-split-purple.dropdown-toggle {
    border-left: solid 1px #814fb5;
  }
  &.btn-u-aqua {
    background: #27d7e7;
    &:hover, &:focus, &:active, &.active {
      background: #26bac8;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-aqua {
  background: #26bac8;
}

.btn-u {
  &.btn-u-split-aqua.dropdown-toggle {
    border-left: solid 1px #26bac8;
  }
  &.btn-u-brown {
    background: #9c8061;
    &:hover, &:focus, &:active, &.active {
      background: #81674b;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-brown {
  background: #81674b;
}

.btn-u {
  &.btn-u-split-brown.dropdown-toggle {
    border-left: solid 1px #81674b;
  }
  &.btn-u-dark-blue {
    background: #4765a0;
    &:hover, &:focus, &:active, &.active {
      background: #324c80;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-dark-blue {
  background: #324c80;
}

.btn-u {
  &.btn-u-split-dark.dropdown-toggle {
    border-left: solid 1px #324c80;
  }
  &.btn-u-light-green {
    background: #79d5b3;
    &:hover, &:focus, &:active, &.active {
      background: #59b795;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-light-green {
  background: #59b795;
}

.btn-u {
  &.btn-u-split-light-green.dropdown-toggle {
    border-left: solid 1px #59b795;
  }
  &.btn-u-dark {
    background: #555;
    &:hover, &:focus, &:active, &.active {
      background: #333;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-dark {
  background: #333;
}

.btn-u {
  &.btn-u-split-dark.dropdown-toggle {
    border-left: solid 1px #333;
  }
  &.btn-u-light-grey {
    background: #585f69;
    &:hover, &:focus, &:active, &.active {
      background: #484f58;
    }
  }
}

.open .dropdown-toggle.btn-u.btn-u-light-grey {
  background: #484f58;
}

.btn-u {
  &.btn-u-split-light-grey.dropdown-toggle {
    border-left: solid 1px #484f58;
  }
  &.btn-brd {
    color: #555;
    /*font-weight: 200;*/
    background: none;
    padding: 5px 13px;
    border: solid 1px transparent;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
    &:hover {
      background: none;
      border: solid 1px #eee;
    }
    &:focus {
      background: none;
    }
    &.btn-brd-hover:hover {
      color: #fff !important;
    }
    border-color: $primary-accent;
    &:hover {
      color: #5fb611;
      border-color: #5fb611;
    }
    &.btn-brd-hover:hover {
      background: #5fb611;
    }
    &.btn-u-blue {
      border-color: #3498db;
      &:hover {
        color: #2980b9;
        border-color: #2980b9;
      }
      &.btn-brd-hover:hover {
        background: #2980b9;
      }
    }
    &.btn-u-red {
      border-color: #e74c3c;
      &:hover {
        color: #c0392b;
        border-color: #c0392b;
      }
      &.btn-brd-hover:hover {
        background: #c0392b;
      }
    }
    &.btn-u-orange {
      border-color: #e67e22;
      &:hover {
        color: #d35400;
        border-color: #d35400;
      }
      &.btn-brd-hover:hover {
        background: #d35400;
      }
    }
    &.btn-u-sea {
      border-color: #1abc9c;
      &:hover {
        color: #16a085;
        border-color: #16a085;
      }
      &.btn-brd-hover:hover {
        background: #16a085;
      }
    }
    &.btn-u-green {
      border-color: #2ecc71;
      &:hover {
        color: #27ae60;
        border-color: #27ae60;
      }
      &.btn-brd-hover:hover {
        background: #27ae60;
      }
    }
    &.btn-u-yellow {
      border-color: #f1c40f;
      &:hover {
        color: #f39c12;
        border-color: #f39c12;
      }
      &.btn-brd-hover:hover {
        background: #f39c12;
      }
    }
    &.btn-u-default {
      border-color: #95a5a6;
      &:hover {
        color: #7f8c8d;
        border-color: #7f8c8d;
      }
      &.btn-brd-hover:hover {
        background: #7f8c8d;
      }
    }
    &.btn-u-dark {
      border-color: #555;
      &:hover {
        color: #333;
        border-color: #333;
      }
      &.btn-brd-hover:hover {
        background: #333;
      }
    }
    &.btn-u-light-grey {
      border-color: #585f69;
      &:hover {
        color: #484f58;
        border-color: #484f58;
      }
      &.btn-brd-hover:hover {
        background: #484f58;
      }
    }
    &.btn-u-purple {
      border-color: #9b6bcc;
      &:hover {
        color: #814fb5;
        border-color: #814fb5;
      }
      &.btn-brd-hover:hover {
        background: #814fb5;
      }
    }
    &.btn-u-aqua {
      border-color: #27d7e7;
      &:hover {
        color: #26bac8;
        border-color: #26bac8;
      }
      &.btn-brd-hover:hover {
        background: #26bac8;
      }
    }
    &.btn-u-brown {
      border-color: #9c8061;
      &:hover {
        color: #81674b;
        border-color: #81674b;
      }
      &.btn-brd-hover:hover {
        background: #81674b;
      }
    }
    &.btn-u-dark-blue {
      border-color: #4765a0;
      &:hover {
        color: #324c80;
        border-color: #324c80;
      }
      &.btn-brd-hover:hover {
        background: #324c80;
      }
    }
    &.btn-u-light-green {
      border-color: #79d5b3;
      &:hover {
        color: #59b795;
        border-color: #59b795;
      }
      &.btn-brd-hover:hover {
        background: #59b795;
      }
    }
    &.btn-u-light {
      color: #fff;
      border-color: #fff;
      &:hover {
        border-color: #fff;
      }
      &.btn-brd-hover:hover {
        background: #fff;
        color: #555 !important;
      }
    }
  }
}

/*Bordered Buttons*/

/*Dropdown Buttons
------------------------------------*/

.dropdown-show {
  box-shadow: 0 0 4px #eee;
  display: inline-block;
  position: relative;
}

/*Badges and Labels
------------------------------------*/
/*Labels*/

span {
  &.label {
    font-size: 11px;
    font-weight: 400;
    padding: 4px 7px;
  }
  &.badge {
    color: #fff;
    font-weight: 400;
    padding: 4px 7px;
  }
}

/*Badges*/

.list-group-item.active > .badge {
  color: #fff;
  font-weight: 400;
  padding: 4px 7px;
}

span {
  &.label-u, &.badge-u {
    background: $primary-accent;
  }
}

.list-group-item.active > .badge.badge-u {
  background: $primary-accent;
}

span {
  &.label-blue, &.badge-blue {
    background: #3498db;
  }
}

.list-group-item.active > .badge {
  background: #3498db;
}

span {
  &.label-red, &.badge-red {
    background: #e74c3c;
  }
}

.list-group-item.active > .badge {
  background: #e74c3c;
}

span {
  &.label-green, &.badge-green {
    background: #2ecc71;
  }
}

.list-group-item.active > .badge.badge-green {
  background: #2ecc71;
}

span {
  &.label-sea, &.badge-sea {
    background: #1abc9c;
  }
}

.list-group-item.active > .badge.badge-sea {
  background: #1abc9c;
}

span {
  &.label-orange, &.badge-orange {
    background: #e67e22;
  }
}

.list-group-item.active > .badge.badge-orange {
  background: #e67e22;
}

span {
  &.label-yellow, &.badge-yellow {
    background: #f1c40f;
  }
}

.list-group-item.active > .badge.badge-yellow {
  background: #f1c40f;
}

span {
  &.label-purple, &.badge-purple {
    background: #9b6bcc;
  }
}

.list-group-item.active > .badge.badge-purple {
  background: #9b6bcc;
}

span {
  &.label-aqua, &.badge-aqua {
    background: #27d7e7;
  }
}

.list-group-item.active > .badge.badge-aqua {
  background: #27d7e7;
}

span {
  &.label-brown, &.badge-brown {
    background: #9c8061;
  }
}

.list-group-item.active > .badge.badge-brown {
  background: #9c8061;
}

span {
  &.label-dark-blue, &.badge-dark-blue {
    background: #4765a0;
  }
}

.list-group-item.active > .badge.badge-dark-blue {
  background: #4765a0;
}

span {
  &.label-light-green, &.badge-light-green {
    background: #79d5b3;
  }
}

.list-group-item.active > .badge.badge-light-green {
  background: #79d5b3;
}

span {
  &.label-light, &.badge-light {
    color: #777;
    background: #ecf0f1;
  }
}

.list-group-item.active > .badge.badge-light {
  color: #777;
  background: #ecf0f1;
}

span {
  &.label-dark, &.badge-dark {
    background: #555;
  }
}

.list-group-item.active > .badge.badge-dark {
  background: #555;
}

/*Badge Lists*/

.badge-lists {
  li {
    position: relative;
  }
  span.badge {
    top: -10px;
    right: -6px;
    position: absolute;
  }
  &.badge-icons {
    span.badge {
      min-width: 12px;
      padding: 3px 6px;
    }
    i {
      font-size: 18px;
      min-width: 25px;
    }
  }
}

/*Badge Icons*/

/*Badge Box v1*/

.badge-box-v1 a {
  color: #777;
  min-width: 40px;
  font-size: 18px;
  padding: 8px 9px;
  display: inline-block;
  border: solid 1px #eee;
}

/*Badge Box v2*/

.badge-box-v2 a {
  color: #777;
  font-size: 12px;
  padding: 10px;
  min-width: 70px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eee;
  i {
    font-size: 20px;
  }
}

/*General Badge Box*/

.badge-box-v1 a i, .badge-box-v2 a i {
  display: block;
  margin: 1px auto 2px;
}

.badge-box-v1 a:hover, .badge-box-v2 a:hover {
  color: #555;
  border-color: #555;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

/*Icons
------------------------------------*/
/*Social Icons*/

.social-icons {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    margin-right: 3px;
    margin-bottom: 5px;
    text-indent: -9999px;
    display: inline-block;
    a {
      width: 28px;
      height: 28px;
      display: block;
      background-position: 0 0;
      background-repeat: no-repeat;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
    }
  }
}

a.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.social-icons li:hover a {
  background-position: 0 -38px;
}

.social-icons-color li a {
  opacity: 0.7;
  background-position: 0 -38px !important;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/
  &:hover {
    opacity: 1;
  }
}

.social_amazon {
  background: url(../img/icons/social/amazon.png) no-repeat;
}

.social_behance {
  background: url(../img/icons/social/behance.png) no-repeat;
}

.social_blogger {
  background: url(../img/icons/social/blogger.png) no-repeat;
}

.social_deviantart {
  background: url(../img/icons/social/deviantart.png) no-repeat;
}

.social_dribbble {
  background: url(../img/icons/social/dribbble.png) no-repeat;
}

.social_dropbox {
  background: url(../img/icons/social/dropbox.png) no-repeat;
}

.social_evernote {
  background: url(../img/icons/social/evernote.png) no-repeat;
}

.social_facebook {
  background: url(../img/icons/social/facebook.png) no-repeat;
}

.social_forrst {
  background: url(../img/icons/social/forrst.png) no-repeat;
}

.social_github {
  background: url(../img/icons/social/github.png) no-repeat;
}

.social_googleplus {
  background: url(../img/icons/social/googleplus.png) no-repeat;
}

.social_jolicloud {
  background: url(../img/icons/social/jolicloud.png) no-repeat;
}

.social_last-fm {
  background: url(../img/icons/social/last-fm.png) no-repeat;
}

.social_linkedin {
  background: url(../img/icons/social/linkedin.png) no-repeat;
}

.social_picasa {
  background: url(../img/icons/social/picasa.png) no-repeat;
}

.social_pintrest {
  background: url(../img/icons/social/pintrest.png) no-repeat;
}

.social_rss {
  background: url(../img/icons/social/rss.png) no-repeat;
}

.social_skype {
  background: url(../img/icons/social/skype.png) no-repeat;
}

.social_spotify {
  background: url(../img/icons/social/spotify.png) no-repeat;
}

.social_stumbleupon {
  background: url(../img/icons/social/stumbleupon.png) no-repeat;
}

.social_tumblr {
  background: url(../img/icons/social/tumblr.png) no-repeat;
}

.social_twitter {
  background: url(../img/icons/social/twitter.png) no-repeat;
}

.social_vimeo {
  background: url(../img/icons/social/vimeo.png) no-repeat;
}

.social_wordpress {
  background: url(../img/icons/social/wordpress.png) no-repeat;
}

.social_xing {
  background: url(../img/icons/social/xing.png) no-repeat;
}

.social_yahoo {
  background: url(../img/icons/social/yahoo.png) no-repeat;
}

.social_youtube {
  background: url(../img/icons/social/youtube.png) no-repeat;
}

.social_vk {
  background: url(../img/icons/social/vk.png) no-repeat;
}

.social_instagram {
  background: url(../img/icons/social/instagram.png) no-repeat;
}

/*Font Awesome Icon Styles*/

i {
  &.icon-custom {
    color: #555;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 5px;
    text-align: center;
    display: inline-block;
    border: solid 1px #555;
  }
  &.icon-sm {
    width: 35px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
  }
  &.icon-md {
    width: 55px;
    height: 55px;
    font-size: 22px;
    line-height: 55px;
  }
  &.icon-lg {
    width: 60px;
    height: 60px;
    font-size: 31px;
    line-height: 60px;
    margin-bottom: 10px;
  }
  &.icon-2x {
    font-size: 30px;
  }
  &.icon-3x {
    font-size: 40px;
  }
  &.icon-4x {
    font-size: 50px;
  }
  &.icon-line {
    font-size: 17px;
  }
  &.icon-sm.icon-line {
    font-size: 14px;
  }
  &.icon-md.icon-line {
    font-size: 22px;
  }
  &.icon-lg.icon-line {
    font-size: 28px;
  }
  &.icon-2x.icon-line {
    font-size: 27px;
  }
  &.icon-3x.icon-line {
    font-size: 36px;
  }
  &.icon-4x.icon-line {
    font-size: 47px;
  }
}

/*Line Icons*/

/*Icon Styles For Links*/

.link-icon, .link-bg-icon {
  color: #555;
}

.link-icon:hover, .link-bg-icon:hover {
  border: none;
  text-decoration: none;
}

.link-icon:hover i {
  color: $primary-accent;
  background: none;
  border: solid 1px $primary-accent;
}

.link-bg-icon:hover i {
  color: $primary-accent;
  background: $primary-accent;
  border-color: $primary-accent;
  color: #fff !important;
}

/*Icons Color*/

i {
  &.icon-color-u, &.icon-color-red, &.icon-color-sea, &.icon-color-dark, &.icon-color-grey, &.icon-color-blue, &.icon-color-green, &.icon-color-yellow, &.icon-color-orange, &.icon-color-purple, &.icon-color-aqua, &.icon-color-brown, &.icon-color-dark-blue, &.icon-color-light-grey, &.icon-color-light-green {
    background: none;
  }
  &.icon-color-u {
    color: $primary-accent;
    border: solid 1px $primary-accent;
  }
  &.icon-color-blue {
    color: #3498db;
    border: solid 1px #3498db;
  }
  &.icon-color-red {
    color: #e74c3c;
    border: solid 1px #e74c3c;
  }
  &.icon-color-sea {
    color: #1abc9c;
    border: solid 1px #1abc9c;
  }
  &.icon-color-green {
    color: #2ecc71;
    border: solid 1px #2ecc71;
  }
  &.icon-color-yellow {
    color: #f1c40f;
    border: solid 1px #f1c40f;
  }
  &.icon-color-orange {
    color: #e67e22;
    border: solid 1px #e67e22;
  }
  &.icon-color-grey {
    color: #95a5a6;
    border: solid 1px #95a5a6;
  }
  &.icon-color-purple {
    color: #9b6bcc;
    border: solid 1px #9b6bcc;
  }
  &.icon-color-aqua {
    color: #27d7e7;
    border: solid 1px #27d7e7;
  }
  &.icon-color-brown {
    color: #9c8061;
    border: solid 1px #9c8061;
  }
  &.icon-color-dark-blue {
    color: #4765a0;
    border: solid 1px #4765a0;
  }
  &.icon-color-light-green {
    color: #79d5b3;
    border: solid 1px #79d5b3;
  }
  &.icon-color-light {
    color: #fff;
    border: solid 1px #fff;
  }
  &.icon-color-light-grey {
    color: #585f69;
    border: solid 1px #585f69;
  }
  &.icon-bg-u, &.icon-bg-red, &.icon-bg-sea, &.icon-bg-dark, &.icon-bg-darker, &.icon-bg-grey, &.icon-bg-blue, &.icon-bg-green, &.icon-bg-yellow, &.icon-bg-orange, &.icon-bg-purple, &.icon-bg-aqua, &.icon-bg-brown, &.icon-bg-dark-blue, &.icon-bg-light-grey, &.icon-bg-light-green {
    color: #fff;
    border-color: transparent;
  }
  &.icon-bg-u {
    background: $primary-accent;
  }
  &.icon-bg-blue {
    background: #3498db;
  }
  &.icon-bg-red {
    background: #e74c3c;
  }
  &.icon-bg-sea {
    background: #1abc9c;
  }
  &.icon-bg-green {
    background: #2ecc71;
  }
  &.icon-bg-yellow {
    background: #f1c40f;
  }
  &.icon-bg-orange {
    background: #e67e22;
  }
  &.icon-bg-grey {
    background: #95a5a6;
  }
  &.icon-bg-dark {
    background: #555;
  }
  &.icon-bg-darker {
    background: #333;
  }
  &.icon-bg-purple {
    background: #9b6bcc;
  }
  &.icon-bg-aqua {
    background: #27d7e7;
  }
  &.icon-bg-brown {
    background: #9c8061;
  }
  &.icon-bg-dark-blue {
    background: #4765a0;
  }
  &.icon-bg-light-green {
    background: #79d5b3;
  }
  &.icon-bg-light {
    background: #fff;
    border-color: transparent;
  }
  &.icon-bg-light-grey {
    background: #585f69;
    border-color: transparent;
  }
}

/*Icons Backgroun Color*/

/* Make Font Awesome icons fixed width */

.fa-fixed {
  [class^="fa"], [class*=" fa"] {
    width: 1.25em;
    text-align: center;
    display: inline-block;
  }
  [class^="fa"].fa-lg, [class*=" fa"].fa-lg {
    /* increased font size for fa-lg */
    width: 1.5625em;
  }
}

/*Content Boxes
------------------------------------*/
/*Content Boxes v1*/

.content-boxes-v1 {
  text-align: center;
  span {
    display: block;
    margin-top: 5px;
  }
}

/*Content Boxes v2*/
@media (max-width: 992px) {
  .content-boxes-v2 {
    text-align: center;
    .text-justify {
      text-align: center;
    }
    span {
      display: block;
      margin-top: 5px;
    }
  }
}

/*Content Boxes v3*/

.content-boxes-v3 {
  i.icon-custom {
    top: 8px;
    float: left;
    position: relative;
  }
  .content-boxes-in-v3 {
    padding: 0 10px;
    overflow: hidden;
    h3 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 3px;
      text-transform: capitalize;
      a {
        color: #555;
      }
    }
  }
  &.content-boxes-v3-right {
    text-align: right;
    i.icon-custom {
      float: right;
      margin-left: 10px;
    }
  }
}

/*Content Boxes Right v3*/

@media (max-width: 768px) {
  .content-boxes-v3.content-boxes-v3-right {
    text-align: inherit;
    i.icon-custom {
      float: left;
      margin-left: 0;
    }
  }
}

/*Content Boxes v4*/

.content-boxes-v4 {
  h2 {
    color: #555;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  a {
    color: #777;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
  i {
    width: 25px;
    color: $primary-accent;
    font-size: 35px;
    margin-top: 10px;
  }
}

.content-boxes-in-v4 {
  padding: 0 10px;
  overflow: hidden;
}

.content-boxes-v4-sm i {
  font-size: 26px;
  margin-top: 10px;
  margin-right: 5px;
}

/*Content Boxes v5*/

.content-boxes-v5 {
  i {
    float: left;
    color: #999;
    width: 50px;
    height: 50px;
    padding: 11px;
    font-size: 22px;
    background: #eee;
    line-height: 28px;
    text-align: center;
    margin-right: 15px;
    display: inline-block;
  }
  &:hover i {
    color: #fff;
    background: $primary-accent;
  }
}

/*Content Boxes v6*/

.content-boxes-v6 {
  padding-top: 25px;
  text-align: center;
  i {
    color: #fff;
    width: 90px;
    height: 90px;
    padding: 30px;
    font-size: 30px;
    line-height: 30px;
    position: relative;
    text-align: center;
    background: #dedede;
    margin-bottom: 25px;
    display: inline-block;
    &:after {
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      content: " ";
      position: absolute;
      border: 1px solid #dedede;
      border-radius: 50% !important;
    }
  }
  &:hover i {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:after {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      border-color: $primary-accent;
    }
    background: $primary-accent;
  }
}

/*Colored Content Boxes
------------------------------------*/

.service-block {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 20px;
  p {
    color: #fff;
  }
  h2 {
    color: #fff;
    a:hover {
      text-decoration: none;
    }
  }
}

.service-block-light {
  background: #fafafa;
  border: solid 1px #eee;
}

.service-block-default {
  background: #fafafa;
  border: solid 1px #eee;
  &:hover {
    box-shadow: 0 0 8px #eee;
  }
}

.service-block-light {
  p, h2 {
    color: #555;
  }
}

.service-block-default {
  p, h2 {
    color: #555;
  }
}

.service-block-u {
  background: $primary-accent;
}

.service-block-blue {
  background: #3498db;
}

.service-block-red {
  background: #e74c3c;
}

.service-block-sea {
  background: #1abc9c;
}

.service-block-grey {
  background: #95a5a6;
}

.service-block-yellow {
  background: #f1c40f;
}

.service-block-orange {
  background: #e67e22;
}

.service-block-green {
  background: #2ecc71;
}

.service-block-purple {
  background: #9b6bcc;
}

.service-block-aqua {
  background: #27d7e7;
}

.service-block-brown {
  background: #9c8061;
}

.service-block-dark-blue {
  background: #4765a0;
}

.service-block-light-green {
  background: #79d5b3;
}

.service-block-dark {
  background: #555;
}

.service-block-light {
  background: #ecf0f1;
}

/*Funny Boxes
------------------------------------*/

.funny-boxes {
  background: #f7f7f7;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  h2 {
    margin-top: 0;
    font-size: 18px;
    line-height: 20px;
    a {
      color: #555;
    }
  }
  p a {
    color: $primary-accent;
  }
  .funny-boxes-img {
    li {
      font-size: 12px;
      margin-bottom: 2px;
      i {
        color: $primary-accent;
        font-size: 12px;
        margin-right: 5px;
      }
    }
    img {
      margin: 5px 10px 15px 0;
    }
  }
  ul.funny-boxes-rating li {
    display: inline-block;
    i {
      color: #f8be2c;
      cursor: pointer;
      font-size: 14px;
      &:hover {
        color: #f8be2c;
      }
    }
  }
}

@media (max-width: 992px) {
  .funny-boxes .funny-boxes-img li {
    display: inline-block;
  }
}

/*Funny Colored Boxes*/

.funny-boxes-colored {
  p, h2 a {
    color: #fff;
  }
  .funny-boxes-img li {
    color: #fff;
    i {
      color: #fff;
    }
  }
}

/*Red Funny Box*/

.funny-boxes-red {
  background: #e74c3c;
}

/*Dark Red Funny Box*/

.funny-boxes-purple {
  background: #9b6bcc;
}

/*Blue Funny Box*/

.funny-boxes-blue {
  background: #3498db;
}

/*Grey Funny Box*/

.funny-boxes-grey {
  background: #95a5a6;
}

/*Turquoise Funny Box*/

.funny-boxes-sea {
  background: #1abc9c;
}

/*Turquoise Top Bordered Funny Box*/

.funny-boxes-top-sea {
  border-top: solid 2px #1abc9c;
  &:hover {
    border-top-color: #16a085;
  }
}

/*Yellow Top Bordered Funny Box**/

.funny-boxes-top-yellow {
  border-top: solid 2px #f1c40f;
  &:hover {
    border-top-color: #f39c12;
  }
}

/*Red Top Bordered Funny Box**/

.funny-boxes-top-red {
  border-top: solid 2px #e74c3c;
  &:hover {
    border-top-color: #c0392b;
  }
}

/*Purple Top Bordered Funny Box**/

.funny-boxes-top-purple {
  border-top: solid 2px #9b6bcc;
  &:hover {
    border-top-color: #814fb5;
  }
}

/*Orange Left Bordered Funny Box**/

.funny-boxes-left-orange {
  border-left: solid 2px #e67e22;
  &:hover {
    border-left-color: #d35400;
  }
}

/*Green Left Bordered Funny Box**/

.funny-boxes-left-green {
  border-left: solid 2px $primary-accent;
  &:hover {
    border-left-color: #5fb611;
  }
}

/*Blue Left Bordered Funny Box**/

.funny-boxes-left-blue {
  border-left: solid 2px #3498db;
  &:hover {
    border-left-color: #2980b9;
  }
}

/*Dark Left Bordered Funny Box**/

.funny-boxes-left-dark {
  border-left: solid 2px #555;
  &:hover {
    border-left-color: #333;
  }
}

/*Typography
------------------------------------*/

.text-justify p {
  text-align: justify;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-normal {
  text-transform: inherit !important;
}

.font-bold {
  font-weight: 600;
}

.font-light {
  font-weight: 200;
}

.font-normal {
  font-weight: 400 !important;
}

/*Text Dropcap*/

.dropcap {
  float: left;
  color: $primary-accent;
  padding: 5px 0;
  font-size: 45px;
  font-weight: 200;
  line-height: 30px;
  margin: 0px 5px 0 0;
}

.dropcap-bg {
  float: left;
  color: #fff;
  padding: 7px 0;
  min-width: 50px;
  font-size: 35px;
  font-weight: 200;
  line-height: 35px;
  text-align: center;
  background: $primary-accent;
  margin: 4px 10px 0 0;
}

/*Text Highlights*/

.text-highlights {
  color: #fff;
  font-weight: 200;
  padding: 0px 5px;
  background: #555;
}

.text-highlights-green {
  background: $primary-accent;
}

.text-highlights-blue {
  background: #3498db;
}

.text-highlights-red {
  background: #e74c3c;
}

.text-highlights-sea {
  background: #1abc9c;
}

.text-highlights-orange {
  background: #e67e22;
}

.text-highlights-yellow {
  background: #f1c40f;
}

.text-highlights-purple {
  background: #9b6bcc;
}

.text-highlights-aqua {
  background: #27d7e7;
}

.text-highlights-brown {
  background: #9c8061;
}

.text-highlights-dark-blue {
  background: #4765a0;
}

.text-highlights-light-green {
  background: #79d5b3;
}

/*Text Borders*/

.text-border {
  border-bottom: dashed 1px #555;
}

.text-border-default {
  border-color: #95a5a6;
}

.text-border-green {
  border-color: $primary-accent;
}

.text-border-blue {
  border-color: #3498db;
}

.text-border-red {
  border-color: #e74c3c;
}

.text-border-yellow {
  border-color: #f1c40f;
}

.text-border-purple {
  border-color: #9b6bcc;
}

/*List Styles*/

.list-styles li {
  margin-bottom: 8px;
}

/*Contextual Backgrounds*/

.contex-bg p {
  opacity: 0.8;
  padding: 8px 10px;
  &:hover {
    opacity: 1;
  }
}

/*Blockquote*/

blockquote {
  padding: 5px 15px;
  border-left-width: 2px;
  p {
    font-size: 14px;
    font-weight: 400;
  }
  h1, h2, span {
    font-size: 18px;
    margin: 0 0 8px;
    line-height: 24px;
  }
  &.bq-text-lg {
    p, small {
      text-transform: uppercase;
    }
    p {
      font-size: 22px;
      font-weight: 300;
      line-height: 32px;
    }
  }
  &.text-right {
    border-left: none;
    border-right: 2px solid #eee;
  }
  &.hero.text-right {
    border-left: none;
    border-right: 2px solid #eee;
    border-color: #555;
    &:hover {
      border-color: #555;
    }
  }
  &:hover, &.text-right:hover {
    border-color: $primary-accent;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &.bq-dark {
    border-color: #585f69;
    &:hover {
      border-color: #585f69;
    }
  }
  &.bq-green {
    border-color: $primary-accent;
  }
  &.hero {
    border: none;
    padding: 18px;
    font-size: 16px;
    background: #f3f3f3;
    border-left: solid 2px #666;
    &:hover {
      background: #eee;
      border-left-color: #666;
    }
    &.hero-dark, &.hero-default {
      border: none;
    }
    &.hero-dark {
      background: #444;
      &:hover {
        background: #555;
      }
    }
    &.hero-default {
      background: $primary-accent;
      &:hover {
        background: #5fb611;
      }
    }
    &.hero-dark {
      p, h2, small {
        color: #fff;
        font-weight: 200;
      }
    }
    &.hero-default {
      p, h2, small {
        color: #fff;
        font-weight: 200;
      }
    }
  }
}

/*Blockquote Styles*/

/*Blockquote Hero Styles*/

/*Tag Boxes
------------------------------------*/

.tag-box {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px;
  h2 {
    font-size: 20px;
    line-height: 25px;
  }
  p {
    margin-bottom: 0;
  }
  &.tag-text-space p {
    margin-bottom: 10px;
  }
}

/*Tag Boxes v1*/

.tag-box-v1 {
  border: solid 1px #eee;
  border-top: solid 2px $primary-accent;
}

/*Tag Boxes v2*/

.tag-box-v2 {
  background: #fafafa;
  border: solid 1px #eee;
  border-left: solid 2px $primary-accent;
}

/*Tag Boxes v3*/

.tag-box-v3 {
  border: solid 2px #eee;
}

/*Tag Boxes v4*/

.tag-box-v4 {
  border: dashed 1px #bbb;
}

/*Tag Boxes v5*/

.tag-box-v5 {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc;
  span {
    color: #555;
    font-size: 28px;
    margin-bottom: 0;
  }
}

/*Tag Boxes v6*/

.tag-box-v6 {
  background: #fafafa;
  border: solid 1px #eee;
}

/*Tag Boxes v7*/

.tag-box-v7 {
  border: solid 1px #eee;
  border-bottom: solid 2px $primary-accent;
}

/*Testimonials
------------------------------------*/
/*Testimonials*/

.testimonials {
  margin-bottom: 10px;
  .testimonial-info {
    color: $primary-accent;
    font-size: 16px;
    padding: 0 15px;
    margin-top: 18px;
    span {
      top: 3px;
      position: relative;
    }
    em {
      color: #777;
      display: block;
      font-size: 13px;
    }
    img {
      width: 60px;
      float: left;
      height: 60px;
      padding: 2px;
      margin-right: 15px;
      border: solid 1px #ccc;
    }
  }
  .testimonial-author {
    overflow: hidden;
  }
  .carousel-arrow {
    top: -65px;
    position: relative;
    i {
      color: #777;
      padding: 2px;
      min-width: 25px;
      font-size: 20px;
      text-align: center;
      background: #f5f5f5;
      &:hover {
        color: #fff;
        background: $primary-accent;
      }
    }
  }
  .carousel-control {
    opacity: 1;
    width: 100%;
    text-align: right;
    text-shadow: none;
    position: absolute;
    filter: Alpha(opacity = 100);
    /*For IE*/
    &.left {
      right: 27px;
      left: auto;
    }
    &.right {
      right: 0px;
    }
  }
  &.testimonials-v1 .item p {
    position: relative;
    &:after, &:before {
      left: 80px;
      bottom: -20px;
    }
    &:after {
      border-top: 22px solid;
      border-left: 0 solid transparent;
      border-right: 22px solid transparent;
    }
  }
  &.testimonials-v2 {
    .testimonial-info {
      padding: 0 20px;
    }
    p {
      padding-bottom: 15px;
    }
    .carousel-arrow {
      top: -55px;
    }
    .item p {
      &:after, &:before {
        left: 8%;
        bottom: 45px;
      }
      &:after {
        border-top: 20px solid;
        border-left: 25px solid transparent;
        border-right: 0px solid transparent;
      }
    }
  }
  &.testimonials-v1 p, &.testimonials-v2 p {
    padding: 15px;
    font-size: 14px;
    font-style: italic;
    background: #f5f5f5;
  }
  &.testimonials-v1 .item p:after, &.testimonials-v2 .item p:after {
    width: 0;
    height: 0;
    content: " ";
    display: block;
    position: absolute;
    border-top-color: #f5f5f5;
    border-left-style: inset;
    /*FF fixes*/
    border-right-style: inset;
    /*FF fixes*/
  }
}

/*Testimonials v1*/

/*Testimonials v2*/

/*General Testimonials v1/v2*/

/*Testimonials Backgrounds*/

.testimonials-bg-dark .item p, .testimonials-bg-default .item p {
  color: #fff;
  font-weight: 200;
}

.testimonials-bg-dark .carousel-arrow i {
  color: #fff;
}

.testimonials-bg-default {
  .carousel-arrow i {
    color: #fff;
  }
  .item p {
    background: $primary-accent;
  }
}

/*Testimonials Default*/

.testimonials.testimonials-bg-default .item p:after {
  border-top-color: $primary-accent;
}

.testimonials-bg-default .carousel-arrow i {
  background: $primary-accent;
}

.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: #5fb611;
}

/*Testimonials Dark*/

.testimonials-bg-dark .item p {
  background: #555;
}

.testimonials.testimonials-bg-dark .item p:after {
  border-top-color: #555;
}

.testimonials-bg-dark .carousel-arrow i {
  color: #fff;
  background: #555;
}

.testimonials.testimonials-bg-dark {
  .carousel-arrow i:hover {
    background: #333;
  }
  .testimonial-info {
    color: #555;
  }
}

/*Panels (Portlets)
------------------------------------*/

.panel-heading {
  color: #fff;
  padding: 5px 15px;
}

/*Panel Table*/

.panel .table {
  margin-bottom: 0;
}

/*Panel Unify*/

.panel-u {
  border-color: $primary-accent;
  > .panel-heading {
    background: $primary-accent;
  }
}

/*Panel Blue*/

.panel-blue {
  border-color: #3498db;
  > .panel-heading {
    background: #3498db;
  }
}

/*Panel Red*/

.panel-red {
  border-color: #e74c3c;
  > .panel-heading {
    background: #e74c3c;
  }
}

/*Panel Green*/

.panel-green {
  border-color: #2ecc71;
  > .panel-heading {
    background: #2ecc71;
  }
}

/*Panel Sea*/

.panel-sea {
  border-color: #1abc9c;
  > .panel-heading {
    background: #1abc9c;
  }
}

/*Panel Orange*/

.panel-orange {
  border-color: #e67e22;
  > .panel-heading {
    background: #e67e22;
  }
}

/*Panel Yellow*/

.panel-yellow {
  border-color: #f1c40f;
  > .panel-heading {
    background: #f1c40f;
  }
}

/*Panel Grey*/

.panel-grey {
  border-color: #95a5a6;
  > .panel-heading {
    background: #95a5a6;
  }
}

/*Panel Dark*/

.panel-dark {
  border-color: #555;
  > .panel-heading {
    background: #555;
  }
}

/*Panel Purple*/

.panel-purple {
  border-color: #9b6bcc;
  > .panel-heading {
    background: #9b6bcc;
  }
}

/*Panel Aqua*/

.panel-aqua {
  border-color: #27d7e7;
  > .panel-heading {
    background: #27d7e7;
  }
}

/*Panel Brown*/

.panel-brown {
  border-color: #9c8061;
  > .panel-heading {
    background: #9c8061;
  }
}

/*Panel Dark Blue*/

.panel-dark-blue {
  border-color: #4765a0;
  > .panel-heading {
    background: #4765a0;
  }
}

/*Panel Light Green*/

.panel-light-green {
  border-color: #79d5b3;
  > .panel-heading {
    background: #79d5b3;
  }
}

/*Panel Default Dark*/

.panel-default-dark {
  border-color: #585f69;
  > .panel-heading {
    background: #585f69;
  }
}

/*Progress Bar
------------------------------------*/

.progress-u {
  box-shadow: none;
  .progress-bar {
    box-shadow: none;
  }
}

/*progress-bar (sizes)*/

.progress-lg {
  height: 25px;
  p {
    padding-top: 3px;
  }
}

.progress-sm {
  height: 12px;
}

.progress-xs {
  height: 7px;
}

.progress-xxs {
  height: 3px;
}

/*progress-bar (colors)*/

.progress {
  background: #e5e5e5;
}

.progress-bar-u {
  background: $primary-accent;
}

.progress-bar-blue {
  background: #3498db;
}

.progress-bar-orange {
  background: #e67e22;
}

.progress-bar-red {
  background: #e74c3c;
}

.progress-bar-purple {
  background: #9b6bcc;
}

.progress-bar-aqua {
  background: #27d7e7;
}

.progress-bar-brown {
  background: #9c8061;
}

.progress-bar-dark-blue {
  background: #4765a0;
}

.progress-bar-light-green {
  background: #79d5b3;
}

.progress-bar-dark {
  background: #555;
}

/*Progress Bar Animation
------------------------------------*/

.progress {
  position: relative;
  .progress-bar {
    overflow: hidden;
    line-height: 20px;
    position: absolute;
  }
}

.progress-box .progress-bar {
  transition: all 3s ease-in;
  -o-transition: all 3s ease-in;
  -ms-transition: all 3s ease-in;
  -moz-transition: all 3s ease-in;
  -webkit-transition: all 3s ease-in;
}

/*Vertical Progress Bar*/

.progress.vertical {
  float: left;
  width: 100%;
  height: 200px;
  margin-right: 20px;
  &.bottom {
    position: relative;
  }
  .progress-bar {
    height: 0;
    width: 100%;
    transition: height 3s ease;
    -o-transition: height 3s ease;
    -ms-transition: height 3s ease;
    -moz-transition: height 3s ease;
    -webkit-transition: height 3s ease;
  }
  &.bottom .progress-bar {
    bottom: 0;
    position: absolute;
  }
}

/*Count Stars
------------------------------------*/

.stars-existing {
  color: $primary-accent;
  cursor: pointer;
}

.star-lg {
  font-size: 30px;
}

.star-sm {
  font-size: 25px;
}

.star-xs {
  font-size: 20px;
}

.star-default {
  font-size: 16px;
}

/*Media (Audio/Videos and Images)
------------------------------------*/
/*Images*/

img {
  &.img-bordered {
    padding: 3px;
    border: solid 1px #eee;
  }
  &.img-circle {
    border-radius: 50% !important;
  }
  &.image-sm {
    width: 50px;
    height: 50px;
  }
  &.image-md {
    width: 100px;
    height: 100px;
  }
}

/*Responsive Video*/

.responsive-video {
  height: 0;
  padding-top: 1px;
  position: relative;
  padding-bottom: 56.25%;
  /*16:9*/
  iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

/*Tags v1
------------------------------------*/

.tags-v1 li {
  margin: 0;
  padding: 0;
  a {
    font-size: 13px;
    padding: 4px 8px;
    line-height: 32px;
    border: solid 2px #eee;
    border-radius: 20px !important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:hover {
      text-decoration: none;
      border-color: #e0e0e0;
    }
  }
}

/*Tags v2
------------------------------------*/

.tags-v2 li {
  padding: 7px 0 7px 4px;
  a {
    color: #555;
    font-size: 13px;
    padding: 5px 10px;
    border: solid 1px #bbb;
    &:hover {
      color: #fff;
      background: #555;
      border-color: #555;
      text-decoration: none;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }
  }
}

/*Lists
------------------------------------*/

.list-row {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

/*Lists v1*/

.lists-v1 {
  li {
    margin-bottom: 10px;
  }
  i {
    color: #fff;
    width: 15px;
    height: 15px;
    padding: 1px;
    font-size: 13px;
    margin-right: 7px;
    text-align: center;
    background: $primary-accent;
    display: inline-block;
    border-radius: 50% !important;
  }
}

/*Lists v2*/

.lists-v2 {
  li {
    margin-bottom: 10px;
  }
  i {
    color: $primary-accent;
    font-size: 13px;
    margin-right: 7px;
    display: inline-block;
  }
}

/*Column Sizes
------------------------------------*/
/*Remove the Gutter Padding from Columns*/

.no-gutter {
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
  &.no-gutter-boxed {
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*Heights
------------------------------------*/

.height-100 {
  min-height: 100px;
}

.height-150 {
  min-height: 150px;
}

.height-200 {
  min-height: 200px;
}

.height-250 {
  min-height: 250px;
}

.height-300 {
  min-height: 300px;
}

.height-350 {
  min-height: 350px;
}

.height-400 {
  min-height: 400px;
}

.height-450 {
  min-height: 450px;
}

.height-500 {
  min-height: 500px !important;
}

/*Spaces
------------------------------------*/

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0;
}

.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.no-bottom-space {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.content-xs {
  padding-top: 20px;
  padding-bottom: 20px;
}

.content-std {
  padding-top: 40px;
  padding-bottom: 40px;
}

.content-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.content-md {
  padding-top: 80px;
  padding-bottom: 80px;
}

.content-lg {
  padding-top: 100px;
  padding-bottom: 100px;
}

.space-lg-hor {
  padding-left: 60px;
  padding-right: 60px;
}

.space-xlg-hor {
  padding-left: 100px;
  padding-right: 100px;
}

.margin-bottom-5, .margin-bottom-10, .margin-bottom-15, .margin-bottom-20, .margin-bottom-25, .margin-bottom-30, .margin-bottom-35, .margin-bottom-40, .margin-bottom-45, .margin-bottom-50, .margin-bottom-55, .margin-bottom-60 {
  clear: both;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-45 {
  margin-bottom: 45px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-55 {
  margin-bottom: 55px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

@media (max-width: 768px) {
  .sm-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .sm-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .sm-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .sm-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .sm-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .sm-margin-bottom-60 {
    margin-bottom: 60px;
  }
}

@media (max-width: 992px) {
  .md-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .md-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .md-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .md-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .md-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .md-margin-bottom-60 {
    margin-bottom: 60px;
  }
}

/*Other Spaces*/

.margin-top-20 {
  margin-top: 20px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-left-5 {
  padding-left: 5px;
}

/*Text Colors
------------------------------------*/

.color-sea {
  color: #1abc9c;
}

.color-red {
  color: #e74c3c;
}

.color-aqua {
  color: #27d7e7;
}

.color-blue {
  color: #3498db;
}

.color-grey {
  color: #95a5a6;
}

.color-dark {
  color: #555555;
}

.color-green {
  color: $primary-accent;
}

.color-brown {
  color: #9c8061;
}

.color-light {
  color: #ffffff;
}

.color-orange {
  color: #e67e22;
}

.color-yellow {
  color: #f1c40f;
}

.color-green1 {
  color: #2ecc71;
}

.color-purple {
  color: #9b6bcc;
}

.color-inherit {
  color: inherit;
}

.color-dark-blue {
  color: #4765a0;
}

.color-light-grey {
  color: #585f69;
}

.color-light-green {
  color: #79d5b3;
}

/*Background Colors
------------------------------------*/

.bg-color-dark, .bg-color-sea, .bg-color-red, .bg-color-aqua, .bg-color-blue, .bg-color-grey, .bg-color-light, .bg-color-green, .bg-color-brown, .bg-color-orange, .bg-color-green1, .bg-color-purple, .bg-color-dark-blue, .bg-color-light-grey, .bg-color-light-green {
  color: #fff;
}

.bg-color-white {
  color: #555;
}

.bg-color-dark {
  background-color: #555 !important;
}

.bg-color-white {
  background-color: #fff !important;
}

.bg-color-sea {
  background-color: #1abc9c !important;
}

.bg-color-red {
  background-color: #e74c3c !important;
}

.bg-color-aqua {
  background-color: #27d7e7 !important;
}

.bg-color-blue {
  background-color: #3498db !important;
}

.bg-color-grey {
  background-color: #95a5a6 !important;
}

.bg-color-light {
  background-color: #f7f7f7 !important;
}

.bg-color-green {
  background-color: $primary-accent !important;
}

.bg-color-brown {
  background-color: #9c8061 !important;
}

.bg-color-orange {
  background-color: #e67e22 !important;
}

.bg-color-green1 {
  background-color: #2ecc71 !important;
}

.bg-color-purple {
  background-color: #9b6bcc !important;
}

.bg-color-dark-blue {
  background-color: #4765a0 !important;
}

.bg-color-light-grey {
  background-color: #585f69 !important;
}

.bg-color-light-green {
  background-color: #79d5b3 !important;
}

.rgba-red {
  background-color: rgba(231, 76, 60, 0.8);
}

.rgba-blue {
  background-color: rgba(52, 152, 219, 0.8);
}

.rgba-aqua {
  background-color: rgba(39, 215, 231, 0.8);
}

.rgba-yellow {
  background-color: rgba(241, 196, 15, 0.8);
}

.rgba-default {
  background-color: rgba(114, 192, 44, 0.8);
}

.rgba-purple {
  background-color: rgba(155, 107, 204, 0.8);
}

/*Grey Backroud*/

.bg-grey {
  background: #f7f7f7;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

/*Rounded and Circle Classes
------------------------------------*/

.no-rounded {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-x {
  border-radius: 50% !important;
}

.rounded-2x {
  border-radius: 10px !important;
}

.rounded-3x {
  border-radius: 15px !important;
}

.rounded-4x {
  border-radius: 20px !important;
}

.rounded-sm {
  border-radius: 2px !important;
}

.rounded-md {
  border-radius: 3px !important;
}

.rounded-top {
  border-radius: 4px 4px 0 0 !important;
}

.rounded-left {
  border-radius: 4px 0 0 4px !important;
}

.rounded-right {
  border-radius: 0 4px 4px 0 !important;
}

.rounded-bottom {
  border-radius: 0 0 4px 4px !important;
}

/*Others
------------------------------------*/

.overflow-h {
  overflow: hidden;
}

.overflow-a {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.clear-both {
  clear: both;
}

/*Display*/

.dp-none {
  display: none;
}

.dp-block {
  display: block;
}

.dp-table {
  display: table;
}

.dp-inline-block {
  display: inline-block;
}

.dp-table-cell {
  display: table-cell;
  vertical-align: middle;
}

/*Full Width*/

.full-width {
  width: 100%;
}

/*Equal Height Columns*/
@media (max-width: 767px) {
  .equal-height-column {
    height: auto !important;
  }
}

/*Image Classes*/

.img-width-200 {
  width: 200px;
}

.lft-img-margin {
  margin: 0 20px 5px 0;
}

.rgt-img-margin {
  margin: 0 0 5px 10px;
}

img.img-center, .img-center img {
  margin-left: auto;
  margin-right: auto;
}

/*Background Light*/

.bg-light {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fcfcfc;
  border: solid 1px #e5e5e5;
  &:hover {
    border: solid 1px #bbb;
  }
}

/*CSS3 Hover Effects*/

.hover-effect {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.hover-effect-kenburn {
  left: 10px;
  margin-left: -10px;
  position: relative;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  &:hover {
    -webkit-transform: scale(2) rotate(5deg);
    -moz-transform: scale(2) rotate(5deg);
    -o-transform: scale(2) rotate(5deg);
    -ms-transform: scale(2) rotate(5deg);
    transform: scale(2) rotate(5deg);
  }
}