$capo-primary: #883682;
$caco-primary: #DDB408;
$cac-primary: #A20233;

@mixin wysiwyg-red {
  color: $naac-red;
}
@mixin wysiwyg-blue {
  color: $naac-blue;
}
@mixin wysiwyg-purple {
  color: $naac-purple;
}
@mixin wysiwyg-yellow {
  color: $naac-yellow;
}
@mixin block-quote {
  background: #f9f9f9;
  border-bottom: 3px solid #ccc;
  margin: 1.5em 10px;
  padding: 1.5em;
  quotes: "\201C""\201D""\2018""\2019";
  content:"\201C";
  font-family: georgia;
  color: #888;
  font-style: italic;
  font-size: 1.4em;
  &:before {
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    color: #ccc;
    font-family: georgia;
    text-shadow: 0 -1px 0px rgba(0,0,0,.4);
  }
  &:after {
    content: close-quote;
    font-weight: bold;
  }
  & p {
    display: inline;
  }
}

.margin-0 {
  margin: 0!important;
}


@-moz-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}