.entitytype-accreditation-form {
  margin-top: 2em;
  .nav-tabs {
    counter-reset: navtabscount;
    li a:before {
      content: counter(navtabscount);
      counter-increment: navtabscount;
      color: rgba(255,255,255,.1);
    }
  }
  #edit-actions {
    text-align: right;
  }
  .field-group-accordion-wrapper {
    margin-top: 1em;
}
  .multipage-pane-wrapper {
    border: 1px solid #dddddd;
    padding: 1em;
    margin-bottom: 1em;
}
  .multipage-counter {
    display: none;
}
  .multipage-controls-list {
    .multipage-button {
      @include clearfix;
      float: none;
  }
    .multipage-link-previous {
      float: left;
      opacity: 0.5;
      transition: border-color 0.2s, opacity 0.2s;
      &:hover {
        opacity: 1;
    }
  }
    .multipage-link-next, .form-actions, #edit-actions {
      float: right;
  }
}
  .addressfield-container-inline .form-item-field-event-location-und-0-locality {
    float: none;
    margin-right: 0;
}
  .link-field-column {
    width: 50%;
}
  .link-field-url .form-text {
    width: 100%;
}
  .field-name-field-total-hours-applied-for {
    width: 50%;
    position: relative;
    &:after {
      content: " hours";
      display: block;
      position: absolute;
      left: 100%;
      top: 30px;
      margin-left: 5px;
  }
}
  .field-name-field-total-charges {
    width: 50%;
}
  .field-name-field-agreement-date {
    .form-type-date-select {
      width: 100%;
      margin: 0;
      float: none;
  }
    .form-type-select {
      margin-right: 1em;
  }
}
  .form-item-field-dates-of-event-und-0-value2 > label {
    display: block;
    padding-left: 1em;
}
  .field-name-field-application-contacts {
    .field-multiple-drag, thead {
      display: none;
  }
    td {
      padding: 1em 0;
  }
    tr {
      border: none;
  }
}
  #field-remit-payment-add-more-wrapper {
    word-wrap: break-word;
}
  @media (min-width: 480px) {
  .field-name-field-application-contacts {
    td > .form-wrapper {
      @include make-xs-column(4);
      margin-bottom: 0;
  }
    td > .field-name-field-contact-email {
      clear: both;
      width: 100%;
  }
    .field-name-field-phone-1 {
      clear: both;
  }
}
  .field-name-field-event-location {
    .panel-body {
      .form-type-textfield, .form-type-select {
        float: left;
        width: 50%;
        margin-right: 0;
        padding: 0 1em;
        label:before {
          margin-right: 2em;
        }
    }
  }
}
  .field-name-field-dates-of-event .form-type-select {
    margin-right: 1em;
}
}
  @media (min-width: 640px) {
    .field-name-field-dates-of-event {
      .form-item-field-dates-of-event-und-0-show-todate {
        margin-left: 0;
        margin-bottom: 1em;
      }
      .date-no-float {
        float: left;
        width: 50%;
        clear: none;
      }
      .form-type-date-select {
        float: none;
        margin: 0;
        width: 100%;
        > label, .date-padding {
          display: inline-block;
          padding: 0;
          float: none;
          vertical-align: middle;
        }
        >label {
          padding-right: 2em;
        }
      }
      .start-date-wrapper {
        clear: both;
     }
    }
  }
  @media (max-width: $screen-xs-max) {
    .nav-tabs.vertical-tabs-list {
      display: block;
      float: none;
      width: 100%;
    }
    .vertical-tabs {
      .tab-content {
        padding: 0;
      }
    }
  }
}
