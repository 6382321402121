/* hero slider */
.slider-inner {
  .content {
    padding: 0;
  }
  .contextual-links-wrapper {
    top: 110px;
  }
}
.flexslider {
  border: 0;
  padding: 0;
  margin: 0;
}
.pane-hero-slider {
  z-index: 1;
  padding: 70px 0;
  position: relative;
  background-size: cover;
}

.view-hero-slider, .view-node-hero {
  position: relative;
  .flex-direction-nav {
    a {
      width: auto;
      height: auto;
      font-size: 0;
      line-height: 1;
    }
    a:before {
      color: #fff !important;
      content: '\f053';
      font-family: "fontawesome";
    }
    a.flex-next:before {
      content: '\f054';
    }
  }
  .flexslider .slides .slide-content-wrapper {
    bottom: 3em;
    top: 0;
    .slide-content {
      padding: 0;
      width: 85% !important;
      @media (max-width: $screen-xs-max ) {
        font-size: .8em;
      }
      h2 {
        font-size: 1.8em;
        margin: 0;
      }
      button {
        margin: 3em 0 0 0;
        display: inline-block;
        transition: all .2s ease-in;
        a {
          background: none;
          display: inline-block;
          padding: .25em;
          color: #fff !important;
          color: rgba(255,255,255,.8)!important;
          font-family: "open sans";
          font-weight: 200;
          font-size: 1.2em;
          @media (max-width: $screen-xs-max ) {
            font-size: 1em;
          }
        }
      }
      img {
        height: auto;
        width: auto;
      }
    }

  }
  .attachment-after {
    position: absolute;
    z-index: 110;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-bottom: 0;
    text-align: center;
    font-size: 18px;
    line-height: 1;
    background: #2c2c2c;
    background: rgba(0,0,0,.5);
    ul.slides {
      width: 100% !important;
      transform: none !important;
      /*padding-bottom: 10px;*/
      > li {
        font-size: 1.4em;
        width: 116px !important;
        display: inline-block !important;
        float: none !important;
        margin-right: 1em;
        background: none;
        color: #D7D7D7;
        color: rgba(255,255,255,.6);
        font-weight: bold;
        transition: all .2s ease-in;
        position: relative !important;
        vertical-align: top;
        border: 0;
        border-bottom: 0;
        border-radius: 12px 12px 0 0;
        padding: .6em 0;
        line-height: 1;
        cursor: pointer;
        @media (max-width: $screen-xs-max) {
          width: auto!important;
          font-size: 1em;
          a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: transparent;
            text-indent: -99999px;
            overflow: hidden;
          }
          i {
            padding: .5em;
          }
        }
        &:hover {
          color: #fff;
          i, img {
            /*transform: scale(1.2);*/
          }
          a {
            color: #fff;
            /*padding-bottom: 1.3em;*/
          }
        }
        &.flex1-active-slide {
          color: $naac-red!important;
          a {
            //font-size: 1.2em;
            color: #757575!important;
            font-weight: 600;

            /* padding-bottom: 1.3em;*/
          }
          i,img {
            -webkit-animation: throb 1.2s ease-out;
            -moz-animation: throb 800ms ease-out;
            -o-animation: throb 800ms ease-out;
            animation: throb 1.2s ease-out;
            transform: scale(1.2);
            opacity: 1;
          }
          &:after {
            content: '';
            position: absolute;
            bottom: -100%;
            top: 0;
            right: 0;
            left: 0;
            border-top: 4px solid $naac-red;
            background: #fff;
            transition: all .2s ease-out;
            opacity: 1;
            z-index: -1;
            visibility: visible;
          }
        }
        &:after {
          content: '';
          position: absolute;
          left: -100%;
          right: 100%;
          top: 0;
          bottom: 0;
          background: none;
          transition: all .2s ease-in;
          opacity: 0;
          visibility: hidden;
        }
        a {
          color: #D7D7D7;
          color: rgba(255,255,255,.6);
          line-height: 1.2em;
          display: block;
          width: 100%;
          font-size: .5em;
          transition: all .2s ease-in;
          text-decoration: none !important;
          font-family: "Open Sans", Helvetica, Arial, sans-serif;
          font-weight: 200;
          text-transform: uppercase;
          margin-top: .8em;

        }
        i {
          //font-size: 1.8em;
          //position: absolute;
          //left: .5em;
          //top: .25em;
          transition: transform .2s ease-in;
          display: block;
          font-size: .9em;
          position: relative;
        }
        img {
          position: absolute;
          left: .5em;
          top: .35em;
          height: 1.9em;
          width: 1.9em;
          opacity: .8;
          transition: transform .2s ease-in;
        }
      }
    }
  }
  &.view-hero-slider .slides img {
    min-width: 100%;
    min-height: 70vh;
    width: auto;
    height: auto;
    @media (max-width: $screen-sm-max) {
      filter: blur(6px)!important;
    }
  }
  &.view-node-hero .slides img {
    min-height: 0;
    @media (max-width: $screen-sm-max) {
      filter: blur(6px)!important;
      min-height: 50vh;
      height: auto;
    }
  }
  .field-name-body {
    line-height: 1.2em;
  }
  div.hero-slide-link {
    z-index: 102;
  }
  .field-name-field-image-attribution {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 10px;
    width: auto;
    color: #A1A1A1;
    a {
      color: #e0e0e0;
    }
    &:hover {
      color: #ededed;
      a { color: #fff; }
    }
  }
}

@media (min-width: 768px) {
  #block-views-hero-slider-block-1 .view-hero-slider {

    // Slides.
    .view-content {

      .slide-content-wrapper {
        
        .field-name-body {
          font-size: 18px;
        }

        h2 {
          font-size: 30px;
          margin: 10px 0;
        }

        .field-name-body, button {
          display: block;
        }

        button {
          margin: 3em 0 0 0;
          display: inline-block;
          transition: all .2s ease-in;
          a {
            background: none;
            display: inline-block;
            padding: .25em;
            color: #fff !important;
            font-family: "open sans";
            font-weight: 200;
            text-transform: uppercase;
            font-size: 1.2em;
          }
        }
      }
    }
    // Indicators
    .attachment-after {
      li {
      }

      i {
      }

      a {
      }
    }
  }
}

@media (min-width: 992px) {
  #block-views-hero-slider-block-1 .view-hero-slider {

    // Slides.
    .view-content {

      .slide-content-wrapper {

        .field-name-body {
          max-width: 600px;
          margin: 0 auto;
        }

        h2 {
          font-size: 35px;
          margin: 40px 0 10px 0;
          padding-top: 20px;
        }
      }
    }
    // Indicators
    .attachment-after {
      i {
      }

      a {
      }
    }
  }
}

body.hero-slide #block-views-node-hero-block {
  .slide-content {
    padding: 0;
  }

  .slide-content-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 12;
    text-align: center;
  }
  
  h2 {
    font-size: 24px;
  }
  
  .field-name-body {
    display: none;
  }
}

@media (min-width: 768px) {
  body.hero-slide #block-views-node-hero-block {
    h2 {
      font-size: 28px;
    }
    
    .field-name-body {
      display: block;
      font-size: 18px;
    }
  }
}

@media (min-width: 992px) {
  body.hero-slide #block-views-node-hero-block {
    
    .slide-content-wrapper {
      margin-top: 40px;
    }
    h2 {
      font-size: 32px;
    }

    .field-name-body {

      font-size: 24px;
    }
  }
}