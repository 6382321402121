$color_fg: $naac-blue;
$color_bg: $naac-red;
$color_progress: $primary-accent;
$color_text: white;
$player_height: 42px;
$button_padding_left: 12px;

/*.jp-audio {
  position: relative;
  font-family: 'Source Sans Pro',sans-serif;
  text-align: left;
  //min-width: 320px;
  //max-width: 1280px;
  width: 100%;
  height: $player_height;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $color_text;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 0;
  line-height: 0;

  .jp-controls {
    left: 0;
    position: absolute;
    top: 0;
    width: $player_height;
    height: 100%;
    background-color: $color_fg;

    a {
      text-decoration: none;
      font-size: 20px;
      padding-left: $button_padding_left;
      line-height: $player_height;
      text-align: center;
      cursor: pointer;
      color: $color_text;
    }
    a:hover {
      color: $color_bg;
    }

    a:before {
      display: inline-block;
      font-family: flat-audio;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      font-variant: normal;
      text-transform: none;
      speak: none;
      width: $player_height;
      line-height: $player_height;
      text-align: center;
      opacity: 1;
    }
  }

  .jp-progress {
    position: absolute;
    top: 0;
    left: $player_height;
    right: 0;
    height: 100%;
    background-color: $color_bg;

    .jp-seek-bar {
      position: absolute;
      top: 0;
      left: 0;
      //min-width: 100%;
      height: 100%;
      cursor: pointer;

      .jp-play-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $color_progress;
      }
    }
  }

  .jp-current-time {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding: 0 12px;
    font-size: 16px;
    line-height: $player_height;
    text-align:right;
  }

  .jp-no-solution {
    position: absolute;
    top: 0;
    left: $player_height;
    right: 0;
    height: $player_height;
    padding: 8px 0;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    display: none;
    color: $color_bg;
    background-color: #b28282;
  }
}
*/