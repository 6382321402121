// forms
select, select.form-control, select.form-select {
  height: 3em;
  border-radius: 0;
  -webkit-appearance: none;
  padding: 0 1em;
  border: 1px solid #e1e1e1;
  box-shadow: inset 0 0 22px rgba(0,0,0,.1);
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-weight: bold;
}
input[type='text'], input[type='password'], input[type='email'], textarea.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 3em;
  border-radius: 0;
  padding: 0 1em;
  border: 1px solid #e1e1e1;
  box-shadow: inset 0 0 22px rgba(0,0,0,.1);
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-weight: bold;
}
textarea.form-control{
  height: auto;
}
.form-control, .form-submit, .btn {
  font-family: "Cabin", Helvetica, Arial, sans-serif!important;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled].active {
  background: #949494 !important;
  border: 1px solid #949494;
  color: rgba(0,0,0,.4);
}

form.commerce-add-to-cart .form-submit, .btn-buy {
  margin-top: 1em;
  margin-right: 1%;
  background: $naac-red;
  border-color: darken($naac-red, 10%);
  color: white;
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  transition: all .2s ease-in;
  @media (max-width: $screen-xs-max) {
    color: transparent;
    width: 3em;
    margin: 0;
    i {
      color: white;
    }
  }
  &:hover {
    background: darken($naac-red, 6%);
    color: white;
  }
}

.btn {
  i {
    margin-right: .5em;
    transition: all .5s ease-in;
  }
  &:hover {
    i {
      animation-name: hvr-wobble-vertical;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }
  }
  &.collapse-button {
    i {
      margin: 0;
    }
    &:hover {
      i {
        transform: none;
      }
    }
  }
}

.form-type-select {
  position: relative;
  label {
    &:before {
      content: '\f078';
      position: absolute;
      right: 1em;
      font-family: fontawesome;
      color: #888;
      font-size: .7em;
      pointer-events: none;
      bottom: 1.5em;
    }
  }
  select {
    display: inline-block;
  }
}

.form-type-date {
  .form-control {
    padding: 6px;
    height: auto;
  }
}

@media (max-width: $screen-xs-max) {
  table {
  }
}

/* handle in js instead
.disabled, .disabled * {
  pointer-events: none!important;
}
*/

.btn.disabled:hover i {
  transform: none;
  animation: none;
}

a.tabledrag-handle .handle {
  padding: .8em;
}
.tabledrag-toggle-weight-wrapper {
  display: none;
}