ul.facetapi-facetapi-links {
  list-style: none;
  padding: 0;
  li {
    padding: .2em 0 .2em 1em;
    position: relative;
    .facetapi-active {
      color: $naac-red;
      text-decoration: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      vertical-align: middle;
      padding-top: .2em;
      &:hover {
        text-decoration: none!important;
      }
    }
  }
}