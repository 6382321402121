.node-type-course .pane-node-content.pane-node-content.pane-node-content {
  padding-top: 0;
  > .pane-title {
    padding-bottom: 0.5em;
  }
}
.node-type-course {
  .radix-layouts-sidebar {
    .field-name-field-course-logo {
      img {
        display: block;
        margin: 0 auto 3em auto;
      }
    }
  }
}
.group-product-main {
  @include make-md-column(8);
}
.group-product-sidebar {
  text-align: center;
  @include make-md-column(4);
  .field-name-field-course-product {
    margin-top: 1em;
    padding: 1em;
    background: #f1f1f1;
    text-align: center;
    .field-items:before {
      content: "Purchase for myself";
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      font-size: 1.4em;
      color: $naac-red;
      text-align: center;
      display: block;
      margin: 0 1em .5em 1em;
      padding: .3em;
    }
    form > div > .form-wrapper {
      margin: 0;
    }
  }
  .field-name-field-course-voucher-product {
    margin-top: 1em;
    padding: 1em;
    background: #f1f1f1;
    text-align: center;
    .field-items:before {
      content: "Purchase for OTHERS in my Organization";
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      font-size: 1.4em;
      color: $naac-red;
      text-align: center;
      display: block;
      margin: 0 1em .5em 1em;
      padding: .3em;
    }
    input {
      width: 100%;
    }
    .form-item-quantity {
      width: 20%;
    }
    form > div > .form-wrapper {
      width: 76%;
    }
    .form-submit {
      margin-top: 1em;
      margin-right: 1%;
      background: $naac-red;
      color: white;
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      font-size: 1.3em;
      transition: all .2s ease-in;
      &:hover {
        background: darken($naac-red, 6%);
      }
    }
  }
  form > div > .form-wrapper,
  .form-item-quantity {
    display: inline-block;
    vertical-align: bottom;
    margin: 1% 1% 0;
    text-align: left;
    transition: all .1s ease;
    .form-group {
      margin-bottom: 0;
    }
  }
  .form-submit {
    vertical-align: bottom;
  }
  .form-item-quantity:after {
    @include clearfix;
  }
  .form-item-line-item-fields-commerce-redhen-organization-ref-und,
  .form-item-quantity {
    label, select, input {
      width: 100%;
    }
  }
  .group-product-price {
    background: #F1F1F1;
    padding: 1em;
    text-align: center;
    font-family: "Cabin", Helvetica, Arial, sans-serif;
    h3 {
      color: $naac-red;
      display: block;
      text-align: center;
    }
  }
  .field-name-commerce-price {
    text-align: center;
    font-family: "Cabin", Helvetica, Arial, sans-serif;
    font-size: 1.6em;
  }
}

// collapsible layouts
.radix-layouts-content.col-md-7 {
  .group-product-sidebar {
    form > div > .form-wrapper,
    .form-item-quantity {
      display: block;
      width: 100%;
      label {
        text-align: center;
      }
    }
  }
}