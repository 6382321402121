div.collapsible.styled {
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  margin: 1em 0;
  > h2, > h3, > h4 {
    margin: 0;
    padding: .5em;
  }
  span.field-group-format-toggler {
    display: block;
    a {
      position: relative;
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0;
      display: block;
      padding-left: 2.5em;
      &:before {
        content: "\f055";
        font-family: 'FontAwesome';
        float: left;
        margin-right: 1em;
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        line-height: 1em;
        color: #A9A9A9;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .field-group-format-wrapper {
    padding: 1em;
  }
}

