.page-ceu-catalog {
  .content-std {
    padding-top: 0;
  }
  .radix-layouts-content {
    clear: both;
    border-left: 1px solid #eae7e7;
    @media (max-width: $screen-xs-max) {
      .pane-title {
        text-align: center;
      }
    }
    @media (min-width: $screen-md-min) {
      clear: none;
      padding: 40px 0 0 20px!important
    }
    @media (min-width: $screen-lg-min) {
      @include make-lg-column(9);
      padding: 40px 0 0 40px!important
    }
  }
  .radix-layouts-sidebar {
    @media (max-width: $screen-xs-max) {
      background: #F9F9F9;
      float: left;
      width: 100%;
      margin-bottom: 1em;
      margin-top: -1px;
      &:before {
        content: 'Filter available CEU\'s';
        display: block;
        text-align: center;
        font-size: 1.1em;
        margin-top: .2em;
      }
    }
    @media (min-width: $screen-md-min) {
      padding-top: 40px;
    }
    @media (min-width: $screen-lg-min) {
      @include make-lg-column(3);
    }
    .pane-block {
      @include make-xs-column(3);
      @media (max-width: $screen-xs-max) {
        font-size: .7em;
        text-align: center;
        padding: 0;
      }
      @media (min-width: $screen-md-min) {
        float: none;
        width: 100%;
        margin-bottom: 2em;
      }
    }
    .headline {
      @media (max-width: $screen-xs-max) {
        text-align: center;
        height: 3em;
        vertical-align: bottom;
        margin: 0 0 1em 0;
      }
    }
    h2 {
      text-align: left;
      @media (max-width: $screen-xs-max) {
        font-size: 1.3em;
        text-align: center;
        border: 0;
        font-weight: bold;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding: .2em 0 .2em 1em;
        @media (max-width: $screen-xs-max) {
          padding: 0;
        }
      }
    }
  }
}

