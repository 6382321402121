.front .block-system {

  .radix-layouts-column1 .view-header {
    margin-right: 0; }

  .radix-layouts-column2 {

    .flex-control-nav {
      position: static;
      bottom: auto;
      margin-top: 10px;
      display: none; }

    .flex-direction-nav a:before {
      line-height: 1;
      vertical-align: top;
      text-shadow: -1px -1px 0 rgba(#fff, 0.6), 1px -1px 0 rgba(#fff, 0.6), -1px 1px 0 rgba(#fff, 0.6), 1px 1px 0 rgba(#fff, 0.6); }

    a.flex-next.flex-next.flex-next {
      left: auto;
      right: 0; }

    a.flex-prev.flex-prev.flex-prev {
      left: 0;
      right: auto; }

    .field-name-field-product {
      padding-top: 0; }

    .product-info {
      text-align: center;
      border: 1px solid #ccc;
      padding: 0 1em; }

    .commerce-product-field {
      padding: 0;
      font-size: 1.5em;
      line-height: 1; } }


  .radix-layouts-column2,
  .radix-layouts-column3 {
    margin: 2em 0;

    @media (min-width: 500px) {
      @include make-xs-column(6); } }

  .col-md-4 {
    @media (min-width: 992px) {
      @include make-xs-column(4);
      margin: 0; } }

  .mailchimp-signup-subscribe-form {
    background: rgba($naac-blue, 0.1);
    padding: 1em;

    .mailchimp-newsletter-mergefields {
      padding-top: 0.5em;
      @include clearfix;

      .form-item {
        margin-bottom: 0.5em; }

      .form-item-mergevars-FNAMEa {
        float: left;
        width: 50%;
        padding-right: 0.5em; }

      .form-item-mergevars-LNAMEa {
        float: left;
        width: 50%;
        padding-left: 0.5em; } }

    .form-submit {
      display: block;
      clear: both;
      width: 100%;
      margin-top: 1em; } } }

