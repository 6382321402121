body {
  //background: #222;
}
.wrapper {
  //background-color: #fff;
}

.footer-v1.footer-v1 {
  color: #fff;
  .footer {
    margin-top: 0!important;
  }
  a {
    color: #fff;

    &:hover {
      color: $naac-red;
      background-color: transparent !important;
    }
  }

  
  // Logo + slogan.
  #block-block-1 {

    p, .media {
      margin: 0;
    }
    .file {
      margin-bottom: 1em;
    }
  }

  // Latest Posts.
  #block-views-news-block-1 {
    font-size: 1.2em;
    line-height: 1.3;

    .views-row {
      margin: 0.5em 0;
    }
    
    .views-field-created {
      font-size: 0.8em;
    }
  }

  // Useful links.
  #block-menu-menu-useful-links {
    a {
      padding: 0.25em 0;
    }
  }

  // Contact us.
  #block-block-4 {
    a {
      margin: 0.5em 0;
      display: block;
      
      &:hover {
        border: none;
      }
    }
    p {
      margin: 0;
    }
  }
  
  // Closure.
  .copyright {
    text-align: center;
  }

  // Copyright.
  #block-block-3 {
    margin-bottom: 1em;
    p {
      margin: 0;
    }
    a:first-child {
      margin-left: 0;
    }
  }

  // Social buttons.
  #block-follow-site {

    a {
      float: none;
      display: inline-block;
    }
  }

  .col-md-3 {
    &, .margin-bottom-40 {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 640px) {
  html, body {
    min-height: 100%;
  }
  
  body {
    margin-bottom: 500px;
    position: static;
  }

  .footer-v1.footer-v1 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    // Columns.
    .col-md-3 {
      @include make-xs-column(4.5);

      &:first-child {
        float: none;
        width: 100%;
      }
      &:nth-child(3) {
        @include make-xs-column(3);
      }
    }

    // Useful links.
    #block-menu-menu-useful-links {
      a {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    // Closure
    .copyright {
      text-align: left;
      .col-md-6 {
        @include make-xs-column(6);
      }
    }

    // Copyright.
    #block-block-3 {
      margin-bottom: 0;
    }

    // Social buttons.
    #block-follow-site {
      margin-top: 0.8em;
      text-align: right;
    }
  }
}

@media (min-width: 992px) {
  body {
    margin-bottom: 390px;
    @media (max-width: $screen-md-max) {
      margin-bottom: 400px;
    }
  }
  .footer-v1.footer-v1 {

    // Columns.
    .col-md-3 {
      &, &:first-child {
        @include make-xs-column(3);
      }
      .headline {
        margin-top: 0;
      }
    }

    // Copyright.
    #block-block-3 {
      margin-top: 0.5em;
      p {
        display: inline-block;
      }
    }

    // Social buttons.
    #block-follow-site {
      margin-top: 0.2em;

    }
  }
}