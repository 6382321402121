.view-id-courses {
  .view-display-id-panel_pane_1 {
    .view-content {
      display: table;
      @media ( width: $screen-lg) {
        min-height: 360px;
      }
      width: 100%;
    }
    .views-row {
      display: table-cell;
      vertical-align: middle;
      padding: 2em;
      background: #fff;
      box-shadow: 0px 120px 110px -100px rgba(0,0,0,.3), inset 0px -1px 3px -1px rgba(0,0,0,.5);
      text-align: center;
      border-radius: 50%!important;
      width: 100%;
      position: relative;
      a {
        color: transparent;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
.pond-1 {
  .view-id-courses {
    .views-row {
      border: 12px solid $cac-primary;
    }
  }
}
.pond-2 {
  .view-id-courses {
    .views-row {
      border: 12px solid $caco-primary;
    }
  }
}
.pond-3 {
  .view-id-courses {
    .views-row {
      border: 12px solid $capo-primary;
    }
  }
}
.view-commerce-cart-form {
  .views-field-edit-attributes {
    clear: both;
    label {
      display: inline-block;
      font-size: 1em;
      line-height: 2em;
      width: 30%;
      text-align: right;
      border-right: 16px solid transparent;
      box-sizing: border-box;
    }
    select {
      display: inline-block;
      width: 69%;
      float: right;
      font-size: 1em;
      line-height: 1.1em;
      height: 2em;
    }
  }
  .views-field-edit-quantity {
    input {
      font-size: 1em;
      line-height: 1.1em;
      height: 2em;
    }
  }
}