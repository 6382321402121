
body {
  color: #333;
  background-color: #fff;
  font-size: 13px;
  line-height: 1.6;
  overflow-x: hidden;
  //padding-top: 115px;
}
body.page-node-take {
  //padding-top: 36px;
}

body.hero-slide, body.front {

  .wrapper {
    //padding-top: 115px;
  }
  @media (min-width: 991px) {
    //padding-top: 37px;
  }

  .breadcrumbs {
    display: none;
  }
}
body.admin-menu {
  .header-sticky {
    //top: 28px !important;
    z-index: 400!important;
  }
}

.main-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.radix-layouts-header {
  padding: 0;
}

.panel-display > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.wrapper {
  background: #fff;
  //min-width: 360px;
}

body.front .pane-title {
  text-align: center;
}



/* calendar stuffz */
.view-calendar {
  &.view-display-id-panel_pane_1 {
    .view-header {
      background: $naac-blue;
      color: lighten($naac-blue, 80%);
      margin-right: 1px;
      //border-radius: 16px 16px 0 0!important;
      box-shadow: inset 0 2px 3px -2px rgba(0,0,0,.5);
      .date-heading a {
        color: #ededed;
        line-height: 3em;
        font-family: "lato", Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 1.1em;
        text-decoration: none;
      }
      .date-nav {
        padding: 0;
        margin: 0;
        ul.pager {
          margin: 0;
          li {
            border: none;
            background: none;
            color: white;
            &.date-prev {
              float: none;
              position: absolute;
              left: 0;
              right: initial;
            }
            > a {
              border: none;
              background: none;
              color: white;
              font-size: 2.8em;
              padding: 0 14px;
            }
          }
        }
      }
    }
    .calendar-calendar table.mini td.empty {
      &:first-child {
        border-left: 1px solid #ededed;
      }
      background: none!important;
      border: 0;
      box-shadow: none!important;
    }
    .calendar-calendar tr {
      background: none;
      border: 0;
    }
    .calendar-calendar th.days {
      background: none;
      border: none;
      color: lighten($naac-blue, 75%);
      color: #B7BDC3;
      font-size: 1em;
      text-transform: uppercase;
      line-height: 2em;
      font-family: "lato", Helvetica, Arial, sans-serif;
      font-weight: 400;
    }
    table.mini {
      text-align: center;
      thead {
        background: darken($naac-blue, 3%);
        color: #efefef;
        box-shadow: 0 1px 3px rgba(255, 255, 255, .1);
      }
      td {
        line-height: 2.5em;
        font-weight: 600;
        font-size: 1.2em;
        position: relative;
        &.today {
          a, .mini-day-off {
            border-radius: 100% !important;
            background: rgb(15, 71, 136);
            color: white!important;
            text-decoration: none;
            line-height: 2em;
            text-align: center;
            width: 2em;
            display: inline-block;
            vertical-align: middle;
            box-shadow: 1px 1px 1px #888;
            position: relative;
            z-index: 300;
            font-size: .8em;
            font-weight: bold;
          }
        }
        &.has-events {
          a {
            color: $naac-red;
            text-decoration: none;
            font-weight: 600;
            transition: all .2s ease-in-out;
            &:hover {
              font-size: 1.2em;
            }
          }
          .calendar-empty {
            display: block;
            width: 10px;
            position: absolute;
            color: #fff;
            padding: 1px 0 4px;
            text-align: center;
            font-family: arial, sans-serif;
            font-weight: bold;
            text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
            top: 1px;
            right: 2px;
            background: $naac-red;
            &:before {
              display: block;
              width: 10px;
              height: 0;
              position: absolute;
              top: 3px;
              content: "";
              border-top: 1px dotted rgba(0, 0, 0, 0.2);
              border-bottom: 1px dotted rgba(255, 255, 255, 0.3);
            }
            &:after {
              display: block;
              width: 0;
              height: 0;
              content: "";
              position: relative;
              bottom: -10px;
              z-index: 100;
              border-width: 0 5px 7px 5px;
              border-style: solid;
              border-color: $naac-red $naac-red transparent $naac-red;
            }
          }
        }
      }
      tr:nth-child(even) {
        td.mini:nth-child(even) {
          color: #AAB8BB;
          background: #fff;
          text-align: center;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
        }
        td.mini:nth-child(odd) {
          color: #A4ADB4;
          background: #FCFCFC;
          text-align: center;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
        }
      }
      tr:nth-child(odd) {
        td.mini:nth-child(odd) {
          color: #AAB8BB;
          background: #fff;
          text-align: center;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
        }

        td.mini:nth-child(even) {
          color: #A4ADB4;
          background: #FCFCFC;
          text-align: center;
          box-shadow: inset 0 0 3px rgba(0, 0, 0, .1);
        }
      }
      tr {
      }
      tr:last-child {
        border-bottom-left-radius: 16px !important;
        border-bottom-right-radius: 16px !important;
        td:first-child {
          border-bottom-left-radius: 16px !important;
        }
        td:last-child {
          border-bottom-right-radius: 16px !important;
        }
        td.empty {
          box-shadow: inset 0px -3px 2px -2px rgba(0, 0, 0, 0.1)!important;
          &:first-child {
          }
          &:last-child {
            box-shadow: inset -2px -3px 2px -2px rgba(0, 0, 0, 0.1)!important;
          }
        }
      }
    }
  }
}

/* featured products pane */
.view-store.view-display-id-panel_pane_1, .view-spotlight {
  .flex-direction-nav a.flex-next {
    left: 100%!important;
    right: initial!important;
    line-height: 3em;
    text-align: center;
    &:before {
      content: '\f105';
      font-family: 'fontAwesome';
    }
  }
  .flex-direction-nav a.flex-prev {
    right: 100%!important;
    left: initial!important;
    line-height: 3em;
    text-align: center;
    &:before {
      content: '\f104';
      font-family: 'fontAwesome';
    }
  }
}

.illustration-v2 {
  .add-to-cart {
    i {
      vertical-align: middle;
      padding-right: 18px;
    }
    a {
      background: none;
      border: none;
      color: #555;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9em;
      padding: 0;
      margin: 0;
      vertical-align: middle;
    }
    .field-name-field-product {
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin: 0;
      .form-wrapper {
        margin: 0;
      }
      .attribute-widgets {
        display: none;
      }
      button {
        background: none;
        border: none;
        color: #555;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 0.9em;
        padding: 0;
        margin: 0;
        display: block;
        vertical-align: top;
      }
    }
  }
  .rating-price {
    .commerce-product-field {
      margin: 0;
      padding: 0;
    }
  }
  &:hover {
    .add-to-cart:hover {
      background: $naac-red;
      background: rgba(163, 2, 52, .7);
      .field-name-field-product {
        button {
          color: #fff;
          background: none;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

.view-spotlight {
  margin-bottom: 3em;
  .slides > li {
    overflow: hidden;
    .field-name-field-media {
      min-height: 250px;
      display: table;
      width: 100%;
      position: relative;
      z-index: 1;
      .field-items {
        display: table-cell;
        vertical-align: middle;
        img {
          width: auto;
          margin: auto;
        }
      }
    }
    .color-overlay {
      position: absolute;
      top: 100%;
      right: -100%;
      bottom: -100%;
      left: 100%;
      opacity: 0.8;
      z-index: 10;
      transition: all .1s ease-in .1s;
    }
    .field-name-body {
      position: absolute;
      top: 100%;
      left: 100%;
      font-size: 2em;
      font-weight: bold;
      padding: 1.5em;
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      right: -100%;
      bottom: -100%;
      z-index: 11;
      -webkit-transition: all .1s ease-out 0s;
      transition: all .1s ease-out 0s;
      .field-items {
        display: table;
        height: 100%;
        width: 100%;
        .field-item {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          color: white;
          p { color: white;
          }
        }
      }
    }
    a.action-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
    }
    &:hover {
      .color-overlay {
        transition: all .1s ease-out 0s;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .field-name-body {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all .2s ease-in 0s;
      }
    }
  }
}
body.not-logged-in {
  .view-spotlight {
    .field-name-field-media {
      min-height: 170px;
    }
  }
}

/* recent news pane */
.view-news {
  &.view-display-id-panel_pane_1 {
    .views-row {
      clear: both;
      border-bottom: 1px dashed #ECECEC;
      padding: 1em 0;
      margin: 0 1em;
      &:last-child {
        border: 0;
      }
      .views-field-title {
        font-family: "Roboto", Helvetica, Arial, sans-serif;
        font-size: 1.3em;
        font-weight: 300;
        min-height: 66px;
        display: table;
        .field-content {
          display: table-cell;
          vertical-align: middle;
          a {
            display: block;
          }
        }
      }
      .date-wrapper {
        float: left;
        margin-right: 1em;
        text-align: right;
        font-size: 2em;
        width: 3em;
        font-family: "Cabin", Helvetica, Arial, sans-serif;
        font-weight: bold;
        .day {
          display: block;
          color: #8C8A8A;
          font-family: "open sans";
          line-height: 1.7em;
          font-weight: 300
        }
        .month {
          display: block;
          color: #A0A0A0;
          padding-bottom: 2px;
          -webkit-transition: all .2s;
          transition: all .2s;
          font-size: .7em;
          text-transform: uppercase;
          font-family: "roboto";
          letter-spacing: -1px;
          font-weight: 300;
          line-height: .4em;
        }
        a:hover {
          text-decoration: none;
        }
      }
      &:hover {
        .month {
          /*background: darken($naac-blue, 5%);*/
        }
      }
    }
  }
}

/* mailchimp block */
#block-mailchimp-signup-homepage-signup {
  .form-group {
    margin-bottom: .8em;
  }
  input {
    background: #3C3C3C;
    border: 4px solid #444;
    border-radius: 4px!important;
    color: white;
    text-shadow: none;
    font-weight: normal;
  }
  button {
    background: $naac-blue;
    color: white;
    font-family: "Cabin", Helvetica, Arial, sans-serif;
    font-weight: bold;
    transition: all .2s ease-in;
    border-color: darken($naac-blue, 1%);
    &:hover {
      background: darken($naac-blue, 6%);
    }
  }
}

/* bootstrap elements */
body.content-well .region-content {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
.tag-box h3, .tag-box .tag-box-title {
  margin-top: -.5em;
  color: $naac-red;
}
.tab-v2 .tab-content {
  background: #fff;
}
li.list-unstyled { padding-left: 15px; }
.pagination li a:hover {
  color: #fff;
  background: $naac-blue;
  border-color: $naac-blue;
}
//vertical tabs
.tabs-left {
  > .tab-content {
    &:after {
      top: 80%;
      width: 50%;
      z-index: -1;
      content: "";
      bottom: 15px;
      max-width: 300px;
      background: #999;
      position: absolute;
      right: 5px;
      transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -webkit-transform: rotate(-3deg);
      box-shadow: 0 15px 10px #999;
      -moz-box-shadow: 0 15px 10px #999;
      -webkit-box-shadow: 0 15px 10px #999;
    }
  }
}
.vertical-tabs-panes > fieldset > div.box-shadow {
  border: 0!important;
  &:before, &:after {
    display: none;
  }
  > .panel-body > p.help-block {
    font-weight: bold;
    text-transform: uppercase;
    text-align: Center;
    font-size: 1.5em;
    padding: .5em;
    display: block;
    text-decoration: none;
    position: relative;
    z-index: 10;
    transition: all .3s ease-in-out;
    border-left: 1px solid #ededed;
    border-top: 1px solid #ededed;
    & {
      color: rgb(50,50,50);
      color: rgba(50, 50, 50, 0.9);
      border-top: 1px solid #ededed;
      background: #FFE97B;
      background: repeating-linear-gradient(45deg, #FFE97B, #FFE97B 10px, #FFD500 10px, #FFD500 20px);
      text-shadow: 0 1px rgba(255, 255, 255, 0.6);
      margin-top: -26px;
      margin-bottom: 1em;
      margin-left: -32px;
      margin-right: -32px;
    }
  }
}
.tab-v1 .nav-tabs > li > a:hover {
  color: #fff;
  background: $naac-blue;
}
.tabs-left>.nav-tabs {
  box-shadow: inset -2px -2px 4px rgba(0,0,0,.3);
}
.tabs-right>.nav-tabs {
  box-shadow: inset 2px -2px 4px rgba(0,0,0,.3);
}
.tabs-left>.nav-tabs, .tabs-right>.nav-tabs {
  background: #4F4F4F;
  padding-bottom: 0;
  li {
    > a {
      color: #c8c8c8;
      font-family: "open sans", sans-serif;
      text-transform: uppercase;
      font-size: 1.1em;
      font-weight: bold;
      transition: all .5s;
      margin: 0!important;
      &:hover {
        background: #444;
        border-color: #3c3c3c!important;
        padding-left: 1.5em;
        color: white!important;
      }
      .summary {
        text-transform: none;
        color: #A1A1A1;
        font-weight: normal;
        font-size: 12px;
        font-family: "lato", Helvetica, Arial, sans-serif;
      }
    }
    &.active>a, &.active>a:hover, &.active>a:focus, &.active:hover > a {
      padding-left: 2em;
      color: $naac-red !important;
      background: white !important;
      border-left: 2px solid $naac-red !important;
    }
    &.active a:hover {
      padding-left: 2em;
      border-color:#ddd transparent #ddd $naac-red !important;
    }
  }
}

/* fieldsets */
.panel-title {
  line-height: 1.5em;
}
legend.panel-heading {
  position: relative;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
  z-index: 10;
}
fieldset .panel-body {
  display: block;
}
a.fieldset-legend, a.accordion-toggle {
  position: relative;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
  &[data-toggle="collapse"] {
    display: block;
    padding-left: 2.5em!important;
    margin: 0 -1em!important;
    &:before {
      content: "\f055";
      font-family: 'FontAwesome';
      float: left;
      margin-right: 1em;
      position: absolute;
      left: 10px;
      font-size: 1.5em;
      line-height: 1em;
      color: #A9A9A9;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
    }
  }
}


/* admin stuffz */
body > .wrapper.contextual-links-region {
  position: static;
}
.ctools-modal-dialog {
  overflow-y: scroll;
  .modal-content {
    float: left;
  }
  .modal-body {
    height: auto !important;
    float: left;
    .panels-add-content-modal {
      float: left;
    }
  }
}
/* panels */
.radix-layouts-sidebar {
  .pane-title {
    text-align: center;
  }
}


/* sticky footer */
html {
  position: relative;
  min-height: 100%;
}
//
//body {
//  /* Margin bottom by footer height */
//  margin-bottom: 380px;
//  position: static;
//}
//
//
///* course */
//
//
//.footer-v1 {
//  position: absolute;
//  bottom: 0;
//  width: 100%;
//  /* Set the fixed height of the footer here */
//  height: 380px;
//  overflow: hidden;
//  margin-top: 0;
//  border-top: 0;
//  .footer {
//    margin-top: 0;
//    height: 330px;
//    overflow: hidden;
//    color: #eee;
//    a { color: #eee; }
//    .nav > li > a:hover, .nav > li > a:focus {
//      background: none;
//      color: $naac-red;
//    }
//  }
//  .copyright {
//    height: 50px;
//  }
//}

/* responsive images */
img { max-width: 100%!important; height: auto!important;}

/* wysiwyg AR Loves it */
.cke_wysiwyg_div {
  min-height: 100px;
}
body.cke_editable {
  padding: 0;
  margin: 0;
}

// Wysiwyg
h2, h3, h4, span{
  &.wysiwyg-red {
    @include wysiwyg-red;
  }

  &.wysiwyg-blue {
    @include wysiwyg-blue;
  }

  &.wysiwyg-purple {
    @include wysiwyg-purple
  }

  &.wysiwyg-yellow {
    @include wysiwyg-yellow;
  }
}

#edit-submit-hidden {
  height: 0;
  width: 0;
  overflow: hidden;
  padding: 0;
  border: none;
}

#quiz-question-answering-form > div > div {
  display: inline-block;
  margin-right: .5em;
  vertical-align: middle;
  & > em {
    font-weight: 600;
    color: $naac-red;
  }
}

div.jp-audio {
  width: 100%!important;
}

.align-left {
  text-align: left!important;
}
.align-center {
  text-align: center!important;
}
.align-right {
  text-align: right!important;
}
.align-justify {
  text-align: justify!important;
}

//img alignment
//wysiwyg widget alignment

img, span.cke_widget_inline {
  &.align-left {
    float: left;
    margin: 1em 1em 1em 0;
  }
  &.align-center {
    display: block;
    margin: 1em auto;
  }
  &.align-right {
    float: right;
    margin: 1em 0 1em 1em;
  }
}

//vertical centering
.vert-wrap {
  display: table;
  .vert-center {
    display: table-cell;
    vertical-align: middle;
  }
}
.block {
  display: block!important;
}


a.lexicon-term {
  border-bottom: 1px dotted #337AB7;
  color: #337AB7;
  cursor: help;
}

.tabledrag-changed {
  color: $naac-red;
}

div.container-inline {
  display: inline;
  button {
    vertical-align: top;
    display: inline-block;
    margin-right: 3px;
  }
}
div.form-item-more-object-type {
  display: inline-block;
}
#edit-submit {
  vertical-align: top;
}

/* forms */
div.form-type-radio, div.form-type-checkbox {
  margin-left: 1em;
}

/* tables */

/* lexicon */
.lexicon-alphabar-instructions {
  font-size: 0.9em;
  text-align: center;
  font-style: italic;
  margin-bottom: 1em;
}
.lexicon-links {
  background: none;
  text-align: center;
  color: #CCCCCC;
  font-size: 2em;
  font-weight: 300;
  a {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 600;
  }
}
.lexicon-list {
  dd {
    margin-bottom: 1em;
  }
}

/* news */
.view-display-id-block_1 {
  .views-row {
    position: relative;
  }
}


#verify-certification-form {
  .form-type-textfield {
    max-width: 480px
  }
  .table-striped {
    margin-top: 1em
  }
}

/* Contact form. */
.webform-client-form-8 {
  .webform-component--questions---comments {
    clear: both;
  }

  .webform-component-markup {
    padding-bottom: 2em;
  }

  @media (min-width: 480px) {
    .webform-component-textfield,
    .webform-component-email {
      @include make-xs-column(4);
    }

    .webform-component--name {
      padding-left: 0;
    }

    .webform-component--phone {
      padding-right: 0;
    }
  }


  .webform-component-textarea {
    clear: both;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.page-node-edit {
  .ui-dialog, .ui-widget {
    z-index: 401;
  }
  .ui-widget-overlay {
    z-index: 400;
  }
}

/* sub nav */
body.subnav {
  .content-std {
    margin-top: 21px;
  }
  .pane-main-menu {
    position: relative;
    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      margin-top: -61px;
    }
    &:before {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: -10000px;
      right: 10000px;
      width: 100000px;
      box-shadow: inset 0 8px 20px #eee, 0 1px 1px #bbb, 0 -1px 0px #DCDCDC;
      background: #F3F3F3;
    }
    li {
      vertical-align: middle;
      > a {
        font-family: "roboto condensed", sans-serif;
        font-size: .9em;
      }
    }
  }
  &.admin-menu {
    .pane-main-menu {
      margin-top: -102px;
    }
  }
}

.region-hero-slider {
  .block {
    box-shadow: inset 0 8px 20px #eee, 0 1px 1px #bbb, 0 -1px 0px #DCDCDC;
    background: #F3F3F3;
    .menu-block-wrapper {
      @include container-fixed();
      @media (min-width: 768px) {
        & {
          width: 750px;
        }
      }
      @media (min-width: 992px) {
        & {
          width: 970px;
        }
      }
      @media (min-width: 1200px) {
        & {
          width: 1170px;
        }
      }
    }
  }
}

.ani-stripes {
  animation: progress-bar-stripes 2s linear infinite;
  background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
  background-size: 40px 40px;
  cursor: default;
  clear: both;
}

.navbar-brand {
 // display: none;
}

.progress-stacked {
  position: relative!important;
}
.progress-remaining {
  opacity: .5;
}