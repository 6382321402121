/* loader */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader-inner-wrapper {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 128px;
  height: 128px;
  margin: -64px 0 0 -64px;
  text-align: center;
  z-index: 1004;
}
/* sphere */
.stage {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 20px;
  text-align: left;
  perspective: 1200px;
  perspective-origin: 50% 50%;
  transform-style: preserve-3d;
  transform: rotateY(0.01deg);
}
.ball {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%!important;
  position: relative;
  background: radial-gradient(circle at 54% 39%, #0d4175, #245588 66%, #BDC7E4 100%);
}
.ball:before {
  content: "";
  position: absolute;
  top: -4%;
  left: -4%;
  width: 100%;
  height: 94%;
  border-radius: 50%;
  background: radial-gradient(circle at 10% 0px, #ffffff, rgba(255, 255, 255, 0) 51%);
  -webkit-filter: blur(15px);
  z-index: 2;
}
.ball:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle at 50% 30%, rgba(245, 237, 48, 0), rgba(36, 85, 136, 0.2) 50%, rgb(21, 54, 88) 100%);
}
.ball .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
  -webkit-transform: rotateX(90deg) translateZ(-80px);
  transform: rotateX(90deg) translateZ(-80px);
  z-index: 1;
}
.splogo {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%!important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}
.splogo:before {
  content: "✱";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  /* background: black; */
  font-size: 12em;
  line-height: 1.15em;
  /* transform: translateX(-22px) translateY(106px) skewX(10deg) skewY(-2deg); */     position: absolute;
  animation: move-star-skew 0.9s linear infinite;;
  font-family: sans-serif;
  font-weight: bold;
  color: rgb(255, 255, 255);
  transform: translateX(-44px) translateY(106px) skewX(10deg) skewY(-2deg);
  text-align:center;
}
.splogo:after {
  /* content: ""; */
  display: block;
  position: absolute;
  width: 31.25%;
  height: 31.25%;
  border-radius: 50%;
  top: 18.75%;
  left: 18.75%;
  background: rgba(255, 255, 255, 0.2); text-align:center;
}
@keyframes move-star-skew {
  0% {
    transform: translateX(-100px) translateY(100px) skewX(-30deg) skewY(-30deg) scale(0.2); }
  50% {
    transform: none; }
  100% {
    transform: translateX(100px) translateY(-100px) skewX(30deg) skewY(30deg) scale(0.4); }
}

/* Preloader5*/
.preloader5 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
  position: relative;
  z-index: 1005;
  em {
    display: block;
    margin-top: 10px;
    span {
      animation: loadingtext .7s linear infinite;
      font-size: 56px;
      font-family: 'montserrat', sans-serif;
      letter-spacing: 2px;
      color: $naac-red;
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      opacity: 1;
      &:nth-child(2) {
        animation-delay: -.7s;
      }
      &:nth-child(3) {
        animation-delay: -.6s;
      }
      &:nth-child(4) {
        animation-delay: -.5s;
      }
      &:nth-child(5) {
        animation-delay: -.4s;
      }
      &:nth-child(6) {
        animation-delay: -.3s;
      }
      &:nth-child(7) {
        animation-delay: -.2s;
      }
      &:nth-child(8) {
        animation-delay: -.1s;
      }
    }
  }
}
@keyframes loadingtext {
  0% { opacity: .7; }
  50% { opacity: 1;  }
  100% { opacity: .7; }
}

#loader {
  display: block;
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50% !important;
  border: 3px solid transparent;
  border-top-color: #3498db;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;
    -webkit-animation: spin 3s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }
  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;
    -webkit-animation: spin 1.5s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}


@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 50%;
  height: 100%;
  background: white;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  -webkit-transform: translateX(0);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0);
  /* IE 9 */
  transform: translateX(0);
  /* Firefox 16+, IE 10+, Opera */
  &.section-left {
    left: 0;
    opacity: 1;
  }
  &.section-right {
    right: 0;
    opacity: 1;
  }
}

/* Loaded */

.loaded {
  #loader-wrapper .loader-section {
    &.section-left {
      -webkit-transform: translateX(-100%);
      /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: translateX(-100%);
      /* IE 9 */
      transform: translateX(-100%);
      /* Firefox 16+, IE 10+, Opera */
      -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: .6;
    }
    &.section-right {
      -webkit-transform: translateX(100%);
      /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: translateX(100%);
      /* IE 9 */
      transform: translateX(100%);
      /* Firefox 16+, IE 10+, Opera */
      -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: .6;
    }
  }
  #loader/*, .preloader5*/ {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: translateY(-100%);
    /* IE 9 */
    transform: translateY(-100%);
    /* Firefox 16+, IE 10+, Opera */
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;
  }
}

#primary-menu-collapse-trigger {
  font-size: 1em;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  text-transform: none;
  i {
    color: #CEAFB9;
    transition: all .2s ease-in-out;
  }
  span.label {
    color: #CEAFB9;
    transition: all .2s ease-in-out;
  }
  &:hover {
    i {
      color: white;
    }
    span.label {
      color: white;
    }
  }
}

/* JavaScript Turned Off */

.no-js #loader-wrapper {
  display: none;
}