.view-news-page {

  .field-name-body {
    margin-bottom: 1em; }

  .views-row {
    margin-bottom: 4em;
    .col-sm-4 {
      text-align: center;
      margin-bottom: 2em; }
    @media (min-width: 400px) {
      .col-sm-4 {
        @include make-xs-column(4); }
      .col-sm-8 {
        @include make-xs-column(8);
        h2 {
          margin-top: 0; } } } } }

.page-news {
  .radix-layouts-sidebar {
    h2 {
      text-align: left; } } }
