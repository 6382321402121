.node-live-training, .node-calendar-event {
  margin-bottom: 2em;
  .picture-col {
    padding-bottom: 1em; }
  .links, .field-name-body {
    margin-top: 1em; }
  @media (min-width: 360px) {
    .picture-col {
      @include make-xs-column(3);
      padding: 0; }
    .content-col {
      @include make-xs-column(9);
      padding-right: 0; } }
  @media (min-width: 768px) {
    .picture-col {
      @include make-xs-column(2);
      padding: 0; }
    .content-col {
      @include make-xs-column(10);
      padding-right: 0; } } }
