.header-v6 {
  background: rgba(#fff, 0.45) url("../images/bg/diagonal-noise.png");
  z-index: 1000;
  position: relative;
  font-family: 'Cabin', Helvetica Neue, Arial, sans-serif;

  .navbar {
    border: none;
    margin-bottom: 0;
  }

  .topbar {
    background: $naac-red;
    padding: 10px 0;
    .logged-in-user {
        color: #efefef;
        width: 25%;
        text-align: center;
      @media (max-width: $screen-xs-max) {
        float: none!important;
        text-align: center;
        display: block;
        width: 100%;
        margin-bottom: 1em;
        display: none;
      }
    }
    ul {
      margin: 0;
      float: right;
      font-size: 0;
      @media (max-width: $screen-xs-max) {
        float: none!important;
        text-align: center;
      }

    }

    li {
      display: inline-block;
      font-size: $font-size-small;

      &:first-child a {
        border-left: none;
      }

      &:last-child a {
        border-right: none;
      }
    }

    a {
      color: rgba(#fff, .7);
      text-shadow: 0 -1px 0 rgba(0,0,0,.4);
      transition: color 0.2s;
      padding: 0 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      border-left: 1px solid rgba(255,255,255,.2);
      letter-spacing: 1px;
      @media (max-width: $screen-xs-max ) {
        width: 2.5em;
        overflow: hidden;
        height: 1.8em;
        font-size: 1.7em;
        line-height: 1.8em;
      }

      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        font-size: .7em;
        i {
          font-size: 1.5em;
        }
      }

      i {
        padding-right: .5em;
        font-size: 1.3em;
      }

      &:hover {
        color: #fff;
        background: transparent;
      }
    }
  }

  .menu-container {
    @include clearfix;
    position: relative;
  }

  .navbar-brand {
    height: 93px;
    transition: height 0.2s;
    padding: 10px 15px;
    img {
      max-height: 100%;
    }
  }

  .navbar-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: 0;
    font-size: 24px;

    &:active {
      color: $naac-red;
    }
  }

  .navbar-collapse .navbar-nav {
    font-size: 1.15em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0;

    a, span {
      transition: color 0.2s;
    }

    > li {
      border-top: 1px solid #fff;

      &:last-child {
        border-bottom: 1px solid #fff;
      }

      li {
        border: none;
      }

      li a {
        font-weight: 400;
        font-size: 1em;
        color: #777;
        text-transform: none;
      }
    }

    a {
      background: transparent;

      &:hover {
        color: #0F4788;
        background: transparent;

        span {
          border-top-color: #0F4788;
        }
      }

      span {
        border-top-color: #555;
      }
    }
  }
}

@media (min-width: 768px) {
  .header-v6 {

    .primary-nav, .topbar .container {
      width: auto;
      max-width: 1170px;
      margin: 0 auto;
    }

    .navbar-collapse {
      padding: 0;
    }

    .menu.container {
      padding: 0;
      margin: 0;
    }

    .navbar-brand.navbar-brand {
      margin: 0 0 -10px 0;
      text-align: center;
      float: none;
    }

    .navbar-collapse .menu.container {
      text-align: center;
      font-size: 0;
    }

    .navbar-collapse .navbar-nav {
      float: none;
      display: inline-block;
      font-weight: 400;



      > li {
        font-size: $font-size-base;
        border-top: none;

        &:last-child {
          border-bottom: none;
        }

        a {
          padding: 15px 10px;
        }

        ul {
          border-top: 3px solid #0F4788;
          text-align: left;
          font-size: 14px;
          letter-spacing: normal;
          top: 5px;
        }

        li a {
          padding: 6px 25px;
        }

        li span {
          transform: rotate(-90deg);
        }

        &:first-child > a {
          padding-left: 0;
        }

        &:last-child > a {
          padding-right: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

@media (min-width: 992px) {

  body  {
    padding-top: 113px;
  }

  body.front, body.hero-slide {

      //margin-top: 40px;
      //padding-top: 0;

    > .wrapper {
      margin-top: -73px;
    }
  }

  body.admin-menu .header-v6 {
    top: 29px;
  }

  .header-v6 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    .primary-nav {
      font-size: 0;
      position: relative;
    }

    .menu-container {
      display: inline-block;
      width: 25%;
    }

    .navbar-collapse {
      width: 75%;
      display: inline-block !important;
      vertical-align: middle;
      font-size: $font-size-base;
      padding: 0;
    }

    .navbar-collapse .navbar-nav {
      float: right;

      > li {

        &:last-child > a {
          padding-right: 10px;
        }

        > a {
          color: white;

          span {
            border-top-color: white;
          }
        }
      }
    }

    .navbar-brand.navbar-brand {
      margin: 0;
    }
    
    &.header-fixed-shrink {
      background: #fff;
      box-shadow: 0 0 3px #bbb;

      .navbar-brand {
        height: 73px;

        img {
          max-height: 100%;
        }
      }

      .navbar-collapse .navbar-nav > li > a {
        color: #777;

        span {
          border-top-color: #777;
        }
      }
    }
  }
}

@media (min-width: 1044px) {
  .header-v6 .navbar-collapse .navbar-nav > li{
    font-size: 15px;
  }
}
