body.page-type-node {
  .radix-layouts-sidebar {
    .panel-pane {
      margin-bottom: 1em;
    }
  }
}
.margin-bottom-1 {
  margin-bottom: 1em;
}
.text-align-center {
  text-align: center;
}