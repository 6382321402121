
body.node-type-quiz, body.node-type-course, body.node-type-h5p-content, body.page-node-takecourse {
  .page-title {
    .course-name {
      color:#A6A6AE;
      color: rgba(41, 41, 41, 0.25);
    }
    .title-divider {
      color: #F3F3F3;
      font-weight: 800;
      text-shadow: 0 -1px 0 rgba(51, 51, 51, 0.25);
      font-size: 2em;
      position: relative;
      top: .25em;
    }
  }
  .radix-layouts-content {
    padding: 0;
    .panel-pane {
      padding: 0;
      //padding: 1.5em 0 1.5em 1.5em;
      clear: both;
    }
    .pane-quiztake-pane {
      padding: 0;
    }
    .pane-node-body {
      padding: 0 15px;
    }
  }
  #radix-collapse {
    //margin-top: -1px;
    color: #2c2c2c;
    background: #fefefe;
    padding: 0;
    box-shadow: 0 0 4px 4px rgba(0,0,0,.2);
  }
  .radix-layouts-sidebar {

    > .panel-panel-inner {
      //margin-left: 27px;
      //background: $naac-red;
      padding: .2em 0;
    }
    .pane-title {
      margin-top: .5em;
    }
    .nav-pills {
      background: $btn-primary-border;
      margin-left: 4.2em;
      margin-top: -2px;
      padding-bottom: 1px;
      a {
        color: $naac-blue;
        font-size: 1.4em;
        text-transform: uppercase;
        font-weight: bold;
        &:hover, &:focus {
          background: #1F517D;
          color: #ABCCE8;
        }
      }
      li.active {
        a {
          background: $btn-primary-bg;
          color: rgba(255,255,255,.9);
        }
      }
    }
    .tab-content-wrapper {
      .tab-content {
        padding: 1em 1.5em;
        .headline {
          display: none!important;
        }
        a {
          color: #efefef;
        }
        table {
          th {
            color: #efefef;
          }
          tbody {
            td {
              border-color: #2e6da4;
              color: #bfbfbf;
              p {
                color: #bfbfbf;
              }
            }
            tr.odd {
              background-color: $btn-primary-border;

            }
          }
        }
      }
    }
    .course-outline {
      .course-title {
        text-align: center;
        font-size: 1.2em;
        padding-top: 1em;
        margin: 0;
        color: #3e3e3e;
        font-weight: 300;
      }
      ul {
        list-style: none;
        padding: 1em 1em 2em 0;
        margin-left: 2.2em;
        margin-bottom: 0;
        font-size: 1.3em;
        counter-reset: stepcount;
        li {
          position: relative;
          font-family: "lato", Helvetica, Arial, sans-serif;
          color: #ababab;
          font-weight: 300;
          cursor: not-allowed;
          text-indent: -1em;
          padding-left: 1em;
          &.active {
            font-weight: bold;
            font-style: italic;
          }

          img {
            position: absolute;
            left: -1.5em;
            top: 6px;
            max-width: 12px !important;
          }
          span.course-outline-status {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            z-index: 99;
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            padding: .1em .5em;
            right: -37px;
            top: 0;
            font-size: .8em;
            margin-top: 0;
            border-radius: 1em!important;
            margin-right: .5em;
            background: $btn-danger-bg;
            color: $btn-danger-color;
            border: 1px solid $btn-danger-border;
            text-indent: 0!important;
            &:after, &:before {
              /*
              right: 100%;
              top: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              */
              pointer-events: none;
            }
            &:after {
              margin-top: -6px;
              border-top: none;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-bottom: 5px solid $btn-danger-border;
              top: 1px;
              left: 50%;
              transform: translateX(-50%);
            }
            &:before {
              content: "To do";
              display: block;
              text-align: center;
            }
          }

          &:hover span.course-outline-status {
            visibility: visible;
            opacity: 1;
          }
          &#complete {
            img {
              display: none;
            }
          }
          &.completed {
            color: darken($naac-blue, 12%);
            > img { display: none; }
            a {
              color: darken($naac-blue, 12%);
              color: rgba(4, 4, 4, 0.41);
              font-weight: bold;
              font-style: oblique;
              text-decoration: line-through;
              &:before {
                //counter-increment:stepcount;
                content:'\f01e';
                font-family: 'fontawesome';
                color: $btn-success-bg;
                width: 2em;
                position: absolute;
                left: -2.5em;
                text-align: right;
                font-size: 1em;
                line-height: 1.8em;
                font-weight: bold;
                text-shadow: 0 -1px rgba(0,0,0,.3);
                text-decoration: none;
              }
            }
            span.course-outline-status {
              background: $btn-success-bg;
              color: $btn-success-color;
              border: 1px solid $btn-success-border;
              &:after {
                border-bottom-color: $btn-success-border;
              }
              &:before {
                display: none;
              }
            }
          }
          &.in-progress {
            > img {
              display: none;
            }
            a {
              &:before {
                //counter-increment: stepcount;
                content: '\f04b';
                font-family: fontawesome;
                color: #efefef;
                width: 2em;
                position: absolute;
                left: -2.5em;
                text-align: right;
                font-size: 1em;
                line-height: 1.8em;
                font-weight: bold;
                text-shadow: 0 -1px rgba(0,0,0,.3);
                text-decoration: none;
                font-style: normal;
              }
            }
            .course-outline-status {
              background: $btn-warning-bg;
              color: $btn-warning-color;
              border: 1px solid $btn-warning-border;
              &:after {
                border-bottom-color: $btn-warning-border;
              }
              &:before {
                display: none;
              }
            }
            > img {
              display: none;
            }
          }
          &.not-accessible:before {
            content: counter(stepcount)"";
            counter-increment: stepcount;
            color: #22629A;
            text-decoration: none;
            font-style: normal;
            width: 2em;
            position: absolute;
            left: -2.5em;
            text-align: right;
            font-size: 1.3em;
            line-height: 1.4em;
            font-weight: bold;
            text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
            font-family: "roboto";
            opacity: 0.5;
          }
        }
          a {
            color: #a9a9a9;
            cursor: pointer;
          }

      }
    }
  }
  .view-certification-links {
    a {
      font-size: .8em;
      line-height: 1.2!important;
      display: block;
      opacity: .9;
      font-weight: 300;
      font-family: "roboto condensed", Helvetica, Arial, sans-serif;
      &:hover {
        opacity: 1;
      }
    }
  }
  /* pane-course-nav
  .pane-course-navigation {
    position: static;
    ul#course-nav {
      margin: 0;
      padding: 0;
      li {
        > a {
          width: 4em;
          height: 4em;
          display: block;
          text-indent: -99999px;
          color: transparent;
        }
        &.course-nav-prev {
          position: absolute;
          left: 1em;
          top: 50%;
          padding: 0;
          margin: 0;
          &:after {
            content: '\f190';
            font-family: 'fontawesome';
            font-size: 3em;
            color: rgba(0, 0, 0, .4);
            line-height: 1em;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            transition: all .2s ease-out;
          }
          &:hover {
            &:after {
              color: $primary-accent;
              font-size: 4em;
            }
          }
        }
        &.course-nav-next {
          position: absolute;
          right: 1em;
          top: 50%;
          padding: 0;
          margin: 0;
          &:after {
            content: '\f18e';
            font-family: 'fontawesome';
            font-size: 3em;
            color: rgba(0, 0, 0, .4);
            line-height: 1em;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 100;
            transition: all .2s ease-out;
          }
          &:hover {
            &:after {
              color: #008926;
              font-size: 4em;
              position: absolute;
              bottom: 0;
            }
          }
        }
        &.course-nav-back {
          position: absolute;
          bottom: 0;
          left: 50%;
          padding: 0;
          margin: 0;
          &:after {
            content: '\f177';
            font-family: 'fontawesome';
            font-size: 4em;
            color: $naac-blue;
            position: absolute;
            bottom: 0;
            right: 100%;
            line-height: 1em;
          }
          > a {
            height: auto;
            width: auto;
            line-height: 4em;
            font-size: 1.5em;
            text-indent: 1em;
            font-weight: 500;
          }
        }
      }
    }
  }
  */

  /* quiz take nav */
  .answering-form {
    text-align: center;
    /*padding: 0 50px 0 80px;*/
    >div {
      text-align: center;
      display: inline-block;
      .form-item {
        display: block!important;
        text-align: left;
      }
    }
    #edit-back {
      /*position: absolute;
      left: 1em;
      top: 50%;
      padding: 0;
      margin: 0;
      z-index: 100;
      transform: translateY(-50%);
      color: transparent;
      background: none;
      border: none;
      display: block;
      height: 4em;
      width: 4em;*/
      margin: 1em;
      font-size: 1.5em;
      font-family: "Open Sans", Helvetica, Arial, sans-serif!important;
      font-weight: 100;
      color: #b3b3b3;
      @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
      &:before {
        font-family: 'cabin';
        content: '\f190';
        position: static;
        display: inline-block;
        font-family: fontawesome;
        float: left;
        padding-right: .5em;
      }
      /*&:after {
        content: '\f190';
        font-family: 'fontawesome';
        font-size: 3em;
        color: rgba(0, 0, 0, .4);
        line-height: 1em;
        position: absolute;
        top: 50%;
        left: 15px;
        z-index: 100;
        transition: all .2s ease-out;
        margin-top: -1em;
      }
      &:hover {
        &:after {
          color: $btn-warning-bg;
          font-size: 4em;
        }
      }*/
    }
    #edit-submit {
      /*position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      margin: 0;
      z-index: 100;
      color: transparent;
      background: none;
      border: none;
      display: block;
      height: 4em;
      width: 4em;*/
      margin: 1em;
      font-size: 1.5em;
      font-family: "Open Sans", Helvetica, Arial, sans-serif!important;
      font-weight: 100;
      @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
      &:before {
        content: '\f18e';
        position: static;
        display: inline-block;
        font-family: fontawesome;
        float: right;
        padding-left: .5em;
      }
      /*&:after {
        content: '\f18e';
        font-family: 'fontawesome';
        font-size: 3em;
        color: rgba(0, 0, 0, .4);
        line-height: 1em;
        z-index: 100;
        transition: all .2s ease-out;
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -1em;
      }
      &:hover {
        &:after {
          color: #008926;
          font-size: 4em;
          position: absolute;
          bottom: 0;
        }
      }*/
    }
    #edit-submit-hidden {
      display: none;
    }
  }
  .h5p-content, .h5p-iframe-wrapper {
    margin: 1.5em 5.5em;
  }
  #quiz_progress,
  #quiz_score_possible,
  #quiz_score_percent {
    background: $naac-red;
    margin-top: -1px;
    line-height: 3em;
    padding-bottom: 3px;
    font-size: 1.25em;
    color: lighten($naac-red, 70%);
    text-align: center;
    font-weight: 600;
    box-shadow: 0 1px 1px #333;
  }
  #quiz_score_percent {
    background: darken($naac-red, 8%);
  }
  #quiz_score_possible a {
    color: #fff;
    text-decoration: underline;
  }
  .radix-moscone-collapse {

    &.h5p_content #radix-collapse {
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
    }
    .radix-layouts-content.col-md-8 {

    }
  }
}

.lms-slide {
  padding: 0;//0.5em 6em 3em;
  min-height: 420px;
  float: left;
  width: 100%;
  position: relative;
  margin: auto;
  text-align: center;

  .lms-slide.lms-slide {
    padding: 0 6em;
    background: transparent;
  }
  .slide-title {
    font-size: 3em;
    text-align: center;
    line-height: 1.2em;
    margin: 1.2em;
    &.big {
      font-size: 3.2em;
    }
  }
  p {
    font-size: 1.8em;
    text-align: left;
  }
  ul, ol {
    text-align: left;
    display: inline-block;
    max-width: 80%;
     ul, ol {
      display: block;
    }
    li {
      font-size: 2em;
      ul li, ol li {
        font-size: 1.8rem;
        ul li, ol li {
          font-size: 1.6rem;
        }
      }
    }
  }
  &.wysiwyg-bg-dark-grey {
    background-color: #939393; /* Old browsers */
    background: radial-gradient(ellipse at center,  rgba(226,226,226,1) 0%,rgba(169,169,169,1) 100%); /* W3C */
  }
  &.wysiwyg-bg-light-grey {
    background-color: #D8D8D8;/* Old browsers */
    background: radial-gradient(ellipse at center,  rgba(244,244,244,1) 0%,rgba(221,221,221,1) 100%); /* W3C */
  }
  &.naac-content-slide-vert-center {
    .vert-wrap {
      min-height: 360px;
    }
  }
  &.naac-content-slide-2-col {
    p {
      font-size: 1.4em;
    }
    h3 {
      font-size: 2.2em;
    }
  }
}
.page-node-take {
  .content-std {
    padding-top: 2em;
  }
  .breadcrumbs {
    display: none;
  }
  .content {
    position: relative;
    .field {
      clear:both;
      width: 100%;
    }
    .field-name-field-slide-audio {
      position: relative;
      width: 33%;
      float: left;
    }
  }
  .jp-playlist {
    display: none;
  }
}
//footer
.quiztake-footer {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+44,000000+100&amp;0+44,0.3+100 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(44%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.3))); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%); /* IE10+ */
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#4d000000', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  position: relative;
  img.align-right {
    margin: 1em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

// course outline
#course-objects {
  div[id^=title] {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 2em;

  }
  tr.draggable {
    td:first-child {
      vertical-align: middle;
      .handle {
        width: 13px;
      }
    }
  }
  div[id^=edit-course-outline-objects] {
    > a {
      text-align: center;
      font-size: 1.2em;
      font-weight: bold;
      position: relative;
      text-decoration: none!important;
      transition: all .1s;
      &:before {
        content: "\f013";
        margin-right: .5em;
        font-family: fontawesome;
        text-decoration: none!important;
      }
      &:hover {
        color: darken($naac-red, 5%);
        &:before {
          animation: 2s linear 0s normal none infinite spin;
          text-decoration: none;
        }
      }
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        font-size: 1em;
        line-height: 1.5em;
        font-style: italic;
        font-weight: 600;
        i {
          margin-right: .5em;
          font-size: 1.3em;
          width: 1em;
          text-align: center;
          &.fa-check {
            color: $brand-success;
          }
          &.fa-eye {
            color: $brand-primary;
          }
          &.fa-exclamation {
            color: $brand-info;
          }
          &.fa-exclamation-triangle {
            color: $brand-danger;
          }
          &.fa-share {
            color: $brand-warning;
          }
        }
      }
    }
  }
}
#quiz-view-table {
  width: 100%;
  border-collapse: separate;
  padding: 0px;
  margin: 0;
  margin-bottom: 20px;
  background: none;
  border: 0;
  margin-top: 1em;
}
.quiz-viewarea {
  background: radial-gradient(ellipse at center,  rgba(244,244,244,1) 0%,rgba(221,221,221,1) 100%); /* W3C */

  .quiz-question-node--multichoice {
    padding: 1em;
    text-align: center;
    font-size: 1.8em;
    .field-name-body {
      max-width: 720px;
      margin: 1em auto 1em auto;
      text-align: left;
      position: relative;
      &:before {
        content: '\f10d';
        display: block;
        color: black;
        position: absolute;
        top: -84px;
        left: -60px;
        font-size: 5em;
        font-family: fontawesome;
        z-index: -1;
        color: rgb(230, 230, 230);
        text-shadow: -1px -1px 0 rgba(200,200,200,.4);
      }
    }
  }

  #quiz-question-answering-form {
    //margin-top: 1em;
    .form-submit {
      box-shadow: none;
    }
    .form-type-radio {
      margin-left: 0;
      margin-bottom: 1.25em;
      padding: 0;
      line-height: 1.2;
      font-size: 1.2em;
      p {
        text-indent: -1.3em;
        padding-left: 1.3em;
      }
      input, label {
        margin: 0;
        display: inline-block;
        vertical-align: top;
        float: none;
        cursor: pointer;
      }

      input {
        margin-left: -20px;
        margin-top: 2px;
      }

      label {
        padding-left: 5px;
        margin-top: -2px;

      }
    }

    .form-type-radios, .form-type-checkboxes {
      margin: .8em auto 2em 2em;
      font-size: $font-size-base;
      max-width: 560px;
    }
  }
}


#quiz_score_possible,
#quiz_score_percent {
  background-color: $naac-red;
  color: #fff;
  text-align: center;
}
#quiz_score_percent {
  background-color: darken($naac-red, 20%);
}
#quiz_summary {
  font-size: 1.5em;
  margin: 3em auto;
  max-width: 768px;
  padding: 0 1em;
}
#quiz-report-form {
  background: radial-gradient(ellipse at center,  rgba(244,244,244,1) 0%,rgba(221,221,221,1) 100%); /* W3C */

  table {
    margin: 0;
    font-size: $font-size-base;
  }

  tr td:first-child {
    padding: 0.5em 18px;
  }

  tr:nth-child(odd) {

    td {
      background: rgba(#c2ccd1, 0.2);
    }
  }

  td {
    border: none;
    background-color: transparent;
    padding: 0.5em 0;
  }

  .dd {
    margin: 0;
    padding: 0;
  }

  .dt {
    position: relative;
    background-color: transparent;
    padding: 0.5em 100px 0 0;

  }

  .multichoice-icon {
    background: none;
    width: auto;
    height: auto;

    &:before {
      font-size: 1.5em;
      line-height: 1ex;
      vertical-align: -4px;
    }
  }

  .wrong:before {
    content: "×";
    color: $naac-red;
    background: none;
  }

  .should:before,
  .correct:before {
    content: "✓";
    color: #555
  }

  .correct:before {
    color: #00bf00;
  }

  .field-name-body {
    padding: 0.5em 1em;
    font-size: $font-size-large;
  }

  .quiz-report-score-container {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
  }
}

.page-node-results .tab-v1 {
  margin: 0;
}

#radix-collapse {
  padding-left: 0;
  margin-bottom: 20px;
  padding-right: 0;
  transition: margin .1s !important;
  &.collapsed {
    margin-bottom: 0;
    animation: shrink-hide 1ms linear .1s forwards;
    margin-left: -99999em;
    margin-left: -100vw;
  }
}

@keyframes shrink-hide {
  0% {
    max-height: 100%;
  }
  100% {
    max-height: 0;
  }
}

.radix-layouts-content {
  transition: width .1s  !important;
  float: right;
}

.no-overflow {
  overflow: hidden;

}

.radix-moscone-collapse {
  padding: 0 0 0 30px;

  .collapse-button {
    position: absolute;
    left: 0;
    z-index: 100;
    font-size: 2em;
    transform-origin: top left;
    top: 1px;
    margin-left: -40px;
    padding: 6px 16px 5px 16px;
    i:before {
      display: inline-block;
      vertical-align: middle;
      //font-size: 14px;
    }
    &:hover {
      opacity: 1;
      transition: opacity 0.2s;
    }

    span {
      padding: 0 5px;
      vertical-align: middle;
    }
  }
}

@media (max-width: 808px) {

  .radix-moscone-collapse .collapse-button {
    position: relative;
    transform: none;
    top: auto;
    bottom: auto;
    margin: 0;
    left: 0;
    display: block;
  }
}

@media  (min-width: 992px) and (max-width: 1022px) {
  .radix-moscone-collapse {
    padding: 0;
    //display: none;

    .collapse-button {
      margin-left: -5px;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1230px) {
  .radix-moscone-collapse {
    padding: 0;
    //display: none;

    .collapse-button {
      margin-left: -5px;
    }
  }
}

//.node-type-course .tabs--primary {
//  margin-left: 10px;
//  margin-right: 10px;
//
//  @media (min-width: 768px) {
//    margin-left: 0;
//    margin-right: 0;
//  }
//
//  @media (min-width: 992px) and (max-width: 1022px) {
//    margin-left: 25px;
//    margin-right: 25px;
//  }
//
//  @media (min-width: 1200px) and (max-width: 1230px) {
//    margin-left: 25px;
//    margin-right: 25px;
//  }
//}

.page-certification-courses .block-system {

  .panel-3col {
    text-align: center;

    @media (min-width: 640px) {
      display: table;
    }

    .panel-panel {
      width: 100%;
      float: none;
      background: rgba($naac-blue, 0.1);
      padding: 1em;
      margin-bottom: 1em;

      @media (min-width: 640px) {
        width: 33%;
        display: table-cell;
        vertical-align: middle;
        border: 10px solid white;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

  }

  h2 {
    margin-top: 0.5em;
  }
}

.course-nav-links {
  text-align: center;

  a {
    display: inline-block;
    margin: 1em;
  }
}

ul.question-actions {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    list-style: none;
  }
}

//* new course layout *

body.page-node-take {
  padding-top: 40px;
  margin-bottom: 0;
  overflow: hidden;
  @media ( max-width: 991px) {
    .header-v6 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      ul.nav.secondary {
        li {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(5) {
          }
        }
      }
    }
    @media ( max-width: 767px) {
      .tabs--primary {
        display: none;
      }
    }
  }
  .primary-nav, .footer-v1, #support{
    display: none;
  }
  .navbar {
    min-height: 0;
  }
  .content-std {
    padding: 0;
   // margin-top: -1px;
  }
  .nav-tabs {
    margin-bottom: 0;
  }
  .sm-logo {
    height: 22px !important;
    img {
      max-height: 100%;
    }
    a {
      border: 0;
    }
  }
  #block-system-main {
    background: #f1f1f1;
  }
  #quiz_title {
    .pane-title {
      text-align: center;
      font-style: italic;
      line-height: 51px;
      margin: 0;
      padding: 0;
      @media ( max-width: 767px ) {
        margin-left: 1.4em;
        line-height: 1.25em;
        font-size: 1.6em;
        min-height: 2.5em;
      }
    }
  }
  #quiz_progress {
    background: #fff;
    margin: 0;
    padding: 0;
    .progress {
      margin: 0;
      padding: 0;
      height: 40px;
      line-height: 40px;
      .progress-bar {
        line-height: 40px;
        font-size: 1.2em;
      }
      #progress_indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 10;
      }
    }
  }
  .quiz-viewarea {
    overflow-y: scroll;
    .lms-slide {
     // float: none;
     // display: inline-block;
      overflow-y: scroll;
      max-width: 100%;
      h2 {
        display: inline-block;
        width: 100%;
        margin: 1.2em 0;
      }
      ul, ol {
        max-width: 820px;
      }
    }
    .quiz-question-node--wrapper {
      overflow-y: scroll;
      display: table;
      max-width: 720px;
      margin: auto;
      .quiz-question-node--wrapper-inner {
        display: table-cell;
        vertical-align: middle;
      }
    }
    form.answering-form {
      clear: both;
      min-height: 80px;
    }
  }
  .radix-moscone-collapse {
    margin-left: 0px;
    margin-right: 15px;
    padding-left: 0;
    @media (max-width: 767px ) {
      margin-right: 0;
    }
    .collapse-button {
      margin-left: 0;
    }
    .radix-layouts-sidebar {
      margin-bottom: 0 !important;
      overflow-y: hidden;
      .tab-content-wrapper {
        overflow-y: scroll;
      }
      .nav-pills {
        //margin-left: 68px;
      }
    }
  }
  .outline-in {
    font-size: 1em !important;
    box-sizing: border-box;
    padding: 0 20px;
    background: #dedede;
  }
}
