@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,900|Cabin:400,500,600,700|Droid+Sans:400,700|Lato:100,300,400,700,900|PT+Sans:400,700|PT+Sans+Narrow:400,700|Asap:400,700|Open+Sans:400,300,600,700,800|Roboto:400,100,300,500,700,900|Roboto+Condensed:400,300,700|Montserrat:400,700|Oxygen:400,300,700);
/* line 47, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_mixins.scss */
.margin-0 {
  margin: 0 !important; }

@-moz-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
.header-v6 {
  background: rgba(255, 255, 255, 0.45) url("../images/bg/diagonal-noise.png");
  z-index: 1000;
  position: relative;
  font-family: 'Cabin', Helvetica Neue, Arial, sans-serif; }
  /* line 7, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar {
    border: none;
    margin-bottom: 0; }
  /* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .topbar {
    background: #A30234;
    padding: 10px 0; }
    /* line 15, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar .logged-in-user {
      color: #efefef;
      width: 25%;
      text-align: center; }
      @media (max-width: 767px) {
        /* line 15, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar .logged-in-user {
          float: none !important;
          text-align: center;
          display: block;
          width: 100%;
          margin-bottom: 1em;
          display: none; } }
    /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar ul {
      margin: 0;
      float: right;
      font-size: 0; }
      @media (max-width: 767px) {
        /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar ul {
          float: none !important;
          text-align: center; } }
    /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar li {
      display: inline-block;
      font-size: 12px; }
      /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar li:first-child a {
        border-left: none; }
      /* line 47, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar li:last-child a {
        border-right: none; }
    /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar a {
      color: rgba(255, 255, 255, 0.7);
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
      transition: color 0.2s;
      padding: 0 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      letter-spacing: 1px; }
      @media (max-width: 767px) {
        /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar a {
          width: 2.5em;
          overflow: hidden;
          height: 1.8em;
          font-size: 1.7em;
          line-height: 1.8em; } }
      @media (min-width: 768px) and (max-width: 991px) {
        /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar a {
          font-size: .7em; }
          /* line 70, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
          .header-v6 .topbar a i {
            font-size: 1.5em; } }
      /* line 75, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar a i {
        padding-right: .5em;
        font-size: 1.3em; }
      /* line 80, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar a:hover {
        color: #fff;
        background: transparent; }
  /* line 87, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .menu-container {
    position: relative; }
    /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .header-v6 .menu-container:before, .header-v6 .menu-container:after {
      content: " ";
      display: table; }
    /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .header-v6 .menu-container:after {
      clear: both; }
  /* line 92, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-brand {
    height: 93px;
    transition: height 0.2s;
    padding: 10px 15px; }
    /* line 96, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-brand img {
      max-height: 100%; }
  /* line 101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: 0;
    font-size: 24px; }
    /* line 109, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-toggle:active {
      color: #A30234; }
  /* line 114, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .navbar-nav {
    font-size: 1.15em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0; }
    /* line 121, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav a, .header-v6 .navbar-collapse .navbar-nav span {
      transition: color 0.2s; }
    /* line 125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav > li {
      border-top: 1px solid #fff; }
      /* line 128, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child {
        border-bottom: 1px solid #fff; }
      /* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li {
        border: none; }
      /* line 136, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li a {
        font-weight: 400;
        font-size: 1em;
        color: #777;
        text-transform: none; }
    /* line 144, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav a {
      background: transparent; }
      /* line 147, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav a:hover {
        color: #0F4788;
        background: transparent; }
        /* line 151, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .navbar-collapse .navbar-nav a:hover span {
          border-top-color: #0F4788; }
      /* line 156, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav a span {
        border-top-color: #555; }

@media (min-width: 768px) {
  /* line 166, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .primary-nav, .header-v6 .topbar .container {
    width: auto;
    max-width: 1170px;
    margin: 0 auto; }
  /* line 172, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse {
    padding: 0; }
  /* line 176, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .menu.container {
    padding: 0;
    margin: 0; }
  /* line 181, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-brand.navbar-brand {
    margin: 0 0 -10px 0;
    text-align: center;
    float: none; }
  /* line 187, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .menu.container {
    text-align: center;
    font-size: 0; }
  /* line 192, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .navbar-nav {
    float: none;
    display: inline-block;
    font-weight: 400; }
    /* line 199, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav > li {
      font-size: 14px;
      border-top: none; }
      /* line 203, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child {
        border-bottom: none; }
      /* line 207, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li a {
        padding: 15px 10px; }
      /* line 211, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li ul {
        border-top: 3px solid #0F4788;
        text-align: left;
        font-size: 14px;
        letter-spacing: normal;
        top: 5px; }
      /* line 219, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li a {
        padding: 6px 25px; }
      /* line 223, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li span {
        transform: rotate(-90deg); }
      /* line 227, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:first-child > a {
        padding-left: 0; }
      /* line 231, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child > a {
        padding-right: 0; }
      /* line 235, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child {
        border-bottom: none; } }

@media (min-width: 992px) {
  /* line 245, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  body {
    padding-top: 113px; }
  /* line 254, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  body.front > .wrapper, body.hero-slide > .wrapper {
    margin-top: -73px; }
  /* line 259, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  body.admin-menu .header-v6 {
    top: 29px; }
  /* line 263, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
    /* line 269, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .primary-nav {
      font-size: 0;
      position: relative; }
    /* line 274, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .menu-container {
      display: inline-block;
      width: 25%; }
    /* line 279, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse {
      width: 75%;
      display: inline-block !important;
      vertical-align: middle;
      font-size: 14px;
      padding: 0; }
    /* line 287, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav {
      float: right; }
      /* line 292, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child > a {
        padding-right: 10px; }
      /* line 296, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li > a {
        color: white; }
        /* line 299, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .navbar-collapse .navbar-nav > li > a span {
          border-top-color: white; }
    /* line 306, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-brand.navbar-brand {
      margin: 0; }
    /* line 310, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6.header-fixed-shrink {
      background: #fff;
      box-shadow: 0 0 3px #bbb; }
      /* line 314, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6.header-fixed-shrink .navbar-brand {
        height: 73px; }
        /* line 317, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6.header-fixed-shrink .navbar-brand img {
          max-height: 100%; }
      /* line 322, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6.header-fixed-shrink .navbar-collapse .navbar-nav > li > a {
        color: #777; }
        /* line 325, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6.header-fixed-shrink .navbar-collapse .navbar-nav > li > a span {
          border-top-color: #777; } }

@media (min-width: 1044px) {
  /* line 334, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .navbar-nav > li {
    font-size: 15px; } }

/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Import Global Compulsory CSS Files*/
/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Header Fixed
------------------------------------*/
/* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .header-fixed .header.header-fixed-shrink {
  background: #fff; }

/*Blog Latest Tweets
------------------------------------*/
/* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .blog-twitter .blog-twitter-inner:after,
.ie8 .blog-twitter .blog-twitter-inner:before {
  border: none; }

/* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .our-clients img {
  width: 100%;
  height: 100px;
  vertical-align: inherit; }

/* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .our-clients .img-hover {
  display: none; }

/*Pricing Stickers*/
/* line 33, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .sticker-left {
  width: 90px;
  text-align: right;
  background: #72c02c; }

/* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .sticker-right {
  width: 100px;
  text-align: left;
  background: #e74c3c; }

/*Easy Block*/
/* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .easy-bg-v2 {
  width: 75px;
  text-align: left; }

/* line 51, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .rgba-red {
  background: #e74c3c; }

/* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .rgba-blue {
  background: #3498db; }

/* line 53, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .rgba-default {
  background: #72c02c; }

/* line 54, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .rgba-purple {
  background: #9b6bcc; }

/* line 55, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .rgba-aqua {
  background: #27d7e7; }

/* line 56, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .rgba-yellow {
  background: #f1c40f; }

/*Sky-Forms*/
/* line 59, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .sky-form .icon-append {
  right: 5px !important;
  padding-left: 0; }

/*Background Opacity*/
/* line 65, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .service-block-v2 .service-block-in {
  background: url(../img/transparent/white.png) repeat; }

/* line 69, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_ie8.scss */
.ie8 .job-img .job-banner,
.ie8 .job-img .job-img-inputs,
.ie8 .parallax-team:before,
.ie8 .image-block .company-description,
.ie8 .search-block:before,
.ie8 .parallax-counter .counters,
.ie8 .carousel-v1 .carousel-caption {
  background: url(../img/transparent/black.png) repeat; }

/*   
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Breadcrumbs
------------------------------------*/
/*Default Breadcrumbs*/
/* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs {
  overflow: hidden;
  padding: 10px 0 6px;
  border-bottom: solid 1px #eee;
  background: url(../images/bg/breadcrumbs.png) repeat; }
  /* line 18, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs h1 {
    color: #666;
    font-size: 22px;
    margin-top: 8px; }

/* line 25, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumb {
  top: 10px;
  padding-right: 0;
  background: none;
  position: relative; }
  /* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumb a {
    color: #777; }
  /* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumb li.active, .breadcrumb li a:hover {
    color: #d50344;
    text-decoration: none; }

@media (max-width: 550px) {
  /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs h1.pull-left, .breadcrumbs ul.pull-right {
    width: 100%;
    text-align: center; }
  /* line 47, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs h1.pull-left {
    margin-bottom: 0; }
  /* line 50, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs .breadcrumb {
    top: 0;
    margin-bottom: 10px; } }

/*Breadcrumbs Dark and Light*/
/* line 60, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-dark {
  background: #222;
  padding: 30px 0 20px;
  border-bottom: solid 3px #777; }

/* line 65, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light {
  background: #222;
  padding: 30px 0 20px;
  border-bottom: solid 3px #777;
  background: #f7f7f7;
  border-bottom: 1px solid #eee; }

/* line 73, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-dark h1, .breadcrumbs.breadcrumbs-dark a {
  color: #fff;
  font-weight: 200; }

/* line 79, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light h1, .breadcrumbs.breadcrumbs-light a {
  color: #fff;
  font-weight: 200; }

/* line 83, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light h1, .breadcrumbs.breadcrumbs-light a {
  color: #555; }

/* line 87, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-dark h1 {
  font-size: 24px; }

/* line 90, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs.breadcrumbs-light h1 {
  font-size: 26px; }

/*Breadcrumbs Sizes*/
/* line 97, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-sm {
  padding: 50px 0; }

/* line 101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-md {
  padding: 100px 0; }

/* line 105, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-lg {
  padding: 200px 0; }

/*Breadcrumbs v1 and v3
------------------------------------*/
/* line 112, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-v1, .breadcrumbs-v3 {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }

/*Breadcrumbs v1
------------------------------------*/
/* line 122, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-v1 {
  padding: 100px 0;
  position: relative;
  background: url(../img/breadcrumbs/img1.jpg) center no-repeat;
  background-size: cover;
  /*Scales the background image to be as large as possible*/ }
  /* line 128, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v1:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.15); }
  /* line 137, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v1 span, .breadcrumbs-v1 h1 {
    color: #fff;
    z-index: 1;
    position: relative; }
  /* line 142, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v1 span {
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 5px;
    display: inline-block; }
  /* line 148, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v1 h1 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 55px;
    text-transform: uppercase; }

@media (max-width: 768px) {
  /* line 157, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v1 {
    padding: 50px 0; }
    /* line 159, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .breadcrumbs-v1 h1 {
      font-size: 40px;
      line-height: 45px; } }

/*Breadcrumbs v2
------------------------------------*/
/* line 169, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-v2 {
  text-align: center;
  position: relative;
  background: url(../img/bg/11.jpg) no-repeat center; }

/* line 175, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-v2-in {
  padding: 80px 0;
  position: relative; }

/* line 180, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-v2 h1 {
  color: #fff;
  text-transform: uppercase; }

/* line 186, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumb-v2 li a, .breadcrumb-v2 li.active {
  color: #fff; }

/* line 189, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumb-v2 li i {
  color: #fff;
  min-width: 19px;
  padding: 3px 7px;
  margin-right: 5px;
  text-align: center;
  border: 1px solid #fff; }

/*Breadcrumbs v3
------------------------------------*/
/* line 202, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.breadcrumbs-v3 {
  z-index: 1;
  padding: 100px 0;
  position: relative; }
  /* line 206, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 200px 0; }
  /* line 209, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3.img-v1 {
    background: url(../img/breadcrumbs/img1.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 214, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3.img-v2 {
    background: url(../img/breadcrumbs/img2.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 219, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3.img-v3 {
    background: url(../img/breadcrumbs/img3.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 224, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.2); }
  /* line 234, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3 h1 {
    color: #fff;
    font-size: 40px;
    line-height: 50px;
    text-transform: uppercase; }
  /* line 240, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3 p {
    color: #fff;
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 0;
    text-transform: uppercase; }

@media (max-width: 992px) {
  /* line 250, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3.breadcrumbs-lg {
    padding: 100px 0; } }

@media (max-width: 768px) {
  /* line 256, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .breadcrumbs-v3 {
    padding: 50px 0; }
    /* line 258, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .breadcrumbs-v3.breadcrumbs-lg {
      padding: 50px 0; }
    /* line 261, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .breadcrumbs-v3 h1 {
      font-size: 30px;
      line-height: 40px; }
    /* line 265, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .breadcrumbs-v3 p {
      font-size: 18px; } }

/*Image Hover Effects
------------------------------------*/
/*Image Hover v1*/
/* line 276, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v1 span {
  display: block;
  overflow: hidden;
  position: relative; }
  /* line 280, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .img-hover-v1 span:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute; }

/* line 289, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v1:hover span:after {
  background: rgba(0, 0, 0, 0.3); }

/* line 292, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v1 img {
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out; }

/* line 300, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v2 img {
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out; }

/* line 308, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v1 span:after {
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out; }

/* line 314, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v1:hover img {
  -transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1); }

/*Image Hover v2*/
/* line 325, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v2 img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%); }

/* line 329, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.img-hover-v2:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0); }

/*Block Grid v1
------------------------------------*/
/* line 338, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.block-grid-v1 {
  background: #fff;
  padding: 15px 15px 5px;
  border-bottom: solid 2px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  /* line 346, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v1:hover {
    border-color: #d50344; }
  /* line 349, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v1 img {
    margin-bottom: 10px; }
    /* line 351, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .block-grid-v1 img.block-grid-v1-img {
      width: 35px;
      height: 35px;
      margin-top: 8px; }
  /* line 357, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v1.content-boxes-v1 img.pull-left {
    float: inherit !important; }
  /* line 360, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v1 h3 a {
    font-size: 22px;
    font-weight: 200;
    margin-right: 10px; }
  /* line 365, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v1.content-boxes-v1 h3 {
    margin-bottom: 0; }
    /* line 367, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .block-grid-v1.content-boxes-v1 h3 a {
      color: #555; }
  /* line 371, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v1 .star-vote {
    padding: 0;
    margin-bottom: 10px;
    vertical-align: middle; }
    /* line 375, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .block-grid-v1 .star-vote li {
      padding: 0;
      font-size: 11px;
      display: inline-block; }

/*Star Vote*/
/*Additional Info*/
/* line 387, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.block-grid-v1-add-info {
  margin: 0;
  padding-top: 15px;
  border-top: solid 1px #eee; }
  /* line 391, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v1-add-info li {
    padding: 0;
    margin-right: 3px;
    margin-bottom: 10px; }
    /* line 395, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .block-grid-v1-add-info li a {
      font-size: 11px;
      padding: 4px 7px;
      text-decoration: none;
      border: solid 2px #eee;
      border-radius: 3px !important; }

/*Block Grid v2
------------------------------------*/
/* line 408, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.block-grid-v2 {
  padding: 0; }
  /* line 410, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .block-grid-v2 li {
    list-style: none;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/ }

/* line 418, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.block-grid-v2-info {
  padding: 15px;
  text-align: center;
  background: #f9f9f9;
  border-bottom: solid 3px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

/* line 429, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.block-grid-v2 li:hover .block-grid-v2-info {
  border-color: #d50344; }

/* line 434, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.block-grid-v2-info h3 {
  font-size: 22px;
  font-weight: 200;
  margin-bottom: 10px; }

/* line 439, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.block-grid-v2-info .star-vote li {
  padding: 2px;
  font-size: 16px; }

/*Services Box v1
------------------------------------*/
/* line 449, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-box-v1 .service-block {
  background: #fff;
  padding-top: 30px;
  padding-bottom: 20px; }

/* line 454, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-box-v1 p {
  position: relative;
  margin-bottom: 50px; }
  /* line 457, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-box-v1 p:after {
    content: " ";
    left: 50%;
    height: 1px;
    width: 50px;
    bottom: -15px;
    position: absolute;
    margin-left: -25px;
    background: #d50344; }

/* line 468, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-box-v1 ul {
  padding: 0 40px; }

/* line 471, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-box-v1 li {
  margin: 8px 0;
  font-size: 15px;
  padding-top: 8px;
  border-top: dotted 1px #d50344; }
  /* line 476, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-box-v1 li:hover {
    color: #d50344; }
  /* line 479, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-box-v1 li:first-child {
    padding-top: 0;
    border-top: none; }

/*Service Block v1
------------------------------------*/
/* line 489, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v1 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  padding: 45px 20px 20px;
  border: 1px solid #f2f2f2; }
  /* line 495, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v1 i {
    left: 50%;
    top: -30px;
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 16px;
    font-size: 30px;
    margin-left: -30px;
    text-align: center;
    position: absolute;
    background: #d50344;
    display: inline-block; }
  /* line 509, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v1 h3, .service-block-v1 p {
    margin-bottom: 20px; }

/*Service Block v2 (used in page_404_error2.html)
------------------------------------*/
/* line 517, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v2 .service-block-in {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 15px;
  background: rgba(255, 255, 255, 0.2); }
  /* line 522, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v2 .service-block-in i {
    font-size: 40px; }
  /* line 525, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v2 .service-block-in h4 {
    line-height: 25px; }
  /* line 528, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v2 .service-block-in p {
    margin-bottom: 20px; }
  /* line 531, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v2 .service-block-in i, .service-block-v2 .service-block-in h4, .service-block-v2 .service-block-in p {
    color: #fff; }

/*Service Block v3 (used in profile pages)
------------------------------------*/
/* line 539, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v3 {
  padding: 20px; }
  /* line 541, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 i {
    color: #fff;
    float: left;
    font-size: 50px;
    margin: 0 20px 20px 0; }
  /* line 547, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .service-heading {
    color: #fff;
    opacity: 0.8;
    line-height: 1; }
  /* line 553, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .service-in small {
    color: #fff;
    opacity: 0.8;
    line-height: 1; }
  /* line 558, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .service-in h4 {
    color: #fff; }
  /* line 562, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .counter {
    color: #fff; }
  /* line 565, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .service-heading {
    font-size: 16px;
    text-transform: uppercase; }
  /* line 569, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .counter {
    display: block;
    line-height: 1;
    font-size: 30px; }
  /* line 574, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .progress {
    margin-bottom: 7px; }
  /* line 578, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .service-in small {
    font-size: 16px;
    text-transform: uppercase; }
  /* line 582, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .service-in h4 {
    font-size: 16px;
    line-height: 0.8;
    margin-bottom: 0; }
  /* line 589, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .statistics .heading-xs {
    color: #fff;
    opacity: 0.8; }
  /* line 593, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .statistics small {
    color: #fff; }
  /* line 596, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .statistics .progress {
    background: #bbb; }
  /* line 599, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v3 .statistics .progress-bar-light {
    background: #fff; }

/*Service Innner*/
/*Statistics*/
/*Service Block v4
------------------------------------*/
/* line 612, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v4 {
  position: relative;
  background: #f7f7f7; }
  /* line 615, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v4:after {
    top: 0;
    width: 0;
    height: 0;
    left: 50%;
    content: '';
    margin-left: -20px;
    position: absolute;
    border-style: solid;
    border-width: 25px 20px 0 20px;
    border-color: #fff transparent transparent transparent; }
  /* line 627, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v4 .service-desc {
    text-align: center; }
    /* line 629, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .service-block-v4 .service-desc i {
      color: #d50344;
      display: block;
      font-size: 30px;
      margin-bottom: 20px; }
    /* line 635, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .service-block-v4 .service-desc h3 {
      font-size: 18px;
      margin-bottom: 15px;
      text-transform: uppercase; }

/*Service Block v5
------------------------------------*/
/* line 646, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v5 {
  text-align: center;
  background: #f7f7f7; }
  /* line 649, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v5 .service-inner {
    padding: 80px 40px; }
    /* line 651, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .service-block-v5 .service-inner i {
      margin-bottom: 30px; }
    /* line 654, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .service-block-v5 .service-inner span {
      display: block;
      font-size: 18px;
      font-weight: 200;
      margin-bottom: 15px;
      text-transform: uppercase; }
  /* line 662, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v5 .service-border {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee; }

@media (max-width: 992px) {
  /* line 669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v5 .service-border {
    border-left: none;
    border-right: none;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee; } }

/*Service Block v6
------------------------------------*/
/* line 681, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v6 i {
  float: left;
  margin: 5px 15px 0 0; }

/* line 685, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v6 .service-desc {
  overflow: hidden; }
  /* line 687, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v6 .service-desc h2 {
    font-size: 18px;
    margin: 0 0 10px;
    text-transform: uppercase; }
  /* line 692, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v6 .service-desc a {
    text-transform: uppercase; }

/*Service Block v7
------------------------------------*/
/* line 701, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v7 {
  margin-top: 20px;
  text-align: center;
  position: relative;
  border: 1px solid #ddd;
  padding: 45px 20px 20px; }
  /* line 707, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v7 i {
    left: 50%;
    top: -25px;
    color: #fff;
    width: 45px;
    height: 45px;
    padding: 10px;
    font-size: 25px;
    text-align: center;
    position: absolute;
    background: #d50344;
    margin-left: -22.5px;
    display: inline-block; }
  /* line 721, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v7 h3, .service-block-v7 p {
    margin-bottom: 20px; }

/*Service Block v8
------------------------------------*/
/* line 729, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.service-block-v8 {
  padding: 25px;
  background: #fff; }
  /* line 732, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v8 i {
    color: #555;
    float: left;
    font-size: 22px;
    margin: 5px 25px 0 0; }
  /* line 738, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .service-block-v8 .service-block-desc {
    overflow: hidden; }
    /* line 740, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .service-block-v8 .service-block-desc h3 {
      font-size: 18px;
      margin: 0 0 30px;
      position: relative;
      text-transform: uppercase; }
      /* line 745, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .service-block-v8 .service-block-desc h3:after {
        left: 0;
        top: 40px;
        height: 1px;
        width: 30px;
        content: " ";
        position: absolute;
        background: #d50344; }

/*Process v1
------------------------------------*/
/* line 762, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.process-v1 .process-in {
  text-align: center; }
  /* line 764, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .process-v1 .process-in > li i {
    z-index: 1;
    width: 30px;
    height: 30px;
    position: relative;
    text-align: center;
    background: #d50344;
    margin-bottom: 15px;
    display: inline-block;
    border: solid 9px #fff; }
    /* line 774, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .process-v1 .process-in > li i:after {
      top: -9px;
      left: -9px;
      right: -9px;
      bottom: -9px;
      content: " ";
      border-radius: 50%;
      position: absolute;
      border: 1px solid #bbb; }
  /* line 785, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .process-v1 .process-in .process-desc {
    padding: 0 20px; }
  /* line 788, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .process-v1 .process-in > li h3 {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px;
    text-transform: uppercase; }

/* line 795, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.process-v1 .process-border {
  position: relative; }
  /* line 797, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .process-v1 .process-border:before {
    top: 15px;
    width: 75%;
    left: 12%;
    content: " ";
    display: block;
    position: absolute;
    border-top: solid 2px #ddd; }

/*Process Description*/
/*Process Border*/
@media (max-width: 768px) {
  /* line 814, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .process-v1 .process-border:before {
    border: none; } }

/*Team v1
------------------------------------*/
/* line 823, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 ul {
  position: relative; }

/* line 827, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li > .team-img {
  position: relative; }
  /* line 829, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v1 li > .team-img:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: transparent;
    transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out; }

/* line 844, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li:hover > .team-img:after {
  background: rgba(0, 0, 0, 0.5); }

/* line 847, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li:hover > .team-img ul {
  top: 50%;
  visibility: visible; }

/* line 851, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li:hover > p:before {
  width: 100%;
  background: #d50344; }

/* line 856, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li > .team-img ul {
  left: 0;
  top: 45%;
  z-index: 1;
  padding: 0;
  width: 100%;
  height: 36px;
  list-style: none;
  margin-top: -18px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

/* line 875, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li > .team-img ul li {
  margin: 0 5px;
  text-align: center;
  display: inline-block; }

/* line 880, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li > .team-img li i {
  color: #eee;
  border-width: 2px;
  border-color: #eee;
  transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out; }
  /* line 888, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v1 li > .team-img li i.fa-twitter:hover {
    color: #fff;
    background: #3498db;
    border-color: #3498db; }
  /* line 893, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v1 li > .team-img li i.fa-facebook:hover {
    color: #fff;
    background: #4765a0;
    border-color: #4765a0; }
  /* line 898, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v1 li > .team-img li i.fa-google-plus:hover {
    color: #fff;
    background: #e74c3c;
    border-color: #e74c3c; }

/* line 905, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li > h3 {
  color: #000;
  font-size: 18px;
  line-height: 20px;
  margin: 15px 0 7px;
  text-transform: uppercase; }

/* line 912, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li > h4 {
  color: #999;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase; }

/* line 918, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v1 li > p {
  margin: 20px 0;
  position: relative; }
  /* line 921, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v1 li > p:before {
    left: 0;
    top: -10px;
    height: 1px;
    width: 40px;
    content: " ";
    position: absolute;
    background: #bbb;
    transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out; }

/*Team Image*/
/*Team Socials*/
/*Team Discription*/
/*Team v2
------------------------------------*/
/* line 948, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v2 {
  position: relative;
  margin-bottom: 20px; }
  /* line 951, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v2 img {
    width: 100%; }
  /* line 954, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v2 .inner-team {
    padding: 20px;
    background: #fff;
    text-align: center; }
    /* line 958, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v2 .inner-team h3 {
      margin: 0; }
    /* line 961, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v2 .inner-team small {
      display: block;
      font-size: 12px;
      margin-bottom: 7px; }
    /* line 966, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v2 .inner-team p {
      font-size: 13px; }
    /* line 969, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v2 .inner-team hr {
      margin: 10px 0 15px; }

/*Team-Social (Temporary)*/
/* line 977, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-social {
  margin-bottom: 0; }
  /* line 979, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-social li {
    padding: 0 2px; }
    /* line 981, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a {
      font-size: 14px;
      padding: 6px 4px;
      text-align: center;
      border-radius: 50% !important; }
    /* line 987, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li i {
      min-width: 20px; }
    /* line 991, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a.fb {
      color: #4862a3;
      border: 1px solid #4862a3; }
    /* line 995, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a.tw {
      color: #159ceb;
      border: 1px solid #159ceb; }
    /* line 999, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a.gp {
      color: #dc4a38;
      border: 1px solid #dc4a38; }
    /* line 1003, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a:hover {
      text-decoration: none; }
    /* line 1006, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a.fb:hover {
      color: #fff;
      background: #4862a3; }
    /* line 1010, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a.tw:hover {
      color: #fff;
      background: #159ceb; }
    /* line 1014, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-social li a.gp:hover {
      color: #fff;
      background: #dc4a38; }

/*Team v3
------------------------------------*/
/* line 1026, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-img {
  position: relative; }
  /* line 1028, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v3 .team-img:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out; }
  /* line 1040, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v3 .team-img:hover:after {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out; }
  /* line 1047, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v3 .team-img > img {
    width: 100%; }

/* line 1051, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-hover {
  left: 0;
  top: 60%;
  z-index: 1;
  padding: 0;
  width: 100%;
  padding: 20px;
  list-style: none;
  margin-top: -90px;
  text-align: center;
  visibility: hidden;
  position: absolute;
  transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out; }

/* line 1068, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-img:hover .team-hover {
  top: 50%;
  visibility: visible; }

/* line 1073, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-hover span, .team-v3 .team-hover small {
  color: #fff;
  display: block; }

/* line 1077, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-hover span {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase; }

/* line 1082, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-hover small {
  font-size: 13px;
  font-weight: 200;
  margin-bottom: 10px; }

/* line 1087, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-hover p {
  color: #fff;
  font-weight: 200;
  margin-bottom: 20px; }

/* line 1092, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v3 .team-hover .team-social-v3 i {
  color: #fff;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  display: inline-block;
  border: 1px solid #fff; }
  /* line 1101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v3 .team-hover .team-social-v3 i:hover {
    color: #666;
    background: #fff;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out; }

/*Team Hover Gradient*/
/*Team Social*/
/*Team v4
------------------------------------*/
/* line 1121, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v4 {
  text-align: center; }
  /* line 1123, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v4 img {
    width: 100%;
    margin-bottom: 20px; }
  /* line 1127, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v4 span {
    display: block;
    font-size: 18px;
    margin-bottom: 5px;
    text-transform: uppercase; }
  /* line 1133, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v4 small {
    color: #999;
    display: block;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 10px; }
  /* line 1140, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v4 .team-social-v4 a {
    color: #555;
    font-size: 16px; }
    /* line 1143, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v4 .team-social-v4 a:hover {
      color: #d50344;
      text-decoration: none; }

/*Team v5
------------------------------------*/
/* line 1153, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v5 {
  text-align: center; }
  /* line 1155, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v5 span {
    color: #777;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase; }
  /* line 1162, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v5 small {
    display: block;
    color: #d50344;
    font-size: 13px;
    font-style: italic;
    position: relative;
    margin-bottom: 25px; }
    /* line 1169, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v5 small:after {
      left: 50%;
      top: 30px;
      height: 1px;
      width: 30px;
      content: " ";
      background: #777;
      margin-left: -15px;
      position: absolute; }
  /* line 1180, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v5 .team-img {
    position: relative; }
    /* line 1182, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v5 .team-img:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      transition: all 0.4s ease-in-out;
      -o-transition: all 0.4s ease-in-out;
      -moz-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out; }
    /* line 1194, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v5 .team-img:hover:after {
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out; }
    /* line 1201, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v5 .team-img > img {
      width: 100%;
      margin-bottom: 20px; }
  /* line 1206, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v5 .team-hover {
    left: 0;
    top: 60%;
    z-index: 1;
    padding: 0;
    width: 100%;
    list-style: none;
    margin-top: -16px;
    text-align: center;
    visibility: hidden;
    position: absolute;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out; }
  /* line 1222, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v5 .team-img:hover .team-hover {
    top: 50%;
    visibility: visible; }
  /* line 1226, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v5 .team-hover .team-social-v5 i {
    color: #fff;
    width: 33px;
    height: 33px;
    padding: 9px;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    border: 1px solid #fff; }
    /* line 1235, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .team-v5 .team-hover .team-social-v5 i:hover {
      color: #666;
      background: #fff;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out; }

/*Team Hover Gradient*/
/*Social Network-*/
/*Team v6
------------------------------------*/
/* line 1254, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v6 {
  text-align: center; }
  /* line 1256, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v6 img {
    width: 100%;
    margin-bottom: 20px; }
  /* line 1260, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v6 span {
    color: #777;
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase; }
  /* line 1267, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v6 small {
    display: block;
    color: #d50344;
    font-size: 13px;
    margin-bottom: 15px; }

/*Team v7
------------------------------------*/
/* line 1279, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-v7-in {
  padding: 0 50px; }

/* line 1282, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-v7-name {
  color: #555;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase; }

/* line 1289, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-v7-position {
  display: block;
  color: #d50344;
  font-style: normal;
  margin-bottom: 15px; }

/* line 1295, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px; }

/* line 1300, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-v7-name {
  margin-top: 60px; }

/* line 1303, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .social-icons-v1 {
  margin-bottom: 60px; }

/* line 1306, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-v7-img {
  position: relative; }
  /* line 1308, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v7 .team-v7-img:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.1); }

/* line 1318, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-arrow-right {
  position: relative; }

/* line 1321, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-arrow-left {
  position: relative; }
  /* line 1323, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v7 .team-arrow-left:before {
    width: 0;
    height: 0;
    top: 20%;
    z-index: 2;
    content: " ";
    position: absolute;
    border-style: solid; }

/* line 1333, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-arrow-right:before {
  width: 0;
  height: 0;
  top: 20%;
  z-index: 2;
  content: " ";
  position: absolute;
  border-style: solid;
  right: -25px;
  border-width: 20px 0 20px 25px;
  border-color: transparent transparent transparent #fff; }

/* line 1345, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.team-v7 .team-arrow-left:before {
  left: -25px;
  border-width: 20px 25px 20px 0;
  border-color: transparent #fff transparent transparent; }

/*Team Space*/
/*Team Image*/
/*Team Info*/
/*Team Info Media Queries*/
@media (max-width: 991px) {
  /*Team Arrows*/
  /* line 1362, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v7 .team-arrow-left:before, .team-v7 .team-arrow-right:before {
    top: auto;
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px;
    border-color: #fff transparent transparent transparent; }
  /* line 1369, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v7 .team-arrow-left:before, .team-v7 .team-arrow-right:before {
    left: 70px;
    bottom: -25px;
    border-width: 25px 20px 0 20px; }
  /* line 1374, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .team-v7 .equal-height-column {
    height: auto !important; }
  /*Equal Height Columns*/ }

/*Call To Action v1
------------------------------------*/
/* line 1385, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.call-action-v1 p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0; }

/* line 1391, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.call-action-v1 .call-action-v1-box {
  width: 100%;
  display: table;
  padding: 30px 0;
  vertical-align: middle; }

/* line 1397, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.call-action-v1 .call-action-v1-in {
  display: table-cell;
  vertical-align: middle; }
  /* line 1400, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .call-action-v1 .call-action-v1-in.inner-btn {
    min-width: 250px;
    text-align: right; }

@media (max-width: 992px) {
  /* line 1408, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .call-action-v1 .call-action-v1-in {
    display: block;
    text-align: center; }
    /* line 1411, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .call-action-v1 .call-action-v1-in.inner-btn {
      display: block;
      text-align: center; }
    /* line 1415, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .call-action-v1 .call-action-v1-in p {
      margin-bottom: 20px; } }

/*Call To Axtion v2
------------------------------------*/
/* line 1424, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.call-action-v2 {
  padding: 50px 0;
  text-align: center;
  background: url(../img/patterns/16.png); }
  /* line 1428, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .call-action-v2 h2 {
    font-size: 22px;
    text-transform: uppercase; }
  /* line 1432, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .call-action-v2 p {
    padding: 0 100px;
    margin-bottom: 20px; }

@media (max-width: 768px) {
  /* line 1439, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .call-action-v2 p {
    padding: inherit; } }

/*Portfolio Box
------------------------------------*/
/*Portfolio Box*/
/* line 1448, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.portfolio-box {
  margin-bottom: 100px; }
  /* line 1450, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box a.fancybox {
    background: #fff;
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/ }
    /* line 1456, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box a.fancybox:after {
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute; }
    /* line 1465, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box a.fancybox:hover:after {
      background: rgba(0, 0, 0, 0.5); }
    /* line 1468, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box a.fancybox img {
      width: 100%;
      overflow: hidden;
      transition: all 500ms ease 0s; }
    /* line 1473, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box a.fancybox:hover img {
      transform: scale(1.1) rotate(0.1deg); }
  /* line 1477, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box .portfolio-box-in {
    left: 0;
    top: 50%;
    z-index: 1;
    width: 100%;
    min-height: 50px;
    margin-top: -25px;
    visibility: hidden;
    text-align: center;
    position: absolute; }
  /* line 1489, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box a.fancybox:hover .portfolio-box-in {
    visibility: visible; }
  /* line 1492, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box a.fancybox:after {
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; }
  /* line 1499, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box a.fancybox:hover:after, .portfolio-box a.fancybox:hover .portfolio-box-in i {
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; }
  /* line 1507, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box .portfolio-box-in i {
    color: #fff;
    width: 50px;
    height: 50px;
    padding: 16px;
    font-size: 20px;
    text-align: center;
    background: #d50344;
    display: inline-block; }
  /* line 1517, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box small.project-tag {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    color: #999; }
    /* line 1522, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box small.project-tag a {
      color: #999; }
    /* line 1525, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box small.project-tag i {
      margin-right: 5px; }
    /* line 1528, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box small.project-tag a:hover {
      color: #d50344; }

@media (min-width: 991px) and (max-width: 1199px) {
  /* line 1535, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box {
    margin-bottom: 60px; } }

@media (min-width: 768px) and (max-width: 992px) {
  /* line 1541, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box {
    margin-bottom: 0; } }

@media (max-width: 768px) {
  /* line 1547, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box {
    margin-bottom: 60px; } }

/*Project Tag*/
/*Portfolio Box v1
------------------------------------*/
/* line 1557, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.portfolio-box-v1 {
  margin: 0; }
  /* line 1559, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 [class^="col-"] {
    padding: 0;
    overflow: hidden; }
  /* line 1563, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 li {
    background: #333;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/ }
    /* line 1568, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v1 li:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute; }
    /* line 1576, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v1 li:hover:after {
      background: rgba(0, 0, 0, 0.7); }
    /* line 1579, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v1 li:after, .portfolio-box-v1 li:hover:after {
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; }
    /* line 1585, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v1 li img {
      width: 100%;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; }
    /* line 1593, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v1 li:hover img {
      -transform: scale(1.1);
      -o-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1); }
  /* line 1600, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 .portfolio-box-v1-in {
    left: 0;
    top: 50%;
    z-index: 1;
    width: 100%;
    visibility: hidden;
    min-height: 150px;
    margin-top: -75px;
    text-align: center;
    position: absolute;
    font-family: "Open Sans", Arial, sans-serif; }
  /* line 1612, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 li:hover .portfolio-box-v1-in {
    visibility: visible; }
  /* line 1616, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 .portfolio-box-v1-in h3 {
    font-size: 22px;
    margin-top: 20px;
    position: relative;
    margin-bottom: 20px;
    text-transform: uppercase; }
    /* line 1622, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v1 .portfolio-box-v1-in h3:after {
      left: 50%;
      height: 1px;
      width: 40px;
      content: " ";
      bottom: -10px;
      background: #fff;
      margin-left: -20px;
      position: absolute; }
  /* line 1633, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 .portfolio-box-v1-in p, .portfolio-box-v1 .portfolio-box-v1-in h3 {
    color: #fff; }
  /* line 1636, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 .portfolio-box-v1-in p {
    font-size: 16px;
    margin-bottom: 20px; }
  /* line 1640, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v1 .portfolio-box-v1-in a.btn-u {
    font-weight: normal;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/ }

/*Portfolio Feature v2
------------------------------------*/
/* line 1651, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.portfolio-box-v2 {
  margin: 0 0 60px; }
  /* line 1653, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v2 [class^="col-"] {
    padding: 0;
    overflow: hidden; }
  /* line 1657, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v2 li {
    background: #333;
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: hidden;
    /*For Chrome*/ }
    /* line 1663, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v2 li:after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute; }
    /* line 1671, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v2 li:hover:after {
      background: rgba(255, 255, 255, 0.6); }
    /* line 1674, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v2 li:after, .portfolio-box-v2 li:hover:after {
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; }
    /* line 1680, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v2 li img {
      width: 100%;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; }
    /* line 1688, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v2 li:hover img {
      -transform: scale(1.1);
      -o-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -webkit-transform: scale(1.1); }
  /* line 1695, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v2 .portfolio-box-v2-in {
    left: 0;
    top: 50%;
    z-index: 1;
    width: 100%;
    visibility: hidden;
    min-height: 60px;
    margin-top: -30px;
    text-align: center;
    position: absolute;
    font-family: "Open Sans", Arial, sans-serif; }
  /* line 1707, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v2 li:hover .portfolio-box-v2-in {
    visibility: visible; }
  /* line 1710, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .portfolio-box-v2 .portfolio-box-v2-in i {
    color: #fff;
    width: 60px;
    height: 60px;
    padding: 20px;
    font-size: 22px;
    cursor: pointer;
    margin-right: 5px;
    text-align: center;
    display: inline-block;
    background: rgba(114, 192, 44, 0.8); }
    /* line 1721, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .portfolio-box-v2 .portfolio-box-v2-in i:hover {
      background: #d50344; }

/*Thumbnail v1
------------------------------------*/
/* line 1731, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.thumbnails-v1 .thumbnail-img {
  margin-bottom: 12px; }

/* line 1734, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.thumbnails-v1 h3 {
  margin-bottom: 5px; }
  /* line 1736, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .thumbnails-v1 h3 a {
    color: #555;
    font-size: 15px;
    text-transform: uppercase; }

/* line 1742, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.thumbnails-v1 .read-more {
  color: #d50344;
  font-size: 12px;
  text-transform: uppercase; }

/*Image Backgrounds v1
------------------------------------*/
/* line 1752, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.bg-image-v1 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/11.jpg) repeat fixed; }
  /* line 1758, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .bg-image-v1:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.5); }

/*Image Backgrounds v2
------------------------------------*/
/* line 1772, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.bg-image-v2 {
  width: 100%;
  height: auto;
  padding: 50px 0;
  position: relative;
  background: url(../img/bg/7.jpg) repeat fixed; }
  /* line 1778, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .bg-image-v2:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(255, 255, 255, 0.7); }

/*Background Dark Cover*/
/* line 1792, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.bg-image-v2-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.5); }

/* line 1801, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.bg-image-v2-dark h2, .bg-image-v2-dark p {
  color: #eee !important; }

/*Parallax Quote
------------------------------------*/
/* line 1809, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-quote {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed; }
  /* line 1815, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.8); }
  /* line 1824, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: url(../img/patterns/gridtile.png) repeat; }

/*Parallax Quote Inner*/
/* line 1837, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-quote-in {
  z-index: 1;
  padding: 0 80px;
  position: relative; }
  /* line 1841, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote-in p {
    color: #fff;
    font-size: 28px;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif; }
    /* line 1846, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-quote-in p:after, .parallax-quote-in p:before {
      content: ' " ';
      position: absolute;
      font-family: Tahoma; }
    /* line 1851, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-quote-in p:after {
      margin-left: 3px; }
    /* line 1854, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-quote-in p:before {
      margin-left: -15px; }
  /* line 1858, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote-in small {
    color: #bbb;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase; }

@media (max-width: 768px) {
  /* line 1867, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote-in {
    padding: 0 40px; }
    /* line 1869, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-quote-in p {
      font-size: 20px; } }

/*Parallax Quote Light*/
/* line 1878, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-quote-light:after {
  background: rgba(255, 255, 255, 0.9); }

/* line 1881, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-quote-light .parallax-quote-in {
  background: #fff;
  padding: 30px 80px;
  border-bottom: solid 2px #ddd; }
  /* line 1885, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote-light .parallax-quote-in p {
    color: #555;
    font-size: 28px;
    font-weight: 200; }
  /* line 1890, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote-light .parallax-quote-in small {
    color: #555; }

@media (max-width: 768px) {
  /* line 1897, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-quote-light .parallax-quote-in {
    padding: 30px 40px; }
    /* line 1899, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-quote-light .parallax-quote-in p {
      font-size: 20px; }
    /* line 1902, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-quote-light .parallax-quote-in small {
      font-weight: 200; } }

/*Parallax Team
------------------------------------*/
/* line 1911, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
  position: relative; }
  /* line 1914, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-team .title-box-v2 {
    position: relative; }
  /* line 1917, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-team:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(255, 255, 255, 0.9); }

/* line 1928, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-team-dark:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(255, 255, 255, 0.9); }

/*Parallax Team*/
/* line 1940, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-team {
  background: url(../img/team/faces.jpg) 50% 0 repeat fixed;
  position: relative; }
  /* line 1943, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-team .title-box-v2 {
    position: relative; }

/* line 1948, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-team-dark:before {
  background: rgba(0, 0, 0, 0.8); }

/*Parallax Counter
------------------------------------*/
/* line 1955, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-bg {
  background: url(../img/patterns/5.png) repeat fixed; }

/*Parallax Counter
------------------------------------*/
/* line 1962, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-counter {
  padding: 30px 0;
  background: url(../img/patterns/5.png) repeat fixed; }
  /* line 1965, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter .counters {
    color: #fff;
    padding: 15px 0; }
  /* line 1969, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter h4 {
    color: #999;
    font-size: 16px;
    text-transform: uppercase; }
  /* line 1974, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter span.counter {
    font-size: 48px; }

/*Parallax Counter v1
------------------------------------*/
/* line 1982, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-counter-v1 {
  padding: 60px 0;
  background: url(../img/patterns/5.png) repeat fixed; }
  /* line 1985, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v1 .counters {
    color: #fff;
    padding: 15px 0 10px;
    border: solid 1px #555; }
  /* line 1990, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v1 h4 {
    color: #eee;
    font-size: 16px;
    text-transform: uppercase; }
  /* line 1995, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v1 span.counter {
    font-size: 42px;
    line-height: 48px; }

/*Parallax Counter v2
------------------------------------*/
/* line 2004, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-counter-v2 {
  position: relative;
  padding: 80px 0 60px;
  background: url(../img/patterns/16.png) repeat fixed; }
  /* line 2008, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v2:after {
    top: 0;
    left: 50%;
    content: " ";
    margin-left: -25px;
    position: absolute;
    border-top: 25px solid #fff;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent; }
  /* line 2018, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v2:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.02); }
  /* line 2027, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v2 .counters {
    padding: 20px 0;
    border-bottom: solid 2px #ddd;
    background: rgba(255, 255, 255, 0.9); }
    /* line 2031, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v2 .counters h4 {
      color: #555;
      font-weight: 200;
      font-family: "Open Sans", Arial, sans-serif; }
    /* line 2036, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v2 .counters span {
      color: #555;
      font-weight: 200;
      font-family: "Open Sans", Arial, sans-serif;
      font-size: 36px; }
    /* line 2042, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v2 .counters h4 {
      font-size: 22px;
      text-transform: uppercase; }

@media (max-width: 992px) {
  /* line 2050, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v2 .counters {
    margin-bottom: 0 !important; } }

/*Parallax Counter v3
------------------------------------*/
/* line 2058, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-counter-v3 {
  z-index: 1;
  height: auto;
  padding: 80px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/25.jpg) 50% 0 fixed; }
  /* line 2066, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v3:after {
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    content: " ";
    height: 100%;
    text-align: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.5); }
  /* line 2077, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v3 .features {
    width: 130px;
    height: 130px;
    padding: 25px;
    position: relative;
    text-align: center;
    display: inline-block;
    border-radius: 50% !important;
    background: rgba(255, 255, 255, 0.2); }
    /* line 2086, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v3 .features:after {
      top: -9px;
      left: -9px;
      right: -9px;
      bottom: -9px;
      content: " ";
      border-radius: 50%;
      position: absolute;
      border: 1px solid #fff; }
    /* line 2096, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v3 .features span {
      color: #fff;
      display: block;
      font-weight: 200; }
      /* line 2100, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .parallax-counter-v3 .features span.counter {
        font-size: 30px; }
      /* line 2103, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .parallax-counter-v3 .features span.features-info {
        text-transform: uppercase; }

@media (max-width: 550px) {
  /* line 2111, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v3 .features {
    width: 110px;
    height: 110px;
    padding: 22px; }
    /* line 2115, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v3 .features:after {
      top: -7px;
      left: -7px;
      right: -7px;
      bottom: -7px; }
    /* line 2122, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v3 .features span.counter {
      font-size: 20px; }
    /* line 2125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .parallax-counter-v3 .features span.features-info {
      font-size: 11px; } }

/*Parallax Counter v4
------------------------------------*/
/* line 2135, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.parallax-counter-v4 {
  z-index: 1;
  height: auto;
  padding: 30px 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background: url(../img/bg/19.jpg) 50% 0 fixed; }
  /* line 2143, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v4:after {
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    content: " ";
    height: 100%;
    text-align: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.5); }
  /* line 2154, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v4 i {
    color: #fff;
    display: block;
    font-size: 50px;
    margin-bottom: 20px; }
  /* line 2160, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v4 span.counter {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 10px; }
  /* line 2166, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .parallax-counter-v4 h4 {
    color: #fff;
    font-size: 18px;
    font-weight: 200;
    text-transform: uppercase; }

/*Flat Background Block
------------------------------------*/
/* line 2177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.flat-bg-block-v1 {
  padding: 40px 0;
  background: url(../img/patterns/16.png) repeat; }
  /* line 2181, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .flat-bg-block-v1 .checked-list.first-child {
    float: left;
    margin-right: 100px; }
  /* line 2185, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .flat-bg-block-v1 .checked-list li {
    color: #888;
    margin-bottom: 5px; }
  /* line 2189, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .flat-bg-block-v1 .checked-list i {
    color: #d50344;
    margin-right: 5px; }
  /* line 2194, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .flat-bg-block-v1 img.img-over {
    top: -70px;
    right: 10px;
    position: absolute; }

/*Image Over*/
@media (min-width: 991px) and (max-width: 1199px) {
  /* line 2204, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .flat-bg-block-v1 img.img-over {
    top: -18px; } }

@media (max-width: 991px) {
  /* line 2210, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .flat-bg-block-v1 img.img-over {
    position: static; } }

/*Flat Testimonials
------------------------------------*/
/* line 2218, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.flat-testimonials {
  padding: 50px 0;
  background-color: #333; }
  /* line 2221, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .flat-testimonials .flat-testimonials-in {
    padding: 30px;
    text-align: center;
    background: rgba(255, 255, 255, 0.2); }
    /* line 2225, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .flat-testimonials .flat-testimonials-in img {
      width: 135px;
      height: 135px;
      margin: 0 auto 25px; }
    /* line 2230, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .flat-testimonials .flat-testimonials-in h3 {
      color: #fff;
      font-size: 22px;
      font-weight: 200; }
    /* line 2235, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .flat-testimonials .flat-testimonials-in span {
      display: block;
      margin-bottom: 5px; }
    /* line 2239, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .flat-testimonials .flat-testimonials-in p {
      color: #fff;
      font-weight: 200;
      position: relative; }
      /* line 2243, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .flat-testimonials .flat-testimonials-in p:before, .flat-testimonials .flat-testimonials-in p:after {
        top: 5px;
        font-size: 22px;
        line-height: 10px;
        position: relative; }
      /* line 2249, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .flat-testimonials .flat-testimonials-in p:before {
        content: "“";
        padding-right: 5px; }
      /* line 2253, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .flat-testimonials .flat-testimonials-in p:after {
        content: "”";
        padding-left: 5px; }

/*Testimonials v4
------------------------------------*/
/* line 2264, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.testimonials-v4 {
  overflow: hidden; }
  /* line 2266, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v4 .testimonials-v4-in {
    background: #fff;
    position: relative;
    margin-bottom: 40px;
    padding: 20px 20px 20px 55px;
    -webkit-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1); }
    /* line 2274, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v4 .testimonials-v4-in:before {
      color: #bbb;
      font-size: 60px;
      content: "\201C";
      position: absolute;
      margin: -25px 0 0 -40px;
      font-family: Georgia, serif; }
    /* line 2282, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v4 .testimonials-v4-in:after {
      width: 0;
      height: 0;
      left: 80px;
      content: " ";
      bottom: -30px;
      position: absolute;
      border-style: solid;
      border-width: 30px 30px 0 0;
      border-color: #fff transparent transparent transparent;
      -webkit-filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1)); }
    /* line 2295, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v4 .testimonials-v4-in p {
      color: #777;
      font-size: 14px;
      font-style: italic; }
  /* line 2301, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v4 img {
    float: left;
    width: 60px;
    height: auto;
    margin: 0 20px; }
  /* line 2307, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v4 .testimonials-author {
    color: #555;
    display: block;
    font-size: 14px;
    overflow: hidden;
    padding-top: 8px;
    text-transform: uppercase; }
    /* line 2314, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v4 .testimonials-author em {
      font-size: 13px;
      text-transform: capitalize; }

/*Testimonials v5
------------------------------------*/
/* line 2324, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.testimonials-v5 {
  padding-top: 50px;
  padding-bottom: 50px; }
  /* line 2328, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v5 .testimonials-desc span {
    font-size: 13px;
    font-weight: 200; }
  /* line 2332, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v5 .testimonials-desc p {
    padding: 15px;
    position: relative;
    background: #f9f9f9;
    margin-bottom: 25px; }
    /* line 2337, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v5 .testimonials-desc p:after {
      width: 0;
      height: 0;
      left: 30px;
      color: #fff;
      bottom: -12px;
      content: " ";
      text-align: center;
      position: absolute;
      border-style: solid;
      border-width: 13px 10px 0 10px;
      border-color: #f9f9f9 transparent transparent transparent; }
  /* line 2352, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v5 .testimonials-by {
    margin-left: 20px; }
    /* line 2354, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v5 .testimonials-by img {
      float: left;
      width: 40px;
      height: auto;
      margin-right: 15px; }
    /* line 2360, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v5 .testimonials-by small {
      color: #d50344;
      font-size: 12px; }
    /* line 2364, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .testimonials-v5 .testimonials-by span {
      text-transform: uppercase; }

/*Testimonials By*/
/*Testimonials v6
------------------------------------*/
/* line 2376, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.testimonials-v6.testimonials-wrap {
  padding-left: 70px;
  padding-right: 70px; }

/* line 2380, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.testimonials-v6 .testimonials-info {
  padding: 20px;
  background: #fff;
  border-bottom: solid 3px #eee;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  /* line 2388, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v6 .testimonials-info:hover {
    border-color: #d50344; }
  /* line 2391, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v6 .testimonials-info img {
    float: left;
    width: 80px;
    height: auto;
    margin-right: 20px; }

/* line 2398, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.testimonials-v6 .testimonials-desc {
  overflow: hidden; }
  /* line 2400, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v6 .testimonials-desc p {
    margin-bottom: 15px; }
  /* line 2403, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v6 .testimonials-desc strong, .testimonials-v6 .testimonials-desc span {
    color: #555;
    display: block; }

@media (max-width: 768px) {
  /* line 2411, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .testimonials-v6 .testimonials-wrap {
    padding-left: 0;
    padding-right: 0; } }

/*Featured Blog
------------------------------------*/
/* line 2421, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.featured-blog h2 {
  font-size: 18px;
  margin: 0 0 25px;
  line-height: 25px;
  position: relative; }
  /* line 2426, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .featured-blog h2:after {
    left: 0;
    z-index: 1;
    width: 30px;
    height: 2px;
    content: " ";
    bottom: -10px;
    position: absolute;
    background: #d50344; }

/* line 2437, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.featured-blog .featured-img {
  position: relative; }
  /* line 2439, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .featured-blog .featured-img:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out; }
  /* line 2451, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .featured-blog .featured-img:hover:after {
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out; }
  /* line 2458, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .featured-blog .featured-img > img {
    width: 100%; }
  /* line 2461, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .featured-blog .featured-img i {
    top: 60%;
    left: 50%;
    z-index: 1;
    width: 100%;
    list-style: none;
    text-align: center;
    visibility: hidden;
    position: absolute;
    margin: -17.5px 0 0 -17.5px;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out; }
  /* line 2476, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .featured-blog .featured-img:hover i {
    top: 50%;
    visibility: visible; }
  /* line 2480, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .featured-blog .featured-img i {
    color: #fff;
    width: 35px;
    height: 35px;
    padding: 11px;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    background: rgba(255, 255, 255, 0.4); }
    /* line 2489, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .featured-blog .featured-img i:hover {
      color: #fff;
      background: #d50344;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out; }

/*Team Hover Gradient*/
/*Blog Comments
------------------------------------*/
/* line 2508, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-comments img {
  width: 80px;
  height: auto; }

/* line 2512, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative; }
  /* line 2516, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself:before {
    width: 0;
    height: 0;
    top: 20px;
    left: -17px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 15px 17px 15px 0;
    border-color: transparent #fff transparent transparent; }
  /* line 2527, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself h3 {
    margin: 0 0 10px; }
  /* line 2530, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself span {
    color: #999;
    float: right;
    font-size: 13px; }
  /* line 2535, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself p {
    color: #999; }

/* line 2539, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-comments.blog-comments-reply {
  margin-left: 70px; }

/*Comments Reply*/
/*Media Queries*/
@media (max-width: 768px) {
  /* line 2549, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself:before {
    display: none; }
  /* line 2552, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent; } }

/*Quote
------------------------------------*/
/* line 2569, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.quote-v1 {
  padding: 60px 0;
  text-align: center;
  background: url(../img/patterns/5.png) repeat; }
  /* line 2573, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .quote-v1 p {
    color: #fff;
    font-size: 22px;
    font-weight: 200;
    padding: 0 100px;
    position: relative;
    margin-bottom: 20px; }
    /* line 2580, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .quote-v1 p:before {
      color: #d50344;
      font-size: 60px;
      content: "\201C";
      position: absolute;
      margin: -30px 0 0 -40px;
      font-family: Georgia, serif; }
  /* line 2589, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .quote-v1 span {
    color: #fff;
    font-size: 18px; }

@media (max-width: 768px) {
  /* line 2596, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .quote-v1 p {
    font-size: 18px;
    padding: 0 50px; } }

/*Quote v2
------------------------------------*/
/* line 2605, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.quote-v2 {
  text-align: center;
  padding: 85px 10% 40px; }
  /* line 2608, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .quote-v2 p {
    color: #555;
    font-size: 16px;
    font-style: italic;
    position: relative;
    margin-bottom: 20px; }
    /* line 2614, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .quote-v2 p:before {
      left: 50%;
      top: -75px;
      font-size: 80px;
      content: "\201C";
      position: absolute;
      margin-left: -25px;
      font-family: Georgia, serif; }
  /* line 2624, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .quote-v2 span {
    color: #555;
    font-weight: 400; }

/*Team Social
------------------------------------*/
/* line 2633, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.social-icons-v1 i {
  color: #555;
  width: 33px;
  height: 33px;
  padding: 9px;
  font-size: 14px;
  text-align: center;
  background: #f7f7f7;
  display: inline-block; }
  /* line 2642, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .social-icons-v1 i:hover {
    color: #fff;
    background: #d50344;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out; }

/*Box Shadows
------------------------------------*/
/* line 2656, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.shadow-wrapper {
  z-index: 1;
  position: relative; }

/*Common Style*/
/* line 2663, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.box-shadow {
  background: #fff;
  position: relative; }
  /* line 2666, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .box-shadow:after, .box-shadow:before {
    top: 80%;
    left: 5px;
    width: 50%;
    z-index: -1;
    content: "";
    bottom: 15px;
    max-width: 300px;
    background: #999;
    position: absolute; }

/*Effect 1*/
/* line 2681, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.shadow-effect-1 {
  box-shadow: 0 10px 6px -6px #bbb;
  -moz-box-shadow: 0 10px 6px -6px #bbb;
  -webkit-box-shadow: 0 10px 6px -6px #bbb; }

/*Effect 2,3,4*/
/* line 2689, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.shadow-effect-2 {
  position: relative; }
  /* line 2691, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .shadow-effect-2:after, .shadow-effect-2:before {
    transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    box-shadow: 0 15px 10px #999;
    -moz-box-shadow: 0 15px 10px #999;
    -webkit-box-shadow: 0 15px 10px #999; }

/* line 2703, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.shadow-effect-3:before, .shadow-effect-4:after {
  transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
  -moz-box-shadow: 0 15px 10px #999;
  -webkit-box-shadow: 0 15px 10px #999; }

/* line 2714, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.shadow-effect-2:after, .shadow-effect-4:after {
  left: auto;
  right: 5px;
  transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -webkit-transform: rotate(3deg); }

/*Interactive Slider v1
------------------------------------*/
/* line 2727, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.interactive-slider-v1 {
  z-index: 1;
  padding: 130px 0;
  position: relative; }
  /* line 2731, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1.img-v1 {
    background: url(../img/bg/18.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 2736, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1.img-v2 {
    background: url(../img/bg/19.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 2741, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1.img-v3 {
    background: url(../img/bg/18.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 2746, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.3); }
  /* line 2756, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1 h2 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase; }
  /* line 2762, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1 p {
    color: #fff;
    font-size: 24px;
    font-weight: 200;
    text-transform: uppercase; }

@media (max-width: 768px) {
  /* line 2772, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1 h2 {
    font-size: 30px; }
  /* line 2775, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v1 p {
    font-size: 18px; } }

/*Interactive Slider v2
------------------------------------*/
/* line 2784, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.interactive-slider-v2 {
  z-index: 1;
  padding: 200px 0;
  position: relative;
  text-align: center;
  background: url(../img/bg/18.jpg) no-repeat;
  background-size: cover;
  background-position: center center; }
  /* line 2792, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v2.img-v1 {
    background: url(../img/bg/25.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 2797, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v2.img-v2 {
    background: url(../img/bg/14.jpg) no-repeat;
    background-size: cover;
    background-position: center center; }
  /* line 2802, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v2:after {
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.4); }
  /* line 2812, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v2 h1 {
    color: #fff;
    font-size: 70px;
    font-weight: 600;
    line-height: 80px;
    text-transform: uppercase; }
  /* line 2819, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v2 p {
    color: #fff;
    font-size: 24px;
    font-weight: 200;
    font-family: "Open Sans", Arial, sans-serif; }

@media (max-width: 768px) {
  /* line 2828, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .interactive-slider-v2 h1 {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 20px; } }

/*Blog Trending
------------------------------------*/
/* line 2839, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-trending li {
  margin-bottom: 20px; }

/* line 2842, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-trending h3 {
  font-size: 14px;
  margin: 0 0 5px;
  line-height: 17px; }

/* line 2847, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-trending small {
  color: #777; }
  /* line 2849, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-trending small a {
    color: #777; }
    /* line 2851, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-trending small a:hover {
      color: #d50344; }

/*Blog Latest Posts
------------------------------------*/
/* line 2862, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-latest-posts li {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd; }
  /* line 2866, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-latest-posts li:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none; }

/* line 2872, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-latest-posts h3 {
  font-size: 14px;
  margin: 0 0 3px;
  line-height: 20px; }

/* line 2877, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-latest-posts small {
  color: #777;
  margin-bottom: 8px;
  display: inline-block; }
  /* line 2879, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-latest-posts small a {
    color: #777; }

/* line 2885, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-latest-posts p {
  font-size: 12px; }

/*Blog Photostream
------------------------------------*/
/* line 2893, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-photostream {
  margin-right: -5px; }
  /* line 2895, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-photostream li {
    padding: 0;
    margin: 3px 5px 5px;
    position: relative; }
    /* line 2899, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-photostream li img {
      width: 78px;
      height: auto; }

/*Newsletter
------------------------------------*/
/* line 2910, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-newsletter p {
  color: #555;
  margin-bottom: 15px; }

/* line 2914, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-newsletter .form-control {
  border: none; }
  /* line 2916, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-newsletter .form-control:focus {
    box-shadow: none; }

/*Blog Post Quote
------------------------------------*/
/* line 2925, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-post-quote {
  padding: 35px 60px;
  background: #fff;
  text-align: center; }
  /* line 2929, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-post-quote p {
    color: #555;
    font-size: 32px;
    font-weight: 200;
    line-height: 45px;
    position: relative;
    margin-bottom: 20px;
    font-family: "Open Sans", Arial, sans-serif; }
    /* line 2937, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-post-quote p:before, .blog-post-quote p:after {
      top: -3px;
      font-size: 30px;
      position: relative; }
    /* line 2942, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-post-quote p:before {
      left: -10px;
      content: "\0022"; }
    /* line 2946, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-post-quote p:after {
      right: -10px;
      content: "\0022"; }
  /* line 2951, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-post-quote span {
    color: #555;
    font-size: 20px;
    font-weight: 200; }

@media (max-width: 768px) {
  /* line 2959, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-post-quote {
    padding: 35px 25px; }
    /* line 2961, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-post-quote p {
      font-size: 26px;
      line-height: 35px; } }

/*Blog Post Author
------------------------------------*/
/* line 2971, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-author {
  padding: 20px;
  background: #fff; }
  /* line 2974, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-author img {
    float: left;
    width: 80px;
    height: auto;
    margin-right: 20px; }
  /* line 2980, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-author .blog-author-desc {
    overflow: hidden; }
    /* line 2982, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-author .blog-author-desc h4 {
      float: left;
      font-size: 18px;
      margin: 0 0 10px;
      text-transform: uppercase; }
    /* line 2988, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-author .blog-author-desc ul {
      float: right;
      margin-top: 6px; }
    /* line 2992, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .blog-author .blog-author-desc p {
      color: #555; }

/*Blog Comments
------------------------------------*/
/* line 3002, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-comments img {
  width: 80px;
  height: auto; }

/* line 3006, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-comments .comments-itself {
  background: #fff;
  padding: 20px 30px;
  position: relative; }
  /* line 3010, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself:before {
    width: 0;
    height: 0;
    top: 20px;
    left: -17px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 15px 17px 15px 0;
    border-color: transparent #fff transparent transparent; }
  /* line 3021, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself h3 {
    margin: 0 0 10px; }
  /* line 3024, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself span {
    color: #555;
    float: right;
    font-size: 13px; }
  /* line 3029, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself p {
    color: #555; }

/* line 3033, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.blog-comments.blog-comments-reply {
  margin-left: 70px; }

/*Comments Reply*/
/*Media Queries*/
@media (max-width: 768px) {
  /* line 3043, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself:before {
    display: none; }
  /* line 3046, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .blog-comments .comments-itself:after {
    width: 0;
    height: 0;
    top: -17px;
    left: 20px;
    content: " ";
    position: absolute;
    border-style: solid;
    border-width: 0 15px 17px 15px;
    border-color: transparent transparent #fff transparent; } }

/*News v1
------------------------------------*/
/* line 3063, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-section {
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee; }

/* line 3069, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-v1 .news-v1-in {
  background: #fafafa; }

/* line 3072, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-v1 h3 {
  margin-bottom: 0;
  font-weight: 200;
  line-height: 28px;
  padding: 10px 15px 15px; }
  /* line 3077, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v1 h3 a {
    color: #555; }
    /* line 3079, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .news-v1 h3 a:hover {
      color: #d50344; }

/* line 3084, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-v1 p {
  color: #555;
  padding: 0 15px;
  margin-bottom: 20px; }

/* line 3089, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-v1 .news-v1-info {
  margin-left: 0;
  margin-top: 30px;
  overflow: hidden;
  padding: 8px 10px;
  border-top: solid 1px #eee; }
  /* line 3095, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v1 .news-v1-info li {
    font-size: 12px; }
    /* line 3097, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .news-v1 .news-v1-info li a:hover {
      color: #d50344;
      text-decoration: none; }

/*News v2
------------------------------------*/
/*News Badge*/
/* line 3110, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-v2 .news-v2-badge {
  overflow: hidden;
  position: relative; }
  /* line 3113, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v2 .news-v2-badge p {
    left: 0;
    top: 20px;
    background: #fff;
    padding: 5px 15px;
    text-align: center;
    position: absolute; }
  /* line 3121, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v2 .news-v2-badge span {
    color: #555;
    display: block;
    font-size: 16px;
    line-height: 16px; }
  /* line 3127, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v2 .news-v2-badge small {
    color: #555;
    display: block;
    font-size: 10px;
    text-transform: uppercase; }

/* line 3134, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-v2 .news-v2-desc {
  padding: 20px;
  background: #fff; }
  /* line 3137, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v2 .news-v2-desc h3 {
    margin: 0 0 3px;
    font-size: 16px; }
    /* line 3140, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .news-v2 .news-v2-desc h3 a {
      color: #555; }
      /* line 3142, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .news-v2 .news-v2-desc h3 a:hover {
        color: #d50344; }
  /* line 3147, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v2 .news-v2-desc small {
    color: #555;
    display: block;
    margin-bottom: 15px;
    text-transform: uppercase; }
  /* line 3153, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v2 .news-v2-desc p {
    color: #555; }

/*News Description*/
/*News v3
------------------------------------*/
/*News Posts*/
/* line 3165, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
.news-v3 {
  background: #fff; }
  /* line 3167, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .news-v3-in {
    padding: 35px 30px; }
  /* line 3170, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .news-v3-in-sm {
    padding: 20px; }
  /* line 3173, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 h2 {
    font-size: 32px;
    font-weight: 200;
    margin: 0 0 20px;
    line-height: 45px;
    text-transform: uppercase; }
  /* line 3180, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .news-v3-in-sm h2 {
    font-size: 22px;
    margin: 0 0 15px;
    line-height: 30px;
    text-transform: inherit; }
  /* line 3186, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 h2 a {
    color: #555; }
    /* line 3188, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .news-v3 h2 a:hover {
      color: #d50344;
      text-decoration: none; }
  /* line 3193, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 p {
    color: #555;
    margin-bottom: 20px; }
  /* line 3197, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .posted-info li {
    color: #555;
    font-style: italic; }
  /* line 3201, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .news-v3-in-sm .posted-info li {
    padding: 0;
    font-size: 12px;
    padding-left: 5px; }
  /* line 3206, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .posted-info li:before, .news-v3 .news-v3-in-sm .posted-info li:before {
    content: '/'; }
  /* line 3209, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .posted-info li:before {
    font-size: 12px;
    line-height: 12px;
    margin-right: 13px; }
  /* line 3214, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .news-v3-in-sm .posted-info li:before {
    font-size: 11px;
    line-height: 11px;
    margin-right: 9px; }
  /* line 3219, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .posted-info li:first-child:before, .news-v3 .news-v3-in-sm .posted-info li:first-child:before {
    content: " ";
    margin-right: 0; }
  /* line 3223, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .posted-info li a {
    color: #555; }
    /* line 3225, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .news-v3 .posted-info li a:hover {
      color: #d50344; }
  /* line 3229, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .post-shares {
    padding-left: 0;
    list-style: none;
    margin: 40px 0 0 -5px; }
  /* line 3234, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .news-v3-in-sm .post-shares {
    margin: 20px 0 0 -5px; }
  /* line 3237, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .post-shares li {
    position: relative;
    padding: 0 10px 0 5px;
    display: inline-block; }
    /* line 3241, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .news-v3 .post-shares li i {
      color: #555;
      width: 40px;
      height: 40px;
      padding: 13px;
      font-size: 16px;
      background: #eee;
      text-align: center;
      display: inline-block; }
      /* line 3250, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
      .news-v3 .post-shares li i:hover {
        background: #ddd; }
    /* line 3254, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
    .news-v3 .post-shares li span {
      top: -5px;
      right: 6px;
      color: #fff;
      width: 22px;
      height: 22px;
      padding: 4px;
      font-size: 10px;
      line-height: 10px;
      position: absolute;
      text-align: center;
      background: #d50344;
      border: 2px solid #fff;
      border-radius: 50% !important; }
  /* line 3270, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 .post-shares-lg li span {
    top: -10px;
    right: 0px;
    width: 29px;
    height: 29px;
    padding: 4px;
    font-size: 10px;
    line-height: 16px; }

@media (max-width: 768px) {
  /* line 3282, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_blocks.scss */
  .news-v3 h2 {
    font-size: 22px;
    line-height: 30px; } }

/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Parallax Slider (Main)
------------------------------------*/
/* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.slider-inner .da-slider {
  box-shadow: none;
  border-bottom: 2px solid #ddd; }

/* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.slider-inner .da-dots {
  z-index: 10; }

@media (max-width: 450px) {
  /* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .da-slide p {
    display: none; } }

@media (max-width: 900px) {
  /*Slider Parallax*/
  /* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .da-slide .da-img {
    display: none; } }

/*Revolution Slider
------------------------------------*/
/* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.tp-dottedoverlay {
  z-index: 1 !important; }

/* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.tp-bullets.preview4 .bullet {
  border-radius: 10px !important; }

/* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.tp-banner-container {
  position: relative; }
  /* line 48, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .tp-banner-container a.btn-u {
    color: #fff;
    border: solid 1px transparent; }
    /* line 51, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .tp-banner-container a.btn-u:hover {
      color: #fff;
      border: solid 1px transparent; }
  /* line 56, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .tp-banner-container a.re-btn-brd {
    background: none;
    border: solid 1px #fff; }
    /* line 59, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .tp-banner-container a.re-btn-brd:hover {
      color: #555;
      background: #fff; }
  /* line 65, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .tp-banner-container .btn-u.btn-brd {
    font-weight: 400; }

/*Fullwidth*/
/*Fullscreen*/
/* line 75, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.fullscreen-container {
  padding: 0;
  width: 100%;
  position: relative; }

/*Slider Contents v1*/
/* line 83, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.revolution-mch-1:after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  position: absolute;
  background: rgba(0, 0, 0, 0.3); }

/* line 94, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.revolution-ch1 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

/* line 103, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.revolution-ch2 {
  color: #fff;
  font-size: 20px;
  max-width: 470px;
  line-height: 32px;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif; }
  /* line 112, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .revolution-ch2:after {
    left: 50%;
    width: 70px;
    height: 2px;
    content: ' ';
    bottom: -20px;
    background: #fff;
    margin-left: -35px;
    position: absolute; }

/*Slider Contents v2*/
/* line 126, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.re-title-v1 {
  color: #fff;
  font-size: 75px;
  font-weight: 600;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

/* line 135, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.re-title-v2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif; }

/* line 145, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.re-text-v1 {
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Open Sans", Arial, sans-serif; }

/* line 152, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.re-text-v2 {
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", Arial, sans-serif; }

/*Slides*/
/* line 160, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.rs-caption-1 {
  color: #fff;
  font-size: 32px;
  font-weight: 200;
  padding: 6px 75px;
  position: relative;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff; }
  /* line 168, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .rs-caption-1:after, .rs-caption-1:before {
    font-size: 16px;
    content: "\f005";
    position: absolute;
    font-family: FontAwesome; }
  /* line 174, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .rs-caption-1:after {
    right: 4%; }
  /* line 177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .rs-caption-1:before {
    left: 4%; }

@media (max-width: 768px) {
  /* line 184, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .rs-caption-1:after, .rs-caption-1:before {
    content: ""; } }

/* line 190, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.rs-caption-2 {
  color: #fff;
  position: relative;
  font-size: 26px;
  font-weight: 200;
  line-height: 40px;
  padding: 10px 50px;
  text-align: center; }
  /* line 198, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .rs-caption-2:after {
    left: 50%;
    content: "";
    height: 1px;
    width: 100px;
    bottom: -10px;
    background: #fff;
    margin-left: -50px;
    position: absolute; }

/* line 210, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.rs-caption-3 a {
  margin: 0 5px;
  padding: 7px 20px;
  text-transform: uppercase; }

@media (max-width: 768px) {
  /* line 217, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .rs-caption-1, .rs-caption-2, .rs-caption-3 {
    font-weight: normal; } }

/*Layer Slider
------------------------------------*/
/* line 225, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.layer_slider {
  overflow: hidden; }

/* line 229, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.ls-ct-half, .ls-ct-center {
  border-radius: 100px !important; }

/*Sequence Slider (Home Page)
------------------------------------*/
@media (max-width: 900px) {
  /* line 236, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  #sequence-theme .info {
    top: 80px !important;
    width: 60% !important;
    margin-right: 50px; } }

@media (max-width: 450px) {
  /* line 245, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  #sequence-theme .info {
    top: 70px !important;
    width: 70% !important;
    margin-right: 25px; }
  /* line 250, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  #sequence-theme h2 {
    font-size: 30px !important; }
  /* line 253, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  #sequence-theme .info p {
    font-size: 18px !important; } }

/*Clients Carousel
------------------------------------*/
/* line 262, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.clients {
  border: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow: none !important;
  margin-bottom: 0 !important; }
  /* line 269, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients li {
    overflow: hidden;
    margin-left: 1px;
    position: relative;
    background: #fafafa; }
    /* line 274, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .clients li:hover {
      background: #f6f6f6; }
    /* line 277, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .clients li img.color-img {
      top: 0;
      left: 0;
      display: none;
      position: absolute; }
    /* line 283, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .clients li:hover img.color-img {
      display: block; }
    /* line 286, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .clients li img {
      filter: gray;
      /* IE6-9 */
      float: none !important;
      margin: 0 auto !important; }
      /* line 291, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
      .clients li img:hover {
        filter: none; }

/*Pie Chart
------------------------------------*/
/* line 302, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.pie-progress-charts .inner-pchart {
  text-align: center; }

/* line 305, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.pie-progress-charts .circle {
  color: #555;
  font-weight: 200;
  margin-bottom: 20px; }

/* line 310, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.pie-progress-charts .circle-title {
  font-size: 22px; }

@media (max-width: 767px) {
  /* line 316, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .pie-progress-charts .inner-pchart {
    margin-bottom: 40px; } }

/*Counter
------------------------------------*/
/* line 324, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.counters {
  color: #555;
  text-align: center; }
  /* line 327, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .counters span {
    font-size: 35px; }
    /* line 329, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .counters span.counter-icon {
      display: block;
      margin-bottom: 10px; }
      /* line 332, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
      .counters span.counter-icon i {
        color: #fff;
        padding: 16px;
        min-width: 65px;
        font-size: 30px;
        position: relative;
        background: #72c02c; }
        /* line 339, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
        .counters span.counter-icon i:after {
          width: 0;
          height: 0;
          left: 38%;
          content: " ";
          bottom: -7px;
          display: block;
          position: absolute;
          border-top: 7px solid #72c02c;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-left-style: inset;
          /*FF fixes*/
          border-right-style: inset;
          /*FF fixes*/ }
  /* line 358, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .counters:hover span.counter-icon i {
    border-color: #000; }

@media (max-width: 767px) {
  /* line 364, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .counters {
    margin-bottom: 30px; }
    /* line 366, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .counters:last-child {
      margin-bottom: inherit; } }

/*Back To Top
------------------------------------*/
/* line 375, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
#topcontrol {
  color: #fff;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #222;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important; }
  /* line 386, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  #topcontrol:after {
    top: -2px;
    left: 8.5px;
    content: "\f106";
    position: absolute;
    text-align: center;
    font-family: FontAwesome; }
  /* line 394, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  #topcontrol:hover {
    color: #fff;
    background: #72c02c;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

/*Owl Carousel
------------------------------------*/
/* line 407, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-btn {
  color: #777;
  cursor: pointer;
  font-size: 18px;
  padding: 2px 9px;
  text-align: center;
  background: #f5f5f5; }
  /* line 414, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-btn:hover {
    color: #fff;
    background: #72c02c; }

/*Owl Carousel v1*/
/* line 423, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v1 .owl-navigation {
  text-align: right;
  margin-bottom: 20px; }

/* line 427, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v1 .item {
  margin: 0 5px; }
  /* line 429, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-carousel-v1 .item img {
    text-align: center; }

/* line 433, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v1 .owl-pagination {
  display: none; }

/*Owl Carousel v2*/
/* line 441, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v2 .owl-slider-v2 {
  margin-bottom: 20px; }

/* line 444, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v2 .item {
  margin: 0 15px; }
  /* line 446, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-carousel-v2 .item img {
    text-align: center; }

/* line 450, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v2 .owl-navigation {
  text-align: center; }

/* line 453, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v2 .owl-pagination {
  display: none; }

/*Owl Carousel v3*/
/* line 461, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v3 .item {
  margin: 0 15px; }
  /* line 463, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-carousel-v3 .item img {
    text-align: center; }

/* line 467, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v3 .owl-pagination {
  display: none; }

/*Owl Carousel v4*/
/* line 475, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v4 .owl-wrapper-outer {
  margin-bottom: 20px; }

/* line 478, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v4 .item {
  margin: 0 3px; }
  /* line 480, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-carousel-v4 .item img {
    width: 100%; }

/*Owl Carousel v5*/
/* line 489, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v5 .owl-wrapper-outer {
  margin-bottom: 20px; }

/* line 493, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v5 .owl-controls .owl-page.active span, .owl-carousel-v5 .owl-controls.clickable .owl-page:hover span {
  background: #72c02c; }

/* line 496, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v5 .owl-controls .owl-page span {
  margin: 5px 3px !important; }

/*Owl Slider v6*/
/* line 505, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v6 .owl-carousel-item {
  margin: 0 10px;
  padding: 7px 0;
  text-align: center;
  position: relative;
  border: 1px solid #e4e2e2; }
  /* line 511, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-carousel-v6 .owl-carousel-item img {
    width: 150px;
    height: auto; }

/* line 516, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v6 .owl-wrapper-outer {
  margin-bottom: 20px; }

/* line 519, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-v6 .owl-controls .owl-page span {
  margin: 5px 3px !important; }

/*Owl Pagination*/
/* line 526, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-pagination {
  text-align: center !important; }

/* line 531, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/ }

/* line 536, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-theme .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
  *display: inline;
  /*IE7 life-saver */ }
  /* line 541, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-theme .owl-controls .owl-page span {
    width: 12px;
    height: 12px;
    opacity: 0.3;
    display: block;
    margin: 5px 7px;
    background: #bbb;
    filter: Alpha(Opacity=50);
    /*IE7 fix*/
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important; }

/*Owl Carousel Style v1*/
/* line 559, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-style-v1 img {
  width: 120px;
  height: auto; }

/*Owl Carousel Style v2*/
/* line 567, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-style-v2 .item {
  margin: 0 3px;
  background: #fafafa;
  text-align: center;
  border: solid 1px #eee; }
  /* line 572, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-carousel-style-v2 .item img {
    padding: 0 3px;
    display: inline-block; }
  /* line 576, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-carousel-style-v2 .item:hover {
    background: #f7f7f7;
    border-color: #e5e5e5;
    transition: all 0.4s ease-in-out; }

/* line 582, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-carousel-style-v2 .owl-pagination {
  display: none; }

/*Owl Clients v2
------------------------------------*/
/* line 591, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-clients-v1 img {
  width: 120px;
  height: auto; }

/* line 595, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-clients-v1 .owl-pagination {
  display: none; }

/*Owl Carousel - Testimonials v3
------------------------------------*/
/*Testimonials Section*/
/* line 604, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.testimonials-v3 {
  padding: 100px 0;
  font-family: "Open Sans", Arial, sans-serif; }
  /* line 607, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 ul {
    margin: 0;
    padding: 0;
    text-align: center;
    position: relative; }
  /* line 614, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 li img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px; }
  /* line 619, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 li > p {
    font-size: 14px; }
  /* line 623, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .testimonials-v3-title {
    font-size: 22px;
    font-weight: 200;
    margin-bottom: 20px; }
    /* line 627, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .testimonials-v3 .testimonials-v3-title p {
      color: #72c02c;
      margin-bottom: 0; }
    /* line 631, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .testimonials-v3 .testimonials-v3-title span {
      color: #777;
      display: block;
      font-size: 14px; }
  /* line 637, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .owl-pagination {
    display: none; }
  /* line 641, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .owl-buttons .owl-prev, .testimonials-v3 .owl-buttons .owl-next {
    padding: 13px;
    position: relative;
    margin: 15px 2px 0;
    background: #f5f5f5;
    display: inline-block; }
  /* line 648, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .owl-buttons .owl-prev:after, .testimonials-v3 .owl-buttons .owl-next:after {
    top: 0;
    color: #bbb;
    font-size: 16px;
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome; }
  /* line 657, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .owl-buttons .owl-prev:after {
    left: 10px;
    content: "\f104"; }
  /* line 661, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .owl-buttons .owl-next:after {
    right: 10px;
    content: "\f105"; }
  /* line 665, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .owl-buttons .owl-prev:hover:after, .testimonials-v3 .owl-buttons .owl-next:hover:after {
    color: #fff; }
  /* line 668, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .testimonials-v3 .owl-buttons .owl-prev:hover, .testimonials-v3 .owl-buttons .owl-next:hover {
    background: #72c02c;
    -webkit-transition: background 0.4s ease-in-out;
    -moz-transition: background 0.4s ease-in-out;
    -o-transition: background 0.4s ease-in-out;
    transition: background 0.4s ease-in-out; }

/*Testimonials Owl Navigation*/
/*Recent Works (Owl Carousel)
------------------------------------*/
/* line 684, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .owl-navigation {
  top: 3px;
  position: relative;
  margin-bottom: 10px; }

/* line 690, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item a {
  text-align: center; }
  /* line 692, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-work-v1 .item a:hover {
    text-decoration: none; }
    /* line 694, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .owl-work-v1 .item a:hover strong {
      color: #555; }

/* line 699, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item em.overflow-hidden {
  display: block; }

/* line 703, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee; }

/* line 708, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item a strong {
  color: #555;
  display: block; }

/* line 712, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item a i {
  color: #777; }

/* line 715, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item a img {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }

/* line 723, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item a:hover img {
  opacity: 0.8;
  -webkit-transform: scale(1.2) rotate(3deg);
  -moz-transform: scale(1.2) rotate(3deg);
  -o-transform: scale(1) rotate(3deg);
  -ms-transform: scale(1.2) rotate(3deg);
  transform: scale(1.2) rotate(3deg); }

/* line 731, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-work-v1 .item a:hover span {
  border-bottom: solid 2px #72c02c;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.3s ease-in-out; }

/*Parallax Twitter (Owl Carousel)
------------------------------------*/
/* line 746, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.parallax-twitter {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: #333 url(../img/bg/19.jpg) 50% 0 fixed;
  background-size: cover;
  background-position: center center; }
  /* line 754, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .parallax-twitter:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.5); }
  /* line 763, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .parallax-twitter:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: url(../img/patterns/gridtile.png) repeat; }

/* line 774, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.parallax-twitter-in {
  z-index: 1;
  position: relative; }
  /* line 777, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .parallax-twitter-in ul {
    margin: 0; }
  /* line 780, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .parallax-twitter-in li {
    color: #fff; }
    /* line 782, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .parallax-twitter-in li p {
      color: #fff;
      font-size: 18px;
      font-weight: 200; }
    /* line 787, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .parallax-twitter-in li a {
      color: #eee;
      text-decoration: underline; }
      /* line 790, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
      .parallax-twitter-in li a:hover {
        text-decoration: none; }
    /* line 794, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .parallax-twitter-in li span {
      color: #ddd;
      display: block;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px; }
  /* line 802, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .parallax-twitter-in .owl-page {
    width: 18px; }

/*Twitter Block (owl-controls)*/
/*Clients Section (Owl Carousel)
------------------------------------*/
/* line 812, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.clients-section {
  padding: 60px 0;
  position: relative;
  background: #333 url(../img/bg/7.jpg) 50% fixed;
  background-size: cover;
  background-position: center center; }
  /* line 818, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients-section:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.8); }
  /* line 827, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients-section:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: url(../img/patterns/gridtile.png) repeat; }
  /* line 837, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients-section .title-v1 p, .clients-section .title-v1 h2 {
    color: #fff; }
  /* line 841, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients-section ul {
    padding: 0;
    z-index: 1;
    list-style: none; }
  /* line 846, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients-section li {
    margin: 0 3px;
    background: #fff; }
    /* line 849, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
    .clients-section li a {
      padding: 10px;
      display: block;
      overflow: hidden;
      text-align: center;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; }
      /* line 858, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
      .clients-section li a:hover {
        -transform: scale(1.2);
        -o-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2); }
  /* line 866, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients-section img {
    width: 100px;
    height: 100px; }
  /* line 870, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .clients-section .owl-pagination {
    display: none; }

/*Owl Video
------------------------------------*/
/* line 879, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-video img {
  position: relative;
  text-align: center;
  margin: 0 auto 20px; }
  /* line 883, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
  .owl-video img.video-play {
    top: 50%;
    left: 50%;
    margin-top: -52px;
    margin-left: -52px;
    position: absolute; }

/* line 891, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_plugins.scss */
.owl-video .owl-controls .owl-page span {
  margin: 5px 3px; }

/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/*Reset Styles
------------------------------------*/
/* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
* {
  border-radius: 0 !important; }

/* line 16, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
a {
  outline: 0 !important; }
  /* line 18, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  a:focus, a:hover, a:active {
    outline: 0 !important; }

/* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
button {
  outline: 0 !important; }
  /* line 25, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  button:hover {
    outline: 0 !important; }

/* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
a:focus {
  text-decoration: none; }

/* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
hr {
  margin: 30px 0; }
  /* line 36, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  hr.hr-xs {
    margin: 10px 0; }
  /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  hr.hr-md {
    margin: 20px 0; }
  /* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  hr.hr-lg {
    margin: 40px 0; }

/*Headings*/
/* line 49, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1 {
  font-size: 28px;
  line-height: 35px; }

/* line 54, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2 {
  font-size: 24px;
  line-height: 33px; }

/* line 59, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h3 {
  font-size: 20px;
  line-height: 27px; }

/* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h4 {
  line-height: 25px; }

/* line 68, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h5 {
  line-height: 20px; }

/* line 72, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h6 {
  line-height: 18px; }

/* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1, h2, h3, h4, h5, h6 {
  color: #555;
  margin-top: 5px;
  text-shadow: none;
  font-weight: normal; }

/* line 83, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1 i, h2 i, h3 i, h4 i, h5 i, h6 i {
  margin-right: 5px; }

/*Block Headline*/
/* line 89, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px dotted #e4e9f0; }
  /* line 93, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline h2 {
    font-size: 22px;
    margin: 0 0 -2px 0;
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid #d50344; }
  /* line 100, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline h3, .headline h4 {
    margin: 0 0 -2px 0;
    padding-bottom: 5px;
    display: inline-block;
    border-bottom: 2px solid #d50344; }

/* line 108, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.headline-md {
  margin-bottom: 15px; }
  /* line 110, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-md h2 {
    font-size: 21px; }

/*Heading Options*/
/* line 117, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.heading {
  text-align: center; }
  /* line 119, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .heading h2 {
    padding: 0 12px;
    position: relative;
    display: inline-block;
    line-height: 34px !important;
    /*For Tagline Boxes*/ }
    /* line 125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .heading h2:before, .heading h2:after {
      content: ' ';
      width: 70%;
      position: absolute;
      border-width: 1px;
      border-color: #bbb; }
    /* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .heading h2:before {
      right: 100%; }
    /* line 135, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .heading h2:after {
      left: 100%; }

@media (max-width: 768px) {
  /* line 143, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .heading h2:before, .heading h2:after {
    width: 20%; } }

/*Headline v1*/
/* line 152, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.heading-v1 h2:before, .heading-v1 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: solid;
  border-bottom-style: solid; }

/*Headline v2*/
/* line 163, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.heading-v2 h2:before, .heading-v2 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: dashed;
  border-bottom-style: dashed; }

/*Headline v3*/
/* line 174, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.heading-v3 h2:before, .heading-v3 h2:after {
  top: 15px;
  height: 6px;
  border-top-style: dotted;
  border-bottom-style: dotted; }

/*Headline v4*/
/* line 185, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.heading-v4 h2:before, .heading-v4 h2:after {
  top: 17px;
  border-bottom-style: solid; }

/*Headline v5*/
/* line 194, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.heading-v5 h2:before, .heading-v5 h2:after {
  top: 17px;
  border-bottom-style: dashed; }

/*Headline v6*/
/* line 203, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.heading-v6 h2:before, .heading-v6 h2:after {
  top: 17px;
  border-bottom-style: dotted; }

/*Heading Titles v1*/
/* line 211, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.title-v1 {
  z-index: 1;
  position: relative;
  text-align: center;
  margin-bottom: 60px; }
  /* line 216, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .title-v1 h1, .title-v1 h2 {
    color: #444;
    font-size: 28px;
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-family: "Open Sans", Arial, sans-serif; }
  /* line 225, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .title-v1 h1:after, .title-v1 h2:after {
    bottom: 0;
    left: 50%;
    height: 1px;
    width: 70px;
    content: " ";
    margin-left: -35px;
    position: absolute;
    background: #d50344; }
  /* line 235, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .title-v1 p {
    font-size: 17px;
    font-weight: 200; }

/*Heading Titles v2*/
/* line 243, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.title-v2 {
  color: #555;
  position: relative;
  margin-bottom: 30px; }
  /* line 247, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  h2.title-v2:after {
    left: 0;
    width: 70px;
    height: 2px;
    content: " ";
    bottom: -10px;
    background: #555;
    position: absolute; }

/* line 258, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1.title-v2.title-center, h2.title-v2.title-center, h3.title-v2.title-center {
  text-align: center; }

/* line 262, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1.title-v2.title-center:after, h2.title-v2.title-center:after, h3.title-v2.title-center:after {
  left: 50%;
  width: 70px;
  margin-left: -35px; }

/* line 268, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1.title-light, h2.title-light, h3.title-light {
  color: #fff; }

/* line 272, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.title-light:after {
  background: #fff; }

/*Heading Title v3*/
/* line 278, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1[class^="title-v3-"], h2[class^="title-v3-"], h3[class^="title-v3-"] {
  color: #555; }

/* line 282, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.title-v3-xlg {
  font-size: 28px;
  line-height: 32px; }

/* line 287, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1.title-v3-lg, h2.title-v3-lg {
  font-size: 24px;
  line-height: 28px; }

/* line 292, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h1.title-v3-md {
  font-size: 20px;
  line-height: 24px; }

/* line 298, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.title-v3-md {
  font-size: 20px;
  line-height: 24px; }

/* line 302, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.title-v3-sm {
  font-size: 18px;
  line-height: 24px; }

/* line 309, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h3.title-v3-md {
  font-size: 18px;
  line-height: 24px;
  line-height: 22px; }

/* line 314, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h3.title-v3-sm {
  font-size: 16px;
  line-height: 20px; }

/* line 320, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.title-v3-xs {
  font-size: 16px;
  line-height: 22px; }

/* line 325, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h3.title-v3-xs {
  font-size: 14px;
  margin-bottom: 0; }

/*Headline Center*/
/* line 332, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.headline-center {
  text-align: center;
  position: relative; }
  /* line 335, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center h2 {
    color: #555;
    font-size: 24px;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 15px; }
    /* line 341, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .headline-center h2:after {
      left: 50%;
      z-index: 1;
      width: 30px;
      height: 2px;
      content: " ";
      bottom: -5px;
      margin-left: -15px;
      text-align: center;
      position: absolute;
      background: #d50344; }
  /* line 354, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center p {
    /*color: #999;*/
    font-size: 14px;
    /*padding: 0 150px;*/ }
  /* line 360, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center.headline-light h2 {
    color: #fff; }
  /* line 363, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center.headline-light p {
    color: #eee; }

@media (max-width: 991px) {
  /* line 370, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center p {
    padding: 0 50px; } }

/*Headline Center v2*/
/* line 377, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.headline-center-v2 {
  z-index: 0;
  text-align: center;
  position: relative; }
  /* line 381, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center-v2 h2 {
    color: #555;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase; }
  /* line 387, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center-v2 span.bordered-icon {
    color: #fff;
    padding: 0 10px;
    font-size: 15px;
    line-height: 18px;
    position: relative;
    margin-bottom: 25px;
    display: inline-block; }
    /* line 395, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .headline-center-v2 span.bordered-icon:before, .headline-center-v2 span.bordered-icon:after {
      top: 8px;
      height: 1px;
      content: " ";
      width: 100px;
      background: #fff;
      position: absolute; }
    /* line 403, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .headline-center-v2 span.bordered-icon:before {
      left: 100%; }
    /* line 406, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .headline-center-v2 span.bordered-icon:after {
      right: 100%; }
  /* line 410, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center-v2 p {
    color: #555;
    font-size: 14px;
    padding: 0 70px; }
  /* line 416, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center-v2.headline-center-v2-dark p {
    color: #666; }
  /* line 419, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-center-v2.headline-center-v2-dark span.bordered-icon {
    color: #666; }
    /* line 421, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .headline-center-v2.headline-center-v2-dark span.bordered-icon:before, .headline-center-v2.headline-center-v2-dark span.bordered-icon:after {
      background: #666; }

/*Headline Left*/
/* line 430, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.headline-left {
  position: relative; }
  /* line 432, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-left .headline-brd {
    color: #555;
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 10px; }
    /* line 437, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .headline-left .headline-brd:after {
      left: 1px;
      z-index: 1;
      width: 30px;
      height: 2px;
      content: " ";
      bottom: -5px;
      position: absolute;
      background: #d50344; }

/*Headline v2
------------------------------------*/
/* line 453, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.headline-v2 {
  display: block;
  background: #fff;
  padding: 1px 10px;
  margin: 0 0 20px 0;
  border-left: 2px solid #000; }
  /* line 459, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .headline-v2 h2 {
    margin: 3px 0;
    font-size: 20px;
    font-weight: 200; }

/*Heading Sizes
------------------------------------*/
/* line 470, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.heading-md {
  font-size: 20px;
  line-height: 24px; }

/* line 474, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.heading-sm {
  font-size: 18px;
  line-height: 24px; }

/* line 481, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h3.heading-md {
  font-size: 18px;
  line-height: 24px;
  line-height: 22px; }

/* line 486, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h3.heading-sm {
  font-size: 16px;
  line-height: 20px; }

/* line 492, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h2.heading-xs {
  font-size: 16px;
  line-height: 22px; }

/* line 497, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
h3.heading-xs {
  font-size: 14px;
  margin-bottom: 0; }

/*Devider
------------------------------------*/
/* line 506, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.devider.devider-dotted {
  border-top: 2px dotted #eee; }

/* line 509, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.devider.devider-dashed {
  border-top: 2px dashed #eee; }

/* line 512, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.devider.devider-db {
  height: 5px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }

/* line 517, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.devider.devider-db-dashed {
  height: 5px;
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd; }

/* line 522, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.devider.devider-db-dotted {
  height: 5px;
  border-top: 1px dotted #ddd;
  border-bottom: 1px dotted #ddd; }

/*Tables
------------------------------------*/
/*Basic Tables*/
/* line 533, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.table thead > tr > th {
  border-bottom: none; }

@media (max-width: 768px) {
  /* line 539, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .table th.hidden-sm, .table td.hidden-sm {
    display: none !important; } }

/*Forms
------------------------------------*/
/* line 548, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.form-control {
  box-shadow: none; }
  /* line 550, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .form-control:focus {
    border-color: #bbb;
    box-shadow: 0 0 2px #c9c9c9; }

/*Form Spacing*/
/* line 558, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.form-spacing .form-control {
  margin-bottom: 15px; }

/*Form Icons*/
/* line 564, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.input-group-addon {
  color: #b3b3b3;
  font-size: 14px;
  background: #fff; }

/*Carousel v1
------------------------------------*/
/* line 574, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.carousel-v1 .carousel-caption {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 7px 15px;
  background: rgba(0, 0, 0, 0.7); }
  /* line 580, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v1 .carousel-caption p {
    color: #fff;
    margin-bottom: 0; }

/* line 586, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.carousel-v1 .carousel-arrow a.carousel-control {
  opacity: 1;
  font-size: 30px;
  height: inherit;
  width: inherit;
  background: none;
  text-shadow: none;
  position: inherit; }

/* line 595, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.carousel-v1 .carousel-arrow a i {
  top: 50%;
  opacity: 0.6;
  background: #000;
  margin-top: -18px;
  padding: 2px 12px;
  position: absolute; }
  /* line 602, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v1 .carousel-arrow a i:hover {
    opacity: 0.8; }

/* line 606, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.carousel-v1 .carousel-arrow a.left i {
  left: 0; }

/* line 609, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.carousel-v1 .carousel-arrow a.right i {
  right: 0; }

/*Carousel v2
------------------------------------*/
/* line 618, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.carousel-v2 .carousel-control {
  opacity: 1;
  text-shadow: none; }
  /* line 621, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v2 .carousel-control:hover {
    opacity: 1;
    text-shadow: none; }
  /* line 625, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v2 .carousel-control.left, .carousel-v2 .carousel-control.right {
    top: 50%;
    z-index: 5;
    color: #eee;
    width: 45px;
    height: 45px;
    font-size: 30px;
    margin-top: -22px;
    position: absolute;
    text-align: center;
    display: inline-block;
    border: 2px solid #eee;
    background: rgba(0, 0, 0, 0.1); }
  /* line 639, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v2 .carousel-control:hover {
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  /* line 646, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v2 .carousel-control.left {
    left: 20px; }
  /* line 649, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v2 .carousel-control.right {
    right: 20px; }
  /* line 652, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v2 .carousel-control .arrow-prev {
    top: -5px;
    position: relative; }
  /* line 656, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-v2 .carousel-control .arrow-next {
    top: -5px;
    position: relative;
    right: -2px; }

@media (min-width: 768px) {
  /* line 664, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .carousel-indicators {
    bottom: 10px; } }

/*Tabs
------------------------------------*/
/*Tabs v1*/
/* line 674, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v1 .nav-tabs {
  border: none;
  background: none;
  border-bottom: solid 2px #d50344; }
  /* line 678, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tab-v1 .nav-tabs a {
    font-size: 14px;
    padding: 5px 15px; }
  /* line 683, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tab-v1 .nav-tabs > .active > a {
    color: #fff;
    border: none;
    background: #d50344; }
    /* line 687, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .tab-v1 .nav-tabs > .active > a:hover, .tab-v1 .nav-tabs > .active > a:focus {
      color: #fff;
      border: none;
      background: #d50344; }
  /* line 693, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tab-v1 .nav-tabs > li > a {
    border: none; }
    /* line 695, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .tab-v1 .nav-tabs > li > a:hover {
      color: #fff;
      background: #d50344; }

/* line 702, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v1 .tab-content {
  padding: 10px 0; }
  /* line 704, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tab-v1 .tab-content img {
    margin-top: 4px;
    margin-bottom: 15px; }
    /* line 707, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .tab-v1 .tab-content img.img-tab-space {
      margin-top: 7px; }

/*Tabs v2*/
/* line 717, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v2 .nav-tabs {
  border-bottom: none; }
  /* line 720, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tab-v2 .nav-tabs li a {
    padding: 9px 16px;
    background: none;
    border: none; }
  /* line 725, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tab-v2 .nav-tabs li.active a {
    background: #fff;
    padding: 7px 15px 9px;
    border: solid 1px #eee;
    border-top: solid 2px #d50344;
    border-bottom: none !important; }

/* line 734, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v2 .tab-content {
  padding: 10px 16px;
  border: solid 1px #eee; }

/*Tabs v3*/
/* line 744, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v3 .nav-pills li a {
  color: #777;
  font-size: 17px;
  padding: 4px 8px;
  margin-bottom: 3px;
  background: #fafafa;
  border: solid 1px #eee; }
  /* line 751, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tab-v3 .nav-pills li a:hover {
    color: #fff;
    background: #d50344;
    border: solid 1px #68af28; }

/* line 757, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v3 .nav-pills li.active a {
  color: #fff;
  background: #d50344;
  border: solid 1px #68af28; }

/* line 762, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v3 .nav-pills li i {
  width: 1.25em;
  margin-right: 5px;
  text-align: center;
  display: inline-block; }

/* line 769, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tab-v3 .tab-content {
  padding: 15px;
  background: #fafafa;
  border: solid 1px #eee; }

/*Accordions
------------------------------------*/
/*Accordion v1*/
/* line 780, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.acc-v1 .panel-heading {
  padding: 0;
  box-shadow: none; }
  /* line 783, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .acc-v1 .panel-heading a {
    display: block;
    font-size: 14px;
    padding: 5px 15px;
    background: #fefefe; }

/* line 792, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.acc-icon a.accordion-toggle i {
  color: #555;
  margin-right: 8px; }

/* line 796, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.acc-icon a.accordion-toggle:hover i {
  color: #39414c; }

/*Navigation
------------------------------------*/
/*Pegination*/
/* line 806, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pagination li a {
  color: #777;
  padding: 5px 15px; }
  /* line 809, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .pagination li a:hover {
    color: #fff;
    background: #5fb611;
    border-color: #5fb611; }

/* line 816, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  border-color: #d50344;
  background-color: #d50344; }

/*Pagination Without Space*/
/* line 825, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pagination-no-space .pagination {
  margin: 0; }

/*Pager*/
/* line 833, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager li > a:hover, .pager li > a:focus {
  color: #fff;
  background: #5fb611;
  border-color: #5fb611; }

/* line 839, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v2 li > a {
  border: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

/* line 846, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v3 li > a {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out; }

/* line 853, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v2 li > a:hover, .pager.pager-v2 li > a:focus {
  color: #fff;
  background: #d50344; }

/* line 859, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v3 li > a:hover, .pager.pager-v3 li > a:focus {
  color: #fff;
  background: #d50344; }

/* line 864, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v2 li.page-amount, .pager.pager-v3 li.page-amount {
  font-size: 16px;
  font-style: italic; }

/* line 868, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v2 li.page-amount {
  top: 7px;
  color: #777;
  position: relative; }
  /* line 872, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .pager.pager-v2 li.page-amount:hover, .pager.pager-v2 li.page-amount:focus {
    top: 7px;
    color: #777;
    position: relative; }

/* line 878, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v3 li.page-amount {
  top: 7px;
  color: #777;
  position: relative; }
  /* line 882, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .pager.pager-v3 li.page-amount:hover, .pager.pager-v3 li.page-amount:focus {
    top: 7px;
    color: #777;
    position: relative; }

/* line 888, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.pager.pager-v2.pager-md li a, .pager.pager-v3.pager-md li a {
  font-size: 16px;
  padding: 8px 18px; }

/*Pager v2 and v3
------------------------------------*/
/*Pager Amount*/
/*Pager Size*/
/*Sidebar Menu
------------------------------------*/
/*Sidebar Menu v1*/
/* line 906, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.sidebar-nav-v1 li {
  padding: 0; }
  /* line 908, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sidebar-nav-v1 li a {
    display: block;
    padding: 8px 30px 8px 10px; }
    /* line 911, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .sidebar-nav-v1 li a:hover {
      text-decoration: none; }
  /* line 915, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sidebar-nav-v1 li ul {
    padding: 0;
    list-style: none;
    background: #f8f8f8; }
  /* line 920, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sidebar-nav-v1 li.active ul a {
    background: #f8f8f8; }
  /* line 923, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sidebar-nav-v1 li ul a {
    color: #555;
    font-size: 12px;
    border-top: solid 1px #ddd;
    padding: 6px 30px 6px 17px; }

/* line 930, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.sidebar-nav-v1 > li.active {
  background: #717984;
  border-color: #ddd; }
  /* line 932, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sidebar-nav-v1 > li.active:hover {
    background: #717984;
    border-color: #ddd; }
  /* line 937, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sidebar-nav-v1 > li.active:focus {
    border-color: #ddd; }
  /* line 940, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sidebar-nav-v1 > li.active > a {
    color: #fff; }

/* line 945, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.sidebar-nav-v1 ul li:hover a, .sidebar-nav-v1 ul li.active a {
  color: #d50344; }

/*Sidebar Sub Navigation*/
/*Sidebar Badges*/
/* line 955, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item li > .badge {
  float: right; }

/* line 960, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.sidebar-nav-v1 span.badge {
  margin-top: 8px;
  margin-right: 10px; }

/* line 964, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.sidebar-nav-v1 .list-toggle > span.badge {
  margin-right: 25px; }

/* line 967, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.sidebar-nav-v1 ul li span.badge {
  margin-top: 8px;
  font-size: 11px;
  padding: 3px 5px;
  margin-right: 10px; }

/*Sidebar List Toggle*/
/* line 978, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-toggle:after {
  top: 7px;
  right: 10px;
  color: #777;
  font-size: 14px;
  content: "\f105";
  position: absolute;
  font-weight: normal;
  display: inline-block;
  font-family: FontAwesome; }

/* line 989, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-toggle.active:after {
  color: #fff;
  content: "\f107"; }

/*Button Styles
------------------------------------*/
/* line 998, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn {
  box-shadow: none; }

/* line 1002, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u {
  border: 0;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  padding: 6px 13px;
  position: relative;
  background: #A30234;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none; }
  /* line 1014, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u:hover {
    color: #fff;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  /* line 1022, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-block {
    text-align: center; }

/* line 1027, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
a.btn-u {
  /*padding: 4px 13px;*/
  /*vertical-align: middle;*/ }

/* line 1032, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u-sm, a.btn-u-sm {
  padding: 3px 12px; }

/* line 1036, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u-lg, a.btn-u-lg {
  font-size: 18px;
  padding: 10px 25px; }

/* line 1041, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u-xs, a.btn-u-xs {
  font-size: 12px;
  padding: 2px 12px;
  line-height: 18px; }

/*Button Groups*/
/* line 1050, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-group .dropdown-menu > li > a {
  padding: 3px 13px; }

/* line 1053, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-group > .btn-u {
  float: left;
  position: relative; }

/* line 1059, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-group-vertical > .btn-u {
  float: left;
  position: relative; }

/* line 1064, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-group > .btn-u:first-child {
  margin-left: 0; }

/*For FF Only*/
@-moz-document url-prefix() {
  /* line 1070, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .footer-subsribe .btn-u {
    padding-bottom: 4px; } }

@media (max-width: 768px) {
  @-moz-document url-prefix() {
    /* line 1078, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u {
      padding-bottom: 6px; } } }

/*Buttons Color*/
/* line 1087, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u:hover, .btn-u:focus, .btn-u:active, .btn-u.active {
  background: #0F4788;
  color: #fff; }

/* line 1093, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u {
  background: #0F4788; }

/* line 1097, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u-split.dropdown-toggle {
  border-left: solid 1px #0F4788; }

/* line 1101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-blue {
  background: #3498db; }
  /* line 1103, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-blue:hover, .btn-u.btn-u-blue:focus, .btn-u.btn-u-blue:active, .btn-u.btn-u-blue.active {
    background: #2980b9; }

/* line 1108, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-blue {
  background: #2980b9; }

/* line 1113, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-blue.dropdown-toggle {
  border-left: solid 1px #2980b9; }

/* line 1116, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-red {
  background: #e74c3c; }
  /* line 1118, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-red:hover, .btn-u.btn-u-red:focus, .btn-u.btn-u-red:active, .btn-u.btn-u-red.active {
    background: #c0392b; }

/* line 1124, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-red {
  background: #c0392b; }

/* line 1129, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-red.dropdown-toggle {
  border-left: solid 1px #c0392b; }

/* line 1132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-orange {
  background: #e67e22; }
  /* line 1134, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-orange:hover, .btn-u.btn-u-orange:focus, .btn-u.btn-u-orange:active, .btn-u.btn-u-orange.active {
    background: #d35400; }

/* line 1140, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-orange {
  background: #d35400; }

/* line 1145, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-orange.dropdown-toggle {
  border-left: solid 1px #d35400; }

/* line 1148, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-sea {
  background: #1abc9c; }
  /* line 1150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-sea:hover, .btn-u.btn-u-sea:focus, .btn-u.btn-u-sea:active, .btn-u.btn-u-sea.active {
    background: #16a085; }

/* line 1156, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-sea {
  background: #16a085; }

/* line 1161, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-sea.dropdown-toggle {
  border-left: solid 1px #16a085; }

/* line 1164, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-green {
  background: #2ecc71; }
  /* line 1166, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-green:hover, .btn-u.btn-u-green:focus, .btn-u.btn-u-green:active, .btn-u.btn-u-green.active {
    background: #27ae60; }

/* line 1172, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-green {
  background: #27ae60; }

/* line 1177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-green.dropdown-toggle {
  border-left: solid 1px #27ae60; }

/* line 1180, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-yellow {
  background: #f1c40f; }
  /* line 1182, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-yellow:hover, .btn-u.btn-u-yellow:focus, .btn-u.btn-u-yellow:active, .btn-u.btn-u-yellow.active {
    background: #f39c12; }

/* line 1188, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-yellow {
  background: #f39c12; }

/* line 1193, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-yellow.dropdown-toggle {
  border-left: solid 1px #f39c12; }

/* line 1196, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-default {
  background: #95a5a6; }
  /* line 1198, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-default:hover, .btn-u.btn-u-default:focus, .btn-u.btn-u-default:active, .btn-u.btn-u-default.active {
    background: #7f8c8d; }

/* line 1204, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-default {
  background: #7f8c8d; }

/* line 1209, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-default.dropdown-toggle {
  border-left: solid 1px #7f8c8d; }

/* line 1212, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-purple {
  background: #9b6bcc; }
  /* line 1214, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-purple:hover, .btn-u.btn-u-purple:focus, .btn-u.btn-u-purple:active, .btn-u.btn-u-purple.active {
    background: #814fb5; }

/* line 1220, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-purple {
  background: #814fb5; }

/* line 1225, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-purple.dropdown-toggle {
  border-left: solid 1px #814fb5; }

/* line 1228, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-aqua {
  background: #27d7e7; }
  /* line 1230, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-aqua:hover, .btn-u.btn-u-aqua:focus, .btn-u.btn-u-aqua:active, .btn-u.btn-u-aqua.active {
    background: #26bac8; }

/* line 1236, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-aqua {
  background: #26bac8; }

/* line 1241, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-aqua.dropdown-toggle {
  border-left: solid 1px #26bac8; }

/* line 1244, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-brown {
  background: #9c8061; }
  /* line 1246, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-brown:hover, .btn-u.btn-u-brown:focus, .btn-u.btn-u-brown:active, .btn-u.btn-u-brown.active {
    background: #81674b; }

/* line 1252, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-brown {
  background: #81674b; }

/* line 1257, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-brown.dropdown-toggle {
  border-left: solid 1px #81674b; }

/* line 1260, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-dark-blue {
  background: #4765a0; }
  /* line 1262, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-dark-blue:hover, .btn-u.btn-u-dark-blue:focus, .btn-u.btn-u-dark-blue:active, .btn-u.btn-u-dark-blue.active {
    background: #324c80; }

/* line 1268, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-dark-blue {
  background: #324c80; }

/* line 1273, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-dark.dropdown-toggle {
  border-left: solid 1px #324c80; }

/* line 1276, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-light-green {
  background: #79d5b3; }
  /* line 1278, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-light-green:hover, .btn-u.btn-u-light-green:focus, .btn-u.btn-u-light-green:active, .btn-u.btn-u-light-green.active {
    background: #59b795; }

/* line 1284, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-light-green {
  background: #59b795; }

/* line 1289, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-light-green.dropdown-toggle {
  border-left: solid 1px #59b795; }

/* line 1292, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-dark {
  background: #555; }
  /* line 1294, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-dark:hover, .btn-u.btn-u-dark:focus, .btn-u.btn-u-dark:active, .btn-u.btn-u-dark.active {
    background: #333; }

/* line 1300, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-dark {
  background: #333; }

/* line 1305, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-dark.dropdown-toggle {
  border-left: solid 1px #333; }

/* line 1308, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-light-grey {
  background: #585f69; }
  /* line 1310, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-u-light-grey:hover, .btn-u.btn-u-light-grey:focus, .btn-u.btn-u-light-grey:active, .btn-u.btn-u-light-grey.active {
    background: #484f58; }

/* line 1316, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.open .dropdown-toggle.btn-u.btn-u-light-grey {
  background: #484f58; }

/* line 1321, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-u-split-light-grey.dropdown-toggle {
  border-left: solid 1px #484f58; }

/* line 1324, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.btn-u.btn-brd {
  color: #555;
  /*font-weight: 200;*/
  background: none;
  padding: 5px 13px;
  border: solid 1px transparent;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  border-color: #d50344; }
  /* line 1334, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd:hover {
    background: none;
    border: solid 1px #eee; }
  /* line 1338, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd:focus {
    background: none; }
  /* line 1341, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-brd-hover:hover {
    color: #fff !important; }
  /* line 1345, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd:hover {
    color: #5fb611;
    border-color: #5fb611; }
  /* line 1349, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-brd-hover:hover {
    background: #5fb611; }
  /* line 1352, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-blue {
    border-color: #3498db; }
    /* line 1354, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-blue:hover {
      color: #2980b9;
      border-color: #2980b9; }
    /* line 1358, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-blue.btn-brd-hover:hover {
      background: #2980b9; }
  /* line 1362, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-red {
    border-color: #e74c3c; }
    /* line 1364, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-red:hover {
      color: #c0392b;
      border-color: #c0392b; }
    /* line 1368, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-red.btn-brd-hover:hover {
      background: #c0392b; }
  /* line 1372, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-orange {
    border-color: #e67e22; }
    /* line 1374, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-orange:hover {
      color: #d35400;
      border-color: #d35400; }
    /* line 1378, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-orange.btn-brd-hover:hover {
      background: #d35400; }
  /* line 1382, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-sea {
    border-color: #1abc9c; }
    /* line 1384, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-sea:hover {
      color: #16a085;
      border-color: #16a085; }
    /* line 1388, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-sea.btn-brd-hover:hover {
      background: #16a085; }
  /* line 1392, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-green {
    border-color: #2ecc71; }
    /* line 1394, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-green:hover {
      color: #27ae60;
      border-color: #27ae60; }
    /* line 1398, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-green.btn-brd-hover:hover {
      background: #27ae60; }
  /* line 1402, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-yellow {
    border-color: #f1c40f; }
    /* line 1404, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-yellow:hover {
      color: #f39c12;
      border-color: #f39c12; }
    /* line 1408, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-yellow.btn-brd-hover:hover {
      background: #f39c12; }
  /* line 1412, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-default {
    border-color: #95a5a6; }
    /* line 1414, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-default:hover {
      color: #7f8c8d;
      border-color: #7f8c8d; }
    /* line 1418, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-default.btn-brd-hover:hover {
      background: #7f8c8d; }
  /* line 1422, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-dark {
    border-color: #555; }
    /* line 1424, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-dark:hover {
      color: #333;
      border-color: #333; }
    /* line 1428, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-dark.btn-brd-hover:hover {
      background: #333; }
  /* line 1432, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-light-grey {
    border-color: #585f69; }
    /* line 1434, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-light-grey:hover {
      color: #484f58;
      border-color: #484f58; }
    /* line 1438, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-light-grey.btn-brd-hover:hover {
      background: #484f58; }
  /* line 1442, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-purple {
    border-color: #9b6bcc; }
    /* line 1444, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-purple:hover {
      color: #814fb5;
      border-color: #814fb5; }
    /* line 1448, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-purple.btn-brd-hover:hover {
      background: #814fb5; }
  /* line 1452, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-aqua {
    border-color: #27d7e7; }
    /* line 1454, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-aqua:hover {
      color: #26bac8;
      border-color: #26bac8; }
    /* line 1458, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-aqua.btn-brd-hover:hover {
      background: #26bac8; }
  /* line 1462, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-brown {
    border-color: #9c8061; }
    /* line 1464, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-brown:hover {
      color: #81674b;
      border-color: #81674b; }
    /* line 1468, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-brown.btn-brd-hover:hover {
      background: #81674b; }
  /* line 1472, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-dark-blue {
    border-color: #4765a0; }
    /* line 1474, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-dark-blue:hover {
      color: #324c80;
      border-color: #324c80; }
    /* line 1478, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-dark-blue.btn-brd-hover:hover {
      background: #324c80; }
  /* line 1482, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-light-green {
    border-color: #79d5b3; }
    /* line 1484, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-light-green:hover {
      color: #59b795;
      border-color: #59b795; }
    /* line 1488, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-light-green.btn-brd-hover:hover {
      background: #59b795; }
  /* line 1492, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .btn-u.btn-brd.btn-u-light {
    color: #fff;
    border-color: #fff; }
    /* line 1495, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-light:hover {
      border-color: #fff; }
    /* line 1498, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .btn-u.btn-brd.btn-u-light.btn-brd-hover:hover {
      background: #fff;
      color: #555 !important; }

/*Bordered Buttons*/
/*Dropdown Buttons
------------------------------------*/
/* line 1511, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dropdown-show {
  box-shadow: 0 0 4px #eee;
  display: inline-block;
  position: relative; }

/*Badges and Labels
------------------------------------*/
/*Labels*/
/* line 1522, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label {
  font-size: 11px;
  font-weight: 400;
  padding: 4px 7px; }

/* line 1527, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.badge {
  color: #fff;
  font-weight: 400;
  padding: 4px 7px; }

/*Badges*/
/* line 1536, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge {
  color: #fff;
  font-weight: 400;
  padding: 4px 7px; }

/* line 1543, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-u, span.badge-u {
  background: #d50344; }

/* line 1548, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-u {
  background: #d50344; }

/* line 1553, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-blue, span.badge-blue {
  background: #3498db; }

/* line 1558, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge {
  background: #3498db; }

/* line 1563, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-red, span.badge-red {
  background: #e74c3c; }

/* line 1568, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge {
  background: #e74c3c; }

/* line 1573, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-green, span.badge-green {
  background: #2ecc71; }

/* line 1578, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-green {
  background: #2ecc71; }

/* line 1583, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-sea, span.badge-sea {
  background: #1abc9c; }

/* line 1588, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-sea {
  background: #1abc9c; }

/* line 1593, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-orange, span.badge-orange {
  background: #e67e22; }

/* line 1598, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-orange {
  background: #e67e22; }

/* line 1603, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-yellow, span.badge-yellow {
  background: #f1c40f; }

/* line 1608, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-yellow {
  background: #f1c40f; }

/* line 1613, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-purple, span.badge-purple {
  background: #9b6bcc; }

/* line 1618, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-purple {
  background: #9b6bcc; }

/* line 1623, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-aqua, span.badge-aqua {
  background: #27d7e7; }

/* line 1628, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-aqua {
  background: #27d7e7; }

/* line 1633, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-brown, span.badge-brown {
  background: #9c8061; }

/* line 1638, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-brown {
  background: #9c8061; }

/* line 1643, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-dark-blue, span.badge-dark-blue {
  background: #4765a0; }

/* line 1648, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-dark-blue {
  background: #4765a0; }

/* line 1653, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-light-green, span.badge-light-green {
  background: #79d5b3; }

/* line 1658, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-light-green {
  background: #79d5b3; }

/* line 1663, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-light, span.badge-light {
  color: #777;
  background: #ecf0f1; }

/* line 1669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-light {
  color: #777;
  background: #ecf0f1; }

/* line 1675, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
span.label-dark, span.badge-dark {
  background: #555; }

/* line 1680, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-group-item.active > .badge.badge-dark {
  background: #555; }

/*Badge Lists*/
/* line 1687, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-lists li {
  position: relative; }

/* line 1690, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-lists span.badge {
  top: -10px;
  right: -6px;
  position: absolute; }

/* line 1696, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-lists.badge-icons span.badge {
  min-width: 12px;
  padding: 3px 6px; }

/* line 1700, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-lists.badge-icons i {
  font-size: 18px;
  min-width: 25px; }

/*Badge Icons*/
/*Badge Box v1*/
/* line 1711, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-box-v1 a {
  color: #777;
  min-width: 40px;
  font-size: 18px;
  padding: 8px 9px;
  display: inline-block;
  border: solid 1px #eee; }

/*Badge Box v2*/
/* line 1722, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-box-v2 a {
  color: #777;
  font-size: 12px;
  padding: 10px;
  min-width: 70px;
  text-align: center;
  display: inline-block;
  border: solid 1px #eee; }
  /* line 1730, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .badge-box-v2 a i {
    font-size: 20px; }

/*General Badge Box*/
/* line 1737, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-box-v1 a i, .badge-box-v2 a i {
  display: block;
  margin: 1px auto 2px; }

/* line 1742, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.badge-box-v1 a:hover, .badge-box-v2 a:hover {
  color: #555;
  border-color: #555;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

/*Icons
------------------------------------*/
/*Social Icons*/
/* line 1756, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social-icons {
  margin: 0;
  padding: 0; }
  /* line 1759, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .social-icons li {
    list-style: none;
    margin-right: 3px;
    margin-bottom: 5px;
    text-indent: -9999px;
    display: inline-block; }
    /* line 1765, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .social-icons li a {
      width: 28px;
      height: 28px;
      display: block;
      background-position: 0 0;
      background-repeat: no-repeat;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; }

/* line 1780, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
a.social-icon {
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

/* line 1793, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social-icons li:hover a {
  background-position: 0 -38px; }

/* line 1797, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social-icons-color li a {
  opacity: 0.7;
  background-position: 0 -38px !important;
  -webkit-backface-visibility: hidden;
  /*For Chrome*/ }
  /* line 1802, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .social-icons-color li a:hover {
    opacity: 1; }

/* line 1807, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_amazon {
  background: url(../img/icons/social/amazon.png) no-repeat; }

/* line 1811, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_behance {
  background: url(../img/icons/social/behance.png) no-repeat; }

/* line 1815, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_blogger {
  background: url(../img/icons/social/blogger.png) no-repeat; }

/* line 1819, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_deviantart {
  background: url(../img/icons/social/deviantart.png) no-repeat; }

/* line 1823, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_dribbble {
  background: url(../img/icons/social/dribbble.png) no-repeat; }

/* line 1827, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_dropbox {
  background: url(../img/icons/social/dropbox.png) no-repeat; }

/* line 1831, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_evernote {
  background: url(../img/icons/social/evernote.png) no-repeat; }

/* line 1835, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_facebook {
  background: url(../img/icons/social/facebook.png) no-repeat; }

/* line 1839, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_forrst {
  background: url(../img/icons/social/forrst.png) no-repeat; }

/* line 1843, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_github {
  background: url(../img/icons/social/github.png) no-repeat; }

/* line 1847, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_googleplus {
  background: url(../img/icons/social/googleplus.png) no-repeat; }

/* line 1851, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_jolicloud {
  background: url(../img/icons/social/jolicloud.png) no-repeat; }

/* line 1855, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_last-fm {
  background: url(../img/icons/social/last-fm.png) no-repeat; }

/* line 1859, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_linkedin {
  background: url(../img/icons/social/linkedin.png) no-repeat; }

/* line 1863, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_picasa {
  background: url(../img/icons/social/picasa.png) no-repeat; }

/* line 1867, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_pintrest {
  background: url(../img/icons/social/pintrest.png) no-repeat; }

/* line 1871, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_rss {
  background: url(../img/icons/social/rss.png) no-repeat; }

/* line 1875, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_skype {
  background: url(../img/icons/social/skype.png) no-repeat; }

/* line 1879, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_spotify {
  background: url(../img/icons/social/spotify.png) no-repeat; }

/* line 1883, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_stumbleupon {
  background: url(../img/icons/social/stumbleupon.png) no-repeat; }

/* line 1887, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_tumblr {
  background: url(../img/icons/social/tumblr.png) no-repeat; }

/* line 1891, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_twitter {
  background: url(../img/icons/social/twitter.png) no-repeat; }

/* line 1895, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_vimeo {
  background: url(../img/icons/social/vimeo.png) no-repeat; }

/* line 1899, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_wordpress {
  background: url(../img/icons/social/wordpress.png) no-repeat; }

/* line 1903, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_xing {
  background: url(../img/icons/social/xing.png) no-repeat; }

/* line 1907, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_yahoo {
  background: url(../img/icons/social/yahoo.png) no-repeat; }

/* line 1911, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_youtube {
  background: url(../img/icons/social/youtube.png) no-repeat; }

/* line 1915, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_vk {
  background: url(../img/icons/social/vk.png) no-repeat; }

/* line 1919, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.social_instagram {
  background: url(../img/icons/social/instagram.png) no-repeat; }

/*Font Awesome Icon Styles*/
/* line 1926, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-custom {
  color: #555;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;
  border: solid 1px #555; }

/* line 1937, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-sm {
  width: 35px;
  height: 35px;
  font-size: 16px;
  line-height: 35px; }

/* line 1943, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-md {
  width: 55px;
  height: 55px;
  font-size: 22px;
  line-height: 55px; }

/* line 1949, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-lg {
  width: 60px;
  height: 60px;
  font-size: 31px;
  line-height: 60px;
  margin-bottom: 10px; }

/* line 1956, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-2x {
  font-size: 30px; }

/* line 1959, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-3x {
  font-size: 40px; }

/* line 1962, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-4x {
  font-size: 50px; }

/* line 1965, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-line {
  font-size: 17px; }

/* line 1968, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-sm.icon-line {
  font-size: 14px; }

/* line 1971, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-md.icon-line {
  font-size: 22px; }

/* line 1974, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-lg.icon-line {
  font-size: 28px; }

/* line 1977, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-2x.icon-line {
  font-size: 27px; }

/* line 1980, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-3x.icon-line {
  font-size: 36px; }

/* line 1983, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-4x.icon-line {
  font-size: 47px; }

/*Line Icons*/
/*Icon Styles For Links*/
/* line 1992, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.link-icon, .link-bg-icon {
  color: #555; }

/* line 1996, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.link-icon:hover, .link-bg-icon:hover {
  border: none;
  text-decoration: none; }

/* line 2001, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.link-icon:hover i {
  color: #d50344;
  background: none;
  border: solid 1px #d50344; }

/* line 2007, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.link-bg-icon:hover i {
  color: #d50344;
  background: #d50344;
  border-color: #d50344;
  color: #fff !important; }

/*Icons Color*/
/* line 2017, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-u, i.icon-color-red, i.icon-color-sea, i.icon-color-dark, i.icon-color-grey, i.icon-color-blue, i.icon-color-green, i.icon-color-yellow, i.icon-color-orange, i.icon-color-purple, i.icon-color-aqua, i.icon-color-brown, i.icon-color-dark-blue, i.icon-color-light-grey, i.icon-color-light-green {
  background: none; }

/* line 2020, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-u {
  color: #d50344;
  border: solid 1px #d50344; }

/* line 2024, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-blue {
  color: #3498db;
  border: solid 1px #3498db; }

/* line 2028, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-red {
  color: #e74c3c;
  border: solid 1px #e74c3c; }

/* line 2032, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-sea {
  color: #1abc9c;
  border: solid 1px #1abc9c; }

/* line 2036, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-green {
  color: #2ecc71;
  border: solid 1px #2ecc71; }

/* line 2040, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-yellow {
  color: #f1c40f;
  border: solid 1px #f1c40f; }

/* line 2044, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-orange {
  color: #e67e22;
  border: solid 1px #e67e22; }

/* line 2048, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-grey {
  color: #95a5a6;
  border: solid 1px #95a5a6; }

/* line 2052, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-purple {
  color: #9b6bcc;
  border: solid 1px #9b6bcc; }

/* line 2056, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-aqua {
  color: #27d7e7;
  border: solid 1px #27d7e7; }

/* line 2060, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-brown {
  color: #9c8061;
  border: solid 1px #9c8061; }

/* line 2064, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-dark-blue {
  color: #4765a0;
  border: solid 1px #4765a0; }

/* line 2068, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-light-green {
  color: #79d5b3;
  border: solid 1px #79d5b3; }

/* line 2072, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-light {
  color: #fff;
  border: solid 1px #fff; }

/* line 2076, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-color-light-grey {
  color: #585f69;
  border: solid 1px #585f69; }

/* line 2080, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-u, i.icon-bg-red, i.icon-bg-sea, i.icon-bg-dark, i.icon-bg-darker, i.icon-bg-grey, i.icon-bg-blue, i.icon-bg-green, i.icon-bg-yellow, i.icon-bg-orange, i.icon-bg-purple, i.icon-bg-aqua, i.icon-bg-brown, i.icon-bg-dark-blue, i.icon-bg-light-grey, i.icon-bg-light-green {
  color: #fff;
  border-color: transparent; }

/* line 2084, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-u {
  background: #d50344; }

/* line 2087, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-blue {
  background: #3498db; }

/* line 2090, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-red {
  background: #e74c3c; }

/* line 2093, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-sea {
  background: #1abc9c; }

/* line 2096, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-green {
  background: #2ecc71; }

/* line 2099, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-yellow {
  background: #f1c40f; }

/* line 2102, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-orange {
  background: #e67e22; }

/* line 2105, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-grey {
  background: #95a5a6; }

/* line 2108, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-dark {
  background: #555; }

/* line 2111, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-darker {
  background: #333; }

/* line 2114, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-purple {
  background: #9b6bcc; }

/* line 2117, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-aqua {
  background: #27d7e7; }

/* line 2120, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-brown {
  background: #9c8061; }

/* line 2123, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-dark-blue {
  background: #4765a0; }

/* line 2126, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-light-green {
  background: #79d5b3; }

/* line 2129, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-light {
  background: #fff;
  border-color: transparent; }

/* line 2133, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
i.icon-bg-light-grey {
  background: #585f69;
  border-color: transparent; }

/*Icons Backgroun Color*/
/* Make Font Awesome icons fixed width */
/* line 2144, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.fa-fixed [class^="fa"], .fa-fixed [class*=" fa"] {
  width: 1.25em;
  text-align: center;
  display: inline-block; }

/* line 2149, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.fa-fixed [class^="fa"].fa-lg, .fa-fixed [class*=" fa"].fa-lg {
  /* increased font size for fa-lg */
  width: 1.5625em; }

/*Content Boxes
------------------------------------*/
/*Content Boxes v1*/
/* line 2159, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v1 {
  text-align: center; }
  /* line 2161, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .content-boxes-v1 span {
    display: block;
    margin-top: 5px; }

/*Content Boxes v2*/
@media (max-width: 992px) {
  /* line 2169, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .content-boxes-v2 {
    text-align: center; }
    /* line 2171, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .content-boxes-v2 .text-justify {
      text-align: center; }
    /* line 2174, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .content-boxes-v2 span {
      display: block;
      margin-top: 5px; } }

/*Content Boxes v3*/
/* line 2184, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v3 i.icon-custom {
  top: 8px;
  float: left;
  position: relative; }

/* line 2189, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v3 .content-boxes-in-v3 {
  padding: 0 10px;
  overflow: hidden; }
  /* line 2192, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .content-boxes-v3 .content-boxes-in-v3 h3 {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 3px;
    text-transform: capitalize; }
    /* line 2197, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .content-boxes-v3 .content-boxes-in-v3 h3 a {
      color: #555; }

/* line 2202, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v3.content-boxes-v3-right {
  text-align: right; }
  /* line 2204, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .content-boxes-v3.content-boxes-v3-right i.icon-custom {
    float: right;
    margin-left: 10px; }

/*Content Boxes Right v3*/
@media (max-width: 768px) {
  /* line 2214, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .content-boxes-v3.content-boxes-v3-right {
    text-align: inherit; }
    /* line 2216, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .content-boxes-v3.content-boxes-v3-right i.icon-custom {
      float: left;
      margin-left: 0; } }

/*Content Boxes v4*/
/* line 2226, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v4 h2 {
  color: #555;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase; }

/* line 2232, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v4 a {
  color: #777;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase; }

/* line 2238, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v4 i {
  width: 25px;
  color: #d50344;
  font-size: 35px;
  margin-top: 10px; }

/* line 2246, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-in-v4 {
  padding: 0 10px;
  overflow: hidden; }

/* line 2251, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v4-sm i {
  font-size: 26px;
  margin-top: 10px;
  margin-right: 5px; }

/*Content Boxes v5*/
/* line 2260, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v5 i {
  float: left;
  color: #999;
  width: 50px;
  height: 50px;
  padding: 11px;
  font-size: 22px;
  background: #eee;
  line-height: 28px;
  text-align: center;
  margin-right: 15px;
  display: inline-block; }

/* line 2273, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v5:hover i {
  color: #fff;
  background: #d50344; }

/*Content Boxes v6*/
/* line 2281, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-boxes-v6 {
  padding-top: 25px;
  text-align: center; }
  /* line 2284, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .content-boxes-v6 i {
    color: #fff;
    width: 90px;
    height: 90px;
    padding: 30px;
    font-size: 30px;
    line-height: 30px;
    position: relative;
    text-align: center;
    background: #dedede;
    margin-bottom: 25px;
    display: inline-block; }
    /* line 2296, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .content-boxes-v6 i:after {
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      content: " ";
      position: absolute;
      border: 1px solid #dedede;
      border-radius: 50% !important; }
  /* line 2307, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .content-boxes-v6:hover i {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background: #d50344; }
    /* line 2312, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .content-boxes-v6:hover i:after {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      border-color: #d50344; }

/*Colored Content Boxes
------------------------------------*/
/* line 2326, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block {
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 20px; }
  /* line 2330, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .service-block p {
    color: #fff; }
  /* line 2333, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .service-block h2 {
    color: #fff; }
    /* line 2335, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .service-block h2 a:hover {
      text-decoration: none; }

/* line 2341, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-light {
  background: #fafafa;
  border: solid 1px #eee; }

/* line 2346, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-default {
  background: #fafafa;
  border: solid 1px #eee; }
  /* line 2349, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .service-block-default:hover {
    box-shadow: 0 0 8px #eee; }

/* line 2355, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-light p, .service-block-light h2 {
  color: #555; }

/* line 2361, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-default p, .service-block-default h2 {
  color: #555; }

/* line 2366, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-u {
  background: #d50344; }

/* line 2370, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-blue {
  background: #3498db; }

/* line 2374, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-red {
  background: #e74c3c; }

/* line 2378, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-sea {
  background: #1abc9c; }

/* line 2382, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-grey {
  background: #95a5a6; }

/* line 2386, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-yellow {
  background: #f1c40f; }

/* line 2390, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-orange {
  background: #e67e22; }

/* line 2394, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-green {
  background: #2ecc71; }

/* line 2398, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-purple {
  background: #9b6bcc; }

/* line 2402, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-aqua {
  background: #27d7e7; }

/* line 2406, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-brown {
  background: #9c8061; }

/* line 2410, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-dark-blue {
  background: #4765a0; }

/* line 2414, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-light-green {
  background: #79d5b3; }

/* line 2418, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-dark {
  background: #555; }

/* line 2422, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.service-block-light {
  background: #ecf0f1; }

/*Funny Boxes
------------------------------------*/
/* line 2429, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes {
  background: #f7f7f7;
  padding: 20px 20px 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  /* line 2436, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes h2 {
    margin-top: 0;
    font-size: 18px;
    line-height: 20px; }
    /* line 2440, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .funny-boxes h2 a {
      color: #555; }
  /* line 2444, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes p a {
    color: #d50344; }
  /* line 2448, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes .funny-boxes-img li {
    font-size: 12px;
    margin-bottom: 2px; }
    /* line 2451, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .funny-boxes .funny-boxes-img li i {
      color: #d50344;
      font-size: 12px;
      margin-right: 5px; }
  /* line 2457, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes .funny-boxes-img img {
    margin: 5px 10px 15px 0; }
  /* line 2461, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes ul.funny-boxes-rating li {
    display: inline-block; }
    /* line 2463, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .funny-boxes ul.funny-boxes-rating li i {
      color: #f8be2c;
      cursor: pointer;
      font-size: 14px; }
      /* line 2467, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
      .funny-boxes ul.funny-boxes-rating li i:hover {
        color: #f8be2c; }

@media (max-width: 992px) {
  /* line 2475, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes .funny-boxes-img li {
    display: inline-block; } }

/*Funny Colored Boxes*/
/* line 2483, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-colored p, .funny-boxes-colored h2 a {
  color: #fff; }

/* line 2486, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-colored .funny-boxes-img li {
  color: #fff; }
  /* line 2488, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-colored .funny-boxes-img li i {
    color: #fff; }

/*Red Funny Box*/
/* line 2496, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-red {
  background: #e74c3c; }

/*Dark Red Funny Box*/
/* line 2502, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-purple {
  background: #9b6bcc; }

/*Blue Funny Box*/
/* line 2508, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-blue {
  background: #3498db; }

/*Grey Funny Box*/
/* line 2514, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-grey {
  background: #95a5a6; }

/*Turquoise Funny Box*/
/* line 2520, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-sea {
  background: #1abc9c; }

/*Turquoise Top Bordered Funny Box*/
/* line 2526, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-top-sea {
  border-top: solid 2px #1abc9c; }
  /* line 2528, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-top-sea:hover {
    border-top-color: #16a085; }

/*Yellow Top Bordered Funny Box**/
/* line 2535, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-top-yellow {
  border-top: solid 2px #f1c40f; }
  /* line 2537, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-top-yellow:hover {
    border-top-color: #f39c12; }

/*Red Top Bordered Funny Box**/
/* line 2544, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-top-red {
  border-top: solid 2px #e74c3c; }
  /* line 2546, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-top-red:hover {
    border-top-color: #c0392b; }

/*Purple Top Bordered Funny Box**/
/* line 2553, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-top-purple {
  border-top: solid 2px #9b6bcc; }
  /* line 2555, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-top-purple:hover {
    border-top-color: #814fb5; }

/*Orange Left Bordered Funny Box**/
/* line 2562, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-left-orange {
  border-left: solid 2px #e67e22; }
  /* line 2564, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-left-orange:hover {
    border-left-color: #d35400; }

/*Green Left Bordered Funny Box**/
/* line 2571, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-left-green {
  border-left: solid 2px #d50344; }
  /* line 2573, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-left-green:hover {
    border-left-color: #5fb611; }

/*Blue Left Bordered Funny Box**/
/* line 2580, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-left-blue {
  border-left: solid 2px #3498db; }
  /* line 2582, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-left-blue:hover {
    border-left-color: #2980b9; }

/*Dark Left Bordered Funny Box**/
/* line 2589, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.funny-boxes-left-dark {
  border-left: solid 2px #555; }
  /* line 2591, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .funny-boxes-left-dark:hover {
    border-left-color: #333; }

/*Typography
------------------------------------*/
/* line 2599, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-justify p {
  text-align: justify; }

/* line 2603, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-transform-uppercase {
  text-transform: uppercase; }

/* line 2607, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-transform-normal {
  text-transform: inherit !important; }

/* line 2611, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.font-bold {
  font-weight: 600; }

/* line 2615, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.font-light {
  font-weight: 200; }

/* line 2619, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.font-normal {
  font-weight: 400 !important; }

/*Text Dropcap*/
/* line 2625, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dropcap {
  float: left;
  color: #d50344;
  padding: 5px 0;
  font-size: 45px;
  font-weight: 200;
  line-height: 30px;
  margin: 0px 5px 0 0; }

/* line 2635, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dropcap-bg {
  float: left;
  color: #fff;
  padding: 7px 0;
  min-width: 50px;
  font-size: 35px;
  font-weight: 200;
  line-height: 35px;
  text-align: center;
  background: #d50344;
  margin: 4px 10px 0 0; }

/*Text Highlights*/
/* line 2650, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights {
  color: #fff;
  font-weight: 200;
  padding: 0px 5px;
  background: #555; }

/* line 2657, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-green {
  background: #d50344; }

/* line 2661, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-blue {
  background: #3498db; }

/* line 2665, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-red {
  background: #e74c3c; }

/* line 2669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-sea {
  background: #1abc9c; }

/* line 2673, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-orange {
  background: #e67e22; }

/* line 2677, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-yellow {
  background: #f1c40f; }

/* line 2681, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-purple {
  background: #9b6bcc; }

/* line 2685, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-aqua {
  background: #27d7e7; }

/* line 2689, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-brown {
  background: #9c8061; }

/* line 2693, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-dark-blue {
  background: #4765a0; }

/* line 2697, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-highlights-light-green {
  background: #79d5b3; }

/*Text Borders*/
/* line 2703, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-border {
  border-bottom: dashed 1px #555; }

/* line 2707, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-border-default {
  border-color: #95a5a6; }

/* line 2711, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-border-green {
  border-color: #d50344; }

/* line 2715, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-border-blue {
  border-color: #3498db; }

/* line 2719, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-border-red {
  border-color: #e74c3c; }

/* line 2723, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-border-yellow {
  border-color: #f1c40f; }

/* line 2727, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.text-border-purple {
  border-color: #9b6bcc; }

/*List Styles*/
/* line 2733, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-styles li {
  margin-bottom: 8px; }

/*Contextual Backgrounds*/
/* line 2739, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.contex-bg p {
  opacity: 0.8;
  padding: 8px 10px; }
  /* line 2742, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .contex-bg p:hover {
    opacity: 1; }

/*Blockquote*/
/* line 2749, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
blockquote {
  padding: 5px 15px;
  border-left-width: 2px; }
  /* line 2752, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote p {
    font-size: 14px;
    font-weight: 400; }
  /* line 2756, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote h1, blockquote h2, blockquote span {
    font-size: 18px;
    margin: 0 0 8px;
    line-height: 24px; }
  /* line 2762, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote.bq-text-lg p, blockquote.bq-text-lg small {
    text-transform: uppercase; }
  /* line 2765, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote.bq-text-lg p {
    font-size: 22px;
    font-weight: 300;
    line-height: 32px; }
  /* line 2771, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote.text-right {
    border-left: none;
    border-right: 2px solid #eee; }
  /* line 2775, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote.hero.text-right {
    border-left: none;
    border-right: 2px solid #eee;
    border-color: #555; }
    /* line 2779, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.hero.text-right:hover {
      border-color: #555; }
  /* line 2783, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote:hover, blockquote.text-right:hover {
    border-color: #d50344;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  /* line 2790, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote.bq-dark {
    border-color: #585f69; }
    /* line 2792, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.bq-dark:hover {
      border-color: #585f69; }
  /* line 2796, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote.bq-green {
    border-color: #d50344; }
  /* line 2799, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  blockquote.hero {
    border: none;
    padding: 18px;
    font-size: 16px;
    background: #f3f3f3;
    border-left: solid 2px #666; }
    /* line 2805, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.hero:hover {
      background: #eee;
      border-left-color: #666; }
    /* line 2809, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.hero.hero-dark, blockquote.hero.hero-default {
      border: none; }
    /* line 2812, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.hero.hero-dark {
      background: #444; }
      /* line 2814, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
      blockquote.hero.hero-dark:hover {
        background: #555; }
    /* line 2818, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.hero.hero-default {
      background: #d50344; }
      /* line 2820, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
      blockquote.hero.hero-default:hover {
        background: #5fb611; }
    /* line 2825, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.hero.hero-dark p, blockquote.hero.hero-dark h2, blockquote.hero.hero-dark small {
      color: #fff;
      font-weight: 200; }
    /* line 2831, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    blockquote.hero.hero-default p, blockquote.hero.hero-default h2, blockquote.hero.hero-default small {
      color: #fff;
      font-weight: 200; }

/*Blockquote Styles*/
/*Blockquote Hero Styles*/
/*Tag Boxes
------------------------------------*/
/* line 2846, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box {
  padding: 20px;
  background: #fff;
  margin-bottom: 30px; }
  /* line 2850, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tag-box h2 {
    font-size: 20px;
    line-height: 25px; }
  /* line 2854, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tag-box p {
    margin-bottom: 0; }
  /* line 2857, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tag-box.tag-text-space p {
    margin-bottom: 10px; }

/*Tag Boxes v1*/
/* line 2864, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box-v1 {
  border: solid 1px #eee;
  border-top: solid 2px #d50344; }

/*Tag Boxes v2*/
/* line 2871, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box-v2 {
  background: #fafafa;
  border: solid 1px #eee;
  border-left: solid 2px #d50344; }

/*Tag Boxes v3*/
/* line 2879, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box-v3 {
  border: solid 2px #eee; }

/*Tag Boxes v4*/
/* line 2885, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box-v4 {
  border: dashed 1px #bbb; }

/*Tag Boxes v5*/
/* line 2891, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box-v5 {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc; }
  /* line 2895, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tag-box-v5 span {
    color: #555;
    font-size: 28px;
    margin-bottom: 0; }

/*Tag Boxes v6*/
/* line 2904, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box-v6 {
  background: #fafafa;
  border: solid 1px #eee; }

/*Tag Boxes v7*/
/* line 2911, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tag-box-v7 {
  border: solid 1px #eee;
  border-bottom: solid 2px #d50344; }

/*Testimonials
------------------------------------*/
/*Testimonials*/
/* line 2920, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials {
  margin-bottom: 10px; }
  /* line 2922, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials .testimonial-info {
    color: #d50344;
    font-size: 16px;
    padding: 0 15px;
    margin-top: 18px; }
    /* line 2927, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials .testimonial-info span {
      top: 3px;
      position: relative; }
    /* line 2931, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials .testimonial-info em {
      color: #777;
      display: block;
      font-size: 13px; }
    /* line 2936, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials .testimonial-info img {
      width: 60px;
      float: left;
      height: 60px;
      padding: 2px;
      margin-right: 15px;
      border: solid 1px #ccc; }
  /* line 2945, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials .testimonial-author {
    overflow: hidden; }
  /* line 2948, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials .carousel-arrow {
    top: -65px;
    position: relative; }
    /* line 2951, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials .carousel-arrow i {
      color: #777;
      padding: 2px;
      min-width: 25px;
      font-size: 20px;
      text-align: center;
      background: #f5f5f5; }
      /* line 2958, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
      .testimonials .carousel-arrow i:hover {
        color: #fff;
        background: #d50344; }
  /* line 2964, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials .carousel-control {
    opacity: 1;
    width: 100%;
    text-align: right;
    text-shadow: none;
    position: absolute;
    filter: Alpha(opacity=100);
    /*For IE*/ }
    /* line 2972, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials .carousel-control.left {
      right: 27px;
      left: auto; }
    /* line 2976, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials .carousel-control.right {
      right: 0px; }
  /* line 2980, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v1 .item p {
    position: relative; }
    /* line 2982, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials.testimonials-v1 .item p:after, .testimonials.testimonials-v1 .item p:before {
      left: 80px;
      bottom: -20px; }
    /* line 2986, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .testimonials.testimonials-v1 .item p:after {
      border-top: 22px solid;
      border-left: 0 solid transparent;
      border-right: 22px solid transparent; }
  /* line 2993, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v2 .testimonial-info {
    padding: 0 20px; }
  /* line 2996, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v2 p {
    padding-bottom: 15px; }
  /* line 2999, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v2 .carousel-arrow {
    top: -55px; }
  /* line 3003, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v2 .item p:after, .testimonials.testimonials-v2 .item p:before {
    left: 8%;
    bottom: 45px; }
  /* line 3007, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v2 .item p:after {
    border-top: 20px solid;
    border-left: 25px solid transparent;
    border-right: 0px solid transparent; }
  /* line 3014, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v1 p, .testimonials.testimonials-v2 p {
    padding: 15px;
    font-size: 14px;
    font-style: italic;
    background: #f5f5f5; }
  /* line 3020, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .testimonials.testimonials-v1 .item p:after, .testimonials.testimonials-v2 .item p:after {
    width: 0;
    height: 0;
    content: " ";
    display: block;
    position: absolute;
    border-top-color: #f5f5f5;
    border-left-style: inset;
    /*FF fixes*/
    border-right-style: inset;
    /*FF fixes*/ }

/*Testimonials v1*/
/*Testimonials v2*/
/*General Testimonials v1/v2*/
/*Testimonials Backgrounds*/
/* line 3042, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials-bg-dark .item p, .testimonials-bg-default .item p {
  color: #fff;
  font-weight: 200; }

/* line 3047, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials-bg-dark .carousel-arrow i {
  color: #fff; }

/* line 3052, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials-bg-default .carousel-arrow i {
  color: #fff; }

/* line 3055, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials-bg-default .item p {
  background: #d50344; }

/*Testimonials Default*/
/* line 3062, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials.testimonials-bg-default .item p:after {
  border-top-color: #d50344; }

/* line 3066, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials-bg-default .carousel-arrow i {
  background: #d50344; }

/* line 3070, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials.testimonials-bg-default .carousel-arrow i:hover {
  background: #5fb611; }

/*Testimonials Dark*/
/* line 3076, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials-bg-dark .item p {
  background: #555; }

/* line 3080, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials.testimonials-bg-dark .item p:after {
  border-top-color: #555; }

/* line 3084, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials-bg-dark .carousel-arrow i {
  color: #fff;
  background: #555; }

/* line 3090, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials.testimonials-bg-dark .carousel-arrow i:hover {
  background: #333; }

/* line 3093, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.testimonials.testimonials-bg-dark .testimonial-info {
  color: #555; }

/*Panels (Portlets)
------------------------------------*/
/* line 3101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-heading {
  color: #fff;
  padding: 5px 15px; }

/*Panel Table*/
/* line 3108, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel .table {
  margin-bottom: 0; }

/*Panel Unify*/
/* line 3114, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-u {
  border-color: #d50344; }
  /* line 3116, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-u > .panel-heading {
    background: #d50344; }

/*Panel Blue*/
/* line 3123, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-blue {
  border-color: #3498db; }
  /* line 3125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-blue > .panel-heading {
    background: #3498db; }

/*Panel Red*/
/* line 3132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-red {
  border-color: #e74c3c; }
  /* line 3134, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-red > .panel-heading {
    background: #e74c3c; }

/*Panel Green*/
/* line 3141, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-green {
  border-color: #2ecc71; }
  /* line 3143, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-green > .panel-heading {
    background: #2ecc71; }

/*Panel Sea*/
/* line 3150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-sea {
  border-color: #1abc9c; }
  /* line 3152, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-sea > .panel-heading {
    background: #1abc9c; }

/*Panel Orange*/
/* line 3159, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-orange {
  border-color: #e67e22; }
  /* line 3161, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-orange > .panel-heading {
    background: #e67e22; }

/*Panel Yellow*/
/* line 3168, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-yellow {
  border-color: #f1c40f; }
  /* line 3170, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-yellow > .panel-heading {
    background: #f1c40f; }

/*Panel Grey*/
/* line 3177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-grey {
  border-color: #95a5a6; }
  /* line 3179, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-grey > .panel-heading {
    background: #95a5a6; }

/*Panel Dark*/
/* line 3186, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-dark {
  border-color: #555; }
  /* line 3188, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-dark > .panel-heading {
    background: #555; }

/*Panel Purple*/
/* line 3195, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-purple {
  border-color: #9b6bcc; }
  /* line 3197, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-purple > .panel-heading {
    background: #9b6bcc; }

/*Panel Aqua*/
/* line 3204, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-aqua {
  border-color: #27d7e7; }
  /* line 3206, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-aqua > .panel-heading {
    background: #27d7e7; }

/*Panel Brown*/
/* line 3213, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-brown {
  border-color: #9c8061; }
  /* line 3215, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-brown > .panel-heading {
    background: #9c8061; }

/*Panel Dark Blue*/
/* line 3222, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-dark-blue {
  border-color: #4765a0; }
  /* line 3224, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-dark-blue > .panel-heading {
    background: #4765a0; }

/*Panel Light Green*/
/* line 3231, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-light-green {
  border-color: #79d5b3; }
  /* line 3233, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-light-green > .panel-heading {
    background: #79d5b3; }

/*Panel Default Dark*/
/* line 3240, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.panel-default-dark {
  border-color: #585f69; }
  /* line 3242, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .panel-default-dark > .panel-heading {
    background: #585f69; }

/*Progress Bar
------------------------------------*/
/* line 3250, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-u {
  box-shadow: none; }
  /* line 3252, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .progress-u .progress-bar {
    box-shadow: none; }

/*progress-bar (sizes)*/
/* line 3259, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-lg {
  height: 25px; }
  /* line 3261, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .progress-lg p {
    padding-top: 3px; }

/* line 3266, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-sm {
  height: 12px; }

/* line 3270, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-xs {
  height: 7px; }

/* line 3274, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-xxs {
  height: 3px; }

/*progress-bar (colors)*/
/* line 3280, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress {
  background: #e5e5e5; }

/* line 3284, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-u {
  background: #d50344; }

/* line 3288, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-blue {
  background: #3498db; }

/* line 3292, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-orange {
  background: #e67e22; }

/* line 3296, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-red {
  background: #e74c3c; }

/* line 3300, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-purple {
  background: #9b6bcc; }

/* line 3304, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-aqua {
  background: #27d7e7; }

/* line 3308, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-brown {
  background: #9c8061; }

/* line 3312, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-dark-blue {
  background: #4765a0; }

/* line 3316, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-light-green {
  background: #79d5b3; }

/* line 3320, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-bar-dark {
  background: #555; }

/*Progress Bar Animation
------------------------------------*/
/* line 3327, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress {
  position: relative; }
  /* line 3329, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .progress .progress-bar {
    overflow: hidden;
    line-height: 20px;
    position: absolute; }

/* line 3336, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress-box .progress-bar {
  transition: all 3s ease-in;
  -o-transition: all 3s ease-in;
  -ms-transition: all 3s ease-in;
  -moz-transition: all 3s ease-in;
  -webkit-transition: all 3s ease-in; }

/*Vertical Progress Bar*/
/* line 3346, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.progress.vertical {
  float: left;
  width: 100%;
  height: 200px;
  margin-right: 20px; }
  /* line 3351, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .progress.vertical.bottom {
    position: relative; }
  /* line 3354, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .progress.vertical .progress-bar {
    height: 0;
    width: 100%;
    transition: height 3s ease;
    -o-transition: height 3s ease;
    -ms-transition: height 3s ease;
    -moz-transition: height 3s ease;
    -webkit-transition: height 3s ease; }
  /* line 3363, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .progress.vertical.bottom .progress-bar {
    bottom: 0;
    position: absolute; }

/*Count Stars
------------------------------------*/
/* line 3372, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.stars-existing {
  color: #d50344;
  cursor: pointer; }

/* line 3377, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.star-lg {
  font-size: 30px; }

/* line 3381, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.star-sm {
  font-size: 25px; }

/* line 3385, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.star-xs {
  font-size: 20px; }

/* line 3389, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.star-default {
  font-size: 16px; }

/*Media (Audio/Videos and Images)
------------------------------------*/
/*Images*/
/* line 3398, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
img.img-bordered {
  padding: 3px;
  border: solid 1px #eee; }

/* line 3402, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
img.img-circle {
  border-radius: 50% !important; }

/* line 3405, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
img.image-sm {
  width: 50px;
  height: 50px; }

/* line 3409, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
img.image-md {
  width: 100px;
  height: 100px; }

/*Responsive Video*/
/* line 3417, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.responsive-video {
  height: 0;
  padding-top: 1px;
  position: relative;
  padding-bottom: 56.25%;
  /*16:9*/ }
  /* line 3423, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .responsive-video iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute; }

/*Tags v1
------------------------------------*/
/* line 3435, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tags-v1 li {
  margin: 0;
  padding: 0; }
  /* line 3438, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tags-v1 li a {
    font-size: 13px;
    padding: 4px 8px;
    line-height: 32px;
    border: solid 2px #eee;
    border-radius: 20px !important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    /* line 3448, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .tags-v1 li a:hover {
      text-decoration: none;
      border-color: #e0e0e0; }

/*Tags v2
------------------------------------*/
/* line 3458, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.tags-v2 li {
  padding: 7px 0 7px 4px; }
  /* line 3460, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .tags-v2 li a {
    color: #555;
    font-size: 13px;
    padding: 5px 10px;
    border: solid 1px #bbb; }
    /* line 3465, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
    .tags-v2 li a:hover {
      color: #fff;
      background: #555;
      border-color: #555;
      text-decoration: none;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }

/*Lists
------------------------------------*/
/* line 3481, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.list-row {
  padding: 0;
  margin-bottom: 0;
  list-style: none; }

/*Lists v1*/
/* line 3490, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.lists-v1 li {
  margin-bottom: 10px; }

/* line 3493, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.lists-v1 i {
  color: #fff;
  width: 15px;
  height: 15px;
  padding: 1px;
  font-size: 13px;
  margin-right: 7px;
  text-align: center;
  background: #d50344;
  display: inline-block;
  border-radius: 50% !important; }

/*Lists v2*/
/* line 3510, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.lists-v2 li {
  margin-bottom: 10px; }

/* line 3513, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.lists-v2 i {
  color: #d50344;
  font-size: 13px;
  margin-right: 7px;
  display: inline-block; }

/*Column Sizes
------------------------------------*/
/*Remove the Gutter Padding from Columns*/
/* line 3526, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

/* line 3530, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-gutter.no-gutter-boxed {
  padding-right: 15px;
  padding-left: 15px; }

/*Heights
------------------------------------*/
/* line 3539, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-100 {
  min-height: 100px; }

/* line 3543, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-150 {
  min-height: 150px; }

/* line 3547, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-200 {
  min-height: 200px; }

/* line 3551, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-250 {
  min-height: 250px; }

/* line 3555, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-300 {
  min-height: 300px; }

/* line 3559, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-350 {
  min-height: 350px; }

/* line 3563, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-400 {
  min-height: 400px; }

/* line 3567, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-450 {
  min-height: 450px; }

/* line 3571, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.height-500 {
  min-height: 500px !important; }

/*Spaces
------------------------------------*/
/* line 3578, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-padding {
  padding: 0 !important; }

/* line 3582, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-margin {
  margin: 0; }

/* line 3586, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important; }

/* line 3591, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-bottom-space {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }

/* line 3596, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-margin-bottom {
  margin-bottom: 0 !important; }

/* line 3600, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-padding-bottom {
  padding-bottom: 0 !important; }

/* line 3604, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-xs {
  padding-top: 20px;
  padding-bottom: 20px; }

/* line 3609, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-std {
  padding-top: 40px;
  padding-bottom: 40px; }

/* line 3614, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-sm {
  padding-top: 60px;
  padding-bottom: 60px; }

/* line 3619, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-md {
  padding-top: 80px;
  padding-bottom: 80px; }

/* line 3624, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.content-lg {
  padding-top: 100px;
  padding-bottom: 100px; }

/* line 3629, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.space-lg-hor {
  padding-left: 60px;
  padding-right: 60px; }

/* line 3634, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.space-xlg-hor {
  padding-left: 100px;
  padding-right: 100px; }

/* line 3639, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-5, .margin-bottom-10, .margin-bottom-15, .margin-bottom-20, .margin-bottom-25, .margin-bottom-30, .margin-bottom-35, .margin-bottom-40, .margin-bottom-45, .margin-bottom-50, .margin-bottom-55, .margin-bottom-60 {
  clear: both; }

/* line 3643, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-5 {
  margin-bottom: 5px; }

/* line 3647, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-10 {
  margin-bottom: 10px; }

/* line 3651, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-15 {
  margin-bottom: 15px; }

/* line 3655, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-20 {
  margin-bottom: 20px; }

/* line 3659, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-25 {
  margin-bottom: 25px; }

/* line 3663, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-30 {
  margin-bottom: 30px; }

/* line 3667, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-35 {
  margin-bottom: 35px; }

/* line 3671, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-40 {
  margin-bottom: 40px; }

/* line 3675, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-45 {
  margin-bottom: 45px; }

/* line 3679, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-50 {
  margin-bottom: 50px; }

/* line 3683, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-55 {
  margin-bottom: 55px; }

/* line 3687, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-bottom-60 {
  margin-bottom: 60px; }

@media (max-width: 768px) {
  /* line 3692, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sm-margin-bottom-10 {
    margin-bottom: 10px; }
  /* line 3695, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sm-margin-bottom-20 {
    margin-bottom: 20px; }
  /* line 3698, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sm-margin-bottom-30 {
    margin-bottom: 30px; }
  /* line 3701, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sm-margin-bottom-40 {
    margin-bottom: 40px; }
  /* line 3704, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sm-margin-bottom-50 {
    margin-bottom: 50px; }
  /* line 3707, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .sm-margin-bottom-60 {
    margin-bottom: 60px; } }

@media (max-width: 992px) {
  /* line 3713, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .md-margin-bottom-10 {
    margin-bottom: 10px; }
  /* line 3716, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .md-margin-bottom-20 {
    margin-bottom: 20px; }
  /* line 3719, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .md-margin-bottom-30 {
    margin-bottom: 30px; }
  /* line 3722, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .md-margin-bottom-40 {
    margin-bottom: 40px; }
  /* line 3725, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .md-margin-bottom-50 {
    margin-bottom: 50px; }
  /* line 3728, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .md-margin-bottom-60 {
    margin-bottom: 60px; } }

/*Other Spaces*/
/* line 3735, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-top-20 {
  margin-top: 20px; }

/* line 3739, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-left-5 {
  margin-left: 5px; }

/* line 3743, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-left-10 {
  margin-left: 10px; }

/* line 3747, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-right-5 {
  margin-right: 5px; }

/* line 3751, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.margin-right-10 {
  margin-right: 10px; }

/* line 3755, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.padding-top-5 {
  padding-top: 5px; }

/* line 3759, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.padding-left-5 {
  padding-left: 5px; }

/*Text Colors
------------------------------------*/
/* line 3766, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-sea {
  color: #1abc9c; }

/* line 3770, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-red {
  color: #e74c3c; }

/* line 3774, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-aqua {
  color: #27d7e7; }

/* line 3778, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-blue {
  color: #3498db; }

/* line 3782, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-grey {
  color: #95a5a6; }

/* line 3786, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-dark {
  color: #555555; }

/* line 3790, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-green {
  color: #d50344; }

/* line 3794, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-brown {
  color: #9c8061; }

/* line 3798, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-light {
  color: #ffffff; }

/* line 3802, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-orange {
  color: #e67e22; }

/* line 3806, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-yellow {
  color: #f1c40f; }

/* line 3810, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-green1 {
  color: #2ecc71; }

/* line 3814, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-purple {
  color: #9b6bcc; }

/* line 3818, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-inherit {
  color: inherit; }

/* line 3822, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-dark-blue {
  color: #4765a0; }

/* line 3826, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-light-grey {
  color: #585f69; }

/* line 3830, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.color-light-green {
  color: #79d5b3; }

/*Background Colors
------------------------------------*/
/* line 3837, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-dark, .bg-color-sea, .bg-color-red, .bg-color-aqua, .bg-color-blue, .bg-color-grey, .bg-color-light, .bg-color-green, .bg-color-brown, .bg-color-orange, .bg-color-green1, .bg-color-purple, .bg-color-dark-blue, .bg-color-light-grey, .bg-color-light-green {
  color: #fff; }

/* line 3841, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-white {
  color: #555; }

/* line 3845, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-dark {
  background-color: #555 !important; }

/* line 3849, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-white {
  background-color: #fff !important; }

/* line 3853, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-sea {
  background-color: #1abc9c !important; }

/* line 3857, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-red {
  background-color: #e74c3c !important; }

/* line 3861, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-aqua {
  background-color: #27d7e7 !important; }

/* line 3865, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-blue {
  background-color: #3498db !important; }

/* line 3869, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-grey {
  background-color: #95a5a6 !important; }

/* line 3873, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-light {
  background-color: #f7f7f7 !important; }

/* line 3877, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-green {
  background-color: #d50344 !important; }

/* line 3881, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-brown {
  background-color: #9c8061 !important; }

/* line 3885, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-orange {
  background-color: #e67e22 !important; }

/* line 3889, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-green1 {
  background-color: #2ecc71 !important; }

/* line 3893, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-purple {
  background-color: #9b6bcc !important; }

/* line 3897, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-dark-blue {
  background-color: #4765a0 !important; }

/* line 3901, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-light-grey {
  background-color: #585f69 !important; }

/* line 3905, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-color-light-green {
  background-color: #79d5b3 !important; }

/* line 3909, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rgba-red {
  background-color: rgba(231, 76, 60, 0.8); }

/* line 3913, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rgba-blue {
  background-color: rgba(52, 152, 219, 0.8); }

/* line 3917, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rgba-aqua {
  background-color: rgba(39, 215, 231, 0.8); }

/* line 3921, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rgba-yellow {
  background-color: rgba(241, 196, 15, 0.8); }

/* line 3925, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rgba-default {
  background-color: rgba(114, 192, 44, 0.8); }

/* line 3929, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rgba-purple {
  background-color: rgba(155, 107, 204, 0.8); }

/*Grey Backroud*/
/* line 3935, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-grey {
  background: #f7f7f7;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee; }

/*Rounded and Circle Classes
------------------------------------*/
/* line 3944, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.no-rounded {
  border-radius: 0 !important; }

/* line 3948, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded {
  border-radius: 4px !important; }

/* line 3952, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-x {
  border-radius: 50% !important; }

/* line 3956, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-2x {
  border-radius: 10px !important; }

/* line 3960, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-3x {
  border-radius: 15px !important; }

/* line 3964, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-4x {
  border-radius: 20px !important; }

/* line 3968, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-sm {
  border-radius: 2px !important; }

/* line 3972, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-md {
  border-radius: 3px !important; }

/* line 3976, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-top {
  border-radius: 4px 4px 0 0 !important; }

/* line 3980, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-left {
  border-radius: 4px 0 0 4px !important; }

/* line 3984, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-right {
  border-radius: 0 4px 4px 0 !important; }

/* line 3988, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rounded-bottom {
  border-radius: 0 0 4px 4px !important; }

/*Others
------------------------------------*/
/* line 3995, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.overflow-h {
  overflow: hidden; }

/* line 3999, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.overflow-a {
  overflow: auto; }

/* line 4003, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.overflow-hidden {
  overflow: hidden; }

/* line 4007, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.clear-both {
  clear: both; }

/*Display*/
/* line 4013, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dp-none {
  display: none; }

/* line 4017, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dp-block {
  display: block; }

/* line 4021, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dp-table {
  display: table; }

/* line 4025, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dp-inline-block {
  display: inline-block; }

/* line 4029, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.dp-table-cell {
  display: table-cell;
  vertical-align: middle; }

/*Full Width*/
/* line 4036, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.full-width {
  width: 100%; }

/*Equal Height Columns*/
@media (max-width: 767px) {
  /* line 4042, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .equal-height-column {
    height: auto !important; } }

/*Image Classes*/
/* line 4049, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.img-width-200 {
  width: 200px; }

/* line 4053, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.lft-img-margin {
  margin: 0 20px 5px 0; }

/* line 4057, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.rgt-img-margin {
  margin: 0 0 5px 10px; }

/* line 4061, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
img.img-center, .img-center img {
  margin-left: auto;
  margin-right: auto; }

/*Background Light*/
/* line 4068, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.bg-light {
  padding: 10px 15px;
  margin-bottom: 10px;
  background: #fcfcfc;
  border: solid 1px #e5e5e5; }
  /* line 4073, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .bg-light:hover {
    border: solid 1px #bbb; }

/*CSS3 Hover Effects*/
/* line 4080, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.hover-effect {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

/* line 4087, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
.hover-effect-kenburn {
  left: 10px;
  margin-left: -10px;
  position: relative;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }
  /* line 4096, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_app.scss */
  .hover-effect-kenburn:hover {
    -webkit-transform: scale(2) rotate(5deg);
    -moz-transform: scale(2) rotate(5deg);
    -o-transform: scale(2) rotate(5deg);
    -ms-transform: scale(2) rotate(5deg);
    transform: scale(2) rotate(5deg); }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
.header-v6 {
  background: rgba(255, 255, 255, 0.45) url("../images/bg/diagonal-noise.png");
  z-index: 1000;
  position: relative;
  font-family: 'Cabin', Helvetica Neue, Arial, sans-serif; }
  /* line 7, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar {
    border: none;
    margin-bottom: 0; }
  /* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .topbar {
    background: #A30234;
    padding: 10px 0; }
    /* line 15, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar .logged-in-user {
      color: #efefef;
      width: 25%;
      text-align: center; }
      @media (max-width: 767px) {
        /* line 15, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar .logged-in-user {
          float: none !important;
          text-align: center;
          display: block;
          width: 100%;
          margin-bottom: 1em;
          display: none; } }
    /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar ul {
      margin: 0;
      float: right;
      font-size: 0; }
      @media (max-width: 767px) {
        /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar ul {
          float: none !important;
          text-align: center; } }
    /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar li {
      display: inline-block;
      font-size: 12px; }
      /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar li:first-child a {
        border-left: none; }
      /* line 47, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar li:last-child a {
        border-right: none; }
    /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .topbar a {
      color: rgba(255, 255, 255, 0.7);
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
      transition: color 0.2s;
      padding: 0 10px;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      letter-spacing: 1px; }
      @media (max-width: 767px) {
        /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar a {
          width: 2.5em;
          overflow: hidden;
          height: 1.8em;
          font-size: 1.7em;
          line-height: 1.8em; } }
      @media (min-width: 768px) and (max-width: 991px) {
        /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .topbar a {
          font-size: .7em; }
          /* line 70, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
          .header-v6 .topbar a i {
            font-size: 1.5em; } }
      /* line 75, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar a i {
        padding-right: .5em;
        font-size: 1.3em; }
      /* line 80, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .topbar a:hover {
        color: #fff;
        background: transparent; }
  /* line 87, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .menu-container {
    position: relative; }
    /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .header-v6 .menu-container:before, .header-v6 .menu-container:after {
      content: " ";
      display: table; }
    /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .header-v6 .menu-container:after {
      clear: both; }
  /* line 92, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-brand {
    height: 93px;
    transition: height 0.2s;
    padding: 10px 15px; }
    /* line 96, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-brand img {
      max-height: 100%; }
  /* line 101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: 0;
    font-size: 24px; }
    /* line 109, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-toggle:active {
      color: #A30234; }
  /* line 114, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .navbar-nav {
    font-size: 1.15em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0; }
    /* line 121, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav a, .header-v6 .navbar-collapse .navbar-nav span {
      transition: color 0.2s; }
    /* line 125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav > li {
      border-top: 1px solid #fff; }
      /* line 128, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child {
        border-bottom: 1px solid #fff; }
      /* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li {
        border: none; }
      /* line 136, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li a {
        font-weight: 400;
        font-size: 1em;
        color: #777;
        text-transform: none; }
    /* line 144, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav a {
      background: transparent; }
      /* line 147, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav a:hover {
        color: #0F4788;
        background: transparent; }
        /* line 151, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .navbar-collapse .navbar-nav a:hover span {
          border-top-color: #0F4788; }
      /* line 156, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav a span {
        border-top-color: #555; }

@media (min-width: 768px) {
  /* line 166, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .primary-nav, .header-v6 .topbar .container {
    width: auto;
    max-width: 1170px;
    margin: 0 auto; }
  /* line 172, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse {
    padding: 0; }
  /* line 176, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .menu.container {
    padding: 0;
    margin: 0; }
  /* line 181, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-brand.navbar-brand {
    margin: 0 0 -10px 0;
    text-align: center;
    float: none; }
  /* line 187, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .menu.container {
    text-align: center;
    font-size: 0; }
  /* line 192, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .navbar-nav {
    float: none;
    display: inline-block;
    font-weight: 400; }
    /* line 199, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav > li {
      font-size: 14px;
      border-top: none; }
      /* line 203, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child {
        border-bottom: none; }
      /* line 207, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li a {
        padding: 15px 10px; }
      /* line 211, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li ul {
        border-top: 3px solid #0F4788;
        text-align: left;
        font-size: 14px;
        letter-spacing: normal;
        top: 5px; }
      /* line 219, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li a {
        padding: 6px 25px; }
      /* line 223, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li li span {
        transform: rotate(-90deg); }
      /* line 227, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:first-child > a {
        padding-left: 0; }
      /* line 231, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child > a {
        padding-right: 0; }
      /* line 235, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child {
        border-bottom: none; } }

@media (min-width: 992px) {
  /* line 245, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  body {
    padding-top: 113px; }
  /* line 254, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  body.front > .wrapper, body.hero-slide > .wrapper {
    margin-top: -73px; }
  /* line 259, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  body.admin-menu .header-v6 {
    top: 29px; }
  /* line 263, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
    /* line 269, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .primary-nav {
      font-size: 0;
      position: relative; }
    /* line 274, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .menu-container {
      display: inline-block;
      width: 25%; }
    /* line 279, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse {
      width: 75%;
      display: inline-block !important;
      vertical-align: middle;
      font-size: 14px;
      padding: 0; }
    /* line 287, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-collapse .navbar-nav {
      float: right; }
      /* line 292, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li:last-child > a {
        padding-right: 10px; }
      /* line 296, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6 .navbar-collapse .navbar-nav > li > a {
        color: white; }
        /* line 299, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6 .navbar-collapse .navbar-nav > li > a span {
          border-top-color: white; }
    /* line 306, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6 .navbar-brand.navbar-brand {
      margin: 0; }
    /* line 310, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
    .header-v6.header-fixed-shrink {
      background: #fff;
      box-shadow: 0 0 3px #bbb; }
      /* line 314, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6.header-fixed-shrink .navbar-brand {
        height: 73px; }
        /* line 317, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6.header-fixed-shrink .navbar-brand img {
          max-height: 100%; }
      /* line 322, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
      .header-v6.header-fixed-shrink .navbar-collapse .navbar-nav > li > a {
        color: #777; }
        /* line 325, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
        .header-v6.header-fixed-shrink .navbar-collapse .navbar-nav > li > a span {
          border-top-color: #777; } }

@media (min-width: 1044px) {
  /* line 334, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_header.scss */
  .header-v6 .navbar-collapse .navbar-nav > li {
    font-size: 15px; } }

/*Footer-v1
------------------------------------*/
/* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 .footer {
  padding: 40px 0;
  background: #272727; }
  /* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
  .footer-v1 .footer p {
    color: #eee; }
  /* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
  .footer-v1 .footer h2 {
    color: #eee;
    font-size: 18px;
    font-weight: 200; }

/* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 .link-list li {
  border-top: solid 1px #353535; }

/* line 20, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 address {
  color: #eee; }

/* line 24, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 .link-list a, .footer-v1 .link-list i {
  color: #eee; }

/* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 address {
  line-height: 23px; }

/* line 31, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 .footer .map-img {
  background: url("../images/bg/map-img.png") 5px 60px no-repeat; }

/* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 .copyright {
  background: #222;
  border-top: none;
  padding: 10px 0 5px; }
  /* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
  .footer-v1 .copyright p {
    color: #eee;
    margin-top: 14px; }
  /* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
  .footer-v1 .copyright a {
    margin: 0 2px; }

/* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
.footer-v1 .footer-socials {
  margin-top: 12px;
  text-align: right; }
  /* line 49, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
  .footer-v1 .footer-socials li a {
    color: #777;
    font-size: 16px; }

/*Link List*/
/*Dotted Map*/
/*Copyright*/
@media (max-width: 991px) {
  /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_footer.scss */
  .footer-v1 .copyright, .footer-v1 .footer-socials {
    text-align: left; } }

/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce and Blog Theme.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/
/* line 9, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
ul.list-inline {
  margin-left: 0; }

/* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
li:first-child {
  padding-left: 0; }

/*Boxed Layout
------------------------------------*/
/* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.wrapper {
  background: #fff; }

/* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.boxed-layout {
  padding: 0;
  box-shadow: 0 0 5px #ccc;
  margin-left: auto !important;
  margin-right: auto !important;
  background: url(../img/patterns/15.png) repeat; }

/*Heading
------------------------------------*/
/* line 33, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.heading h2 {
  color: #687074;
  font-size: 24px;
  text-transform: uppercase; }

/* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.heading h2:before,
.heading h2:after {
  width: 100%; }

@media (max-width: 991px) {
  /* line 45, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .heading h2:before,
  .heading h2:after {
    width: 65%; } }

@media (max-width: 768px) {
  /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .heading h2:before,
  .heading h2:after {
    width: 35%; } }

@media (max-width: 600px) {
  /* line 59, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .heading h2 {
    font-size: 19px; }
  /* line 63, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .heading h2:before,
  .heading h2:after {
    width: 15%; } }

/*Illustration v1
------------------------------------*/
/*Illustration v1*/
/* line 72, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 {
  display: block;
  overflow: hidden;
  min-height: 170px;
  position: relative;
  background-size: cover;
  background-position: center center; }

/* line 81, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1:before {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(42, 42, 42, 0.3); }

/* line 92, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1:hover {
  text-decoration: none; }

/* line 96, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .illustration-bg {
  width: 100%;
  display: table;
  min-height: 170px;
  background-clip: padding-box; }

/* line 103, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .illustration-ads {
  width: 100%;
  height: 170px;
  overflow: hidden;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 15px 20px 20px;
  background-clip: padding-box; }

/*Illustration Advertaisemnt Details v1*/
/* line 115, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1.illustration-img1 {
  width: 100%;
  overflow: hidden;
  background-image: url(../img/blog/07.jpg);
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

/* line 125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1.illustration-img1:hover {
  -transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05); }

/* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v1 h3 {
  color: #fff;
  font-size: 30px;
  line-height: 30px;
  position: relative;
  margin-bottom: 35px;
  font-weight: normal; }

/* line 141, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v1 h3:after {
  left: 50%;
  z-index: 1;
  width: 30px;
  height: 3px;
  content: " ";
  bottom: -17px;
  background: #fff;
  margin-left: -15px;
  position: absolute; }

/* line 153, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v1 .btn-brd {
  font-weight: 400;
  border-width: 2px; }

/* line 158, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v1 .btn-brd:hover {
  border-width: 2px; }

/* line 162, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v1 .btn-u {
  padding: 5px 30px; }

/*Illustration Advertaisemnt Details v2*/
/* line 167, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1.illustration-img2 {
  width: 100%;
  overflow: hidden;
  background-image: url(../img/blog/08.jpg);
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

/* line 177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1.illustration-img2:hover {
  -transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05); }

/* line 184, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v2 span.item-time {
  color: #fff;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  text-transform: uppercase; }

/* line 194, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v2 span.item-time:before,
.illustration-v1 .ad-details-v2 span.item-time:after {
  top: 15px;
  width: 40%;
  height: 2px;
  content: " ";
  background: #fff;
  position: absolute; }

/* line 204, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v2 span.item-time:before {
  left: 100%; }

/* line 207, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v2 span.item-time:after {
  right: 100%; }

/* line 211, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v1 .ad-details-v2 span.item-name {
  color: #fff;
  display: block;
  font-size: 60px;
  line-height: 60px;
  position: relative;
  font-weight: normal;
  text-transform: uppercase; }

/*Illustration v2
------------------------------------*/
/*Owl Navigation*/
/* line 224, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .customNavigation {
  text-align: center; }

/* line 228, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .customNavigation a.owl-btn {
  color: #ccc;
  width: 30px;
  height: 30px;
  font-size: 17px;
  cursor: pointer;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  border: 2px solid #ddd; }

/* line 240, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .customNavigation a.owl-btn:hover {
  color: #555;
  border-color: #777;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

/* line 249, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .customNavigation a.owl-btn.prev i {
  margin-left: -2px; }

/* line 252, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .customNavigation a.owl-btn.next i {
  margin-right: -2px; }

/*illustration-v2*/
/* line 257, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .item {
  margin: 0 10px;
  padding-bottom: 5px; }

/* line 262, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .product-img {
  position: relative; }

/* line 266, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .product-img-brd {
  box-shadow: 0 0 3px #ccc; }

/* line 270, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 a.product-review {
  left: 0;
  bottom: 0;
  color: #fff;
  width: 100%;
  padding: 5px 0;
  font-size: 12px;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5); }

/*Add to Cart*/
/* line 284, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .add-to-cart {
  left: 0;
  right: 0;
  top: 50%;
  z-index: 1;
  width: 100%;
  color: #555;
  border: none;
  padding: 10px 0;
  font-size: 20px;
  margin-top: -20px;
  text-align: center;
  position: absolute;
  visibility: hidden;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.8); }

/* line 302, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .add-to-cart i {
  line-height: 15px;
  margin-right: 12px;
  padding-right: 12px;
  border-right: 1px solid #555; }

/* line 309, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .add-to-cart:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(24, 171, 155, 0.5);
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out; }

/* line 319, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .add-to-cart:hover i {
  color: #fff;
  border-color: #fff; }

/* line 324, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v2 .product-img:hover .add-to-cart {
  visibility: visible; }

/*List Product Description*/
/* line 329, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.list-product-description {
  padding: 20px 10px; }

/* line 333, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.list-product-description img {
  border-right: 1px solid #dedede; }

@media (max-width: 768px) {
  /* line 339, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .list-product-description img {
    padding-right: 0;
    border-right: none; } }

/*Product Description*/
/* line 346, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description {
  padding: 10px;
  overflow: hidden; }

/* line 351, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description-brd {
  box-shadow: 0 0 2px #ccc; }

/* line 355, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description .title-price {
  color: #687074;
  font-size: 18px; }

/* line 360, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description h4.title-price {
  margin: 2px 0 0; }

/* line 364, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description h4.title-price a {
  color: #687074; }

/* line 368, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description h4.title-price a:hover {
  color: #18ba9b; }

/* line 372, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description .product-price {
  text-align: right;
  padding-left: 10px; }

/* line 377, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description .product-price span {
  display: block; }

/* line 381, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description .line-through {
  color: #c9253c;
  text-decoration: line-through; }

/* line 386, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description .gender {
  color: #999;
  display: block;
  font-size: 14px; }

/* line 392, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description .product-ratings {
  overflow: hidden; }

/*Product Description v2*/
/* line 397, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description-v2 .title-price {
  color: #687074;
  font-size: 18px; }

/* line 402, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description-v2 span.title-price {
  margin-right: 15px; }

/* line 406, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description-v2 .title-price a {
  color: #687074; }

/* line 410, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description-v2 h4.title-price a:hover {
  color: #18ba9b; }

/* line 414, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-description-v2 .title-price.line-through {
  color: #c9253c;
  text-decoration: line-through; }

/*Rating*/
/* line 420, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li {
  padding: 0; }

/* line 424, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li .rating {
  color: #8bdccd;
  font-size: 14px; }

/* line 429, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li .rating-selected {
  color: #18ba9b;
  font-size: 14px; }

/*Like Icon*/
/* line 435, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li.like-icon {
  float: right; }

/* line 439, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li.like-icon a {
  color: #8bdccd; }

/* line 443, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li.like-icon .tooltip {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase; }

/* line 449, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li.like-icon a:hover {
  color: #18ba9b;
  text-decoration: none; }

/*Reply*/
/* line 455, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-ratings li.reply a {
  color: #18ba9b; }

/*Illustration v3
------------------------------------*/
/*Illustration v3*/
/* line 462, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3 {
  display: block;
  overflow: hidden;
  min-height: 400px;
  position: relative;
  background-size: cover;
  background-position: center center; }

/* line 471, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3:before {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(42, 42, 42, 0.3); }

/* line 482, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3:hover {
  text-decoration: none; }

/* line 486, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3 .illustration-bg {
  width: 100%;
  display: table;
  min-height: 400px;
  background-clip: padding-box; }

/* line 493, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3 .illustration-ads {
  width: 100%;
  height: 400px;
  overflow: hidden;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 15px 20px 20px;
  background-clip: padding-box; }

/*Illustration Images*/
/* line 505, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3.illustration-img1,
.illustration-v3.illustration-img2,
.illustration-v3.illustration-img3 {
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

/* line 514, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3.illustration-img1 {
  background-image: url(../img/blog/13.jpg); }

/* line 518, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3.illustration-img2 {
  background-image: url(../img/blog/14.jpg); }

/* line 522, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3.illustration-img3 {
  background-image: url(../img/blog/15.jpg); }

/* line 526, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3.illustration-img1:hover,
.illustration-v3.illustration-img2:hover,
.illustration-v3.illustration-img3:hover {
  -transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05); }

/* line 535, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3 .illustration-v3-category {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  position: relative;
  border-top: 5px solid rgba(255, 255, 255, 0.5);
  border-bottom: 5px solid rgba(255, 255, 255, 0.5); }

/* line 546, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3 span.product-category {
  color: #fff;
  display: block;
  font-size: 35px;
  font-weight: 600;
  line-height: 50px;
  letter-spacing: 12px;
  text-transform: uppercase; }

/* line 556, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v3 span.product-amount {
  color: #ddd;
  font-size: 24px; }

/*Twitter Block
------------------------------------*/
/* line 563, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block {
  padding: 25px 0 75px;
  background: url(../img/twitter-bg.jpg) repeat; }

/*Heading*/
/* line 569, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .heading h2 {
  color: #fff;
  font-size: 24px; }

/* line 574, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .heading h2:before,
.twitter-block .heading h2:after {
  border-color: #777; }

/*Carousel*/
/* line 580, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .item {
  padding: 0 150px;
  text-align: center; }

@media (max-width: 991px) {
  /* line 586, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .twitter-block .item {
    padding: 0 100px; } }

/* line 591, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .item p {
  color: #fff;
  font-size: 18px; }

@media (max-width: 768px) {
  /* line 597, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .twitter-block .item p {
    font-size: 14px;
    font-weight: 200; } }

/* line 603, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .item a {
  color: #18ba9b; }

/* line 607, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .twitter-icons i {
  font-size: 18px; }

/* line 611, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .twitter-icons i:hover {
  color: #fff; }

/* line 615, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .carousel-control {
  top: 50%;
  color: #fff;
  font-size: 40px;
  margin-top: -30px; }

/* line 622, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .carousel-control.left {
  left: -10px; }

/* line 625, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .carousel-control.right {
  right: -10px; }

/* line 629, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.twitter-block .carousel-indicators {
  bottom: -50px; }

/*Product Service
------------------------------------*/
/* line 635, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-service .product-service-heading {
  padding: 10px 0;
  text-align: center;
  background: #18ba9b; }

/* line 641, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-service:hover .product-service-heading {
  background: #8bdccd;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 649, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-service .product-service-heading i {
  color: #fff;
  font-size: 40px; }

/* line 654, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-service .product-service-in {
  padding: 15px 30px;
  text-align: center;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

/* line 662, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-service .product-service-in h3 {
  color: #555;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase; }

/* line 669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-service .product-service-in a {
  color: #c9253c;
  font-size: 14px;
  font-weight: 400; }

/*Collection Banner
------------------------------------*/
/* line 677, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner {
  padding: 50px 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../img/collection-bg.jpg); }

/* line 686, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  position: absolute;
  background: rgba(0, 0, 0, 0.3); }

/* line 696, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner h2 {
  color: #fff;
  font-size: 36px;
  line-height: 40px;
  margin: 12px 0 15px;
  text-transform: uppercase; }

/* line 704, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner p {
  color: #eee; }

/*Percent Off*/
/* line 709, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .percent-numb {
  color: #fff;
  float: left;
  padding: 10px 0;
  font-size: 200px;
  font-weight: 400;
  line-height: 150px;
  margin-right: 15px; }

/* line 719, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .percent-off {
  float: left;
  margin-right: 30px; }

/* line 724, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .percent-off span {
  color: #fff;
  display: block; }

/* line 729, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .percent-off span.discount-percent {
  font-size: 70px;
  margin-top: 30px;
  font-weight: 200;
  line-height: 80px; }

/* line 736, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .percent-off span.discount-off {
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase; }

@media (min-width: 992px) and (max-width: 1199px) {
  /* line 743, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner h2 {
    font-size: 30px; }
  /* line 747, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner h3 {
    font-size: 145px; }
  /* line 751, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner .percent-off span.discount-percent {
    font-size: 70px;
    margin-top: 35px;
    line-height: 60px; }
  /* line 757, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner .percent-off span.discount-off {
    font-size: 30px; } }

@media (max-width: 514px) {
  /* line 763, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner h3 {
    font-size: 145px; }
  /* line 767, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner .percent-off span.discount-percent {
    font-size: 70px;
    margin-top: 35px;
    line-height: 60px; }
  /* line 773, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner .percent-off span.discount-off {
    font-size: 30px; } }

/*New Offers*/
/* line 779, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .new-offers {
  top: -30px;
  width: 80px;
  height: 80px;
  position: absolute;
  padding: 20px 10px;
  text-align: center;
  display: inline-block; }

/* line 789, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .new-offers p {
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 17px; }

/* line 797, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.collection-banner .new-offers span {
  color: #fff;
  font-size: 14px; }

@media (max-width: 400px) {
  /* line 803, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .collection-banner .new-offers {
    top: -50px;
    right: 10px; } }

/*Illustration v4
------------------------------------*/
/* line 811, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product {
  padding: 10px;
  overflow: hidden;
  margin-bottom: 20px; }

/* line 817, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product:hover {
  box-shadow: 0 0 3px #dedede; }

/* line 821, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .heading h2 {
  font-size: 18px; }

/* line 825, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .heading h2:before,
.illustration-v4 .heading h2:after {
  width: 50%; }

/* line 830, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 img.thumb-product-img {
  float: left;
  width: 100px;
  height: 100px;
  margin-right: 25px; }

/* line 837, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-in {
  overflow: hidden;
  margin-top: 15px; }

/* line 842, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-in h4 {
  margin: 0 0 2px; }

/* line 846, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-in h4 a {
  color: #687074;
  font-size: 18px; }

/* line 851, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-in h4 a:hover {
  color: #18ba9b; }

/* line 855, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-in span {
  display: block; }

/* line 859, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 span.thumb-product-type {
  color: #999;
  font-size: 14px; }

/* line 864, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-price {
  color: #687074;
  font-size: 16px; }

/* line 869, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-price.line-through {
  color: #c9253c;
  text-decoration: line-through; }

/* line 874, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-purchase {
  color: #ccc;
  float: right;
  margin-top: 3px; }

/* line 880, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-purchase a {
  color: #8bdccd; }

/* line 884, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-purchase i {
  top: 1px;
  font-size: 16px;
  position: relative; }

/* line 890, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.illustration-v4 .thumb-product-purchase a:hover {
  color: #18ba9b;
  text-decoration: none; }

/*Owl Carousel v2
------------------------------------*/
/* line 897, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .owl-wrapper-outer {
  margin-bottom: 20px; }

/* line 901, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .item {
  margin: 0 10px;
  text-align: center;
  padding: 7px 0;
  position: relative;
  border: 1px solid #e4e2e2; }

/* line 909, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .item img {
  width: 150px;
  height: auto; }

/*Owl Pagination*/
/* line 915, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .owl-pagination {
  text-align: center !important; }

/* line 919, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .owl-controls .owl-page.active span,
.owl-slider-v2 .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  background: #888;
  filter: Alpha(Opacity=100); }

/* line 926, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .owl-controls .owl-page span {
  border: 1px solid #999 !important; }

/* line 930, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
  *display: inline;
  /*IE7 life-saver */ }

/* line 936, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v2 .owl-controls .owl-page span {
  width: 12px;
  height: 12px;
  opacity: 0.3;
  display: block;
  margin: 5px 3px;
  background: transparent;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important; }

/*Shop-subscribe
------------------------------------*/
/* line 951, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe {
  padding: 20px 0;
  background: #18ba9b; }

/* line 956, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe h2 {
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 200;
  text-transform: uppercase; }

/*Input Form Control*/
/* line 965, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe input {
  border-color: #fff;
  border-right: none;
  background: transparent; }

/* line 971, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .form-control {
  color: #fff;
  font-size: 14px;
  font-weight: 200; }

/* line 977, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .form-control:focus {
  box-shadow: none;
  border-color: #fff; }

/* line 982, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .form-control::-moz-placeholder {
  color: #fff; }

/* line 985, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .form-control:-ms-input-placeholder {
  color: #fff; }

/* line 988, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .form-control::-webkit-input-placeholder {
  color: #fff; }

/* line 992, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .input-group-btn {
  border-color: #fff;
  background: transparent; }

/* line 997, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .input-group-btn .btn {
  border: 1px solid #fff;
  background: transparent; }

/* line 1002, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-subscribe .input-group-btn i {
  color: #fff;
  font-size: 16px;
  font-weight: 200; }

/*Shop Product
------------------------------------*/
/* line 1010, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product {
  background: #f8f8f8;
  padding: 10px 0 30px;
  border-bottom: 1px solid #dedede; }

/* line 1016, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product h2 {
  float: left;
  color: #687074;
  font-size: 26px;
  text-transform: uppercase; }

/*Shp Product Title*/
/* line 1024, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .shop-product-heading {
  overflow: hidden;
  margin-bottom: 12px;
  border-bottom: 1px solid #dedede; }

/* line 1030, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .shop-product-social {
  float: right;
  margin-top: 11px; }

/* line 1035, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .shop-product-social li i {
  color: #dedede;
  font-size: 26px;
  display: inline-block; }

/* line 1041, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .shop-product-social li i:hover {
  color: #18ba9b;
  text-decoration: none; }

/*Product Ratings*/
/* line 1047, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-review-list {
  margin-left: 20px; }

/* line 1051, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-review-list,
.shop-product .product-review-list a {
  color: #999;
  font-size: 14px; }

/* line 1057, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-review-list a:hover {
  color: #18ba9b; }

/*Shop Product Prices*/
/* line 1062, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .shop-product-prices li:first-child {
  padding-left: 0; }

/* line 1066, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .shop-product-prices li {
  font-size: 30px; }

/* line 1070, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .line-through {
  color: #bbb;
  text-decoration: line-through; }

/*Badge*/
/* line 1076, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .time-day-left {
  top: -6px;
  color: #fff;
  font-size: 12px;
  padding: 3px 8px;
  margin-left: 40px;
  position: relative; }

/*Shop Product Title*/
/* line 1086, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product h3.shop-product-title {
  color: #555;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase; }

/*Product Size*/
/* line 1094, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-size input {
  left: -9999px;
  position: absolute; }

/* line 1099, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-size label {
  width: 50px;
  height: 50px;
  float: right;
  padding: 6px 0;
  display: block;
  color: #dedede;
  font-size: 24px;
  text-align: center;
  background: #f8f8f8;
  text-transform: uppercase;
  border: 2px solid #dedede; }

/* line 1113, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-size label {
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s; }

/* line 1119, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-size label:hover {
  color: #18ba9b;
  cursor: pointer;
  border-color: #18ba9b; }

/* line 1125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-size input:checked ~ label {
  color: #18ba9b;
  border-color: #18ba9b; }

/*Product Color*/
/* line 1131, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color input {
  left: -9999px;
  position: absolute; }

/* line 1136, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color label {
  width: 50px;
  height: 50px;
  float: right;
  padding: 5px;
  display: block;
  font-size: 24px;
  text-align: center; }

/* line 1146, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color label.color-one {
  background: #646969; }

/* line 1150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color label.color-two {
  background: #e4e4e2; }

/* line 1154, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color label.color-three {
  background: #171a2e; }

/* line 1158, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color label {
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s; }

/* line 1164, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color label:hover {
  cursor: pointer;
  border: 2px solid #18ba9b; }

/* line 1169, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-color input:checked ~ label {
  border: 2px solid #18ba9b; }

/*Product Quantity*/
/* line 1174, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .product-quantity {
  float: left;
  margin-right: 30px; }

/* line 1179, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .quantity-button {
  color: #555;
  width: 55px;
  height: 50px;
  padding: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 20px;
  background: #eee;
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
  display: inline-block;
  background-image: none; }

/* line 1196, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shop-product .quantity-field {
  width: 55px;
  height: 50px;
  outline: none;
  margin: 0 -4px;
  font-size: 20px;
  text-align: center;
  border: 1px solid #eee; }

/*Add to Wishlist*/
/* line 1207, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.add-to-wishlist-brd {
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede; }

/* line 1212, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.add-to-wishlist li {
  padding-left: 20px;
  margin-right: 10px;
  border-left: 1px solid #dedede; }

/* line 1218, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.add-to-wishlist li:first-child {
  padding-left: 0;
  border-left: none; }

/* line 1223, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.add-to-wishlist i {
  color: #8bdccd;
  font-size: 16px;
  margin-right: 7px; }

/* line 1229, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.add-to-wishlist a {
  color: #999;
  font-size: 14px; }

/* line 1234, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.add-to-wishlist a:hover,
.add-to-wishlist .wishlist-in:hover i,
.add-to-wishlist .compare-in:hover i {
  color: #18ba9b;
  text-decoration: none; }

/* line 1241, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.add-to-wishlist .wishlist-in:hover i,
.add-to-wishlist .compare-in:hover i {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* line 1249, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.wishlist-category a {
  color: #18ba9b; }

/*Headline Left*/
/* line 1254, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.headline-left {
  position: relative; }

/* line 1258, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.headline-left h2 {
  color: #555;
  font-size: 18px;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px; }

/* line 1266, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.headline-left h2:after {
  left: 1px;
  z-index: 1;
  width: 30px;
  height: 2px;
  content: " ";
  bottom: -5px;
  position: absolute;
  background: #dedede; }

/*Specifies List*/
/* line 1278, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.specifies-list li {
  color: #999; }

/* line 1282, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.specifies-list li i {
  margin-right: 8px; }

/* line 1286, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.specifies-list li span {
  color: #777; }

/*Product Comment*/
/* line 1291, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-comment {
  padding: 20px;
  border: 1px solid #f0f0f0; }

@media (min-width: 768px) {
  /* line 1297, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .product-comment .product-comment-in .product-comment-in {
    margin-left: 50px;
    padding-top: 30px;
    border-top: 1px solid #eee; } }

/* line 1304, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-comment .product-comment-in:after,
.product-comment .product-comment-in:before {
  content: " ";
  display: table; }

/* line 1310, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-comment .product-comment-in:after {
  clear: both; }

/* line 1314, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-comment img.product-comment-img {
  float: left;
  width: 50px;
  height: 50px;
  margin-right: 15px; }

/* line 1321, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-comment .product-comment-dtl {
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px; }

/* line 1327, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-comment .product-comment-dtl h4 {
  color: #555;
  font-size: 18px;
  overflow: hidden; }

/* line 1333, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-comment .product-comment-dtl h4 small {
  color: #888;
  float: right;
  display: block;
  margin-top: 6px; }

/* line 1340, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sky-changes-4 label.label-v2 {
  color: #999;
  display: block;
  font-size: 14px;
  font-weight: 400; }

/* line 1347, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sky-changes-4 h3 {
  color: #555; }

/*Review Submit*/
/* line 1352, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.review-submit {
  overflow: hidden; }

/* line 1356, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings {
  float: left; }

/* line 1360, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings li {
  padding: 0; }

/* line 1364, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings input {
  left: -9999px;
  position: absolute; }

/* line 1369, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings label {
  float: right;
  height: 17px;
  display: block;
  padding: 0 2px;
  font-size: 14px;
  cursor: pointer;
  line-height: 17px; }

/* line 1379, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings label {
  color: #8bdccd;
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s; }

/* line 1386, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings input + label:hover,
.stars-ratings input + label:hover ~ label {
  color: #18ba9b; }

/* line 1391, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings input:checked ~ label {
  color: #18ba9b; }

/* line 1395, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.review-submit button.btn-u.pull-right {
  margin-top: -17px; }

/*Stars Ratings Label*/
/* line 1400, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.stars-ratings-label label {
  font-size: 24px;
  margin: 0 4px 5px; }

/*Owl Slider v3
------------------------------------*/
/* line 1407, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v3 .owl-pagination {
  text-align: center !important; }

/* line 1411, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v3 .owl-wrapper-outer {
  margin-bottom: 20px; }

/* line 1415, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v3.owl-theme .owl-controls .owl-page.active span,
.owl-slider-v3.owl-theme .owl-controls.clickable .owl-page:hover span {
  opacity: 1;
  background: #18ba9b;
  filter: Alpha(Opacity=100);
  /*IE7 fix*/ }

/* line 1422, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v3.owl-theme .owl-controls .owl-page {
  zoom: 1;
  display: inline-block;
  *display: inline;
  /*IE7 life-saver */ }

/* line 1428, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v3.owl-theme .owl-controls .owl-page span {
  width: 12px;
  height: 12px;
  opacity: 0.3;
  display: block;
  margin: 5px 3px;
  background: #bbb;
  filter: Alpha(Opacity=50);
  /*IE7 fix*/
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important; }

/* line 1441, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.owl-slider-v3 .item {
  margin: 0 1px; }

/*Filter By Block
------------------------------------*/
/*Panel Group*/
/* line 1448, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block h1 {
  color: #fff;
  width: 100%;
  font-size: 24px;
  padding: 7px 10px;
  text-align: center;
  margin-bottom: 5px;
  background: #18ba9b;
  text-transform: uppercase; }

/* line 1459, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block .panel-default {
  border-color: #dedede; }

/* line 1463, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block .panel-heading {
  padding: 0;
  background: inherit; }

/* line 1468, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block .panel-title {
  overflow: hidden; }

/* line 1472, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block .panel-group h2 a {
  color: #687074;
  display: block;
  font-size: 20px;
  padding: 10px 15px;
  border-bottom: 1px solid #dedede; }

/* line 1480, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block .panel-group h2 i {
  float: right;
  font-size: 18px;
  margin-top: 8px; }

/* line 1486, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block .panel-group h2 a:hover {
  color: #687074;
  text-decoration: none; }

/* line 1491, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-by-block .panel-body {
  padding: 15px 20px;
  border-top: none !important; }

/*Checkbox List*/
/* line 1497, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list {
  margin-bottom: 0; }

/* line 1501, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list li {
  margin-top: 10px; }

/* line 1505, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list li:first-child {
  margin-top: 0; }

/* line 1509, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox {
  display: block;
  overflow: hidden;
  position: relative; }

/* line 1515, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox {
  color: #999;
  margin: 4px 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 25px;
  padding-left: 27px; }

/* line 1524, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox input {
  left: -9999px;
  position: absolute; }

/* line 1529, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox i {
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  outline: none;
  display: block;
  background: #fff;
  border-width: 2px;
  position: absolute;
  border-style: solid; }

/* line 1542, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox input + i:after {
  opacity: 0;
  position: absolute; }

/* line 1547, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox input + i:after {
  top: -3px;
  left: -2px;
  width: 15px;
  height: 15px;
  content: '\f00c';
  text-align: center;
  font: normal 8px/17px FontAwesome; }

/* line 1557, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox input:checked + i:after {
  opacity: 1; }

/* line 1561, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox i {
  border-color: #8bdccd;
  -ms-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  -webkit-transition: border-color 0.3s; }

/* line 1568, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox:hover i {
  border-color: #18ba9b; }

/* line 1572, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox input:focus + i {
  border-color: #18ba9b; }

/* line 1576, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox input + i:after {
  color: #18ba9b; }

/* line 1579, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox input:checked + i {
  border-color: #18ba9b; }

/* line 1583, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox small {
  float: right; }

/* line 1587, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.checkbox-list .checkbox small a {
  color: #18ba9b;
  font-size: 12px; }

/*Slider Snap*/
/* line 1593, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap {
  margin: 20px 0; }

/* line 1597, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap.noUi-background {
  -webkit-box-shadow: inset 0px 1px 1px 0px #b5b5b5;
  -moz-box-shadow: inset 0px 1px 1px 0px #b5b5b5;
  box-shadow: inset 0px 1px 1px 0px #b5b5b5; }

/* line 1603, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap.noUi-connect {
  background: #18ba9b; }

/* line 1607, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap.noUi-horizontal {
  height: 10px;
  background: #f8f8f8;
  border-radius: 4px !important; }

/* line 1613, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap.noUi-horizontal .noUi-handle {
  width: 25px;
  height: 20px;
  border-radius: 3px !important;
  box-shadow: 0 1px 1px 0 #b5b5b5; }

/* line 1620, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap .noUi-handle:before,
.slider-snap .noUi-handle:after {
  left: 9px;
  height: 7px; }

/* line 1626, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap .noUi-handle:after {
  left: 13px; }

/* line 1630, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap-text {
  text-align: center; }

/* line 1634, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap-text span {
  position: relative; }

/* line 1638, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap-text span + span:before {
  top: -2px;
  left: -15px;
  content: '\2013';
  position: absolute; }

/* line 1645, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap-text span:after {
  left: 0;
  top: -1px;
  content: "\0024";
  position: absolute; }

/* line 1652, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.slider-snap-text span {
  color: #999;
  font-size: 16px;
  font-weight: 600;
  padding: 0 19px 0 11px; }

/*Product Color List*/
/* line 1660, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-color-list {
  margin-bottom: 0;
  text-align: center; }

/* line 1665, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-color-list .list-inline > li {
  padding-left: 0; }

/* line 1669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-color-list li {
  padding: 2px; }

/* line 1673, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-color-list li img {
  width: 24px;
  height: 24px; }

/* line 1678, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.product-color-list li:first-child {
  padding-left: 0; }

/*Result Category*/
/* line 1683, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.result-category {
  /*background: #000;*/
  overflow: hidden;
  margin-bottom: 5px; }

/* line 1689, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.result-category h2 {
  float: left;
  font-size: 30px;
  margin-right: 20px;
  text-transform: uppercase; }

/* line 1696, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.result-category .badge-results {
  top: 12px;
  color: #fff;
  font-size: 12px;
  padding: 3px 8px;
  position: relative; }

/*Grid List Icons*/
/* line 1705, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.grid-list-icons {
  float: right;
  margin-top: 9px; }

/* line 1710, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.grid-list-icons i {
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 6px;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  background: #18ba9b;
  display: inline-block; }

/* line 1722, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.grid-list-icons i:hover {
  background: #8bdccd;
  text-decoration: none; }

/*Sort List Button*/
/* line 1728, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn {
  float: right;
  margin: 6px 15px 0 0; }

/* line 1733, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn h3 {
  float: left;
  color: #555;
  font-size: 14px;
  margin-right: 10px; }

/* line 1740, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn .btn {
  margin-top: 2px; }

/* line 1744, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn .btn .caret {
  margin-left: 50px; }

/* line 1748, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn .btn-default {
  color: #999;
  font-size: 13px;
  border-color: #dedede; }

/* line 1754, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn .btn-default:hover,
.sort-list-btn .btn-default:focus,
.sort-list-btn .btn-default:active,
.sort-list-btn .btn-default.active,
.sort-list-btn .open > .dropdown-toggle.btn-default {
  color: #999;
  box-shadow: none;
  background: inherit;
  border-color: #dedede; }

/* line 1765, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn .dropdown-menu {
  box-shadow: 0 5px 5px 0px rgba(90, 90, 90, 0.075); }

/* line 1769, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.sort-list-btn .dropdown-menu li a {
  color: #999;
  font-size: 12px; }

/*Filter Results*/
/* line 1775, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.filter-results {
  padding: 40px 0;
  margin-bottom: 30px;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede; }

/*Pagination v2*/
/* line 1783, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.pagination.pagination-v2 > li > a {
  padding: 5px;
  margin: 0 2px;
  min-width: 35px;
  border-color: #f0f0f0; }

/* line 1790, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.pagination.pagination-v2 > li > a,
.pagination.pagination-v2 > li > span {
  background: #f7f7f7; }

/* line 1795, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.pagination.pagination-v2 > li > a:hover,
.pagination.pagination-v2 > .active > a,
.pagination.pagination-v2 > .active > span,
.pagination.pagination-v2 > .active > a:hover,
.pagination.pagination-v2 > .active > span:hover,
.pagination.pagination-v2 > .active > a:focus,
.pagination.pagination-v2 > .active > span:focus {
  background: #18ba9b;
  border-color: #18ba9b; }

/*Info Block v2
------------------------------------*/
/*Info Block v2*/
@media (min-width: 480px) {
  /* line 1810, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .info-block-v2 {
    margin: 0 120px 20px 0; } }

/* line 1815, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.info-block-v2 i {
  float: left;
  color: #18ba9b;
  font-size: 30px;
  margin: 7px 15px 0 0; }

/* line 1822, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.info-block-in {
  overflow: hidden; }

/* line 1826, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.info-block-in h3 {
  color: #555;
  font-size: 18px;
  margin: 0 0 5px; }

/*Shopping Cart
------------------------------------*/
/*Coupon Code*/
/* line 1835, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .coupon-code {
  margin-top: 35px;
  padding-top: 35px;
  border-top: 1px solid #eee; }

/* line 1841, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .coupon-code h3 {
  margin: 0;
  color: #687074;
  font-size: 18px; }

/* line 1847, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .coupon-code input {
  width: 60%;
  margin-right: 10px;
  display: inline-block; }

/*Total Result*/
/* line 1854, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result li {
  display: block; }

/* line 1858, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result li:after,
.shopping-cart .total-result li:before {
  content: " ";
  display: table; }

/* line 1864, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result li:after {
  clear: both; }

/* line 1868, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background: #eee; }

/* line 1875, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result h4 {
  float: left;
  margin-right: 15px; }

/* line 1880, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result-in {
  overflow: hidden;
  text-align: right; }

/* line 1885, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result-in h4,
.shopping-cart .total-result-in span {
  color: #687074;
  font-size: 18px; }

/* line 1891, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result .total-price h4,
.shopping-cart .total-result .total-price span {
  font-weight: 600; }

/* line 1896, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result .total-price h4 {
  margin: 0 auto; }

/* line 1900, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .total-result .total-price span {
  color: #c9253c; }

/*Table*/
/* line 1905, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .table > thead > tr > th,
.shopping-cart .table > tbody > tr > th,
.shopping-cart .table > tfoot > tr > th,
.shopping-cart .table > thead > tr > td,
.shopping-cart .table > tbody > tr > td,
.shopping-cart .table > tfoot > tr > td {
  border-top: none;
  vertical-align: middle; }

/* line 1915, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .table > thead > tr > th,
.shopping-cart .table > tbody > tr > td {
  padding: 15px;
  text-align: center; }

/* line 1921, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .table > thead > tr > th {
  color: #687074;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 20px;
  text-transform: uppercase; }

/* line 1929, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .table > tbody > tr > td {
  color: #888; }

/* line 1933, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .table > tbody > tr > td.shop-red {
  color: #c9253c; }

/*Product In Table*/
/* line 1938, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .product-in-table {
  text-align: left !important; }

/* line 1942, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .product-it-in {
  overflow: hidden; }

@media (min-width: 991px) {
  /* line 1947, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .shopping-cart .product-it-in {
    margin-top: 30px; } }

@media (max-width: 992px) {
  /* line 1953, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .shopping-cart .product-it-in {
    clear: both; } }

/* line 1958, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .product-in-table img {
  float: left;
  width: 120px;
  height: auto;
  padding: 10px;
  background: #fff;
  margin-right: 15px;
  border: 1px solid #eee; }

/* line 1968, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .product-in-table h3 {
  font-size: 16px;
  margin-bottom: 5px; }

/*Quantity Field Form*/
/* line 1974, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .quantity-button {
  color: #888;
  width: 45px;
  height: 40px;
  padding: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  background: #eee;
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
  display: inline-block;
  background-image: none; }

/* line 1991, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .quantity-field {
  width: 45px;
  height: 40px;
  outline: none;
  margin: 0 -4px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  border: 1px solid #eee; }

/*Close*/
/* line 2003, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .close {
  opacity: 1;
  color: #888;
  font-size: 18px;
  padding-right: 10px; }

/* line 2010, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .close:hover {
  color: #555; }

/*Billing Info*/
/* line 2015, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart h2.title-type {
  font-size: 16px;
  margin-left: 20px;
  text-transform: uppercase; }

/* line 2021, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .billing-info-inputs {
  padding: 20px;
  background: #f8f8f8;
  border: 1px solid #eee; }

/* line 2027, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .billing-info-inputs label {
  color: #555;
  margin-bottom: 0;
  vertical-align: middle; }

/* line 2033, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .billing-info-inputs input {
  min-height: 40px;
  border-color: #eee;
  margin-bottom: 10px; }

/* line 2039, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .billing-info-inputs .form-control {
  color: #888;
  box-shadow: none; }

/* line 2044, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .billing-info-inputs .form-control::-moz-placeholder {
  color: #888; }

/* line 2047, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .billing-info-inputs .form-control:-ms-input-placeholder {
  color: #888; }

/* line 2050, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.shopping-cart .billing-info-inputs .form-control::-webkit-input-placeholder {
  color: #888; }

/*Custom Form Horizontal*/
/* line 2055, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .form-group {
  margin: 0 0 15px;
  overflow: hidden;
  padding-bottom: 7px; }

/* line 2061, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .form-group label {
  color: #555;
  margin-top: 4px;
  font-size: 15px;
  font-weight: 400;
  text-align: left; }

/* line 2069, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.wizard > .content > .body .cus-form-horizontal input {
  color: #888;
  box-shadow: none;
  border-color: #eee; }

/* line 2075, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .form-group input::-moz-placeholder {
  color: #ccc;
  font-size: 12px;
  font-weight: 600;
  text-align: center; }

/* line 2081, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .form-group input:-ms-input-placeholder {
  color: #ccc;
  font-size: 12px;
  font-weight: 600;
  text-align: center; }

/* line 2087, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .form-group input::-webkit-input-placeholder {
  color: #ccc;
  font-size: 12px;
  font-weight: 600;
  text-align: center; }

/* line 2094, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .input-small-field a {
  top: 5px;
  color: #18ba9b;
  position: relative;
  text-decoration: underline; }

/* line 2101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .input-small-field a:hover {
  color: #18ba9b;
  text-decoration: none; }

/*Payment Type*/
/* line 2107, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .payment-type li {
  margin-right: 10px; }

/* line 2111, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.cus-form-horizontal .payment-type i {
  color: #888;
  font-size: 35px; }

/**
 * Store index.
 */
/* line 2120, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .file-image, .display-full .file-image {
  text-align: center; }

/* line 2125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .list-product-description .title-price, .view-store .list-product-description .field-name-field-rating, .display-full .list-product-description .title-price, .display-full .list-product-description .field-name-field-rating {
  margin-bottom: 0.5em; }

/* line 2129, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .list-product-description .add-to-wishlist, .display-full .list-product-description .add-to-wishlist {
  margin-bottom: 0; }

@media (max-width: 479px) {
  /* line 2134, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .view-store .list-product-description .title-price, .view-store .list-product-description .field-name-field-rating, .view-store .list-product-description .price-cart-wrap, .display-full .list-product-description .title-price, .display-full .list-product-description .field-name-field-rating, .display-full .list-product-description .price-cart-wrap {
    text-align: center; }
  /* line 2138, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .view-store .list-product-description .field-name-field-rating, .display-full .list-product-description .field-name-field-rating {
    margin-bottom: 0; }
  /* line 2141, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .view-store .list-product-description .field-name-field-rating > .field-items, .display-full .list-product-description .field-name-field-rating > .field-items {
    display: inline-block; }
  /* line 2144, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .view-store .list-product-description .field-name-body, .view-store .list-product-description .add-to-wishlist, .display-full .list-product-description .field-name-body, .display-full .list-product-description .add-to-wishlist {
    padding: 0 1em; } }

/* line 2150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .list-product-description .field-name-commerce-price, .display-full .list-product-description .field-name-commerce-price {
  font-size: 18px;
  color: #687074; }

/* line 2155, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .list-product-description .col-sm-4, .display-full .list-product-description .col-sm-4 {
  padding: 0 10px 0 22px; }

@media (min-width: 480px) {
  /* line 2160, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .view-store .list-product-description .col-sm-4, .display-full .list-product-description .col-sm-4 {
    position: relative;
    float: left;
    width: 33.33333%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding: 0 10px 0 22px; }
  /* line 2164, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .view-store .list-product-description .col-sm-8, .display-full .list-product-description .col-sm-8 {
    position: relative;
    float: left;
    width: 66.66667%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0; } }

/* line 2171, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .image-caption, .display-full .image-caption {
  padding-top: 0.25em;
  font-style: italic; }

/* line 2176, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .commerce-product-field-commerce-price, .view-store .field-name-field-product, .display-full .commerce-product-field-commerce-price, .display-full .field-name-field-product {
  display: inline-block;
  vertical-align: middle;
  padding: 1em 1em 0 0; }

/* line 2182, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.view-store .commerce-add-to-cart .form-group, .display-full .commerce-add-to-cart .form-group {
  margin-bottom: 0; }

/**
 * Full product.
 */
@media (min-width: 480px) {
  /* line 2193, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .node-type-product-display .radix-layouts-sidebar {
    position: relative;
    float: left;
    width: 33.33333%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
  /* line 2196, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .node-type-product-display .radix-layouts-content {
    position: relative;
    float: left;
    width: 66.66667%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; } }

/* line 2202, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.node-type-product-display .radix-layouts-sidebar .flex-control-nav {
  position: static;
  bottom: auto;
  text-align: center;
  padding-top: 1em; }

/* line 2210, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.node-type-product-display .pane-node-content h2 {
  margin-bottom: 0; }

/* line 2213, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.node-type-product-display .display-full {
  padding: 0; }
  /* line 2217, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .node-type-product-display .display-full .view-product-slidehow .flex-control-nav {
    position: static;
    bottom: auto;
    margin-top: 20px; }
  /* line 2222, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .node-type-product-display .display-full .view-product-slidehow .flex-direction-nav a:before {
    line-height: 1;
    vertical-align: top;
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.6), 1px -1px 0 rgba(255, 255, 255, 0.6), -1px 1px 0 rgba(255, 255, 255, 0.6), 1px 1px 0 rgba(255, 255, 255, 0.6); }
  /* line 2234, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
  .node-type-product-display .display-full .field-name-commerce-price {
    font-size: 18px;
    color: #687074; }

/* line 2239, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_shop.scss */
.node-type-product-display .pane-node-comment-wrapper {
  margin-top: 2em; }

/* line 24, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
body {
  color: #333;
  font-size: 13px;
  line-height: 1.6; }
  /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  body.header-fixed-space {
    padding-top: 94px; }

@media (max-width: 991px) {
  /* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  body.header-fixed-space {
    padding-top: 0; } }

/* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
p {
  color: #555; }

/* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
li {
  color: #555; }
  /* line 45, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  li a {
    color: #555; }

/* line 50, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
label {
  color: #555; }

/* line 54, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
a {
  color: #d50344;
  text-decoration: none;
  outline: 0 !important; }
  /* line 58, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  a:focus, a:hover, a:active {
    outline: 0 !important; }
  /* line 61, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  a:focus {
    text-decoration: none; }
  /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  a:hover {
    color: #d50344;
    text-decoration: underline; }

/*Boxed Layout
------------------------------------*/
/* line 73, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.wrapper {
  background: #fff; }

/* line 77, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.boxed-layout {
  padding: 0;
  box-shadow: 0 0 5px #ccc;
  margin-left: auto !important;
  margin-right: auto !important;
  background: url(../img/patterns/15.png) repeat; }

/*Main Styles
------------------------------------*/
/*Purchase Block*/
/* line 89, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.purchase {
  padding: 25px 0 30px;
  border-bottom: solid 1px #eee;
  background: url(../img/patterns/breadcrumbs.png) repeat; }
  /* line 93, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .purchase p {
    margin-bottom: 0; }
  /* line 96, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .purchase span {
    color: #666;
    display: block;
    font-size: 24px;
    line-height: 35px;
    font-weight: normal;
    margin-bottom: 12px;
    font-family: "Open Sans", Arial, sans-serif; }
  /* line 105, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .purchase .btn-buy {
    text-align: center; }
    /* line 107, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .purchase .btn-buy a {
      margin-top: 27px; }
      /* line 109, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
      .purchase .btn-buy a i {
        margin-right: 5px; }

@media (max-width: 992px) {
  /* line 117, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .purchase {
    text-align: center; }
    /* line 119, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .purchase span {
      font-size: 22px;
      line-height: 29px; }
    /* line 123, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .purchase .btn-buy a {
      margin-top: 25px;
      font-size: 16px; } }

/*Service*/
/* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.service {
  overflow: hidden;
  margin-bottom: 10px;
  padding: 15px 15px 10px; }
  /* line 136, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service:hover {
    background: #fcfcfc;
    box-shadow: 0 0 5px #ddd;
    -webkit-transition: box-shadow 0.2s ease-in-out;
    -moz-transition: box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out; }
    /* line 143, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .service:hover i {
      color: #656565; }
  /* line 147, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service .desc {
    padding: 0 15px;
    overflow: hidden; }
    /* line 150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .service .desc h4 {
      font-size: 22px;
      line-height: 25px; }
  /* line 155, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service .service-icon {
    float: left;
    padding: 10px;
    color: #d50344;
    font-size: 35px;
    text-align: center;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
    /* line 165, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .service .service-icon.icon-cogs {
      font-size: 37px; }
    /* line 168, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .service .service-icon.icon-plane {
      font-size: 43px; }

/* Recent Work  */
/* line 177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work a {
  text-align: center;
  background: #fcfcfc;
  display: inline-block; }
  /* line 181, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .recent-work a:hover {
    text-decoration: none; }
    /* line 183, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .recent-work a:hover strong {
      color: #555; }

/* line 188, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work em.overflow-hidden {
  display: block; }

/* line 192, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work a span {
  display: block;
  padding: 10px;
  border-bottom: solid 2px #eee; }

/* line 197, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work a strong {
  color: #555;
  display: block; }

/* line 201, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work a i {
  color: #777; }

/* line 204, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work a img {
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }

/* line 212, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work a:hover img {
  opacity: 0.8;
  -webkit-transform: scale(1.2) rotate(3deg);
  -moz-transform: scale(1.2) rotate(3deg);
  -o-transform: scale(1) rotate(3deg);
  -ms-transform: scale(1.2) rotate(3deg);
  transform: scale(1.2) rotate(3deg); }

/* line 220, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.recent-work a:hover span {
  border-bottom: solid 2px #d50344;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.3s ease-in-out; }

/* Work */
/* line 233, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.work {
  margin-top: 6px; }
  /* line 235, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .work .details {
    padding: 10px; }
  /* line 238, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .work .caption {
    display: block;
    padding-top: 5px;
    color: #585f69 !important;
    font-size: 18px !important; }
    /* line 243, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .work .caption:hover {
      cursor: pointer;
      color: #444 !important;
      text-decoration: underline; }

/*Other Footer Common Classes
------------------------------------*/
/*Thumb Headline*/
/* line 255, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumb-headline h2 {
  color: #eee;
  font-size: 16px; }

/*Footer Logo*/
/* line 262, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.footer-logo {
  margin: 17px 0 20px; }

/*Latest List*/
/* line 269, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.latest-list li {
  padding: 8px 0;
  border-top: 1px solid #353535; }
  /* line 272, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .latest-list li:first-child {
    padding-top: 0;
    border-top: none; }
  /* line 276, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .latest-list li a {
    color: #eee; }

/* line 280, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.latest-list small {
  color: #999;
  display: block; }

/*Link List*/
/* line 289, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.link-list li {
  border-top: solid 1px #353535; }
  /* line 291, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .link-list li:first-child {
    border-top: none !important; }

/* line 295, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.link-list a {
  color: #eee;
  font-size: 11px;
  padding: 6px 0px;
  display: inline-block;
  text-transform: uppercase; }

/* line 302, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.link-list li i {
  color: #bbb;
  float: right;
  margin-top: 10px; }

/*Latest Tweets*/
/* line 312, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.latest-tweets .latest-tweets-inner p {
  color: #555;
  font-size: 13px; }

/* line 316, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.latest-tweets .latest-tweets-inner a {
  /*color: #3498db;*/
  text-decoration: none; }
  /* line 319, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .latest-tweets .latest-tweets-inner a:hover {
    text-decoration: underline; }

/* line 323, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.latest-tweets .latest-tweets-inner i.fa {
  top: 2px;
  float: left;
  color: #bbb;
  color: #55acee;
  font-size: 18px;
  margin-right: 5px;
  position: relative; }

/* line 332, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.latest-tweets .latest-tweets-inner small {
  color: #777;
  display: block; }

/*Social Icons*/
/* line 340, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.social-icons {
  margin-top: 5px; }
  /* line 342, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .social-icons li {
    margin: 0; }
    /* line 344, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .social-icons li a {
      margin: 0 3px; }

/*Thumbnails
------------------------------------*/
/* line 353, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
a.thumbnail {
  padding: 0;
  border: none;
  margin-bottom: 15px; }

/* line 359, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail-style {
  padding: 7px;
  margin-bottom: 20px; }
  /* line 362, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .thumbnail-style:hover {
    box-shadow: 0 0 8px #ddd;
    -webkit-transition: box-shadow 0.2s ease-in-out;
    -moz-transition: box-shadow 0.2s ease-in-out;
    -o-transition: box-shadow 0.2s ease-in-out;
    transition: box-shadow 0.2s ease-in-out; }

/* line 371, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail h3, .thumbnail-style h3 {
  margin: 6px 0 8px 0; }

/* line 375, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail h3 a, .thumbnail-style h3 a {
  color: #585f69;
  font-size: 18px; }

/* line 380, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail h3 a:hover {
  color: #d50344;
  text-decoration: none; }

/* line 386, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail-style h3 a:hover {
  color: #d50344;
  text-decoration: none; }

/* line 390, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail-style .thumbnail-img {
  position: relative;
  margin-bottom: 11px; }

/* line 394, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail-style a.btn-more {
  right: -10px;
  bottom: 10px;
  color: #fff;
  padding: 1px 6px;
  position: absolute;
  background: #d50344;
  display: inline-block; }
  /* line 402, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .thumbnail-style a.btn-more:hover {
    text-decoration: none;
    box-shadow: 0 0 0 2px #5fb611; }

/* line 407, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail-style:hover a.btn-more {
  right: 10px; }

/* line 413, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail-kenburn img {
  left: 10px;
  margin-left: -10px;
  position: relative;
  -webkit-transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  -ms-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out; }

/* line 423, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.thumbnail-kenburn:hover img {
  -webkit-transform: scale(1.2) rotate(2deg);
  -moz-transform: scale(1.2) rotate(2deg);
  -o-transform: scale(1.2) rotate(2deg);
  -ms-transform: scale(1.2) rotate(2deg);
  transform: scale(1.2) rotate(2deg); }

/*Servive Blocks
------------------------------------*/
/*Service Alternative Block*/
/* line 436, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.service-alternative .service:hover {
  background: #76ca2c;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  /* line 442, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service-alternative .service:hover i, .service-alternative .service:hover p, .service-alternative .service:hover h4 {
    color: #fff; }

/*Servive Block Versions*/
/* line 450, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.service-v1 h2 {
  margin: 15px 0 10px;
  font-size: 20px;
  line-height: 28px; }

/* line 455, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.service-v1 p {
  text-align: justify; }

/*Blog Posts
------------------------------------*/
/* line 463, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.posts .dl-horizontal {
  margin-bottom: 15px;
  overflow: hidden; }
  /* line 464, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .posts .dl-horizontal a {
    color: #555; }
  /* line 469, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .posts .dl-horizontal dt {
    width: 60px;
    float: left; }
    /* line 472, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .posts .dl-horizontal dt img {
      width: 60px;
      height: 60px;
      padding: 2px;
      margin-top: 2px;
      border: solid 1px #ddd; }
  /* line 480, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .posts .dl-horizontal dd {
    margin-left: 70px; }
    /* line 482, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .posts .dl-horizontal dd p {
      margin: 0; }
    /* line 485, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .posts .dl-horizontal dd a {
      font-size: 14px;
      line-height: 16px !important; }
      /* line 488, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
      .posts .dl-horizontal dd a:hover {
        color: #d50344;
        text-decoration: none; }
  /* line 495, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .posts .dl-horizontal:hover dt img, .posts .dl-horizontal:hover dd a {
    color: #d50344;
    border-color: #d50344 !important;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }

/*About Page
------------------------------------*/
/* line 510, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.team ul.team-socail {
  text-align: right; }
  /* line 512, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .team ul.team-socail li {
    margin: 0 !important;
    padding: 0 !important; }
    /* line 515, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .team ul.team-socail li a {
      text-decoration: none; }
      /* line 517, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
      .team ul.team-socail li a:hover {
        text-decoration: none; }
    /* line 521, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .team ul.team-socail li i {
      color: #aaa;
      padding: 6px 7px;
      min-width: 30px;
      font-size: 18px;
      text-align: center;
      background: #f7f7f7;
      display: inline-block; }
      /* line 529, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
      .team ul.team-socail li i:hover {
        color: #fff;
        background: #d50344;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; }

/* line 540, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.team .thumbnail-style {
  padding: 12px; }
  /* line 542, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .team .thumbnail-style img {
    margin-bottom: 7px; }
  /* line 545, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .team .thumbnail-style h3 {
    margin-bottom: 5px; }
  /* line 548, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .team .thumbnail-style:hover h3 a {
    color: #d50344 !important; }
  /* line 551, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .team .thumbnail-style small {
    display: block;
    margin-top: 5px;
    font-size: 12px; }

/*About Me Page
------------------------------------*/
/* line 562, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.about-me {
  overflow: hidden; }
  /* line 564, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .about-me h2 {
    margin: 0;
    font-size: 20px;
    text-transform: uppercase; }
  /* line 569, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .about-me span {
    color: #999;
    display: block;
    font-size: 14px;
    margin-bottom: 10px; }
  /* line 575, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .about-me .social-icons {
    margin-top: 7px; }

/*About My Goals*/
/* line 582, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.about-my-goals h2 {
  text-transform: uppercase; }

/*About Skills*/
/* line 588, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.about-skills {
  padding: 40px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background: url(../img/breadcrumbs.png) repeat; }

/*Gallery Page
------------------------------------*/
/* line 599, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.gallery-page .thumbnail {
  padding: 0;
  border: none;
  box-shadow: none; }
  /* line 603, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .gallery-page .thumbnail:hover {
    padding: 0;
    border: none;
    box-shadow: none; }

/* line 609, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.gallery-page .thumbnails {
  margin-bottom: 6px; }

/*Privacy Page
------------------------------------*/
/* line 617, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.privacy a {
  text-decoration: underline; }
  /* line 619, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .privacy a:hover {
    color: #d50344;
    text-decoration: none; }

/*Portfolio Item Page v1
------------------------------------*/
/* line 629, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.portfolio-item h2 {
  color: #555;
  font-size: 24px;
  margin: 0 0 15px; }

/* line 634, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.portfolio-item li {
  padding: 3px 0; }

/* line 637, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.portfolio-item p a {
  text-decoration: underline; }
  /* line 639, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .portfolio-item p a:hover {
    text-decoration: none; }

/*Portfolio Item v1*/
/* line 648, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.portfolio-item1 ul {
  margin: 10px 0 20px; }

/* line 651, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.portfolio-item1 li {
  font-size: 16px;
  margin-bottom: 3px; }
  /* line 654, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .portfolio-item1 li i {
    font-size: 18px;
    margin-right: 4px; }

/*Sidebar Features
------------------------------------*/
/*Blog Tags*/
/* line 666, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
ul.blog-tags li {
  display: inline-block; }

/* line 669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
ul.blog-tags a {
  font-size: 13px;
  padding: 2px 7px;
  background: #f7f7f7;
  margin: 0 3px 6px 0;
  display: inline-block; }
  /* line 675, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  ul.blog-tags a i {
    color: #666; }
  /* line 678, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  ul.blog-tags a:hover {
    background: #d50344;
    color: #fff !important;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    /* line 686, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    ul.blog-tags a:hover i {
      color: #fff !important;
      text-decoration: none;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }

/*Blog Photo Stream*/
/* line 700, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.blog-photos li {
  display: inline; }
  /* line 702, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-photos li img {
    width: 58px;
    height: 58px;
    margin: 0 2px 8px; }
    /* line 706, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .blog-photos li img:hover {
      box-shadow: 0 0 0 2px #d50344; }

/*Blog Latest Tweets*/
/* line 714, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.blog-twitter .blog-twitter-inner {
  padding: 10px;
  position: relative;
  background: #f7f7f7;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }
  /* line 724, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner:after, .blog-twitter .blog-twitter-inner:before {
    transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; }
  /* line 731, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner:after, .blog-twitter .blog-twitter-inner:before {
    width: 0;
    height: 0;
    right: 0px;
    bottom: 0px;
    content: " ";
    display: block;
    position: absolute; }
  /* line 740, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner:after {
    border-top: 15px solid #eee;
    border-right: 15px solid transparent;
    border-left: 0px solid transparent;
    border-left-style: inset;
    /*FF fixes*/
    border-right-style: inset;
    /*FF fixes*/ }
  /* line 749, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner:before {
    border-bottom: 15px solid #fff;
    border-right: 0 solid transparent;
    border-left: 15px solid transparent;
    border-left-style: inset;
    /*FF fixes*/
    border-bottom-style: inset;
    /*FF fixes*/ }
  /* line 758, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner:hover {
    border-color: #d50344;
    border-top-color: #d50344; }
    /* line 761, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .blog-twitter .blog-twitter-inner:hover:after {
      border-top-color: #d50344; }
  /* line 765, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner span.twitter-time {
    color: #777;
    display: block;
    font-size: 11px; }
  /* line 770, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner a {
    color: #d50344;
    text-decoration: none; }
    /* line 773, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .blog-twitter .blog-twitter-inner a:hover {
      text-decoration: underline; }
  /* line 777, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .blog-twitter .blog-twitter-inner i.fa {
    top: 2px;
    color: #bbb;
    font-size: 18px;
    position: relative; }

/*Choose Block*/
/* line 787, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.who li {
  margin-bottom: 6px; }
  /* line 789, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .who li i {
    color: #d50344;
    font-size: 13px;
    min-width: 15px;
    margin-right: 8px;
    text-align: center;
    position: relative; }
  /* line 798, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .who li:hover i {
    color: #777; }
  /* line 801, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .who li:hover a {
    color: #777; }
    /* line 803, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .who li:hover a:hover {
      text-decoration: none; }

/*Promo Page
------------------------------------*/
/*Slider Part*/
/* line 814, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.fullwidthbanner-container.slider-promo {
  max-height: 460px !important; }

/* line 818, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.slider-promo-info-bg {
  padding: 25px 20px 20px;
  background: url(../img/bg-black.png) repeat; }

/* line 824, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.slider-promo-info span.big-size {
  color: #fff;
  display: block;
  font-size: 30px;
  margin-bottom: 25px;
  text-shadow: none !important; }

/* line 831, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.slider-promo-info p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 0; }

/*Promo Box*/
/* line 840, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.promo-box {
  padding: 20px;
  margin: 25px 0 20px;
  text-align: center; }
  /* line 844, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .promo-box i {
    display: block;
    font-size: 50px; }
  /* line 848, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .promo-box p {
    font-size: 14px;
    margin-bottom: 0; }
  /* line 852, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .promo-box strong {
    color: #555;
    display: block;
    font-size: 20px;
    font-weight: 200;
    margin: 20px 0 15px; }
    /* line 858, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .promo-box strong a {
      color: #555;
      display: block;
      font-size: 20px;
      font-weight: 200;
      margin: 20px 0 15px; }
  /* line 866, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .promo-box:hover strong {
    color: #d50344; }
    /* line 868, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .promo-box:hover strong a {
      color: #d50344; }

/*Promo Service*/
/* line 876, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.promo-service {
  margin-bottom: 20px; }
  /* line 878, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .promo-service li {
    margin-bottom: 5px; }
  /* line 881, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .promo-service a.btn-u {
    margin: 0 20px 10px 0; }

/*Promo Elements*/
/* line 888, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.title-box {
  font-size: 16px;
  text-align: center;
  padding: 10px 0 30px;
  border-bottom: solid 1px #eee; }
  /* line 893, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .title-box .title-box-text {
    color: #555;
    display: block;
    font-size: 35px;
    font-weight: 200; }

@media (max-width: 768px) {
  /* line 903, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .title-box .title-box-text {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px; }
  /* line 908, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .title-box p {
    font-size: 15px; } }

/* line 914, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.block-blockquote {
  margin: 20px 0;
  text-align: center;
  border: dashed 1px #ccc; }

/* line 920, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.block-blockquote-inner {
  padding: 15px; }

/* line 924, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.block-blockquote p {
  font-size: 26px;
  margin-bottom: 0; }

/*Info Blocks*/
/* line 931, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.info-blocks {
  margin-bottom: 15px; }
  /* line 933, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .info-blocks:hover i.icon-info-blocks {
    color: #d50344;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  /* line 940, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .info-blocks i.icon-info-blocks {
    float: left;
    color: #777;
    font-size: 30px;
    min-width: 50px;
    margin-top: 10px;
    text-align: center; }
  /* line 948, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .info-blocks .info-blocks-in {
    padding: 0 10px;
    overflow: hidden; }
    /* line 951, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .info-blocks .info-blocks-in h3 {
      color: #555;
      font-size: 20px;
      line-height: 28px; }
    /* line 956, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .info-blocks .info-blocks-in p {
      font-size: 12px; }

/*Brand Page
------------------------------------*/
/* line 965, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.brand-page .btn {
  margin-bottom: 10px; }

/*Form Pages
------------------------------------*/
/* line 973, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.form-page input, .form-page select {
  margin-bottom: 10px; }

/*Labels and Badges Page
------------------------------------*/
/* line 981, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
ul.labels-demo {
  margin: 5px 0; }
  /* line 983, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  ul.labels-demo li {
    list-style: none;
    display: inline-block;
    margin: 0 5px 2px 0; }

/* line 990, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.badge-sidebar {
  border-left: solid 1px #eee; }

@media (max-width: 991px) {
  /* line 995, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .badge-sidebar {
    border-left: none; } }

/*Line Icon Page
------------------------------------*/
/* line 1004, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.line-icon-page .item-box {
  width: 25%;
  float: left;
  height: 90px;
  text-align: center;
  padding: 20px 10px;
  margin: 0 -1px -1px 0;
  word-wrap: break-word;
  border: 1px solid #ddd; }
  /* line 1013, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .line-icon-page .item-box:hover {
    background: #fafafa; }

/* line 1018, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.line-icon-page .item span {
  display: block;
  font-size: 28px;
  margin-bottom: 10px; }

/* line 1023, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.line-icon-page .item:hover {
  color: #d50344; }

@media (max-width: 768px) {
  /* line 1030, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .line-icon-page .item-box {
    height: 115px; } }

@media (max-width: 450px) {
  /* line 1036, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .line-icon-page .item-box {
    height: 130px; } }

/*Line Icon Page
------------------------------------*/
/* line 1044, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.icon-page-fa {
  margin-bottom: 20px; }
  /* line 1046, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .icon-page-fa .item-box {
    float: left;
    height: 100px;
    width: 16% !important; }
  /* line 1051, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .icon-page-fa .item {
    font-size: 12px; }
    /* line 1053, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .icon-page-fa .item i {
      color: #555;
      display: block;
      font-size: 24px;
      margin-bottom: 10px; }

@media (max-width: 767px) {
  /* line 1063, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .icon-page-fa .item-box {
    width: 20% !important; } }

@media (max-width: 600px) {
  /* line 1069, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .icon-page-fa .item-box {
    width: 30% !important; } }

@media (max-width: 400px) {
  /* line 1075, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .icon-page-fa .item-box {
    width: 50% !important; } }

/*GLYPHICONS Icons Page
------------------------------------*/
/*Special styles for displaying the icons and their classes*/
/* line 1084, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.bs-glyphicons {
  padding-left: 0;
  list-style: none;
  overflow: hidden;
  padding-bottom: 1px;
  margin-bottom: 20px; }
  /* line 1090, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .bs-glyphicons li {
    width: 25%;
    float: left;
    height: 115px;
    padding: 10px;
    font-size: 11px;
    line-height: 1.4;
    text-align: center;
    margin: 0 -1px -1px 0;
    border: 1px solid #ddd; }
  /* line 1101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .bs-glyphicons .glyphicon {
    display: block;
    font-size: 18px;
    margin: 5px auto 10px; }
  /* line 1106, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .bs-glyphicons li:hover {
    color: #d50344;
    background-color: #fafafa; }

@media (min-width: 768px) {
  /* line 1113, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .bs-glyphicons li {
    width: 12.5%; } }

/*Glyphicons Pro*/
/* line 1121, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.demo-pro-glyphicons li {
  width: 170px;
  height: 100px;
  padding: 20px 10px 30px; }

/* line 1126, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.demo-pro-glyphicons span {
  height: 40px;
  display: block; }
  /* line 1129, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .demo-pro-glyphicons span:before {
    color: #555;
    padding: 10px 62px; }
  /* line 1133, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .demo-pro-glyphicons span.glyphicons:before {
    font-size: 22px; }
  /* line 1136, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .demo-pro-glyphicons span.halflings {
    height: 30px; }
    /* line 1138, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .demo-pro-glyphicons span.halflings:before {
      padding: 10px 70px; }

/*Glyphicons Pro Halflings*/
/*Contact Pages
------------------------------------*/
/* line 1150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.map {
  width: 100%;
  height: 350px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee; }

/* line 1157, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.map-box {
  height: 250px; }

/* line 1161, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.map-box-space {
  margin-top: 15px; }

/* line 1165, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.map-box-space1 {
  margin-top: 7px; }

/*Background Opacity
------------------------------------*/
/* line 1172, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.service-or {
  overflow: hidden;
  position: relative; }

/* line 1177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.service-bg {
  width: 250px;
  padding: 60px;
  position: absolute;
  top: -10px;
  right: -100px;
  background-color: rgba(255, 255, 255, 0.1);
  transform: rotate(55deg);
  -o-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  -moz-transform: rotate(55deg);
  -webkit-transform: rotate(55deg); }

/*Easy Block (used for image badges)
------------------------------------*/
/* line 1194, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.easy-block-v1 {
  position: relative; }
  /* line 1196, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v1 img {
    width: 100%; }
  /* line 1199, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v1 .overflow-h h3 {
    width: 60%;
    float: left;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px; }
  /* line 1206, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v1 .easy-block-v1-badge {
    left: 0px;
    top: 10px;
    z-index: 1;
    color: #fff;
    padding: 4px 10px;
    position: absolute; }

/*Easy Block v2 (used for image badges)
------------------------------------*/
/* line 1219, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.easy-block-v2 {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px; }
  /* line 1223, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v2 img {
    width: 100%; }
  /* line 1226, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v2 .easy-bg-v2 {
    top: 5px;
    color: #fff;
    width: 100px;
    padding: 5px;
    right: -32px;
    font-size: 13px;
    position: absolute;
    text-align: center;
    letter-spacing: 2px;
    transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

/*Easy Block v3 (used for image badges)
------------------------------------*/
/* line 1247, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.easy-block-v3 {
  text-align: left;
  background: #585f69;
  padding: 30px 20px 20px; }
  /* line 1251, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v3.first {
    background: #696f77; }
  /* line 1254, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v3.second {
    background: #707780; }
  /* line 1257, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v3.third {
    background: #787f88; }
  /* line 1260, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v3 i {
    color: #fff;
    float: left;
    font-size: 30px;
    min-width: 45px;
    padding-top: 10px; }
  /* line 1267, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .easy-block-v3 .inner-faq-b {
    overflow: hidden; }
    /* line 1269, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .easy-block-v3 .inner-faq-b h2, .easy-block-v3 .inner-faq-b h3 {
      font-size: 21px;
      margin-bottom: 8px; }
    /* line 1273, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .easy-block-v3 .inner-faq-b h4 {
      color: #fff;
      font-size: 17px;
      margin-bottom: 2px; }
    /* line 1278, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .easy-block-v3 .inner-faq-b h2, .easy-block-v3 .inner-faq-b h3, .easy-block-v3 .inner-faq-b p {
      color: #fff; }

/*Full Width Block
------------------------------------*/
/* line 1287, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.full-w-block {
  padding: 30px 0;
  background: #282828; }
  /* line 1290, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .full-w-block .info-blocks i {
    color: #fff; }
  /* line 1294, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .full-w-block .info-blocks-in h3 {
    color: #fff; }
  /* line 1297, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .full-w-block .info-blocks-in p {
    color: #ccc; }
  /* line 1301, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .full-w-block .info-blocks:hover i.icon-info-blocks {
    color: #fff; }

/*Simple Block
------------------------------------*/
/* line 1309, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.simple-block {
  margin-bottom: 20px; }
  /* line 1311, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .simple-block img {
    width: 100%; }
  /* line 1314, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .simple-block p {
    text-align: center; }
  /* line 1317, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .simple-block .carousel-indicators {
    top: 10px;
    left: 65%;
    text-align: right; }
    /* line 1321, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .simple-block .carousel-indicators .active {
      background: #555; }
    /* line 1324, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .simple-block .carousel-indicators li {
      border-color: #555; }
  /* line 1328, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .simple-block .carousel-inner > .item {
    margin: 0; }
  /* line 1331, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .simple-block .responsive-video {
    margin-bottom: 3px; }

/*Two Blocks
------------------------------------*/
/* line 1339, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.two-blocks {
  text-align: center; }

/* line 1343, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.two-blocks-in {
  margin-bottom: 30px; }
  /* line 1345, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .two-blocks-in i {
    color: #fff;
    padding: 11px;
    font-size: 30px;
    min-width: 50px;
    text-align: center;
    background: #585f69;
    margin-bottom: 10px;
    display: inline-block; }

/*Three Blocks
------------------------------------*/
/* line 1360, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.three-blocks {
  text-align: center; }

/* line 1364, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.three-blocks-in {
  margin-bottom: 30px; }
  /* line 1366, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .three-blocks-in i {
    color: #777;
    padding: 9px 6px;
    font-size: 20px;
    min-width: 40px;
    margin-bottom: 10px;
    border: 1px solid #777; }
  /* line 1374, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .three-blocks-in h3 {
    font-size: 20px;
    margin-bottom: 7px; }

/*Temperory Classes
------------------------------------*/
/*Banner-info*/
/* line 1384, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.banner-info {
  margin-bottom: 10px; }
  /* line 1386, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .banner-info i {
    float: left;
    color: #fff;
    padding: 11px;
    /*min-width works with line-icon*/
    min-width: 40px;
    font-size: 22px;
    text-align: center;
    margin: 7px 20px 0 0; }
    /* line 1395, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .banner-info i.fa {
      min-width: 46px; }
  /* line 1399, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .banner-info.light i {
    border: 1px solid #fff; }
  /* line 1402, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .banner-info.dark i {
    background: #555; }
  /* line 1405, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .banner-info h3 {
    font-size: 21px;
    margin-bottom: 5px; }
  /* line 1410, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .banner-info.light h3 {
    color: #fff; }
  /* line 1413, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .banner-info.light p {
    color: #eee; }

/*min-width works with FontAwesome*/
/*Title-Box-V2*/
/* line 1423, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.title-box-v2 {
  text-align: center;
  margin-bottom: 40px; }
  /* line 1426, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .title-box-v2 h2 {
    font-size: 25px;
    text-transform: uppercase; }
  /* line 1430, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .title-box-v2 p {
    font-size: 13px; }

/*remove padding from "Col-"*/
/* line 1437, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.no-space {
  padding: 0; }

/* line 1441, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.no-space-left {
  padding-left: 0;
  padding-right: 15px !important; }

/* line 1446, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.no-space-right {
  padding-right: 0;
  padding-left: 15px !important; }

/*List Style v1*/
/* line 1454, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.list-style-v1 li {
  margin-bottom: 7px; }

/* line 1457, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.list-style-v1 i {
  margin-right: 5px; }

/*Social Contacts
------------------------------------*/
/* line 1465, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.social-contacts li {
  padding: 5px 0;
  border-top: 1px solid #ddd; }
  /* line 1468, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .social-contacts li:first-child {
    border-top: none; }
  /* line 1471, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .social-contacts li i {
    color: #777;
    font-size: 13px;
    min-width: 13px;
    margin-right: 12px;
    text-align: center; }

/*Status Bar
------------------------------------*/
/* line 1483, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.status-bar li {
  padding-left: 9px;
  border-left: 1px solid #eee; }
  /* line 1486, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .status-bar li:first-child {
    border-left: none; }
  /* line 1489, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .status-bar li i {
    color: #d50344;
    margin-right: 5px; }
  /* line 1493, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .status-bar li a:hover {
    text-decoration: none; }

/* line 1498, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.status-bar-in {
  padding: 10px;
  position: relative;
  background: #f0f0f0; }
  /* line 1502, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .status-bar-in:after {
    width: 0;
    height: 0;
    top: -10px;
    left: 50px;
    content: " ";
    display: block;
    position: absolute;
    border-bottom: 11px solid #f0f0f0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-left-style: inset;
    /*FF fixes*/
    border-right-style: inset;
    /*FF fixes*/ }

/*Share List*/
/* line 1522, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.share-list {
  margin-bottom: 0; }
  /* line 1524, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .share-list li {
    padding-left: 20px; }
    /* line 1526, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .share-list li:first-child {
      padding-left: 0; }
    /* line 1529, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .share-list li i {
      color: #d50344;
      margin-right: 5px; }

/*People Say
------------------------------------*/
/* line 1540, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.people-say img {
  float: left;
  width: 40px;
  height: auto;
  margin: 6px 10px 0 0; }

/* line 1547, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.people-say .overflow-h span {
  font-weight: 700; }

/* line 1550, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.people-say .overflow-h p {
  font-style: italic;
  line-height: 1.3; }

/* line 1554, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.people-say .overflow-h small {
  text-align: right;
  font-style: italic; }

/*Inspire Page
------------------------------------*/
/* line 1564, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
img.img-mouse {
  width: 150px;
  height: auto;
  margin: 0 auto 60px; }

/*Desire Page
------------------------------------*/
/*Service Info*/
/* line 1574, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.service-info {
  position: relative;
  padding: 40px 0 50px;
  background: url(../img/patterns/17.png) repeat; }
  /* line 1578, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service-info .info-description {
    overflow: hidden; }
  /* line 1581, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service-info i.service-info-icon {
    color: #777;
    float: left;
    width: 50px;
    height: 50px;
    padding: 15px;
    font-size: 20px;
    line-height: 17px;
    margin-right: 20px;
    text-align: center;
    display: inline-block;
    border: 1px solid #777; }
  /* line 1595, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service-info .info-description h3 {
    margin: 0 0 10px; }
  /* line 1598, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .service-info .info-description p {
    color: #777; }

/*Portfolio Single Item Page
------------------------------------*/
/*Project Details*/
/* line 1609, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.project-details li {
  color: #555;
  font-weight: 200;
  margin-bottom: 5px; }

/* line 1614, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.project-details strong {
  min-width: 100px;
  display: inline-block; }

/* line 1618, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.project-details a {
  text-decoration: underline; }

/*Bootstrap Testimonials
------------------------------------*/
/* line 1626, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.testimonials-bs {
  padding: 50px 0;
  background: url(../img/bg/16.jpg) 50% 0 repeat fixed; }
  /* line 1629, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .testimonials-bs .headline-center-v2 span.author {
    color: #777;
    font-size: 14px; }
  /* line 1633, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .testimonials-bs .item {
    margin-bottom: 30px; }
  /* line 1636, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .testimonials-bs .carousel-arrow {
    text-align: center; }
  /* line 1640, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .testimonials-bs .carousel-control-v2 i {
    color: #888;
    width: 30px;
    height: 30px;
    padding: 2px;
    font-size: 22px;
    display: inline-block;
    border: 2px solid #888; }
    /* line 1648, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
    .testimonials-bs .carousel-control-v2 i:hover {
      color: #d50344;
      border-color: #d50344;
      text-decoration: none;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out; }
  /* line 1658, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
  .testimonials-bs .carousel-control-v2.left, .testimonials-bs .carousel-control-v2.right {
    text-decoration: none;
    background-image: none; }

/*Removes Bootstrap carousel background images*/
/*Team v1*/
/* line 1669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/unify/_unify.scss */
.team-v1 li > h3 {
  color: #555; }

/* line 2, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
body.node-type-quiz, body.node-type-course, body.node-type-h5p-content, body.page-node-takecourse {
  /* pane-course-nav
  .pane-course-navigation {
    position: static;
    ul#course-nav {
      margin: 0;
      padding: 0;
      li {
        > a {
          width: 4em;
          height: 4em;
          display: block;
          text-indent: -99999px;
          color: transparent;
        }
        &.course-nav-prev {
          position: absolute;
          left: 1em;
          top: 50%;
          padding: 0;
          margin: 0;
          &:after {
            content: '\f190';
            font-family: 'fontawesome';
            font-size: 3em;
            color: rgba(0, 0, 0, .4);
            line-height: 1em;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            transition: all .2s ease-out;
          }
          &:hover {
            &:after {
              color: $primary-accent;
              font-size: 4em;
            }
          }
        }
        &.course-nav-next {
          position: absolute;
          right: 1em;
          top: 50%;
          padding: 0;
          margin: 0;
          &:after {
            content: '\f18e';
            font-family: 'fontawesome';
            font-size: 3em;
            color: rgba(0, 0, 0, .4);
            line-height: 1em;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 100;
            transition: all .2s ease-out;
          }
          &:hover {
            &:after {
              color: #008926;
              font-size: 4em;
              position: absolute;
              bottom: 0;
            }
          }
        }
        &.course-nav-back {
          position: absolute;
          bottom: 0;
          left: 50%;
          padding: 0;
          margin: 0;
          &:after {
            content: '\f177';
            font-family: 'fontawesome';
            font-size: 4em;
            color: $naac-blue;
            position: absolute;
            bottom: 0;
            right: 100%;
            line-height: 1em;
          }
          > a {
            height: auto;
            width: auto;
            line-height: 4em;
            font-size: 1.5em;
            text-indent: 1em;
            font-weight: 500;
          }
        }
      }
    }
  }
  */
  /* quiz take nav */ }
  /* line 4, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .page-title .course-name, body.node-type-course .page-title .course-name, body.node-type-h5p-content .page-title .course-name, body.page-node-takecourse .page-title .course-name {
    color: #A6A6AE;
    color: rgba(41, 41, 41, 0.25); }
  /* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .page-title .title-divider, body.node-type-course .page-title .title-divider, body.node-type-h5p-content .page-title .title-divider, body.page-node-takecourse .page-title .title-divider {
    color: #F3F3F3;
    font-weight: 800;
    text-shadow: 0 -1px 0 rgba(51, 51, 51, 0.25);
    font-size: 2em;
    position: relative;
    top: .25em; }
  /* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-layouts-content, body.node-type-course .radix-layouts-content, body.node-type-h5p-content .radix-layouts-content, body.page-node-takecourse .radix-layouts-content {
    padding: 0; }
    /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-content .panel-pane, body.node-type-course .radix-layouts-content .panel-pane, body.node-type-h5p-content .radix-layouts-content .panel-pane, body.page-node-takecourse .radix-layouts-content .panel-pane {
      padding: 0;
      clear: both; }
    /* line 24, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-content .pane-quiztake-pane, body.node-type-course .radix-layouts-content .pane-quiztake-pane, body.node-type-h5p-content .radix-layouts-content .pane-quiztake-pane, body.page-node-takecourse .radix-layouts-content .pane-quiztake-pane {
      padding: 0; }
    /* line 27, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-content .pane-node-body, body.node-type-course .radix-layouts-content .pane-node-body, body.node-type-h5p-content .radix-layouts-content .pane-node-body, body.page-node-takecourse .radix-layouts-content .pane-node-body {
      padding: 0 15px; }
  /* line 31, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz #radix-collapse, body.node-type-course #radix-collapse, body.node-type-h5p-content #radix-collapse, body.page-node-takecourse #radix-collapse {
    color: #2c2c2c;
    background: #fefefe;
    padding: 0;
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2); }
  /* line 40, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-layouts-sidebar > .panel-panel-inner, body.node-type-course .radix-layouts-sidebar > .panel-panel-inner, body.node-type-h5p-content .radix-layouts-sidebar > .panel-panel-inner, body.page-node-takecourse .radix-layouts-sidebar > .panel-panel-inner {
    padding: .2em 0; }
  /* line 45, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-layouts-sidebar .pane-title, body.node-type-course .radix-layouts-sidebar .pane-title, body.node-type-h5p-content .radix-layouts-sidebar .pane-title, body.page-node-takecourse .radix-layouts-sidebar .pane-title {
    margin-top: .5em; }
  /* line 48, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-layouts-sidebar .nav-pills, body.node-type-course .radix-layouts-sidebar .nav-pills, body.node-type-h5p-content .radix-layouts-sidebar .nav-pills, body.page-node-takecourse .radix-layouts-sidebar .nav-pills {
    background: #2e6da4;
    margin-left: 4.2em;
    margin-top: -2px;
    padding-bottom: 1px; }
    /* line 53, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .nav-pills a, body.node-type-course .radix-layouts-sidebar .nav-pills a, body.node-type-h5p-content .radix-layouts-sidebar .nav-pills a, body.page-node-takecourse .radix-layouts-sidebar .nav-pills a {
      color: #0F4788;
      font-size: 1.4em;
      text-transform: uppercase;
      font-weight: bold; }
      /* line 58, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .nav-pills a:hover, body.node-type-quiz .radix-layouts-sidebar .nav-pills a:focus, body.node-type-course .radix-layouts-sidebar .nav-pills a:hover, body.node-type-course .radix-layouts-sidebar .nav-pills a:focus, body.node-type-h5p-content .radix-layouts-sidebar .nav-pills a:hover, body.node-type-h5p-content .radix-layouts-sidebar .nav-pills a:focus, body.page-node-takecourse .radix-layouts-sidebar .nav-pills a:hover, body.page-node-takecourse .radix-layouts-sidebar .nav-pills a:focus {
        background: #1F517D;
        color: #ABCCE8; }
    /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .nav-pills li.active a, body.node-type-course .radix-layouts-sidebar .nav-pills li.active a, body.node-type-h5p-content .radix-layouts-sidebar .nav-pills li.active a, body.page-node-takecourse .radix-layouts-sidebar .nav-pills li.active a {
      background: #337ab7;
      color: rgba(255, 255, 255, 0.9); }
  /* line 71, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-layouts-sidebar .tab-content-wrapper .tab-content, body.node-type-course .radix-layouts-sidebar .tab-content-wrapper .tab-content, body.node-type-h5p-content .radix-layouts-sidebar .tab-content-wrapper .tab-content, body.page-node-takecourse .radix-layouts-sidebar .tab-content-wrapper .tab-content {
    padding: 1em 1.5em; }
    /* line 73, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .tab-content-wrapper .tab-content .headline, body.node-type-course .radix-layouts-sidebar .tab-content-wrapper .tab-content .headline, body.node-type-h5p-content .radix-layouts-sidebar .tab-content-wrapper .tab-content .headline, body.page-node-takecourse .radix-layouts-sidebar .tab-content-wrapper .tab-content .headline {
      display: none !important; }
    /* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .tab-content-wrapper .tab-content a, body.node-type-course .radix-layouts-sidebar .tab-content-wrapper .tab-content a, body.node-type-h5p-content .radix-layouts-sidebar .tab-content-wrapper .tab-content a, body.page-node-takecourse .radix-layouts-sidebar .tab-content-wrapper .tab-content a {
      color: #efefef; }
    /* line 80, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .tab-content-wrapper .tab-content table th, body.node-type-course .radix-layouts-sidebar .tab-content-wrapper .tab-content table th, body.node-type-h5p-content .radix-layouts-sidebar .tab-content-wrapper .tab-content table th, body.page-node-takecourse .radix-layouts-sidebar .tab-content-wrapper .tab-content table th {
      color: #efefef; }
    /* line 84, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td, body.node-type-course .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td, body.node-type-h5p-content .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td, body.page-node-takecourse .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td {
      border-color: #2e6da4;
      color: #bfbfbf; }
      /* line 87, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td p, body.node-type-course .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td p, body.node-type-h5p-content .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td p, body.page-node-takecourse .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody td p {
        color: #bfbfbf; }
    /* line 91, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody tr.odd, body.node-type-course .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody tr.odd, body.node-type-h5p-content .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody tr.odd, body.page-node-takecourse .radix-layouts-sidebar .tab-content-wrapper .tab-content table tbody tr.odd {
      background-color: #2e6da4; }
  /* line 100, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-layouts-sidebar .course-outline .course-title, body.node-type-course .radix-layouts-sidebar .course-outline .course-title, body.node-type-h5p-content .radix-layouts-sidebar .course-outline .course-title, body.page-node-takecourse .radix-layouts-sidebar .course-outline .course-title {
    text-align: center;
    font-size: 1.2em;
    padding-top: 1em;
    margin: 0;
    color: #3e3e3e;
    font-weight: 300; }
  /* line 108, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-layouts-sidebar .course-outline ul, body.node-type-course .radix-layouts-sidebar .course-outline ul, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul {
    list-style: none;
    padding: 1em 1em 2em 0;
    margin-left: 2.2em;
    margin-bottom: 0;
    font-size: 1.3em;
    counter-reset: stepcount; }
    /* line 115, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .course-outline ul li, body.node-type-course .radix-layouts-sidebar .course-outline ul li, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li {
      position: relative;
      font-family: "lato", Helvetica, Arial, sans-serif;
      color: #ababab;
      font-weight: 300;
      cursor: not-allowed;
      text-indent: -1em;
      padding-left: 1em; }
      /* line 123, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.active, body.node-type-course .radix-layouts-sidebar .course-outline ul li.active, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.active, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.active {
        font-weight: bold;
        font-style: italic; }
      /* line 128, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li img, body.node-type-course .radix-layouts-sidebar .course-outline ul li img, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li img, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li img {
        position: absolute;
        left: -1.5em;
        top: 6px;
        max-width: 12px !important; }
      /* line 134, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li span.course-outline-status, body.node-type-course .radix-layouts-sidebar .course-outline ul li span.course-outline-status, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li span.course-outline-status, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li span.course-outline-status {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: 99;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        padding: .1em .5em;
        right: -37px;
        top: 0;
        font-size: .8em;
        margin-top: 0;
        border-radius: 1em !important;
        margin-right: .5em;
        background: #d9534f;
        color: #fff;
        border: 1px solid #d43f3a;
        text-indent: 0 !important; }
        /* line 152, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after, body.node-type-quiz .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before, body.node-type-course .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after, body.node-type-course .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before {
          /*
              right: 100%;
              top: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              */
          pointer-events: none; }
        /* line 164, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after, body.node-type-course .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li span.course-outline-status:after {
          margin-top: -6px;
          border-top: none;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #d43f3a;
          top: 1px;
          left: 50%;
          transform: translateX(-50%); }
        /* line 174, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before, body.node-type-course .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li span.course-outline-status:before {
          content: "To do";
          display: block;
          text-align: center; }
      /* line 181, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li:hover span.course-outline-status, body.node-type-course .radix-layouts-sidebar .course-outline ul li:hover span.course-outline-status, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li:hover span.course-outline-status, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li:hover span.course-outline-status {
        visibility: visible;
        opacity: 1; }
      /* line 186, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li#complete img, body.node-type-course .radix-layouts-sidebar .course-outline ul li#complete img, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li#complete img, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li#complete img {
        display: none; }
      /* line 190, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.completed, body.node-type-course .radix-layouts-sidebar .course-outline ul li.completed, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.completed, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.completed {
        color: #092a51; }
        /* line 192, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.completed > img, body.node-type-course .radix-layouts-sidebar .course-outline ul li.completed > img, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.completed > img, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.completed > img {
          display: none; }
        /* line 193, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.completed a, body.node-type-course .radix-layouts-sidebar .course-outline ul li.completed a, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.completed a, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.completed a {
          color: #092a51;
          color: rgba(4, 4, 4, 0.41);
          font-weight: bold;
          font-style: oblique;
          text-decoration: line-through; }
          /* line 199, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
          body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.completed a:before, body.node-type-course .radix-layouts-sidebar .course-outline ul li.completed a:before, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.completed a:before, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.completed a:before {
            content: '\f01e';
            font-family: 'fontawesome';
            color: #5cb85c;
            width: 2em;
            position: absolute;
            left: -2.5em;
            text-align: right;
            font-size: 1em;
            line-height: 1.8em;
            font-weight: bold;
            text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
            text-decoration: none; }
        /* line 215, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status, body.node-type-course .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status {
          background: #5cb85c;
          color: #fff;
          border: 1px solid #4cae4c; }
          /* line 219, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
          body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:after, body.node-type-course .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:after, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:after, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:after {
            border-bottom-color: #4cae4c; }
          /* line 222, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
          body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:before, body.node-type-course .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:before, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:before, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.completed span.course-outline-status:before {
            display: none; }
      /* line 228, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.in-progress > img, body.node-type-course .radix-layouts-sidebar .course-outline ul li.in-progress > img, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.in-progress > img, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.in-progress > img {
        display: none; }
      /* line 232, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.in-progress a:before, body.node-type-course .radix-layouts-sidebar .course-outline ul li.in-progress a:before, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.in-progress a:before, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.in-progress a:before {
        content: '\f04b';
        font-family: fontawesome;
        color: #efefef;
        width: 2em;
        position: absolute;
        left: -2.5em;
        text-align: right;
        font-size: 1em;
        line-height: 1.8em;
        font-weight: bold;
        text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
        text-decoration: none;
        font-style: normal; }
      /* line 249, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status, body.node-type-course .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status {
        background: #f0ad4e;
        color: #fff;
        border: 1px solid #eea236; }
        /* line 253, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:after, body.node-type-course .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:after, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:after, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:after {
          border-bottom-color: #eea236; }
        /* line 256, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:before, body.node-type-course .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:before, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:before, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.in-progress .course-outline-status:before {
          display: none; }
      /* line 260, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.in-progress > img, body.node-type-course .radix-layouts-sidebar .course-outline ul li.in-progress > img, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.in-progress > img, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.in-progress > img {
        display: none; }
      /* line 264, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .radix-layouts-sidebar .course-outline ul li.not-accessible:before, body.node-type-course .radix-layouts-sidebar .course-outline ul li.not-accessible:before, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul li.not-accessible:before, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul li.not-accessible:before {
        content: counter(stepcount) "";
        counter-increment: stepcount;
        color: #22629A;
        text-decoration: none;
        font-style: normal;
        width: 2em;
        position: absolute;
        left: -2.5em;
        text-align: right;
        font-size: 1.3em;
        line-height: 1.4em;
        font-weight: bold;
        text-shadow: 0 -1px rgba(0, 0, 0, 0.2);
        font-family: "roboto";
        opacity: 0.5; }
    /* line 282, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .radix-layouts-sidebar .course-outline ul a, body.node-type-course .radix-layouts-sidebar .course-outline ul a, body.node-type-h5p-content .radix-layouts-sidebar .course-outline ul a, body.page-node-takecourse .radix-layouts-sidebar .course-outline ul a {
      color: #a9a9a9;
      cursor: pointer; }
  /* line 291, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .view-certification-links a, body.node-type-course .view-certification-links a, body.node-type-h5p-content .view-certification-links a, body.page-node-takecourse .view-certification-links a {
    font-size: .8em;
    line-height: 1.2 !important;
    display: block;
    opacity: .9;
    font-weight: 300;
    font-family: "roboto condensed", Helvetica, Arial, sans-serif; }
    /* line 298, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .view-certification-links a:hover, body.node-type-course .view-certification-links a:hover, body.node-type-h5p-content .view-certification-links a:hover, body.page-node-takecourse .view-certification-links a:hover {
      opacity: 1; }
  /* line 400, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .answering-form, body.node-type-course .answering-form, body.node-type-h5p-content .answering-form, body.page-node-takecourse .answering-form {
    text-align: center;
    /*padding: 0 50px 0 80px;*/ }
    /* line 403, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .answering-form > div, body.node-type-course .answering-form > div, body.node-type-h5p-content .answering-form > div, body.page-node-takecourse .answering-form > div {
      text-align: center;
      display: inline-block; }
      /* line 406, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .answering-form > div .form-item, body.node-type-course .answering-form > div .form-item, body.node-type-h5p-content .answering-form > div .form-item, body.page-node-takecourse .answering-form > div .form-item {
        display: block !important;
        text-align: left; }
    /* line 411, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .answering-form #edit-back, body.node-type-course .answering-form #edit-back, body.node-type-h5p-content .answering-form #edit-back, body.page-node-takecourse .answering-form #edit-back {
      /*position: absolute;
      left: 1em;
      top: 50%;
      padding: 0;
      margin: 0;
      z-index: 100;
      transform: translateY(-50%);
      color: transparent;
      background: none;
      border: none;
      display: block;
      height: 4em;
      width: 4em;*/
      margin: 1em;
      font-size: 1.5em;
      font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
      font-weight: 100;
      color: #b3b3b3;
      color: #fff;
      background-color: #f0ad4e;
      border-color: #eea236;
      /*&:after {
        content: '\f190';
        font-family: 'fontawesome';
        font-size: 3em;
        color: rgba(0, 0, 0, .4);
        line-height: 1em;
        position: absolute;
        top: 50%;
        left: 15px;
        z-index: 100;
        transition: all .2s ease-out;
        margin-top: -1em;
      }
      &:hover {
        &:after {
          color: $btn-warning-bg;
          font-size: 4em;
        }
      }*/ }
      /* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-back:hover, body.node-type-quiz .answering-form #edit-back:focus, body.node-type-quiz .answering-form #edit-back.focus, body.node-type-quiz .answering-form #edit-back:active, body.node-type-quiz .answering-form #edit-back.active,
      .open > body.node-type-quiz .answering-form #edit-back.dropdown-toggle, body.node-type-course .answering-form #edit-back:hover, body.node-type-course .answering-form #edit-back:focus, body.node-type-course .answering-form #edit-back.focus, body.node-type-course .answering-form #edit-back:active, body.node-type-course .answering-form #edit-back.active,
      .open > body.node-type-course .answering-form #edit-back.dropdown-toggle, body.node-type-h5p-content .answering-form #edit-back:hover, body.node-type-h5p-content .answering-form #edit-back:focus, body.node-type-h5p-content .answering-form #edit-back.focus, body.node-type-h5p-content .answering-form #edit-back:active, body.node-type-h5p-content .answering-form #edit-back.active,
      .open > body.node-type-h5p-content .answering-form #edit-back.dropdown-toggle, body.page-node-takecourse .answering-form #edit-back:hover, body.page-node-takecourse .answering-form #edit-back:focus, body.page-node-takecourse .answering-form #edit-back.focus, body.page-node-takecourse .answering-form #edit-back:active, body.page-node-takecourse .answering-form #edit-back.active,
      .open > body.page-node-takecourse .answering-form #edit-back.dropdown-toggle {
        color: #fff;
        background-color: #ec971f;
        border-color: #d58512; }
      /* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-back:active, body.node-type-quiz .answering-form #edit-back.active,
      .open > body.node-type-quiz .answering-form #edit-back.dropdown-toggle, body.node-type-course .answering-form #edit-back:active, body.node-type-course .answering-form #edit-back.active,
      .open > body.node-type-course .answering-form #edit-back.dropdown-toggle, body.node-type-h5p-content .answering-form #edit-back:active, body.node-type-h5p-content .answering-form #edit-back.active,
      .open > body.node-type-h5p-content .answering-form #edit-back.dropdown-toggle, body.page-node-takecourse .answering-form #edit-back:active, body.page-node-takecourse .answering-form #edit-back.active,
      .open > body.page-node-takecourse .answering-form #edit-back.dropdown-toggle {
        background-image: none; }
      /* line 29, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-back.disabled, body.node-type-quiz .answering-form #edit-back.disabled:hover, body.node-type-quiz .answering-form #edit-back.disabled:focus, body.node-type-quiz .answering-form #edit-back.disabled.focus, body.node-type-quiz .answering-form #edit-back.disabled:active, body.node-type-quiz .answering-form #edit-back.disabled.active, body.node-type-quiz .answering-form #edit-back[disabled], body.node-type-quiz .answering-form #edit-back[disabled]:hover, body.node-type-quiz .answering-form #edit-back[disabled]:focus, body.node-type-quiz .answering-form #edit-back[disabled].focus, body.node-type-quiz .answering-form #edit-back[disabled]:active, body.node-type-quiz .answering-form #edit-back[disabled].active,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-back,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-back:hover,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-back:focus,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-back.focus,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-back:active,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-back.active, body.node-type-course .answering-form #edit-back.disabled, body.node-type-course .answering-form #edit-back.disabled:hover, body.node-type-course .answering-form #edit-back.disabled:focus, body.node-type-course .answering-form #edit-back.disabled.focus, body.node-type-course .answering-form #edit-back.disabled:active, body.node-type-course .answering-form #edit-back.disabled.active, body.node-type-course .answering-form #edit-back[disabled], body.node-type-course .answering-form #edit-back[disabled]:hover, body.node-type-course .answering-form #edit-back[disabled]:focus, body.node-type-course .answering-form #edit-back[disabled].focus, body.node-type-course .answering-form #edit-back[disabled]:active, body.node-type-course .answering-form #edit-back[disabled].active,
      fieldset[disabled] body.node-type-course .answering-form #edit-back,
      fieldset[disabled] body.node-type-course .answering-form #edit-back:hover,
      fieldset[disabled] body.node-type-course .answering-form #edit-back:focus,
      fieldset[disabled] body.node-type-course .answering-form #edit-back.focus,
      fieldset[disabled] body.node-type-course .answering-form #edit-back:active,
      fieldset[disabled] body.node-type-course .answering-form #edit-back.active, body.node-type-h5p-content .answering-form #edit-back.disabled, body.node-type-h5p-content .answering-form #edit-back.disabled:hover, body.node-type-h5p-content .answering-form #edit-back.disabled:focus, body.node-type-h5p-content .answering-form #edit-back.disabled.focus, body.node-type-h5p-content .answering-form #edit-back.disabled:active, body.node-type-h5p-content .answering-form #edit-back.disabled.active, body.node-type-h5p-content .answering-form #edit-back[disabled], body.node-type-h5p-content .answering-form #edit-back[disabled]:hover, body.node-type-h5p-content .answering-form #edit-back[disabled]:focus, body.node-type-h5p-content .answering-form #edit-back[disabled].focus, body.node-type-h5p-content .answering-form #edit-back[disabled]:active, body.node-type-h5p-content .answering-form #edit-back[disabled].active,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-back,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-back:hover,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-back:focus,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-back.focus,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-back:active,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-back.active, body.page-node-takecourse .answering-form #edit-back.disabled, body.page-node-takecourse .answering-form #edit-back.disabled:hover, body.page-node-takecourse .answering-form #edit-back.disabled:focus, body.page-node-takecourse .answering-form #edit-back.disabled.focus, body.page-node-takecourse .answering-form #edit-back.disabled:active, body.page-node-takecourse .answering-form #edit-back.disabled.active, body.page-node-takecourse .answering-form #edit-back[disabled], body.page-node-takecourse .answering-form #edit-back[disabled]:hover, body.page-node-takecourse .answering-form #edit-back[disabled]:focus, body.page-node-takecourse .answering-form #edit-back[disabled].focus, body.page-node-takecourse .answering-form #edit-back[disabled]:active, body.page-node-takecourse .answering-form #edit-back[disabled].active,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-back,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-back:hover,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-back:focus,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-back.focus,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-back:active,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-back.active {
        background-color: #f0ad4e;
        border-color: #eea236; }
      /* line 40, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-back .badge, body.node-type-course .answering-form #edit-back .badge, body.node-type-h5p-content .answering-form #edit-back .badge, body.page-node-takecourse .answering-form #edit-back .badge {
        color: #f0ad4e;
        background-color: #fff; }
      /* line 431, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .answering-form #edit-back:before, body.node-type-course .answering-form #edit-back:before, body.node-type-h5p-content .answering-form #edit-back:before, body.page-node-takecourse .answering-form #edit-back:before {
        font-family: 'cabin';
        content: '\f190';
        position: static;
        display: inline-block;
        font-family: fontawesome;
        float: left;
        padding-right: .5em; }
    /* line 460, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .answering-form #edit-submit, body.node-type-course .answering-form #edit-submit, body.node-type-h5p-content .answering-form #edit-submit, body.page-node-takecourse .answering-form #edit-submit {
      /*position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      padding: 0;
      margin: 0;
      z-index: 100;
      color: transparent;
      background: none;
      border: none;
      display: block;
      height: 4em;
      width: 4em;*/
      margin: 1em;
      font-size: 1.5em;
      font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
      font-weight: 100;
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
      /*&:after {
        content: '\f18e';
        font-family: 'fontawesome';
        font-size: 3em;
        color: rgba(0, 0, 0, .4);
        line-height: 1em;
        z-index: 100;
        transition: all .2s ease-out;
        position: absolute;
        top: 50%;
        right: 15px;
        margin-top: -1em;
      }
      &:hover {
        &:after {
          color: #008926;
          font-size: 4em;
          position: absolute;
          bottom: 0;
        }
      }*/ }
      /* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-submit:hover, body.node-type-quiz .answering-form #edit-submit:focus, body.node-type-quiz .answering-form #edit-submit.focus, body.node-type-quiz .answering-form #edit-submit:active, body.node-type-quiz .answering-form #edit-submit.active,
      .open > body.node-type-quiz .answering-form #edit-submit.dropdown-toggle, body.node-type-course .answering-form #edit-submit:hover, body.node-type-course .answering-form #edit-submit:focus, body.node-type-course .answering-form #edit-submit.focus, body.node-type-course .answering-form #edit-submit:active, body.node-type-course .answering-form #edit-submit.active,
      .open > body.node-type-course .answering-form #edit-submit.dropdown-toggle, body.node-type-h5p-content .answering-form #edit-submit:hover, body.node-type-h5p-content .answering-form #edit-submit:focus, body.node-type-h5p-content .answering-form #edit-submit.focus, body.node-type-h5p-content .answering-form #edit-submit:active, body.node-type-h5p-content .answering-form #edit-submit.active,
      .open > body.node-type-h5p-content .answering-form #edit-submit.dropdown-toggle, body.page-node-takecourse .answering-form #edit-submit:hover, body.page-node-takecourse .answering-form #edit-submit:focus, body.page-node-takecourse .answering-form #edit-submit.focus, body.page-node-takecourse .answering-form #edit-submit:active, body.page-node-takecourse .answering-form #edit-submit.active,
      .open > body.page-node-takecourse .answering-form #edit-submit.dropdown-toggle {
        color: #fff;
        background-color: #449d44;
        border-color: #398439; }
      /* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-submit:active, body.node-type-quiz .answering-form #edit-submit.active,
      .open > body.node-type-quiz .answering-form #edit-submit.dropdown-toggle, body.node-type-course .answering-form #edit-submit:active, body.node-type-course .answering-form #edit-submit.active,
      .open > body.node-type-course .answering-form #edit-submit.dropdown-toggle, body.node-type-h5p-content .answering-form #edit-submit:active, body.node-type-h5p-content .answering-form #edit-submit.active,
      .open > body.node-type-h5p-content .answering-form #edit-submit.dropdown-toggle, body.page-node-takecourse .answering-form #edit-submit:active, body.page-node-takecourse .answering-form #edit-submit.active,
      .open > body.page-node-takecourse .answering-form #edit-submit.dropdown-toggle {
        background-image: none; }
      /* line 29, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-submit.disabled, body.node-type-quiz .answering-form #edit-submit.disabled:hover, body.node-type-quiz .answering-form #edit-submit.disabled:focus, body.node-type-quiz .answering-form #edit-submit.disabled.focus, body.node-type-quiz .answering-form #edit-submit.disabled:active, body.node-type-quiz .answering-form #edit-submit.disabled.active, body.node-type-quiz .answering-form #edit-submit[disabled], body.node-type-quiz .answering-form #edit-submit[disabled]:hover, body.node-type-quiz .answering-form #edit-submit[disabled]:focus, body.node-type-quiz .answering-form #edit-submit[disabled].focus, body.node-type-quiz .answering-form #edit-submit[disabled]:active, body.node-type-quiz .answering-form #edit-submit[disabled].active,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-submit,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-submit:hover,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-submit:focus,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-submit.focus,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-submit:active,
      fieldset[disabled] body.node-type-quiz .answering-form #edit-submit.active, body.node-type-course .answering-form #edit-submit.disabled, body.node-type-course .answering-form #edit-submit.disabled:hover, body.node-type-course .answering-form #edit-submit.disabled:focus, body.node-type-course .answering-form #edit-submit.disabled.focus, body.node-type-course .answering-form #edit-submit.disabled:active, body.node-type-course .answering-form #edit-submit.disabled.active, body.node-type-course .answering-form #edit-submit[disabled], body.node-type-course .answering-form #edit-submit[disabled]:hover, body.node-type-course .answering-form #edit-submit[disabled]:focus, body.node-type-course .answering-form #edit-submit[disabled].focus, body.node-type-course .answering-form #edit-submit[disabled]:active, body.node-type-course .answering-form #edit-submit[disabled].active,
      fieldset[disabled] body.node-type-course .answering-form #edit-submit,
      fieldset[disabled] body.node-type-course .answering-form #edit-submit:hover,
      fieldset[disabled] body.node-type-course .answering-form #edit-submit:focus,
      fieldset[disabled] body.node-type-course .answering-form #edit-submit.focus,
      fieldset[disabled] body.node-type-course .answering-form #edit-submit:active,
      fieldset[disabled] body.node-type-course .answering-form #edit-submit.active, body.node-type-h5p-content .answering-form #edit-submit.disabled, body.node-type-h5p-content .answering-form #edit-submit.disabled:hover, body.node-type-h5p-content .answering-form #edit-submit.disabled:focus, body.node-type-h5p-content .answering-form #edit-submit.disabled.focus, body.node-type-h5p-content .answering-form #edit-submit.disabled:active, body.node-type-h5p-content .answering-form #edit-submit.disabled.active, body.node-type-h5p-content .answering-form #edit-submit[disabled], body.node-type-h5p-content .answering-form #edit-submit[disabled]:hover, body.node-type-h5p-content .answering-form #edit-submit[disabled]:focus, body.node-type-h5p-content .answering-form #edit-submit[disabled].focus, body.node-type-h5p-content .answering-form #edit-submit[disabled]:active, body.node-type-h5p-content .answering-form #edit-submit[disabled].active,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-submit,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-submit:hover,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-submit:focus,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-submit.focus,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-submit:active,
      fieldset[disabled] body.node-type-h5p-content .answering-form #edit-submit.active, body.page-node-takecourse .answering-form #edit-submit.disabled, body.page-node-takecourse .answering-form #edit-submit.disabled:hover, body.page-node-takecourse .answering-form #edit-submit.disabled:focus, body.page-node-takecourse .answering-form #edit-submit.disabled.focus, body.page-node-takecourse .answering-form #edit-submit.disabled:active, body.page-node-takecourse .answering-form #edit-submit.disabled.active, body.page-node-takecourse .answering-form #edit-submit[disabled], body.page-node-takecourse .answering-form #edit-submit[disabled]:hover, body.page-node-takecourse .answering-form #edit-submit[disabled]:focus, body.page-node-takecourse .answering-form #edit-submit[disabled].focus, body.page-node-takecourse .answering-form #edit-submit[disabled]:active, body.page-node-takecourse .answering-form #edit-submit[disabled].active,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-submit,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-submit:hover,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-submit:focus,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-submit.focus,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-submit:active,
      fieldset[disabled] body.page-node-takecourse .answering-form #edit-submit.active {
        background-color: #5cb85c;
        border-color: #4cae4c; }
      /* line 40, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_buttons.scss */
      body.node-type-quiz .answering-form #edit-submit .badge, body.node-type-course .answering-form #edit-submit .badge, body.node-type-h5p-content .answering-form #edit-submit .badge, body.page-node-takecourse .answering-form #edit-submit .badge {
        color: #5cb85c;
        background-color: #fff; }
      /* line 479, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.node-type-quiz .answering-form #edit-submit:before, body.node-type-course .answering-form #edit-submit:before, body.node-type-h5p-content .answering-form #edit-submit:before, body.page-node-takecourse .answering-form #edit-submit:before {
        content: '\f18e';
        position: static;
        display: inline-block;
        font-family: fontawesome;
        float: right;
        padding-left: .5em; }
    /* line 509, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.node-type-quiz .answering-form #edit-submit-hidden, body.node-type-course .answering-form #edit-submit-hidden, body.node-type-h5p-content .answering-form #edit-submit-hidden, body.page-node-takecourse .answering-form #edit-submit-hidden {
      display: none; }
  /* line 513, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .h5p-content, body.node-type-quiz .h5p-iframe-wrapper, body.node-type-course .h5p-content, body.node-type-course .h5p-iframe-wrapper, body.node-type-h5p-content .h5p-content, body.node-type-h5p-content .h5p-iframe-wrapper, body.page-node-takecourse .h5p-content, body.page-node-takecourse .h5p-iframe-wrapper {
    margin: 1.5em 5.5em; }
  /* line 516, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz #quiz_progress,
  body.node-type-quiz #quiz_score_possible,
  body.node-type-quiz #quiz_score_percent, body.node-type-course #quiz_progress,
  body.node-type-course #quiz_score_possible,
  body.node-type-course #quiz_score_percent, body.node-type-h5p-content #quiz_progress,
  body.node-type-h5p-content #quiz_score_possible,
  body.node-type-h5p-content #quiz_score_percent, body.page-node-takecourse #quiz_progress,
  body.page-node-takecourse #quiz_score_possible,
  body.page-node-takecourse #quiz_score_percent {
    background: #A30234;
    margin-top: -1px;
    line-height: 3em;
    padding-bottom: 3px;
    font-size: 1.25em;
    color: white;
    text-align: center;
    font-weight: 600;
    box-shadow: 0 1px 1px #333; }
  /* line 529, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz #quiz_score_percent, body.node-type-course #quiz_score_percent, body.node-type-h5p-content #quiz_score_percent, body.page-node-takecourse #quiz_score_percent {
    background: #7b0227; }
  /* line 532, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz #quiz_score_possible a, body.node-type-course #quiz_score_possible a, body.node-type-h5p-content #quiz_score_possible a, body.page-node-takecourse #quiz_score_possible a {
    color: #fff;
    text-decoration: underline; }
  /* line 538, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.node-type-quiz .radix-moscone-collapse.h5p_content #radix-collapse, body.node-type-course .radix-moscone-collapse.h5p_content #radix-collapse, body.node-type-h5p-content .radix-moscone-collapse.h5p_content #radix-collapse, body.page-node-takecourse .radix-moscone-collapse.h5p_content #radix-collapse {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0; }

/* line 550, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.lms-slide {
  padding: 0;
  min-height: 420px;
  float: left;
  width: 100%;
  position: relative;
  margin: auto;
  text-align: center; }
  /* line 559, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide .lms-slide.lms-slide {
    padding: 0 6em;
    background: transparent; }
  /* line 563, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide .slide-title {
    font-size: 3em;
    text-align: center;
    line-height: 1.2em;
    margin: 1.2em; }
    /* line 568, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .lms-slide .slide-title.big {
      font-size: 3.2em; }
  /* line 572, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide p {
    font-size: 1.8em;
    text-align: left; }
  /* line 576, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide ul, .lms-slide ol {
    text-align: left;
    display: inline-block;
    max-width: 80%; }
    /* line 580, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .lms-slide ul ul, .lms-slide ul ol, .lms-slide ol ul, .lms-slide ol ol {
      display: block; }
    /* line 583, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .lms-slide ul li, .lms-slide ol li {
      font-size: 2em; }
      /* line 585, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      .lms-slide ul li ul li, .lms-slide ul li ol li, .lms-slide ol li ul li, .lms-slide ol li ol li {
        font-size: 1.8rem; }
        /* line 587, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        .lms-slide ul li ul li ul li, .lms-slide ul li ul li ol li, .lms-slide ul li ol li ul li, .lms-slide ul li ol li ol li, .lms-slide ol li ul li ul li, .lms-slide ol li ul li ol li, .lms-slide ol li ol li ul li, .lms-slide ol li ol li ol li {
          font-size: 1.6rem; }
  /* line 593, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide.wysiwyg-bg-dark-grey {
    background-color: #939393;
    /* Old browsers */
    background: radial-gradient(ellipse at center, #e2e2e2 0%, darkgray 100%);
    /* W3C */ }
  /* line 597, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide.wysiwyg-bg-light-grey {
    background-color: #D8D8D8;
    /* Old browsers */
    background: radial-gradient(ellipse at center, #f4f4f4 0%, #dddddd 100%);
    /* W3C */ }
  /* line 602, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide.naac-content-slide-vert-center .vert-wrap {
    min-height: 360px; }
  /* line 607, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide.naac-content-slide-2-col p {
    font-size: 1.4em; }
  /* line 610, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .lms-slide.naac-content-slide-2-col h3 {
    font-size: 2.2em; }

/* line 616, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.page-node-take .content-std {
  padding-top: 2em; }

/* line 619, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.page-node-take .breadcrumbs {
  display: none; }

/* line 622, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.page-node-take .content {
  position: relative; }
  /* line 624, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .page-node-take .content .field {
    clear: both;
    width: 100%; }
  /* line 628, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .page-node-take .content .field-name-field-slide-audio {
    position: relative;
    width: 33%;
    float: left; }

/* line 634, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.page-node-take .jp-playlist {
  display: none; }

/* line 639, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.quiztake-footer {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+44,000000+100&amp;0+44,0.3+100 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(44%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.3)));
  /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%);
  /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%);
  /* IE10+ */
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 44%, rgba(0, 0, 0, 0.3) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#4d000000', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  position: relative; }
  /* line 649, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .quiztake-footer img.align-right {
    margin: 1em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0; }

/* line 660, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#course-objects div[id^=title] {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 2em; }

/* line 667, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#course-objects tr.draggable td:first-child {
  vertical-align: middle; }
  /* line 669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #course-objects tr.draggable td:first-child .handle {
    width: 13px; }

/* line 675, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#course-objects div[id^=edit-course-outline-objects] > a {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  position: relative;
  text-decoration: none !important;
  transition: all .1s; }
  /* line 682, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #course-objects div[id^=edit-course-outline-objects] > a:before {
    content: "\f013";
    margin-right: .5em;
    font-family: fontawesome;
    text-decoration: none !important; }
  /* line 688, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #course-objects div[id^=edit-course-outline-objects] > a:hover {
    color: #8a022c; }
    /* line 690, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    #course-objects div[id^=edit-course-outline-objects] > a:hover:before {
      animation: 2s linear 0s normal none infinite spin;
      text-decoration: none; }

/* line 696, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#course-objects div[id^=edit-course-outline-objects] ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  /* line 700, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #course-objects div[id^=edit-course-outline-objects] ul li {
    font-size: 1em;
    line-height: 1.5em;
    font-style: italic;
    font-weight: 600; }
    /* line 705, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    #course-objects div[id^=edit-course-outline-objects] ul li i {
      margin-right: .5em;
      font-size: 1.3em;
      width: 1em;
      text-align: center; }
      /* line 710, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      #course-objects div[id^=edit-course-outline-objects] ul li i.fa-check {
        color: #5cb85c; }
      /* line 713, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      #course-objects div[id^=edit-course-outline-objects] ul li i.fa-eye {
        color: #337ab7; }
      /* line 716, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      #course-objects div[id^=edit-course-outline-objects] ul li i.fa-exclamation {
        color: #5bc0de; }
      /* line 719, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      #course-objects div[id^=edit-course-outline-objects] ul li i.fa-exclamation-triangle {
        color: #d9534f; }
      /* line 722, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      #course-objects div[id^=edit-course-outline-objects] ul li i.fa-share {
        color: #f0ad4e; }

/* line 730, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#quiz-view-table {
  width: 100%;
  border-collapse: separate;
  padding: 0px;
  margin: 0;
  margin-bottom: 20px;
  background: none;
  border: 0;
  margin-top: 1em; }

/* line 740, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.quiz-viewarea {
  background: radial-gradient(ellipse at center, #f4f4f4 0%, #dddddd 100%);
  /* W3C */ }
  /* line 743, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .quiz-viewarea .quiz-question-node--multichoice {
    padding: 1em;
    text-align: center;
    font-size: 1.8em; }
    /* line 747, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .quiz-viewarea .quiz-question-node--multichoice .field-name-body {
      max-width: 720px;
      margin: 1em auto 1em auto;
      text-align: left;
      position: relative; }
      /* line 752, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      .quiz-viewarea .quiz-question-node--multichoice .field-name-body:before {
        content: '\f10d';
        display: block;
        color: black;
        position: absolute;
        top: -84px;
        left: -60px;
        font-size: 5em;
        font-family: fontawesome;
        z-index: -1;
        color: #e6e6e6;
        text-shadow: -1px -1px 0 rgba(200, 200, 200, 0.4); }
  /* line 770, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .quiz-viewarea #quiz-question-answering-form .form-submit {
    box-shadow: none; }
  /* line 773, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .quiz-viewarea #quiz-question-answering-form .form-type-radio {
    margin-left: 0;
    margin-bottom: 1.25em;
    padding: 0;
    line-height: 1.2;
    font-size: 1.2em; }
    /* line 779, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .quiz-viewarea #quiz-question-answering-form .form-type-radio p {
      text-indent: -1.3em;
      padding-left: 1.3em; }
    /* line 783, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .quiz-viewarea #quiz-question-answering-form .form-type-radio input, .quiz-viewarea #quiz-question-answering-form .form-type-radio label {
      margin: 0;
      display: inline-block;
      vertical-align: top;
      float: none;
      cursor: pointer; }
    /* line 791, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .quiz-viewarea #quiz-question-answering-form .form-type-radio input {
      margin-left: -20px;
      margin-top: 2px; }
    /* line 796, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .quiz-viewarea #quiz-question-answering-form .form-type-radio label {
      padding-left: 5px;
      margin-top: -2px; }
  /* line 803, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .quiz-viewarea #quiz-question-answering-form .form-type-radios, .quiz-viewarea #quiz-question-answering-form .form-type-checkboxes {
    margin: .8em auto 2em 2em;
    font-size: 14px;
    max-width: 560px; }

/* line 812, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#quiz_score_possible,
#quiz_score_percent {
  background-color: #A30234;
  color: #fff;
  text-align: center; }

/* line 818, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#quiz_score_percent {
  background-color: #3e0114; }

/* line 821, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#quiz_summary {
  font-size: 1.5em;
  margin: 3em auto;
  max-width: 768px;
  padding: 0 1em; }

/* line 827, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#quiz-report-form {
  background: radial-gradient(ellipse at center, #f4f4f4 0%, #dddddd 100%);
  /* W3C */ }
  /* line 830, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form table {
    margin: 0;
    font-size: 14px; }
  /* line 835, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form tr td:first-child {
    padding: 0.5em 18px; }
  /* line 841, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form tr:nth-child(odd) td {
    background: rgba(194, 204, 209, 0.2); }
  /* line 846, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form td {
    border: none;
    background-color: transparent;
    padding: 0.5em 0; }
  /* line 852, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .dd {
    margin: 0;
    padding: 0; }
  /* line 857, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .dt {
    position: relative;
    background-color: transparent;
    padding: 0.5em 100px 0 0; }
  /* line 864, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .multichoice-icon {
    background: none;
    width: auto;
    height: auto; }
    /* line 869, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    #quiz-report-form .multichoice-icon:before {
      font-size: 1.5em;
      line-height: 1ex;
      vertical-align: -4px; }
  /* line 876, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .wrong:before {
    content: "×";
    color: #A30234;
    background: none; }
  /* line 882, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .should:before,
  #quiz-report-form .correct:before {
    content: "✓";
    color: #555; }
  /* line 888, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .correct:before {
    color: #00bf00; }
  /* line 892, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .field-name-body {
    padding: 0.5em 1em;
    font-size: 18px; }
  /* line 897, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #quiz-report-form .quiz-report-score-container {
    position: absolute;
    right: 0;
    top: 0;
    border: none; }

/* line 905, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.page-node-results .tab-v1 {
  margin: 0; }

/* line 909, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
#radix-collapse {
  padding-left: 0;
  margin-bottom: 20px;
  padding-right: 0;
  transition: margin .1s !important; }
  /* line 914, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  #radix-collapse.collapsed {
    margin-bottom: 0;
    animation: shrink-hide 1ms linear .1s forwards;
    margin-left: -99999em;
    margin-left: -100vw; }

@keyframes shrink-hide {
  0% {
    max-height: 100%; }
  100% {
    max-height: 0; } }

/* line 931, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.radix-layouts-content {
  transition: width .1s  !important;
  float: right; }

/* line 936, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.no-overflow {
  overflow: hidden; }

/* line 941, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.radix-moscone-collapse {
  padding: 0 0 0 30px; }
  /* line 944, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .radix-moscone-collapse .collapse-button {
    position: absolute;
    left: 0;
    z-index: 100;
    font-size: 2em;
    transform-origin: top left;
    top: 1px;
    margin-left: -40px;
    padding: 6px 16px 5px 16px; }
    /* line 953, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .radix-moscone-collapse .collapse-button i:before {
      display: inline-block;
      vertical-align: middle; }
    /* line 958, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .radix-moscone-collapse .collapse-button:hover {
      opacity: 1;
      transition: opacity 0.2s; }
    /* line 963, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .radix-moscone-collapse .collapse-button span {
      padding: 0 5px;
      vertical-align: middle; }

@media (max-width: 808px) {
  /* line 972, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .radix-moscone-collapse .collapse-button {
    position: relative;
    transform: none;
    top: auto;
    bottom: auto;
    margin: 0;
    left: 0;
    display: block; } }

@media (min-width: 992px) and (max-width: 1022px) {
  /* line 984, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .radix-moscone-collapse {
    padding: 0; }
    /* line 988, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .radix-moscone-collapse .collapse-button {
      margin-left: -5px; } }

@media (min-width: 1200px) and (max-width: 1230px) {
  /* line 995, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .radix-moscone-collapse {
    padding: 0; }
    /* line 999, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .radix-moscone-collapse .collapse-button {
      margin-left: -5px; } }

/* line 1027, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.page-certification-courses .block-system .panel-3col {
  text-align: center; }
  @media (min-width: 640px) {
    /* line 1027, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    .page-certification-courses .block-system .panel-3col {
      display: table; } }
  /* line 1034, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .page-certification-courses .block-system .panel-3col .panel-panel {
    width: 100%;
    float: none;
    background: rgba(15, 71, 136, 0.1);
    padding: 1em;
    margin-bottom: 1em; }
    @media (min-width: 640px) {
      /* line 1034, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      .page-certification-courses .block-system .panel-3col .panel-panel {
        width: 33%;
        display: table-cell;
        vertical-align: middle;
        border: 10px solid white; }
        /* line 1047, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        .page-certification-courses .block-system .panel-3col .panel-panel:first-child {
          border-left: none; }
        /* line 1051, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
        .page-certification-courses .block-system .panel-3col .panel-panel:last-child {
          border-right: none; } }

/* line 1059, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.page-certification-courses .block-system h2 {
  margin-top: 0.5em; }

/* line 1064, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
.course-nav-links {
  text-align: center; }
  /* line 1067, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  .course-nav-links a {
    display: inline-block;
    margin: 1em; }

/* line 1073, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
ul.question-actions {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style: none; }
  /* line 1080, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  ul.question-actions li {
    list-style: none; }

/* line 1087, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
body.page-node-take {
  padding-top: 40px;
  margin-bottom: 0;
  overflow: hidden; }
  @media (max-width: 991px) {
    /* line 1092, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .header-v6 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0; }
      /* line 1099, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take .header-v6 ul.nav.secondary li:nth-child(1) {
        display: none; } }

@media (max-width: 991px) and (max-width: 767px) {
  /* line 1108, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .tabs--primary {
    display: none; } }
  /* line 1113, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .primary-nav, body.page-node-take .footer-v1, body.page-node-take #support {
    display: none; }
  /* line 1116, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .navbar {
    min-height: 0; }
  /* line 1119, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .content-std {
    padding: 0; }
  /* line 1123, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .nav-tabs {
    margin-bottom: 0; }
  /* line 1126, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .sm-logo {
    height: 22px !important; }
    /* line 1128, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .sm-logo img {
      max-height: 100%; }
    /* line 1131, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .sm-logo a {
      border: 0; }
  /* line 1135, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take #block-system-main {
    background: #f1f1f1; }
  /* line 1139, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take #quiz_title .pane-title {
    text-align: center;
    font-style: italic;
    line-height: 51px;
    margin: 0;
    padding: 0; }
    @media (max-width: 767px) {
      /* line 1139, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take #quiz_title .pane-title {
        margin-left: 1.4em;
        line-height: 1.25em;
        font-size: 1.6em;
        min-height: 2.5em; } }
  /* line 1153, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take #quiz_progress {
    background: #fff;
    margin: 0;
    padding: 0; }
    /* line 1157, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take #quiz_progress .progress {
      margin: 0;
      padding: 0;
      height: 40px;
      line-height: 40px; }
      /* line 1162, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take #quiz_progress .progress .progress-bar {
        line-height: 40px;
        font-size: 1.2em; }
      /* line 1166, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take #quiz_progress .progress #progress_indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 10; }
  /* line 1177, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .quiz-viewarea {
    overflow-y: scroll; }
    /* line 1179, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .quiz-viewarea .lms-slide {
      overflow-y: scroll;
      max-width: 100%; }
      /* line 1184, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take .quiz-viewarea .lms-slide h2 {
        display: inline-block;
        width: 100%;
        margin: 1.2em 0; }
      /* line 1189, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take .quiz-viewarea .lms-slide ul, body.page-node-take .quiz-viewarea .lms-slide ol {
        max-width: 820px; }
    /* line 1193, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .quiz-viewarea .quiz-question-node--wrapper {
      overflow-y: scroll;
      display: table;
      max-width: 720px;
      margin: auto; }
      /* line 1198, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take .quiz-viewarea .quiz-question-node--wrapper .quiz-question-node--wrapper-inner {
        display: table-cell;
        vertical-align: middle; }
    /* line 1203, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .quiz-viewarea form.answering-form {
      clear: both;
      min-height: 80px; }
  /* line 1208, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .radix-moscone-collapse {
    margin-left: 0px;
    margin-right: 15px;
    padding-left: 0; }
    @media (max-width: 767px) {
      /* line 1208, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take .radix-moscone-collapse {
        margin-right: 0; } }
    /* line 1215, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .radix-moscone-collapse .collapse-button {
      margin-left: 0; }
    /* line 1218, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
    body.page-node-take .radix-moscone-collapse .radix-layouts-sidebar {
      margin-bottom: 0 !important;
      overflow-y: hidden; }
      /* line 1221, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
      body.page-node-take .radix-moscone-collapse .radix-layouts-sidebar .tab-content-wrapper {
        overflow-y: scroll; }
  /* line 1229, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_course.scss */
  body.page-node-take .outline-in {
    font-size: 1em !important;
    box-sizing: border-box;
    padding: 0 20px;
    background: #dedede; }

/*.jp-audio {
  position: relative;
  font-family: 'Source Sans Pro',sans-serif;
  text-align: left;
  //min-width: 320px;
  //max-width: 1280px;
  width: 100%;
  height: $player_height;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $color_text;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 0;
  line-height: 0;

  .jp-controls {
    left: 0;
    position: absolute;
    top: 0;
    width: $player_height;
    height: 100%;
    background-color: $color_fg;

    a {
      text-decoration: none;
      font-size: 20px;
      padding-left: $button_padding_left;
      line-height: $player_height;
      text-align: center;
      cursor: pointer;
      color: $color_text;
    }
    a:hover {
      color: $color_bg;
    }

    a:before {
      display: inline-block;
      font-family: flat-audio;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      font-variant: normal;
      text-transform: none;
      speak: none;
      width: $player_height;
      line-height: $player_height;
      text-align: center;
      opacity: 1;
    }
  }

  .jp-progress {
    position: absolute;
    top: 0;
    left: $player_height;
    right: 0;
    height: 100%;
    background-color: $color_bg;

    .jp-seek-bar {
      position: absolute;
      top: 0;
      left: 0;
      //min-width: 100%;
      height: 100%;
      cursor: pointer;

      .jp-play-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background-color: $color_progress;
      }
    }
  }

  .jp-current-time {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding: 0 12px;
    font-size: 16px;
    line-height: $player_height;
    text-align:right;
  }

  .jp-no-solution {
    position: absolute;
    top: 0;
    left: $player_height;
    right: 0;
    height: $player_height;
    padding: 8px 0;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
    display: none;
    color: $color_bg;
    background-color: #b28282;
  }
}
*/
/* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
body.page-redhen .field.field-name-redhen-contact-email {
  display: table;
  width: 100%;
  border: solid 2px #eee;
  position: relative;
  outline: 1px solid #ddd;
  margin: 1.3em 0; }
  /* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  body.page-redhen .field.field-name-redhen-contact-email:after, body.page-redhen .field.field-name-redhen-contact-email:before {
    top: 80%;
    left: 5px;
    width: 50%;
    z-index: -1;
    content: "";
    bottom: 15px;
    max-width: 300px;
    background: #999;
    position: absolute; }
  /* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  body.page-redhen .field.field-name-redhen-contact-email:after {
    left: auto;
    right: 5px;
    transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
    box-shadow: 0 15px 10px #999;
    -moz-box-shadow: 0 15px 10px #999;
    -webkit-box-shadow: 0 15px 10px #999; }
  /* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  body.page-redhen .field.field-name-redhen-contact-email div.field-label {
    display: table-caption;
    padding: 5px 15px;
    color: #333333;
    background-color: #f5f5f5;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid #ddd; }
    /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
    body.page-redhen .field.field-name-redhen-contact-email div.field-label:before {
      font-family: 'fontawesome';
      content: "\f0e0";
      color: #ccc;
      margin-right: .5em; }
  /* line 50, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  body.page-redhen .field.field-name-redhen-contact-email div.legend {
    display: table-header-group; }
    /* line 53, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
    body.page-redhen .field.field-name-redhen-contact-email div.legend div.email-address, body.page-redhen .field.field-name-redhen-contact-email div.legend div.email-label, body.page-redhen .field.field-name-redhen-contact-email div.legend div.bulk-label, body.page-redhen .field.field-name-redhen-contact-email div.legend div.hold-label, body.page-redhen .field.field-name-redhen-contact-email div.legend div.primary-label {
      display: table-cell;
      padding: 8px;
      line-height: 1.5;
      vertical-align: middle;
      margin: auto auto;
      font-weight: bold; }
  /* line 63, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  body.page-redhen .field.field-name-redhen-contact-email div.field-items {
    display: table-row-group;
    width: 100%; }
    /* line 66, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
    body.page-redhen .field.field-name-redhen-contact-email div.field-items div.field-item {
      display: table-row;
      width: 100%; }
      /* line 69, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
      body.page-redhen .field.field-name-redhen-contact-email div.field-items div.field-item:nth-of-type(odd) {
        background-color: #f9f9f9; }
      /* line 72, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
      body.page-redhen .field.field-name-redhen-contact-email div.field-items div.field-item .email-address, body.page-redhen .field.field-name-redhen-contact-email div.field-items div.field-item .email-label, body.page-redhen .field.field-name-redhen-contact-email div.field-items div.field-item .bulk-label, body.page-redhen .field.field-name-redhen-contact-email div.field-items div.field-item .hold-label, body.page-redhen .field.field-name-redhen-contact-email div.field-items div.field-item .primary-label {
        display: table-cell;
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #ddd;
        margin: auto auto; }
  /* line 82, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  body.page-redhen .field.field-name-redhen-contact-email div .primary-label, body.page-redhen .field.field-name-redhen-contact-email div .hold-label, body.page-redhen .field.field-name-redhen-contact-email div .bulk-label {
    text-align: center; }

/* line 87, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
body.page-redhen div.redhen-fields-email-formatter-row {
  display: table-row;
  width: 100%; }

/* line 94, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
#redhen-filter-form {
  margin-top: 1em; }

/* line 97, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
a[href^="mailto"] {
  color: #337ab7;
  transition: all .1s; }
  /* line 100, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  a[href^="mailto"]:before {
    font-family: 'fontawesome';
    content: "\f0e0";
    margin: 0 .25em; }
  /* line 105, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  a[href^="mailto"]:hover {
    border-bottom: 1px dotted;
    text-decoration: none;
    color: #23527c; }

/* line 111, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
a[href^="tel:"] {
  color: #337ab7;
  transition: all .1s; }
  /* line 114, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  a[href^="tel:"]:before {
    font-family: 'fontawesome';
    content: "\f095";
    margin: 0 .25em; }
  /* line 119, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_redhen.scss */
  a[href^="tel:"]:hover {
    border-bottom: 1px dotted;
    text-decoration: none;
    color: #23527c; }

/* loader */
/* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; }

/* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
#loader-inner-wrapper {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 128px;
  height: 128px;
  margin: -64px 0 0 -64px;
  text-align: center;
  z-index: 1004; }

/* sphere */
/* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.stage {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 20px;
  text-align: left;
  perspective: 1200px;
  perspective-origin: 50% 50%;
  transform-style: preserve-3d;
  transform: rotateY(0.01deg); }

/* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.ball {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50% !important;
  position: relative;
  background: radial-gradient(circle at 54% 39%, #0d4175, #245588 66%, #BDC7E4 100%); }

/* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.ball:before {
  content: "";
  position: absolute;
  top: -4%;
  left: -4%;
  width: 100%;
  height: 94%;
  border-radius: 50%;
  background: radial-gradient(circle at 10% 0px, #ffffff, rgba(255, 255, 255, 0) 51%);
  -webkit-filter: blur(15px);
  z-index: 2; }

/* line 55, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.ball:after {
  content: "";
  position: absolute;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: radial-gradient(circle at 50% 30%, rgba(245, 237, 48, 0), rgba(36, 85, 136, 0.2) 50%, #153658 100%); }

/* line 65, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.ball .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, transparent 50%);
  -webkit-transform: rotateX(90deg) translateZ(-80px);
  transform: rotateX(90deg) translateZ(-80px);
  z-index: 1; }

/* line 74, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.splogo {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50% !important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0); }

/* line 90, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.splogo:before {
  content: "✱";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  /* background: black; */
  font-size: 12em;
  line-height: 1.15em;
  /* transform: translateX(-22px) translateY(106px) skewX(10deg) skewY(-2deg); */
  position: absolute;
  animation: move-star-skew 0.9s linear infinite;
  font-family: sans-serif;
  font-weight: bold;
  color: white;
  transform: translateX(-44px) translateY(106px) skewX(10deg) skewY(-2deg);
  text-align: center; }

/* line 110, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.splogo:after {
  /* content: ""; */
  display: block;
  position: absolute;
  width: 31.25%;
  height: 31.25%;
  border-radius: 50%;
  top: 18.75%;
  left: 18.75%;
  background: rgba(255, 255, 255, 0.2);
  text-align: center; }

@keyframes move-star-skew {
  0% {
    transform: translateX(-100px) translateY(100px) skewX(-30deg) skewY(-30deg) scale(0.2); }
  50% {
    transform: none; }
  100% {
    transform: translateX(100px) translateY(-100px) skewX(30deg) skewY(30deg) scale(0.4); } }

/* Preloader5*/
/* line 131, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.preloader5 {
  display: inline-block;
  font-size: 0px;
  padding: 0px;
  position: relative;
  z-index: 1005; }
  /* line 137, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  .preloader5 em {
    display: block;
    margin-top: 10px; }
    /* line 140, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
    .preloader5 em span {
      animation: loadingtext .7s linear infinite;
      font-size: 56px;
      font-family: 'montserrat', sans-serif;
      letter-spacing: 2px;
      color: #A30234;
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      opacity: 1; }
      /* line 150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
      .preloader5 em span:nth-child(2) {
        animation-delay: -.7s; }
      /* line 153, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
      .preloader5 em span:nth-child(3) {
        animation-delay: -.6s; }
      /* line 156, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
      .preloader5 em span:nth-child(4) {
        animation-delay: -.5s; }
      /* line 159, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
      .preloader5 em span:nth-child(5) {
        animation-delay: -.4s; }
      /* line 162, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
      .preloader5 em span:nth-child(6) {
        animation-delay: -.3s; }
      /* line 165, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
      .preloader5 em span:nth-child(7) {
        animation-delay: -.2s; }
      /* line 168, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
      .preloader5 em span:nth-child(8) {
        animation-delay: -.1s; }

@keyframes loadingtext {
  0% {
    opacity: .7; }
  50% {
    opacity: 1; }
  100% {
    opacity: .7; } }

/* line 180, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
#loader {
  display: block;
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50% !important;
  border: 3px solid transparent;
  border-top-color: #3498db;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001; }
  /* line 193, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #e74c3c;
    -webkit-animation: spin 3s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 3s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */ }
  /* line 208, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #f9c922;
    -webkit-animation: spin 1.5s linear infinite;
    /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 1.5s linear infinite;
    /* Chrome, Firefox 16+, IE 10+, Opera */ }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */ }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */ } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */ }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */ } }

/* line 266, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 50%;
  height: 100%;
  background: white;
  background: rgba(255, 255, 255, 0.95);
  z-index: 1000;
  -webkit-transform: translateX(0);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0);
  /* IE 9 */
  transform: translateX(0);
  /* Firefox 16+, IE 10+, Opera */ }
  /* line 280, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #loader-wrapper .loader-section.section-left {
    left: 0;
    opacity: 1; }
  /* line 284, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #loader-wrapper .loader-section.section-right {
    right: 0;
    opacity: 1; }

/* Loaded */
/* line 294, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);
  /* IE 9 */
  transform: translateX(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: .6; }

/* line 305, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);
  /* IE 9 */
  transform: translateX(100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: .6; }

/* line 317, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

/* line 322, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%);
  /* IE 9 */
  transform: translateY(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out; }

/* line 335, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
#primary-menu-collapse-trigger {
  font-size: 1em;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  text-transform: none; }
  /* line 340, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #primary-menu-collapse-trigger i {
    color: #CEAFB9;
    transition: all .2s ease-in-out; }
  /* line 344, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #primary-menu-collapse-trigger span.label {
    color: #CEAFB9;
    transition: all .2s ease-in-out; }
  /* line 349, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #primary-menu-collapse-trigger:hover i {
    color: white; }
  /* line 352, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
  #primary-menu-collapse-trigger:hover span.label {
    color: white; }

/* JavaScript Turned Off */
/* line 360, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_preloader.scss */
.no-js #loader-wrapper {
  display: none; }

/* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
.view-id-courses .view-display-id-panel_pane_1 .view-content {
  display: table;
  width: 100%; }
  @media (width: 1200px) {
    /* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
    .view-id-courses .view-display-id-panel_pane_1 .view-content {
      min-height: 360px; } }

/* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
.view-id-courses .view-display-id-panel_pane_1 .views-row {
  display: table-cell;
  vertical-align: middle;
  padding: 2em;
  background: #fff;
  box-shadow: 0px 120px 110px -100px rgba(0, 0, 0, 0.3), inset 0px -1px 3px -1px rgba(0, 0, 0, 0.5);
  text-align: center;
  border-radius: 50% !important;
  width: 100%;
  position: relative; }
  /* line 20, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
  .view-id-courses .view-display-id-panel_pane_1 .views-row a {
    color: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }

/* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
.pond-1 .view-id-courses .views-row {
  border: 12px solid #A20233; }

/* line 41, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
.pond-2 .view-id-courses .views-row {
  border: 12px solid #DDB408; }

/* line 48, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
.pond-3 .view-id-courses .views-row {
  border: 12px solid #883682; }

/* line 54, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
.view-commerce-cart-form .views-field-edit-attributes {
  clear: both; }
  /* line 56, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
  .view-commerce-cart-form .views-field-edit-attributes label {
    display: inline-block;
    font-size: 1em;
    line-height: 2em;
    width: 30%;
    text-align: right;
    border-right: 16px solid transparent;
    box-sizing: border-box; }
  /* line 65, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
  .view-commerce-cart-form .views-field-edit-attributes select {
    display: inline-block;
    width: 69%;
    float: right;
    font-size: 1em;
    line-height: 1.1em;
    height: 2em; }

/* line 75, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_views.scss */
.view-commerce-cart-form .views-field-edit-quantity input {
  font-size: 1em;
  line-height: 1.1em;
  height: 2em; }

/* line 2, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body {
  color: #333;
  background-color: #fff;
  font-size: 13px;
  line-height: 1.6;
  overflow-x: hidden; }

/* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.hero-slide .breadcrumbs, body.front .breadcrumbs {
  display: none; }

/* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.admin-menu .header-sticky {
  z-index: 400 !important; }

/* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.main-container {
  overflow: hidden;
  width: 100%;
  position: relative; }

/* line 40, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.radix-layouts-header {
  padding: 0; }

/* line 44, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.panel-display > .container-fluid {
  padding-left: 0;
  padding-right: 0; }

/* line 48, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.wrapper {
  background: #fff; }

/* line 53, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.front .pane-title {
  text-align: center; }

/* calendar stuffz */
/* line 62, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-calendar.view-display-id-panel_pane_1 .view-header {
  background: #0F4788;
  color: white;
  margin-right: 1px;
  box-shadow: inset 0 2px 3px -2px rgba(0, 0, 0, 0.5); }
  /* line 68, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 .view-header .date-heading a {
    color: #ededed;
    line-height: 3em;
    font-family: "lato", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 1.1em;
    text-decoration: none; }
  /* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 .view-header .date-nav {
    padding: 0;
    margin: 0; }
    /* line 79, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-calendar.view-display-id-panel_pane_1 .view-header .date-nav ul.pager {
      margin: 0; }
      /* line 81, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-calendar.view-display-id-panel_pane_1 .view-header .date-nav ul.pager li {
        border: none;
        background: none;
        color: white; }
        /* line 85, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
        .view-calendar.view-display-id-panel_pane_1 .view-header .date-nav ul.pager li.date-prev {
          float: none;
          position: absolute;
          left: 0;
          right: initial; }
        /* line 91, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
        .view-calendar.view-display-id-panel_pane_1 .view-header .date-nav ul.pager li > a {
          border: none;
          background: none;
          color: white;
          font-size: 2.8em;
          padding: 0 14px; }

/* line 102, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-calendar.view-display-id-panel_pane_1 .calendar-calendar table.mini td.empty {
  background: none !important;
  border: 0;
  box-shadow: none !important; }
  /* line 103, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 .calendar-calendar table.mini td.empty:first-child {
    border-left: 1px solid #ededed; }

/* line 110, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-calendar.view-display-id-panel_pane_1 .calendar-calendar tr {
  background: none;
  border: 0; }

/* line 114, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-calendar.view-display-id-panel_pane_1 .calendar-calendar th.days {
  background: none;
  border: none;
  color: white;
  color: #B7BDC3;
  font-size: 1em;
  text-transform: uppercase;
  line-height: 2em;
  font-family: "lato", Helvetica, Arial, sans-serif;
  font-weight: 400; }

/* line 125, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-calendar.view-display-id-panel_pane_1 table.mini {
  text-align: center; }
  /* line 127, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 table.mini thead {
    background: #0d407a;
    color: #efefef;
    box-shadow: 0 1px 3px rgba(255, 255, 255, 0.1); }
  /* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 table.mini td {
    line-height: 2.5em;
    font-weight: 600;
    font-size: 1.2em;
    position: relative; }
    /* line 138, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-calendar.view-display-id-panel_pane_1 table.mini td.today a, .view-calendar.view-display-id-panel_pane_1 table.mini td.today .mini-day-off {
      border-radius: 100% !important;
      background: #0f4788;
      color: white !important;
      text-decoration: none;
      line-height: 2em;
      text-align: center;
      width: 2em;
      display: inline-block;
      vertical-align: middle;
      box-shadow: 1px 1px 1px #888;
      position: relative;
      z-index: 300;
      font-size: .8em;
      font-weight: bold; }
    /* line 156, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-calendar.view-display-id-panel_pane_1 table.mini td.has-events a {
      color: #A30234;
      text-decoration: none;
      font-weight: 600;
      transition: all .2s ease-in-out; }
      /* line 161, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-calendar.view-display-id-panel_pane_1 table.mini td.has-events a:hover {
        font-size: 1.2em; }
    /* line 165, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-calendar.view-display-id-panel_pane_1 table.mini td.has-events .calendar-empty {
      display: block;
      width: 10px;
      position: absolute;
      color: #fff;
      padding: 1px 0 4px;
      text-align: center;
      font-family: arial, sans-serif;
      font-weight: bold;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
      top: 1px;
      right: 2px;
      background: #A30234; }
      /* line 178, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-calendar.view-display-id-panel_pane_1 table.mini td.has-events .calendar-empty:before {
        display: block;
        width: 10px;
        height: 0;
        position: absolute;
        top: 3px;
        content: "";
        border-top: 1px dotted rgba(0, 0, 0, 0.2);
        border-bottom: 1px dotted rgba(255, 255, 255, 0.3); }
      /* line 188, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-calendar.view-display-id-panel_pane_1 table.mini td.has-events .calendar-empty:after {
        display: block;
        width: 0;
        height: 0;
        content: "";
        position: relative;
        bottom: -10px;
        z-index: 100;
        border-width: 0 5px 7px 5px;
        border-style: solid;
        border-color: #A30234 #A30234 transparent #A30234; }
  /* line 204, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 table.mini tr:nth-child(even) td.mini:nth-child(even) {
    color: #AAB8BB;
    background: #fff;
    text-align: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }
  /* line 210, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 table.mini tr:nth-child(even) td.mini:nth-child(odd) {
    color: #A4ADB4;
    background: #FCFCFC;
    text-align: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }
  /* line 218, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 table.mini tr:nth-child(odd) td.mini:nth-child(odd) {
    color: #AAB8BB;
    background: #fff;
    text-align: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }
  /* line 225, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 table.mini tr:nth-child(odd) td.mini:nth-child(even) {
    color: #A4ADB4;
    background: #FCFCFC;
    text-align: center;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1); }
  /* line 234, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-calendar.view-display-id-panel_pane_1 table.mini tr:last-child {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important; }
    /* line 237, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-calendar.view-display-id-panel_pane_1 table.mini tr:last-child td:first-child {
      border-bottom-left-radius: 16px !important; }
    /* line 240, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-calendar.view-display-id-panel_pane_1 table.mini tr:last-child td:last-child {
      border-bottom-right-radius: 16px !important; }
    /* line 243, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-calendar.view-display-id-panel_pane_1 table.mini tr:last-child td.empty {
      box-shadow: inset 0px -3px 2px -2px rgba(0, 0, 0, 0.1) !important; }
      /* line 247, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-calendar.view-display-id-panel_pane_1 table.mini tr:last-child td.empty:last-child {
        box-shadow: inset -2px -3px 2px -2px rgba(0, 0, 0, 0.1) !important; }

/* featured products pane */
/* line 258, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-store.view-display-id-panel_pane_1 .flex-direction-nav a.flex-next, .view-spotlight .flex-direction-nav a.flex-next {
  left: 100% !important;
  right: initial !important;
  line-height: 3em;
  text-align: center; }
  /* line 263, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-store.view-display-id-panel_pane_1 .flex-direction-nav a.flex-next:before, .view-spotlight .flex-direction-nav a.flex-next:before {
    content: '\f105';
    font-family: 'fontAwesome'; }

/* line 268, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-store.view-display-id-panel_pane_1 .flex-direction-nav a.flex-prev, .view-spotlight .flex-direction-nav a.flex-prev {
  right: 100% !important;
  left: initial !important;
  line-height: 3em;
  text-align: center; }
  /* line 273, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-store.view-display-id-panel_pane_1 .flex-direction-nav a.flex-prev:before, .view-spotlight .flex-direction-nav a.flex-prev:before {
    content: '\f104';
    font-family: 'fontAwesome'; }

/* line 282, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.illustration-v2 .add-to-cart i {
  vertical-align: middle;
  padding-right: 18px; }

/* line 286, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.illustration-v2 .add-to-cart a {
  background: none;
  border: none;
  color: #555;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.9em;
  padding: 0;
  margin: 0;
  vertical-align: middle; }

/* line 297, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.illustration-v2 .add-to-cart .field-name-field-product {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0; }
  /* line 302, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .illustration-v2 .add-to-cart .field-name-field-product .form-wrapper {
    margin: 0; }
  /* line 305, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .illustration-v2 .add-to-cart .field-name-field-product .attribute-widgets {
    display: none; }
  /* line 308, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .illustration-v2 .add-to-cart .field-name-field-product button {
    background: none;
    border: none;
    color: #555;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    padding: 0;
    margin: 0;
    display: block;
    vertical-align: top; }

/* line 323, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.illustration-v2 .rating-price .commerce-product-field {
  margin: 0;
  padding: 0; }

/* line 329, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.illustration-v2:hover .add-to-cart:hover {
  background: #A30234;
  background: rgba(163, 2, 52, 0.7); }
  /* line 333, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .illustration-v2:hover .add-to-cart:hover .field-name-field-product button {
    color: #fff;
    background: none; }
  /* line 338, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .illustration-v2:hover .add-to-cart:hover a {
    color: #fff;
    text-decoration: none; }

/* line 346, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-spotlight {
  margin-bottom: 3em; }
  /* line 348, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-spotlight .slides > li {
    overflow: hidden; }
    /* line 350, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-spotlight .slides > li .field-name-field-media {
      min-height: 250px;
      display: table;
      width: 100%;
      position: relative;
      z-index: 1; }
      /* line 356, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-spotlight .slides > li .field-name-field-media .field-items {
        display: table-cell;
        vertical-align: middle; }
        /* line 359, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
        .view-spotlight .slides > li .field-name-field-media .field-items img {
          width: auto;
          margin: auto; }
    /* line 365, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-spotlight .slides > li .color-overlay {
      position: absolute;
      top: 100%;
      right: -100%;
      bottom: -100%;
      left: 100%;
      opacity: 0.8;
      z-index: 10;
      transition: all .1s ease-in .1s; }
    /* line 375, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-spotlight .slides > li .field-name-body {
      position: absolute;
      top: 100%;
      left: 100%;
      font-size: 2em;
      font-weight: bold;
      padding: 1.5em;
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      right: -100%;
      bottom: -100%;
      z-index: 11;
      -webkit-transition: all .1s ease-out 0s;
      transition: all .1s ease-out 0s; }
      /* line 388, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-spotlight .slides > li .field-name-body .field-items {
        display: table;
        height: 100%;
        width: 100%; }
        /* line 392, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
        .view-spotlight .slides > li .field-name-body .field-items .field-item {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          color: white; }
          /* line 397, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
          .view-spotlight .slides > li .field-name-body .field-items .field-item p {
            color: white; }
    /* line 402, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-spotlight .slides > li a.action-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20; }
    /* line 411, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-spotlight .slides > li:hover .color-overlay {
      transition: all .1s ease-out 0s;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    /* line 418, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-spotlight .slides > li:hover .field-name-body {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all .2s ease-in 0s; }

/* line 430, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.not-logged-in .view-spotlight .field-name-field-media {
  min-height: 170px; }

/* recent news pane */
/* line 439, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-news.view-display-id-panel_pane_1 .views-row {
  clear: both;
  border-bottom: 1px dashed #ECECEC;
  padding: 1em 0;
  margin: 0 1em; }
  /* line 444, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-news.view-display-id-panel_pane_1 .views-row:last-child {
    border: 0; }
  /* line 447, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-news.view-display-id-panel_pane_1 .views-row .views-field-title {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 1.3em;
    font-weight: 300;
    min-height: 66px;
    display: table; }
    /* line 453, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-news.view-display-id-panel_pane_1 .views-row .views-field-title .field-content {
      display: table-cell;
      vertical-align: middle; }
      /* line 456, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .view-news.view-display-id-panel_pane_1 .views-row .views-field-title .field-content a {
        display: block; }
  /* line 461, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-news.view-display-id-panel_pane_1 .views-row .date-wrapper {
    float: left;
    margin-right: 1em;
    text-align: right;
    font-size: 2em;
    width: 3em;
    font-family: "Cabin", Helvetica, Arial, sans-serif;
    font-weight: bold; }
    /* line 469, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-news.view-display-id-panel_pane_1 .views-row .date-wrapper .day {
      display: block;
      color: #8C8A8A;
      font-family: "open sans";
      line-height: 1.7em;
      font-weight: 300; }
    /* line 476, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-news.view-display-id-panel_pane_1 .views-row .date-wrapper .month {
      display: block;
      color: #A0A0A0;
      padding-bottom: 2px;
      -webkit-transition: all .2s;
      transition: all .2s;
      font-size: .7em;
      text-transform: uppercase;
      font-family: "roboto";
      letter-spacing: -1px;
      font-weight: 300;
      line-height: .4em; }
    /* line 489, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .view-news.view-display-id-panel_pane_1 .views-row .date-wrapper a:hover {
      text-decoration: none; }
  /* line 494, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .view-news.view-display-id-panel_pane_1 .views-row:hover .month {
    /*background: darken($naac-blue, 5%);*/ }

/* mailchimp block */
/* line 504, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#block-mailchimp-signup-homepage-signup .form-group {
  margin-bottom: .8em; }

/* line 507, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#block-mailchimp-signup-homepage-signup input {
  background: #3C3C3C;
  border: 4px solid #444;
  border-radius: 4px !important;
  color: white;
  text-shadow: none;
  font-weight: normal; }

/* line 515, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#block-mailchimp-signup-homepage-signup button {
  background: #0F4788;
  color: white;
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-weight: bold;
  transition: all .2s ease-in;
  border-color: #0e4583; }
  /* line 522, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  #block-mailchimp-signup-homepage-signup button:hover {
    background: #0c396c; }

/* bootstrap elements */
/* line 529, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.content-well .region-content {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }

/* line 539, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tag-box h3, .tag-box .tag-box-title {
  margin-top: -.5em;
  color: #A30234; }

/* line 543, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tab-v2 .tab-content {
  background: #fff; }

/* line 546, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
li.list-unstyled {
  padding-left: 15px; }

/* line 547, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.pagination li a:hover {
  color: #fff;
  background: #0F4788;
  border-color: #0F4788; }

/* line 555, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tabs-left > .tab-content:after {
  top: 80%;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute;
  right: 5px;
  transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -webkit-transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
  -moz-box-shadow: 0 15px 10px #999;
  -webkit-box-shadow: 0 15px 10px #999; }

/* line 576, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.vertical-tabs-panes > fieldset > div.box-shadow {
  border: 0 !important; }
  /* line 578, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .vertical-tabs-panes > fieldset > div.box-shadow:before, .vertical-tabs-panes > fieldset > div.box-shadow:after {
    display: none; }
  /* line 581, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .vertical-tabs-panes > fieldset > div.box-shadow > .panel-body > p.help-block {
    font-weight: bold;
    text-transform: uppercase;
    text-align: Center;
    font-size: 1.5em;
    padding: .5em;
    display: block;
    text-decoration: none;
    position: relative;
    z-index: 10;
    transition: all .3s ease-in-out;
    border-left: 1px solid #ededed;
    border-top: 1px solid #ededed; }
    /* line 594, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .vertical-tabs-panes > fieldset > div.box-shadow > .panel-body > p.help-block {
      color: #323232;
      color: rgba(50, 50, 50, 0.9);
      border-top: 1px solid #ededed;
      background: #FFE97B;
      background: repeating-linear-gradient(45deg, #FFE97B, #FFE97B 10px, #FFD500 10px, #FFD500 20px);
      text-shadow: 0 1px rgba(255, 255, 255, 0.6);
      margin-top: -26px;
      margin-bottom: 1em;
      margin-left: -32px;
      margin-right: -32px; }

/* line 608, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tab-v1 .nav-tabs > li > a:hover {
  color: #fff;
  background: #0F4788; }

/* line 612, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tabs-left > .nav-tabs {
  box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.3); }

/* line 615, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tabs-right > .nav-tabs {
  box-shadow: inset 2px -2px 4px rgba(0, 0, 0, 0.3); }

/* line 618, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tabs-left > .nav-tabs, .tabs-right > .nav-tabs {
  background: #4F4F4F;
  padding-bottom: 0; }
  /* line 622, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .tabs-left > .nav-tabs li > a, .tabs-right > .nav-tabs li > a {
    color: #c8c8c8;
    font-family: "open sans", sans-serif;
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: bold;
    transition: all .5s;
    margin: 0 !important; }
    /* line 630, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .tabs-left > .nav-tabs li > a:hover, .tabs-right > .nav-tabs li > a:hover {
      background: #444;
      border-color: #3c3c3c !important;
      padding-left: 1.5em;
      color: white !important; }
    /* line 636, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .tabs-left > .nav-tabs li > a .summary, .tabs-right > .nav-tabs li > a .summary {
      text-transform: none;
      color: #A1A1A1;
      font-weight: normal;
      font-size: 12px;
      font-family: "lato", Helvetica, Arial, sans-serif; }
  /* line 644, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .tabs-left > .nav-tabs li.active > a, .tabs-left > .nav-tabs li.active > a:hover, .tabs-left > .nav-tabs li.active > a:focus, .tabs-left > .nav-tabs li.active:hover > a, .tabs-right > .nav-tabs li.active > a, .tabs-right > .nav-tabs li.active > a:hover, .tabs-right > .nav-tabs li.active > a:focus, .tabs-right > .nav-tabs li.active:hover > a {
    padding-left: 2em;
    color: #A30234 !important;
    background: white !important;
    border-left: 2px solid #A30234 !important; }
  /* line 650, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .tabs-left > .nav-tabs li.active a:hover, .tabs-right > .nav-tabs li.active a:hover {
    padding-left: 2em;
    border-color: #ddd transparent #ddd #A30234 !important; }

/* fieldsets */
/* line 658, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.panel-title {
  line-height: 1.5em; }

/* line 661, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
legend.panel-heading {
  position: relative;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
  z-index: 10; }

/* line 669, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
fieldset .panel-body {
  display: block; }

/* line 672, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
a.fieldset-legend, a.accordion-toggle {
  position: relative;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0; }
  /* line 678, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  a.fieldset-legend[data-toggle="collapse"], a.accordion-toggle[data-toggle="collapse"] {
    display: block;
    padding-left: 2.5em !important;
    margin: 0 -1em !important; }
    /* line 682, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    a.fieldset-legend[data-toggle="collapse"]:before, a.accordion-toggle[data-toggle="collapse"]:before {
      content: "\f055";
      font-family: 'FontAwesome';
      float: left;
      margin-right: 1em;
      position: absolute;
      left: 10px;
      font-size: 1.5em;
      line-height: 1em;
      color: #A9A9A9;
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5); }

/* admin stuffz */
/* line 699, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body > .wrapper.contextual-links-region {
  position: static; }

/* line 702, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.ctools-modal-dialog {
  overflow-y: scroll; }
  /* line 704, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .ctools-modal-dialog .modal-content {
    float: left; }
  /* line 707, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .ctools-modal-dialog .modal-body {
    height: auto !important;
    float: left; }
    /* line 710, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    .ctools-modal-dialog .modal-body .panels-add-content-modal {
      float: left; }

/* panels */
/* line 717, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.radix-layouts-sidebar .pane-title {
  text-align: center; }

/* sticky footer */
/* line 724, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
html {
  position: relative;
  min-height: 100%; }

/* responsive images */
/* line 765, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
img {
  max-width: 100% !important;
  height: auto !important; }

/* wysiwyg AR Loves it */
/* line 768, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.cke_wysiwyg_div {
  min-height: 100px; }

/* line 771, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.cke_editable {
  padding: 0;
  margin: 0; }

/* line 778, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
h2.wysiwyg-red, h3.wysiwyg-red, h4.wysiwyg-red, span.wysiwyg-red {
  color: #A30234; }

/* line 782, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
h2.wysiwyg-blue, h3.wysiwyg-blue, h4.wysiwyg-blue, span.wysiwyg-blue {
  color: #0F4788; }

/* line 786, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
h2.wysiwyg-purple, h3.wysiwyg-purple, h4.wysiwyg-purple, span.wysiwyg-purple {
  color: #4F2270; }

/* line 790, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
h2.wysiwyg-yellow, h3.wysiwyg-yellow, h4.wysiwyg-yellow, span.wysiwyg-yellow {
  color: #FFC71F; }

/* line 795, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#edit-submit-hidden {
  height: 0;
  width: 0;
  overflow: hidden;
  padding: 0;
  border: none; }

/* line 803, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#quiz-question-answering-form > div > div {
  display: inline-block;
  margin-right: .5em;
  vertical-align: middle; }
  /* line 807, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  #quiz-question-answering-form > div > div > em {
    font-weight: 600;
    color: #A30234; }

/* line 813, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
div.jp-audio {
  width: 100% !important; }

/* line 817, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.align-left {
  text-align: left !important; }

/* line 820, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.align-center {
  text-align: center !important; }

/* line 823, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.align-right {
  text-align: right !important; }

/* line 826, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.align-justify {
  text-align: justify !important; }

/* line 834, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
img.align-left, span.cke_widget_inline.align-left {
  float: left;
  margin: 1em 1em 1em 0; }

/* line 838, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
img.align-center, span.cke_widget_inline.align-center {
  display: block;
  margin: 1em auto; }

/* line 842, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
img.align-right, span.cke_widget_inline.align-right {
  float: right;
  margin: 1em 0 1em 1em; }

/* line 849, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.vert-wrap {
  display: table; }
  /* line 851, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .vert-wrap .vert-center {
    display: table-cell;
    vertical-align: middle; }

/* line 856, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.block {
  display: block !important; }

/* line 861, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
a.lexicon-term {
  border-bottom: 1px dotted #337AB7;
  color: #337AB7;
  cursor: help; }

/* line 867, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.tabledrag-changed {
  color: #A30234; }

/* line 871, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
div.container-inline {
  display: inline; }
  /* line 873, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  div.container-inline button {
    vertical-align: top;
    display: inline-block;
    margin-right: 3px; }

/* line 879, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
div.form-item-more-object-type {
  display: inline-block; }

/* line 882, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#edit-submit {
  vertical-align: top; }

/* forms */
/* line 887, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
div.form-type-radio, div.form-type-checkbox {
  margin-left: 1em; }

/* tables */
/* lexicon */
/* line 894, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.lexicon-alphabar-instructions {
  font-size: 0.9em;
  text-align: center;
  font-style: italic;
  margin-bottom: 1em; }

/* line 900, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.lexicon-links {
  background: none;
  text-align: center;
  color: #CCCCCC;
  font-size: 2em;
  font-weight: 300; }
  /* line 906, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .lexicon-links a {
    font-size: 1em;
    text-transform: uppercase;
    font-weight: 600; }

/* line 913, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.lexicon-list dd {
  margin-bottom: 1em; }

/* news */
/* line 920, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.view-display-id-block_1 .views-row {
  position: relative; }

/* line 927, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#verify-certification-form .form-type-textfield {
  max-width: 480px; }

/* line 930, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
#verify-certification-form .table-striped {
  margin-top: 1em; }

/* Contact form. */
/* line 937, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.webform-client-form-8 .webform-component--questions---comments {
  clear: both; }

/* line 941, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.webform-client-form-8 .webform-component-markup {
  padding-bottom: 2em; }

@media (min-width: 480px) {
  /* line 946, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .webform-client-form-8 .webform-component-textfield,
  .webform-client-form-8 .webform-component-email {
    position: relative;
    float: left;
    width: 33.33333%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
  /* line 951, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .webform-client-form-8 .webform-component--name {
    padding-left: 0; }
  /* line 955, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .webform-client-form-8 .webform-component--phone {
    padding-right: 0; } }

/* line 961, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.webform-client-form-8 .webform-component-textarea {
  clear: both;
  padding-top: 2em;
  padding-bottom: 2em; }

/* line 969, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.page-node-edit .ui-dialog, .page-node-edit .ui-widget {
  z-index: 401; }

/* line 972, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.page-node-edit .ui-widget-overlay {
  z-index: 400; }

/* sub nav */
/* line 979, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.subnav .content-std {
  margin-top: 21px; }

/* line 982, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.subnav .pane-main-menu {
  position: relative; }
  @media (min-width: 768px) {
    /* line 982, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    body.subnav .pane-main-menu {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      margin-top: -61px; } }
  /* line 992, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  body.subnav .pane-main-menu:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: -10000px;
    right: 10000px;
    width: 100000px;
    box-shadow: inset 0 8px 20px #eee, 0 1px 1px #bbb, 0 -1px 0px #DCDCDC;
    background: #F3F3F3; }
  /* line 1003, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  body.subnav .pane-main-menu li {
    vertical-align: middle; }
    /* line 1005, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
    body.subnav .pane-main-menu li > a {
      font-family: "roboto condensed", sans-serif;
      font-size: .9em; }

/* line 1012, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
body.subnav.admin-menu .pane-main-menu {
  margin-top: -102px; }

/* line 1019, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.region-hero-slider .block {
  box-shadow: inset 0 8px 20px #eee, 0 1px 1px #bbb, 0 -1px 0px #DCDCDC;
  background: #F3F3F3; }
  /* line 1022, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
  .region-hero-slider .block .menu-block-wrapper {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
    /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .region-hero-slider .block .menu-block-wrapper:before, .region-hero-slider .block .menu-block-wrapper:after {
      content: " ";
      display: table; }
    /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .region-hero-slider .block .menu-block-wrapper:after {
      clear: both; }
    @media (min-width: 768px) {
      /* line 1025, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .region-hero-slider .block .menu-block-wrapper {
        width: 750px; } }
    @media (min-width: 992px) {
      /* line 1030, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .region-hero-slider .block .menu-block-wrapper {
        width: 970px; } }
    @media (min-width: 1200px) {
      /* line 1035, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
      .region-hero-slider .block .menu-block-wrapper {
        width: 1170px; } }

/* line 1043, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.ani-stripes {
  animation: progress-bar-stripes 2s linear infinite;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  cursor: default;
  clear: both; }

/* line 1055, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.progress-stacked {
  position: relative !important; }

/* line 1058, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_custom.scss */
.progress-remaining {
  opacity: .5; }

/* hero slider */
/* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
.slider-inner .content {
  padding: 0; }

/* line 6, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
.slider-inner .contextual-links-wrapper {
  top: 110px; }

/* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
.flexslider {
  border: 0;
  padding: 0;
  margin: 0; }

/* line 15, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
.pane-hero-slider {
  z-index: 1;
  padding: 70px 0;
  position: relative;
  background-size: cover; }

/* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
.view-hero-slider, .view-node-hero {
  position: relative; }
  /* line 25, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider .flex-direction-nav a, .view-node-hero .flex-direction-nav a {
    width: auto;
    height: auto;
    font-size: 0;
    line-height: 1; }
  /* line 31, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider .flex-direction-nav a:before, .view-node-hero .flex-direction-nav a:before {
    color: #fff !important;
    content: '\f053';
    font-family: "fontawesome"; }
  /* line 36, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider .flex-direction-nav a.flex-next:before, .view-node-hero .flex-direction-nav a.flex-next:before {
    content: '\f054'; }
  /* line 40, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider .flexslider .slides .slide-content-wrapper, .view-node-hero .flexslider .slides .slide-content-wrapper {
    bottom: 3em;
    top: 0; }
    /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
    .view-hero-slider .flexslider .slides .slide-content-wrapper .slide-content, .view-node-hero .flexslider .slides .slide-content-wrapper .slide-content {
      padding: 0;
      width: 85% !important; }
      @media (max-width: 767px) {
        /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .flexslider .slides .slide-content-wrapper .slide-content, .view-node-hero .flexslider .slides .slide-content-wrapper .slide-content {
          font-size: .8em; } }
      /* line 49, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
      .view-hero-slider .flexslider .slides .slide-content-wrapper .slide-content h2, .view-node-hero .flexslider .slides .slide-content-wrapper .slide-content h2 {
        font-size: 1.8em;
        margin: 0; }
      /* line 53, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
      .view-hero-slider .flexslider .slides .slide-content-wrapper .slide-content button, .view-node-hero .flexslider .slides .slide-content-wrapper .slide-content button {
        margin: 3em 0 0 0;
        display: inline-block;
        transition: all .2s ease-in; }
        /* line 57, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .flexslider .slides .slide-content-wrapper .slide-content button a, .view-node-hero .flexslider .slides .slide-content-wrapper .slide-content button a {
          background: none;
          display: inline-block;
          padding: .25em;
          color: #fff !important;
          color: rgba(255, 255, 255, 0.8) !important;
          font-family: "open sans";
          font-weight: 200;
          font-size: 1.2em; }
          @media (max-width: 767px) {
            /* line 57, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
            .view-hero-slider .flexslider .slides .slide-content-wrapper .slide-content button a, .view-node-hero .flexslider .slides .slide-content-wrapper .slide-content button a {
              font-size: 1em; } }
      /* line 71, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
      .view-hero-slider .flexslider .slides .slide-content-wrapper .slide-content img, .view-node-hero .flexslider .slides .slide-content-wrapper .slide-content img {
        height: auto;
        width: auto; }
  /* line 78, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider .attachment-after, .view-node-hero .attachment-after {
    position: absolute;
    z-index: 110;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-bottom: 0;
    text-align: center;
    font-size: 18px;
    line-height: 1;
    background: #2c2c2c;
    background: rgba(0, 0, 0, 0.5); }
    /* line 91, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
    .view-hero-slider .attachment-after ul.slides, .view-node-hero .attachment-after ul.slides {
      width: 100% !important;
      transform: none !important;
      /*padding-bottom: 10px;*/ }
      /* line 95, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
      .view-hero-slider .attachment-after ul.slides > li, .view-node-hero .attachment-after ul.slides > li {
        font-size: 1.4em;
        width: 116px !important;
        display: inline-block !important;
        float: none !important;
        margin-right: 1em;
        background: none;
        color: #D7D7D7;
        color: rgba(255, 255, 255, 0.6);
        font-weight: bold;
        transition: all .2s ease-in;
        position: relative !important;
        vertical-align: top;
        border: 0;
        border-bottom: 0;
        border-radius: 12px 12px 0 0;
        padding: .6em 0;
        line-height: 1;
        cursor: pointer; }
        @media (max-width: 767px) {
          /* line 95, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
          .view-hero-slider .attachment-after ul.slides > li, .view-node-hero .attachment-after ul.slides > li {
            width: auto !important;
            font-size: 1em; }
            /* line 117, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
            .view-hero-slider .attachment-after ul.slides > li a, .view-node-hero .attachment-after ul.slides > li a {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              color: transparent;
              text-indent: -99999px;
              overflow: hidden; }
            /* line 127, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
            .view-hero-slider .attachment-after ul.slides > li i, .view-node-hero .attachment-after ul.slides > li i {
              padding: .5em; } }
        /* line 131, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .attachment-after ul.slides > li:hover, .view-node-hero .attachment-after ul.slides > li:hover {
          color: #fff; }
          /* line 133, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
          .view-hero-slider .attachment-after ul.slides > li:hover i, .view-hero-slider .attachment-after ul.slides > li:hover img, .view-node-hero .attachment-after ul.slides > li:hover i, .view-node-hero .attachment-after ul.slides > li:hover img {
            /*transform: scale(1.2);*/ }
          /* line 136, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
          .view-hero-slider .attachment-after ul.slides > li:hover a, .view-node-hero .attachment-after ul.slides > li:hover a {
            color: #fff;
            /*padding-bottom: 1.3em;*/ }
        /* line 141, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .attachment-after ul.slides > li.flex1-active-slide, .view-node-hero .attachment-after ul.slides > li.flex1-active-slide {
          color: #A30234 !important; }
          /* line 143, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
          .view-hero-slider .attachment-after ul.slides > li.flex1-active-slide a, .view-node-hero .attachment-after ul.slides > li.flex1-active-slide a {
            color: #757575 !important;
            font-weight: 600;
            /* padding-bottom: 1.3em;*/ }
          /* line 150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
          .view-hero-slider .attachment-after ul.slides > li.flex1-active-slide i, .view-hero-slider .attachment-after ul.slides > li.flex1-active-slide img, .view-node-hero .attachment-after ul.slides > li.flex1-active-slide i, .view-node-hero .attachment-after ul.slides > li.flex1-active-slide img {
            -webkit-animation: throb 1.2s ease-out;
            -moz-animation: throb 800ms ease-out;
            -o-animation: throb 800ms ease-out;
            animation: throb 1.2s ease-out;
            transform: scale(1.2);
            opacity: 1; }
          /* line 158, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
          .view-hero-slider .attachment-after ul.slides > li.flex1-active-slide:after, .view-node-hero .attachment-after ul.slides > li.flex1-active-slide:after {
            content: '';
            position: absolute;
            bottom: -100%;
            top: 0;
            right: 0;
            left: 0;
            border-top: 4px solid #A30234;
            background: #fff;
            transition: all .2s ease-out;
            opacity: 1;
            z-index: -1;
            visibility: visible; }
        /* line 173, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .attachment-after ul.slides > li:after, .view-node-hero .attachment-after ul.slides > li:after {
          content: '';
          position: absolute;
          left: -100%;
          right: 100%;
          top: 0;
          bottom: 0;
          background: none;
          transition: all .2s ease-in;
          opacity: 0;
          visibility: hidden; }
        /* line 185, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .attachment-after ul.slides > li a, .view-node-hero .attachment-after ul.slides > li a {
          color: #D7D7D7;
          color: rgba(255, 255, 255, 0.6);
          line-height: 1.2em;
          display: block;
          width: 100%;
          font-size: .5em;
          transition: all .2s ease-in;
          text-decoration: none !important;
          font-family: "Open Sans", Helvetica, Arial, sans-serif;
          font-weight: 200;
          text-transform: uppercase;
          margin-top: .8em; }
        /* line 200, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .attachment-after ul.slides > li i, .view-node-hero .attachment-after ul.slides > li i {
          transition: transform .2s ease-in;
          display: block;
          font-size: .9em;
          position: relative; }
        /* line 210, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
        .view-hero-slider .attachment-after ul.slides > li img, .view-node-hero .attachment-after ul.slides > li img {
          position: absolute;
          left: .5em;
          top: .35em;
          height: 1.9em;
          width: 1.9em;
          opacity: .8;
          transition: transform .2s ease-in; }
  /* line 222, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider.view-hero-slider .slides img, .view-node-hero.view-hero-slider .slides img {
    min-width: 100%;
    min-height: 70vh;
    width: auto;
    height: auto; }
    @media (max-width: 991px) {
      /* line 222, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
      .view-hero-slider.view-hero-slider .slides img, .view-node-hero.view-hero-slider .slides img {
        filter: blur(6px) !important; } }
  /* line 231, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider.view-node-hero .slides img, .view-node-hero.view-node-hero .slides img {
    min-height: 0; }
    @media (max-width: 991px) {
      /* line 231, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
      .view-hero-slider.view-node-hero .slides img, .view-node-hero.view-node-hero .slides img {
        filter: blur(6px) !important;
        min-height: 50vh;
        height: auto; } }
  /* line 239, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider .field-name-body, .view-node-hero .field-name-body {
    line-height: 1.2em; }
  /* line 242, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider div.hero-slide-link, .view-node-hero div.hero-slide-link {
    z-index: 102; }
  /* line 245, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  .view-hero-slider .field-name-field-image-attribution, .view-node-hero .field-name-field-image-attribution {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 10px;
    width: auto;
    color: #A1A1A1; }
    /* line 252, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
    .view-hero-slider .field-name-field-image-attribution a, .view-node-hero .field-name-field-image-attribution a {
      color: #e0e0e0; }
    /* line 255, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
    .view-hero-slider .field-name-field-image-attribution:hover, .view-node-hero .field-name-field-image-attribution:hover {
      color: #ededed; }
      /* line 257, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
      .view-hero-slider .field-name-field-image-attribution:hover a, .view-node-hero .field-name-field-image-attribution:hover a {
        color: #fff; }

@media (min-width: 768px) {
  /* line 270, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper .field-name-body {
    font-size: 18px; }
  /* line 274, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper h2 {
    font-size: 30px;
    margin: 10px 0; }
  /* line 279, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper .field-name-body, #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper button {
    display: block; }
  /* line 283, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper button {
    margin: 3em 0 0 0;
    display: inline-block;
    transition: all .2s ease-in; }
    /* line 287, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
    #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper button a {
      background: none;
      display: inline-block;
      padding: .25em;
      color: #fff !important;
      font-family: "open sans";
      font-weight: 200;
      text-transform: uppercase;
      font-size: 1.2em; } }

@media (min-width: 992px) {
  /* line 322, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper .field-name-body {
    max-width: 600px;
    margin: 0 auto; }
  /* line 327, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  #block-views-hero-slider-block-1 .view-hero-slider .view-content .slide-content-wrapper h2 {
    font-size: 35px;
    margin: 40px 0 10px 0;
    padding-top: 20px; } }

/* line 346, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
body.hero-slide #block-views-node-hero-block .slide-content {
  padding: 0; }

/* line 350, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
body.hero-slide #block-views-node-hero-block .slide-content-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;
  text-align: center; }

/* line 361, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
body.hero-slide #block-views-node-hero-block h2 {
  font-size: 24px; }

/* line 365, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
body.hero-slide #block-views-node-hero-block .field-name-body {
  display: none; }

@media (min-width: 768px) {
  /* line 372, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  body.hero-slide #block-views-node-hero-block h2 {
    font-size: 28px; }
  /* line 376, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  body.hero-slide #block-views-node-hero-block .field-name-body {
    display: block;
    font-size: 18px; } }

@media (min-width: 992px) {
  /* line 386, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  body.hero-slide #block-views-node-hero-block .slide-content-wrapper {
    margin-top: 40px; }
  /* line 389, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  body.hero-slide #block-views-node-hero-block h2 {
    font-size: 32px; }
  /* line 393, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_slider.scss */
  body.hero-slide #block-views-node-hero-block .field-name-body {
    font-size: 24px; } }

/* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
.footer-v1.footer-v1 {
  color: #fff; }
  /* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 .footer {
    margin-top: 0 !important; }
  /* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 a {
    color: #fff; }
    /* line 16, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 a:hover {
      color: #A30234;
      background-color: transparent !important; }
  /* line 26, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-block-1 p, .footer-v1.footer-v1 #block-block-1 .media {
    margin: 0; }
  /* line 29, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-block-1 .file {
    margin-bottom: 1em; }
  /* line 35, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-views-news-block-1 {
    font-size: 1.2em;
    line-height: 1.3; }
    /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-views-news-block-1 .views-row {
      margin: 0.5em 0; }
    /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-views-news-block-1 .views-field-created {
      font-size: 0.8em; }
  /* line 50, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-menu-menu-useful-links a {
    padding: 0.25em 0; }
  /* line 57, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-block-4 a {
    margin: 0.5em 0;
    display: block; }
    /* line 61, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-block-4 a:hover {
      border: none; }
  /* line 65, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-block-4 p {
    margin: 0; }
  /* line 71, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 .copyright {
    text-align: center; }
  /* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-block-3 {
    margin-bottom: 1em; }
    /* line 78, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-block-3 p {
      margin: 0; }
    /* line 81, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-block-3 a:first-child {
      margin-left: 0; }
  /* line 89, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-follow-site a {
    float: none;
    display: inline-block; }
  /* line 96, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 .col-md-3, .footer-v1.footer-v1 .col-md-3 .margin-bottom-40 {
    margin-bottom: 0; }

@media (min-width: 640px) {
  /* line 103, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  html, body {
    min-height: 100%; }
  /* line 107, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  body {
    margin-bottom: 500px;
    position: static; }
  /* line 112, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    /* line 119, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 .col-md-3 {
      position: relative;
      float: left;
      width: 37.5%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      /* line 122, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
      .footer-v1.footer-v1 .col-md-3:first-child {
        float: none;
        width: 100%; }
      /* line 126, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
      .footer-v1.footer-v1 .col-md-3:nth-child(3) {
        position: relative;
        float: left;
        width: 25%;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px; }
    /* line 133, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-menu-menu-useful-links a {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    /* line 142, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 .copyright {
      text-align: left; }
      /* line 144, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
      .footer-v1.footer-v1 .copyright .col-md-6 {
        position: relative;
        float: left;
        width: 50%;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px; }
    /* line 150, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-block-3 {
      margin-bottom: 0; }
    /* line 155, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-follow-site {
      margin-top: 0.8em;
      text-align: right; } }

@media (min-width: 992px) {
  /* line 163, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  body {
    margin-bottom: 390px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    /* line 163, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    body {
      margin-bottom: 400px; } }

@media (min-width: 992px) {
  /* line 173, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 .col-md-3, .footer-v1.footer-v1 .col-md-3:first-child {
    position: relative;
    float: left;
    width: 25%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
  /* line 176, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 .col-md-3 .headline {
    margin-top: 0; }
  /* line 182, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-block-3 {
    margin-top: 0.5em; }
    /* line 184, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
    .footer-v1.footer-v1 #block-block-3 p {
      display: inline-block; }
  /* line 190, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_footer.scss */
  .footer-v1.footer-v1 #block-follow-site {
    margin-top: 0.2em; } }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
.entitytype-accreditation-form {
  margin-top: 2em; }
  /* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .nav-tabs {
    counter-reset: navtabscount; }
    /* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .nav-tabs li a:before {
      content: counter(navtabscount);
      counter-increment: navtabscount;
      color: rgba(255, 255, 255, 0.1); }
  /* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form #edit-actions {
    text-align: right; }
  /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-group-accordion-wrapper {
    margin-top: 1em; }
  /* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .multipage-pane-wrapper {
    border: 1px solid #dddddd;
    padding: 1em;
    margin-bottom: 1em; }
  /* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .multipage-counter {
    display: none; }
  /* line 26, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .multipage-controls-list .multipage-button {
    float: none; }
    /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .entitytype-accreditation-form .multipage-controls-list .multipage-button:before, .entitytype-accreditation-form .multipage-controls-list .multipage-button:after {
      content: " ";
      display: table; }
    /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .entitytype-accreditation-form .multipage-controls-list .multipage-button:after {
      clear: both; }
  /* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .multipage-controls-list .multipage-link-previous {
    float: left;
    opacity: 0.5;
    transition: border-color 0.2s, opacity 0.2s; }
    /* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .multipage-controls-list .multipage-link-previous:hover {
      opacity: 1; }
  /* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .multipage-controls-list .multipage-link-next, .entitytype-accreditation-form .multipage-controls-list .form-actions, .entitytype-accreditation-form .multipage-controls-list #edit-actions {
    float: right; }
  /* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .addressfield-container-inline .form-item-field-event-location-und-0-locality {
    float: none;
    margin-right: 0; }
  /* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .link-field-column {
    width: 50%; }
  /* line 49, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .link-field-url .form-text {
    width: 100%; }
  /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-name-field-total-hours-applied-for {
    width: 50%;
    position: relative; }
    /* line 55, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-total-hours-applied-for:after {
      content: " hours";
      display: block;
      position: absolute;
      left: 100%;
      top: 30px;
      margin-left: 5px; }
  /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-name-field-total-charges {
    width: 50%; }
  /* line 68, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-name-field-agreement-date .form-type-date-select {
    width: 100%;
    margin: 0;
    float: none; }
  /* line 73, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-name-field-agreement-date .form-type-select {
    margin-right: 1em; }
  /* line 77, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .form-item-field-dates-of-event-und-0-value2 > label {
    display: block;
    padding-left: 1em; }
  /* line 82, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-name-field-application-contacts .field-multiple-drag, .entitytype-accreditation-form .field-name-field-application-contacts thead {
    display: none; }
  /* line 85, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-name-field-application-contacts td {
    padding: 1em 0; }
  /* line 88, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form .field-name-field-application-contacts tr {
    border: none; }
  /* line 92, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
  .entitytype-accreditation-form #field-remit-payment-add-more-wrapper {
    word-wrap: break-word; }
  @media (min-width: 480px) {
    /* line 97, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-application-contacts td > .form-wrapper {
      position: relative;
      float: left;
      width: 33.33333%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 0; }
    /* line 101, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-application-contacts td > .field-name-field-contact-email {
      clear: both;
      width: 100%; }
    /* line 105, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-application-contacts .field-name-field-phone-1 {
      clear: both; }
    /* line 111, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-event-location .panel-body .form-type-textfield, .entitytype-accreditation-form .field-name-field-event-location .panel-body .form-type-select {
      float: left;
      width: 50%;
      margin-right: 0;
      padding: 0 1em; }
      /* line 116, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
      .entitytype-accreditation-form .field-name-field-event-location .panel-body .form-type-textfield label:before, .entitytype-accreditation-form .field-name-field-event-location .panel-body .form-type-select label:before {
        margin-right: 2em; }
    /* line 122, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-dates-of-event .form-type-select {
      margin-right: 1em; } }
  @media (min-width: 640px) {
    /* line 128, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-dates-of-event .form-item-field-dates-of-event-und-0-show-todate {
      margin-left: 0;
      margin-bottom: 1em; }
    /* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-dates-of-event .date-no-float {
      float: left;
      width: 50%;
      clear: none; }
    /* line 137, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-dates-of-event .form-type-date-select {
      float: none;
      margin: 0;
      width: 100%; }
      /* line 141, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
      .entitytype-accreditation-form .field-name-field-dates-of-event .form-type-date-select > label, .entitytype-accreditation-form .field-name-field-dates-of-event .form-type-date-select .date-padding {
        display: inline-block;
        padding: 0;
        float: none;
        vertical-align: middle; }
      /* line 147, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
      .entitytype-accreditation-form .field-name-field-dates-of-event .form-type-date-select > label {
        padding-right: 2em; }
    /* line 151, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .field-name-field-dates-of-event .start-date-wrapper {
      clear: both; } }
  @media (max-width: 767px) {
    /* line 157, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .nav-tabs.vertical-tabs-list {
      display: block;
      float: none;
      width: 100%; }
    /* line 163, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_vendor_app.scss */
    .entitytype-accreditation-form .vertical-tabs .tab-content {
      padding: 0; } }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.page-calendar .tabs--primary {
  margin-left: 15px;
  margin-right: 15px; }

/* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.calendar-calendar td.empty {
  color: #9d9d9d; }

/* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.calendar-calendar td a {
  transition: color 0.2s; }
  /* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .calendar-calendar td a:hover {
    color: #0F4788; }

/* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.page-calendar .breadcrumbs {
  padding: 0; }

/* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.view-calendar .view-header .pager {
  margin: 0; }
  /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .view-calendar .view-header .pager a {
    transition: background-color 0.2s, color 0.2s; }
    /* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
    .view-calendar .view-header .pager a:hover {
      background: #0F4788;
      border-color: #dfdfdf;
      color: #fff; }

@media (max-width: 500px) {
  /* line 27, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .view-calendar .pager.pager {
    margin-top: 16px;
    font-size: 0; }
  /* line 31, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .view-calendar .date-prev.date-prev,
  .view-calendar .date-next.date-next {
    position: relative;
    display: inline-block;
    left: auto;
    right: 0; } }

@media (min-width: 501px) {
  /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .view-calendar .pager.pager {
    margin-top: 0; }
  /* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .view-calendar .date-prev.date-prev {
    position: absolute;
    right: 75px; }
  /* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .view-calendar .date-next.date-next {
    position: absolute; } }

/* line 50, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.calendar-calendar td .inner div.day a {
  color: #0F4788;
  transition: color 0.2s; }
  /* line 54, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .calendar-calendar td .inner div.day a:hover {
    color: #A30234;
    text-decoration: none; }

/* line 58, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.calendar-calendar .month-view .full td.date-box.today,
.calendar-calendar .month-view .full tr td.single-day.today {
  border-color: #0F4788;
  border-color: rgba(15, 71, 136, 0.5); }

/* line 63, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.calendar-calendar .day-view .full td.multi-day div.dayview a {
  color: #fff;
  text-decoration: underline; }
  /* line 67, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .calendar-calendar .day-view .full td.multi-day div.dayview a:hover {
    color: #0F4788; }

/* line 71, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.node-type-calendar-event .tabs--primary {
  margin-left: 15px;
  margin-right: 15px; }

/* line 77, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.node-calendar-event .picture-col {
  padding-bottom: 1em; }

/* line 79, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.node-calendar-event .links, .node-calendar-event .field-name-body {
  margin-top: 1em; }

@media (min-width: 360px) {
  /* line 82, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .node-calendar-event .picture-col {
    position: relative;
    float: left;
    width: 33.33333%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding: 0; }
  /* line 85, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
  .node-calendar-event .content-col {
    position: relative;
    float: left;
    width: 66.66667%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    padding-right: 0; } }

/* line 91, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_calendar.sass */
.view-id-calendar.view-display-id-panel_pane_1 .date-next.date-next {
  position: absolute; }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_training.sass */
.node-live-training, .node-calendar-event {
  margin-bottom: 2em; }
  /* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_training.sass */
  .node-live-training .picture-col, .node-calendar-event .picture-col {
    padding-bottom: 1em; }
  /* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_training.sass */
  .node-live-training .links, .node-live-training .field-name-body, .node-calendar-event .links, .node-calendar-event .field-name-body {
    margin-top: 1em; }
  @media (min-width: 360px) {
    /* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_training.sass */
    .node-live-training .picture-col, .node-calendar-event .picture-col {
      position: relative;
      float: left;
      width: 25%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      padding: 0; }
    /* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_training.sass */
    .node-live-training .content-col, .node-calendar-event .content-col {
      position: relative;
      float: left;
      width: 75%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      padding-right: 0; } }
  @media (min-width: 768px) {
    /* line 15, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_training.sass */
    .node-live-training .picture-col, .node-calendar-event .picture-col {
      position: relative;
      float: left;
      width: 16.66667%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      padding: 0; }
    /* line 18, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_training.sass */
    .node-live-training .content-col, .node-calendar-event .content-col {
      position: relative;
      float: left;
      width: 83.33333%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      padding-right: 0; } }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
.node-type-course .pane-node-content.pane-node-content.pane-node-content {
  padding-top: 0; }
  /* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .node-type-course .pane-node-content.pane-node-content.pane-node-content > .pane-title {
    padding-bottom: 0.5em; }

/* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
.node-type-course .radix-layouts-sidebar .field-name-field-course-logo img {
  display: block;
  margin: 0 auto 3em auto; }

/* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
.group-product-main {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 992px) {
    /* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-main {
      float: left;
      width: 66.66667%; } }

/* line 20, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
.group-product-sidebar {
  text-align: center;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 992px) {
    /* line 20, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar {
      float: left;
      width: 33.33333%; } }
  /* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .group-product-sidebar .field-name-field-course-product {
    margin-top: 1em;
    padding: 1em;
    background: #f1f1f1;
    text-align: center; }
    /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .field-name-field-course-product .field-items:before {
      content: "Purchase for myself";
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      font-size: 1.4em;
      color: #A30234;
      text-align: center;
      display: block;
      margin: 0 1em .5em 1em;
      padding: .3em; }
    /* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .field-name-field-course-product form > div > .form-wrapper {
      margin: 0; }
  /* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .group-product-sidebar .field-name-field-course-voucher-product {
    margin-top: 1em;
    padding: 1em;
    background: #f1f1f1;
    text-align: center; }
    /* line 47, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .field-name-field-course-voucher-product .field-items:before {
      content: "Purchase for OTHERS in my Organization";
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      font-size: 1.4em;
      color: #A30234;
      text-align: center;
      display: block;
      margin: 0 1em .5em 1em;
      padding: .3em; }
    /* line 57, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .field-name-field-course-voucher-product input {
      width: 100%; }
    /* line 60, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .field-name-field-course-voucher-product .form-item-quantity {
      width: 20%; }
    /* line 63, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .field-name-field-course-voucher-product form > div > .form-wrapper {
      width: 76%; }
    /* line 66, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .field-name-field-course-voucher-product .form-submit {
      margin-top: 1em;
      margin-right: 1%;
      background: #A30234;
      color: white;
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      font-size: 1.3em;
      transition: all .2s ease-in; }
      /* line 74, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
      .group-product-sidebar .field-name-field-course-voucher-product .form-submit:hover {
        background: #85022a; }
  /* line 79, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .group-product-sidebar form > div > .form-wrapper,
  .group-product-sidebar .form-item-quantity {
    display: inline-block;
    vertical-align: bottom;
    margin: 1% 1% 0;
    text-align: left;
    transition: all .1s ease; }
    /* line 86, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar form > div > .form-wrapper .form-group,
    .group-product-sidebar .form-item-quantity .form-group {
      margin-bottom: 0; }
  /* line 90, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .group-product-sidebar .form-submit {
    vertical-align: bottom; }
  /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
  .group-product-sidebar .form-item-quantity:after:before, .group-product-sidebar .form-item-quantity:after:after {
    content: " ";
    display: table; }
  /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
  .group-product-sidebar .form-item-quantity:after:after {
    clear: both; }
  /* line 98, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .group-product-sidebar .form-item-line-item-fields-commerce-redhen-organization-ref-und label, .group-product-sidebar .form-item-line-item-fields-commerce-redhen-organization-ref-und select, .group-product-sidebar .form-item-line-item-fields-commerce-redhen-organization-ref-und input,
  .group-product-sidebar .form-item-quantity label,
  .group-product-sidebar .form-item-quantity select,
  .group-product-sidebar .form-item-quantity input {
    width: 100%; }
  /* line 102, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .group-product-sidebar .group-product-price {
    background: #F1F1F1;
    padding: 1em;
    text-align: center;
    font-family: "Cabin", Helvetica, Arial, sans-serif; }
    /* line 107, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
    .group-product-sidebar .group-product-price h3 {
      color: #A30234;
      display: block;
      text-align: center; }
  /* line 113, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .group-product-sidebar .field-name-commerce-price {
    text-align: center;
    font-family: "Cabin", Helvetica, Arial, sans-serif;
    font-size: 1.6em; }

/* line 123, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
.radix-layouts-content.col-md-7 .group-product-sidebar form > div > .form-wrapper,
.radix-layouts-content.col-md-7 .group-product-sidebar .form-item-quantity {
  display: block;
  width: 100%; }
  /* line 127, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__course.scss */
  .radix-layouts-content.col-md-7 .group-product-sidebar form > div > .form-wrapper label,
  .radix-layouts-content.col-md-7 .group-product-sidebar .form-item-quantity label {
    text-align: center; }

/* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column1 .view-header {
  margin-right: 0; }

/* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2 .flex-control-nav {
  position: static;
  bottom: auto;
  margin-top: 10px;
  display: none; }

/* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2 .flex-direction-nav a:before {
  line-height: 1;
  vertical-align: top;
  text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.6), 1px -1px 0 rgba(255, 255, 255, 0.6), -1px 1px 0 rgba(255, 255, 255, 0.6), 1px 1px 0 rgba(255, 255, 255, 0.6); }

/* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2 a.flex-next.flex-next.flex-next {
  left: auto;
  right: 0; }

/* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2 a.flex-prev.flex-prev.flex-prev {
  left: 0;
  right: auto; }

/* line 27, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2 .field-name-field-product {
  padding-top: 0; }

/* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2 .product-info {
  text-align: center;
  border: 1px solid #ccc;
  padding: 0 1em; }

/* line 35, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2 .commerce-product-field {
  padding: 0;
  font-size: 1.5em;
  line-height: 1; }

/* line 41, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .radix-layouts-column2,
.front .block-system .radix-layouts-column3 {
  margin: 2em 0; }
  @media (min-width: 500px) {
    /* line 41, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
    .front .block-system .radix-layouts-column2,
    .front .block-system .radix-layouts-column3 {
      position: relative;
      float: left;
      width: 50%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; } }

@media (min-width: 992px) {
  /* line 48, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
  .front .block-system .col-md-4 {
    position: relative;
    float: left;
    width: 33.33333%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0; } }

/* line 53, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
.front .block-system .mailchimp-signup-subscribe-form {
  background: rgba(15, 71, 136, 0.1);
  padding: 1em; }
  /* line 57, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
  .front .block-system .mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields {
    padding-top: 0.5em; }
    /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .front .block-system .mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields:before, .front .block-system .mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields:after {
      content: " ";
      display: table; }
    /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
    .front .block-system .mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields:after {
      clear: both; }
    /* line 61, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
    .front .block-system .mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields .form-item {
      margin-bottom: 0.5em; }
    /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
    .front .block-system .mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields .form-item-mergevars-FNAMEa {
      float: left;
      width: 50%;
      padding-right: 0.5em; }
    /* line 69, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
    .front .block-system .mailchimp-signup-subscribe-form .mailchimp-newsletter-mergefields .form-item-mergevars-LNAMEa {
      float: left;
      width: 50%;
      padding-left: 0.5em; }
  /* line 74, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__front.sass */
  .front .block-system .mailchimp-signup-subscribe-form .form-submit {
    display: block;
    clear: both;
    width: 100%;
    margin-top: 1em; }

/* line 2, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
.page-ceu-catalog .content-std {
  padding-top: 0; }

/* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
.page-ceu-catalog .radix-layouts-content {
  clear: both;
  border-left: 1px solid #eae7e7; }
  @media (max-width: 767px) {
    /* line 9, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-content .pane-title {
      text-align: center; } }
  @media (min-width: 992px) {
    /* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-content {
      clear: none;
      padding: 40px 0 0 20px !important; } }
  @media (min-width: 1200px) {
    /* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-content {
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      padding: 40px 0 0 40px !important; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    /* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-content {
      float: left;
      width: 75%; } }

@media (max-width: 767px) {
  /* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
  .page-ceu-catalog .radix-layouts-sidebar {
    background: #F9F9F9;
    float: left;
    width: 100%;
    margin-bottom: 1em;
    margin-top: -1px; }
    /* line 29, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-sidebar:before {
      content: 'Filter available CEU\'s';
      display: block;
      text-align: center;
      font-size: 1.1em;
      margin-top: .2em; } }

@media (min-width: 992px) {
  /* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
  .page-ceu-catalog .radix-layouts-sidebar {
    padding-top: 40px; } }

@media (min-width: 1200px) {
  /* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
  .page-ceu-catalog .radix-layouts-sidebar {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    /* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-sidebar {
      float: left;
      width: 25%; } }

/* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
.page-ceu-catalog .radix-layouts-sidebar .pane-block {
  position: relative;
  float: left;
  width: 25%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (max-width: 767px) {
    /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-sidebar .pane-block {
      font-size: .7em;
      text-align: center;
      padding: 0; } }
  @media (min-width: 992px) {
    /* line 43, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-sidebar .pane-block {
      float: none;
      width: 100%;
      margin-bottom: 2em; } }

@media (max-width: 767px) {
  /* line 56, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
  .page-ceu-catalog .radix-layouts-sidebar .headline {
    text-align: center;
    height: 3em;
    vertical-align: bottom;
    margin: 0 0 1em 0; } }

/* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
.page-ceu-catalog .radix-layouts-sidebar h2 {
  text-align: left; }
  @media (max-width: 767px) {
    /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
    .page-ceu-catalog .radix-layouts-sidebar h2 {
      font-size: 1.3em;
      text-align: center;
      border: 0;
      font-weight: bold; } }

/* line 73, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
.page-ceu-catalog .radix-layouts-sidebar ul {
  list-style: none;
  padding: 0; }
  /* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
  .page-ceu-catalog .radix-layouts-sidebar ul li {
    padding: .2em 0 .2em 1em; }
    @media (max-width: 767px) {
      /* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_page__ceu_catalog.scss */
      .page-ceu-catalog .radix-layouts-sidebar ul li {
        padding: 0; } }

/* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_view__news_page.sass */
.view-news-page .field-name-body {
  margin-bottom: 1em; }

/* line 6, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_view__news_page.sass */
.view-news-page .views-row {
  margin-bottom: 4em; }
  /* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_view__news_page.sass */
  .view-news-page .views-row .col-sm-4 {
    text-align: center;
    margin-bottom: 2em; }
  @media (min-width: 400px) {
    /* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_view__news_page.sass */
    .view-news-page .views-row .col-sm-4 {
      position: relative;
      float: left;
      width: 33.33333%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
    /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_view__news_page.sass */
    .view-news-page .views-row .col-sm-8 {
      position: relative;
      float: left;
      width: 66.66667%;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px; }
      /* line 16, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_view__news_page.sass */
      .view-news-page .views-row .col-sm-8 h2 {
        margin-top: 0; } }

/* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_view__news_page.sass */
.page-news .radix-layouts-sidebar h2 {
  text-align: left; }

/* line 2, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
.page-organization .pane-title {
  text-align: left; }

/* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
.page-organization .radix-layouts-contentfooter {
  margin-top: 2em; }

/* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
.page-organization-people .view-organizational-employees {
  margin: 0; }
  /* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
  .page-organization-people .view-organizational-employees .actions a {
    display: inline-block;
    padding-right: 0.5em; }
  /* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
  .page-organization-people .view-organizational-employees .views-row {
    margin: 1em 0; }
    @media (max-width: 990px) {
      /* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
      .page-organization-people .view-organizational-employees .views-row .employee-card .col-md-9 {
        padding: 1em 0; } }
    /* line 26, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
    .page-organization-people .view-organizational-employees .views-row:nth-child(2n+1) {
      clear: both; }
    /* line 29, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
    .page-organization-people .view-organizational-employees .views-row .nav-tabs {
      margin-bottom: 0; }

/* line 37, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_users.scss */
body.page-user-naac-certification .btn.apply-credit {
  position: absolute;
  top: 0;
  right: 15px; }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_facetapi.scss */
ul.facetapi-facetapi-links {
  list-style: none;
  padding: 0; }
  /* line 4, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_facetapi.scss */
  ul.facetapi-facetapi-links li {
    padding: .2em 0 .2em 1em;
    position: relative; }
    /* line 7, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_facetapi.scss */
    ul.facetapi-facetapi-links li .facetapi-active {
      color: #A30234;
      text-decoration: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      vertical-align: middle;
      padding-top: .2em; }
      /* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_facetapi.scss */
      ul.facetapi-facetapi-links li .facetapi-active:hover {
        text-decoration: none !important; }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
.comment {
  clear: both;
  margin: 0;
  padding: 1em 0; }
  /* line 6, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment.odd {
    background: rgba(15, 71, 136, 0.1); }
  /* line 9, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment .links {
    margin-top: 1em;
    font-size: 0.8em; }
  /* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment .indented {
    padding-left: 1em; }
  /* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment .picture-col {
    float: left;
    position: relative;
    z-index: 2;
    padding: 0 1em; }
  /* line 23, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment .content-col {
    padding-right: 1em; }
  /* line 26, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment .comments-itself {
    padding: 0;
    background: transparent; }
    /* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
    .comment .comments-itself:after {
      display: none; }
    /* line 33, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
    .comment .comments-itself span {
      float: none; }
    /* line 36, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
    .comment .comments-itself .links {
      float: right; }
    /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
    .comment .comments-itself .field-name-comment-body {
      padding-top: 1em; }

/* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
.comment-form {
  margin-top: 1em; }
  /* line 45, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment-form .form-group {
    margin-bottom: 0; }
  /* line 49, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment-form .form-type-fivestar label, .comment-form .form-type-fivestar .form-type-select {
    display: inline-block; }
  /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_comments.sass */
  .comment-form .form-submit {
    margin-top: 1em; }

/* line 2, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
select, select.form-control, select.form-select {
  height: 3em;
  border-radius: 0;
  -webkit-appearance: none;
  padding: 0 1em;
  border: 1px solid #e1e1e1;
  box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-weight: bold; }

/* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
input[type='text'], input[type='password'], input[type='email'], textarea.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 3em;
  border-radius: 0;
  padding: 0 1em;
  border: 1px solid #e1e1e1;
  box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-weight: bold; }

/* line 24, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
textarea.form-control {
  height: auto; }

/* line 27, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.form-control, .form-submit, .btn {
  font-family: "Cabin", Helvetica, Arial, sans-serif !important; }

/* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled].active {
  background: #949494 !important;
  border: 1px solid #949494;
  color: rgba(0, 0, 0, 0.4); }

/* line 47, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
form.commerce-add-to-cart .form-submit, .btn-buy {
  margin-top: 1em;
  margin-right: 1%;
  background: #A30234;
  border-color: #710124;
  color: white;
  font-family: "Cabin", Helvetica, Arial, sans-serif;
  font-size: 1.3em;
  transition: all .2s ease-in; }
  @media (max-width: 767px) {
    /* line 47, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
    form.commerce-add-to-cart .form-submit, .btn-buy {
      color: transparent;
      width: 3em;
      margin: 0; }
      /* line 60, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
      form.commerce-add-to-cart .form-submit i, .btn-buy i {
        color: white; } }
  /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
  form.commerce-add-to-cart .form-submit:hover, .btn-buy:hover {
    background: #85022a;
    color: white; }

/* line 71, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.btn i {
  margin-right: .5em;
  transition: all .5s ease-in; }

/* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.btn:hover i {
  animation-name: hvr-wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1; }

/* line 84, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.btn.collapse-button i {
  margin: 0; }

/* line 88, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.btn.collapse-button:hover i {
  transform: none; }

/* line 95, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.form-type-select {
  position: relative; }
  /* line 98, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
  .form-type-select label:before {
    content: '\f078';
    position: absolute;
    right: 1em;
    font-family: fontawesome;
    color: #888;
    font-size: .7em;
    pointer-events: none;
    bottom: 1.5em; }
  /* line 109, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
  .form-type-select select {
    display: inline-block; }

/* line 115, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.form-type-date .form-control {
  padding: 6px;
  height: auto; }

/* handle in js instead
.disabled, .disabled * {
  pointer-events: none!important;
}
*/
/* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.btn.disabled:hover i {
  transform: none;
  animation: none; }

/* line 137, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
a.tabledrag-handle .handle {
  padding: .8em; }

/* line 140, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_shadowdom.scss */
.tabledrag-toggle-weight-wrapper {
  display: none; }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_accreditation-form.scss */
div.collapsible.styled {
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  margin: 1em 0; }
  /* line 6, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_accreditation-form.scss */
  div.collapsible.styled > h2, div.collapsible.styled > h3, div.collapsible.styled > h4 {
    margin: 0;
    padding: .5em; }
  /* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_accreditation-form.scss */
  div.collapsible.styled span.field-group-format-toggler {
    display: block; }
    /* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_accreditation-form.scss */
    div.collapsible.styled span.field-group-format-toggler a {
      position: relative;
      font-size: 1em;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0;
      display: block;
      padding-left: 2.5em; }
      /* line 20, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_accreditation-form.scss */
      div.collapsible.styled span.field-group-format-toggler a:before {
        content: "\f055";
        font-family: 'FontAwesome';
        float: left;
        margin-right: 1em;
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        line-height: 1em;
        color: #A9A9A9;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5); }
  /* line 34, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_accreditation-form.scss */
  div.collapsible.styled .field-group-format-wrapper {
    padding: 1em; }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
#user-login-form, #user-register-form {
  padding: 0 2em; }
  /* line 4, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form .form-item-name:before, #user-register-form .form-item-name:before {
    font-family: fontawesome;
    content: '\f007';
    line-height: 40px;
    float: left;
    width: 12%;
    border-radius: 0;
    font-size: 1.5em;
    padding: 0 .5em;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
    border-right: 0;
    color: #145fb6; }
  /* line 18, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form .form-item-name input, #user-register-form .form-item-name input {
    width: 88%;
    display: inline-block; }
  /* line 24, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form .form-type-password:before, #user-register-form .form-type-password:before {
    font-family: fontawesome;
    content: '\f023';
    line-height: 40px;
    float: left;
    width: 12%;
    border-radius: 0;
    font-size: 1.5em;
    padding: 0 .5em;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
    border-right: 0;
    color: #145fb6; }
  /* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form .form-type-password input, #user-register-form .form-type-password input {
    width: 88%;
    display: inline-block; }
  /* line 44, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form .form-item-mail:before, #user-register-form .form-item-mail:before {
    font-family: fontawesome;
    content: '\f0e0';
    line-height: 40px;
    float: left;
    width: 12%;
    border-radius: 0;
    font-size: 1.5em;
    padding: 0 .5em;
    border: 1px solid #e1e1e1;
    box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
    border-right: 0;
    color: #145fb6; }
  /* line 58, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form .form-item-mail input, #user-register-form .form-item-mail input {
    width: 88%;
    display: inline-block; }
  /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form button, #user-register-form button {
    margin-top: 1em;
    margin-right: 1%;
    background: #A30234;
    border: #710124;
    color: white;
    font-family: "Cabin", Helvetica, Arial, sans-serif;
    font-size: 1.3em;
    transition: all .2s ease-in;
    display: block;
    width: 100%;
    height: 2.6em; }
    /* line 76, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
    #user-login-form button:hover, #user-register-form button:hover {
      background: #85022a; }
  /* line 81, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form#user-register-form .form-item-name:before, #user-login-form#user-register-form .form-item-mail:before, #user-register-form#user-register-form .form-item-name:before, #user-register-form#user-register-form .form-item-mail:before {
    color: #A30234; }
  /* line 84, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form#user-register-form button, #user-register-form#user-register-form button {
    background: #0F4788;
    border: #0a2f5a; }
    /* line 87, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
    #user-login-form#user-register-form button:hover, #user-register-form#user-register-form button:hover {
      background: #0c396c; }
  /* line 95, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
  #user-login-form ul.links, #user-register-form ul.links {
    display: block;
    text-align: center;
    padding: 0; }
    /* line 99, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
    #user-login-form ul.links li, #user-register-form ul.links li {
      font-family: "Cabin", Helvetica, Arial, sans-serif;
      list-style: none;
      display: inline-block;
      font-size: 1.1em;
      margin: 0; }
      /* line 105, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
      #user-login-form ul.links li a, #user-register-form ul.links li a {
        color: #999;
        transition: all .1s;
        transform: scale(1);
        padding: 1em .5em;
        margin: 0 .5em; }
        /* line 111, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
        #user-login-form ul.links li a:hover, #user-register-form ul.links li a:hover {
          color: #A30234;
          text-decoration: none;
          transform: scale(1.1); }
      /* line 117, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
      #user-login-form ul.links li.first, #user-register-form ul.links li.first {
        margin-top: 1em;
        margin-right: 1em; }

/* line 126, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
body.page-user-login .col-md-6 {
  text-align: center; }

/* line 129, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_user-login.scss */
body.page-user-login .register {
  display: none; }

/* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__page.scss */
body.page-type-node .radix-layouts-sidebar .panel-pane {
  margin-bottom: 1em; }

/* line 8, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__page.scss */
.margin-bottom-1 {
  margin-bottom: 1em; }

/* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__page.scss */
.text-align-center {
  text-align: center; }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
.quiz-report {
  background: white; }

/* line 4, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
.q-correct {
  background: #5CB85C;
  font-weight: bold;
  color: white;
  border-radius: 1em !important;
  overflow: .6em;
  border: .2em solid #96DC96; }

/* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
.q-wrong {
  background: #A30234;
  font-weight: bold;
  color: white;
  border-radius: 1em !important;
  overflow: hidden;
  margin: .6em;
  border: 0.2em solid #d50344; }

/* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
.quiz_score {
  border: 1px solid #ECECEC;
  margin-bottom: .8em; }
  /* line 24, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
  .quiz_score span {
    width: 50%;
    display: inline-block;
    text-align: center;
    padding: .5em; }
  /* line 32, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
  .quiz_score.pass span.text {
    background: #96DC96;
    color: #5CB85C;
    text-transform: uppercase;
    font-weight: 600; }
  /* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
  .quiz_score.pass span.score {
    background: #5CB85C;
    color: white; }
  /* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
  .quiz_score.fail span.text {
    background: #D88D8D;
    color: #A30234;
    text-transform: uppercase;
    font-weight: 600; }
  /* line 52, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_quiz.scss */
  .quiz_score.fail span.score {
    background: #A30234;
    color: white; }

/*.outline-in {
  font-size: .9em;
  .quiz_score {
    span {
      display: block;
      width: 100%;
    }
  }
  .btn {
    font-size: 14px!important;
  }
}*/
/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
.commerce-add-to-cart-confirmation {
  position: fixed;
  top: 25%;
  width: 50%;
  left: 25%;
  z-index: 999;
  margin: 0;
  padding: 0; }
  @media (max-width: 767px) {
    /* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
    .commerce-add-to-cart-confirmation {
      width: 80%;
      left: 10%;
      top: 20%;
      font-size: .8em; } }
  /* line 15, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
  .commerce-add-to-cart-confirmation .message-inner {
    border: 1.5em solid rgba(255, 255, 255, 0.5);
    border-radius: 1em !important; }
    /* line 18, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
    .commerce-add-to-cart-confirmation .message-inner .row {
      margin: 0;
      background: #efefef; }
    /* line 22, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
    .commerce-add-to-cart-confirmation .message-inner .added-product-title {
      text-align: center;
      font-size: 2em;
      font-family: 'Lato', 'Open Sans', Arial, Helvetica, sans-serif;
      padding: .8em; }
    /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
    .commerce-add-to-cart-confirmation .message-inner .product-wrapper {
      background: #E8E8E8;
      height: 150px;
      table-layout: fixed; }
      @media (max-width: 767px) {
        /* line 28, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
        .commerce-add-to-cart-confirmation .message-inner .product-wrapper {
          height: auto; } }
    /* line 36, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
    .commerce-add-to-cart-confirmation .message-inner .button-wrapper {
      position: absolute;
      right: 12px;
      top: 19px;
      bottom: 19px;
      padding: 0 3em; }
      @media (max-width: 767px) {
        /* line 36, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
        .commerce-add-to-cart-confirmation .message-inner .button-wrapper {
          position: static;
          padding: 0 2em; } }
      /* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
      .commerce-add-to-cart-confirmation .message-inner .button-wrapper .vert-wrap {
        height: 100%;
        width: 100%; }
        @media (max-width: 767px) {
          /* line 46, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
          .commerce-add-to-cart-confirmation .message-inner .button-wrapper .vert-wrap {
            display: block; }
            /* line 51, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
            .commerce-add-to-cart-confirmation .message-inner .button-wrapper .vert-wrap .vert-center {
              display: block;
              margin: 2em 0; } }
      /* line 58, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
      .commerce-add-to-cart-confirmation .message-inner .button-wrapper .button a {
        color: #fff; }
    /* line 63, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
    .commerce-add-to-cart-confirmation .message-inner .view {
      padding-bottom: 2em; }

/* line 69, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_alert-block.scss */
div.commerce_add_to_cart_confirmation_overlay {
  background: transparent url("/sites/all/modules/contrib/commerce_add_to_cart_confirmation/images/bg_add_to_cart_overlay.png") left top;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 400; }

/* line 2, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
.page-purchase-form .btn-group {
  margin-bottom: 1em; }
  /* line 4, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
  .page-purchase-form .btn-group .btn-who {
    margin-right: 1em; }
  /* line 7, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
  .page-purchase-form .btn-group i {
    margin-right: .7em; }

/* line 11, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
.page-purchase-form .field-name-field-course-logo {
  margin-bottom: 2em; }
  /* line 13, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
  .page-purchase-form .field-name-field-course-logo img {
    margin: auto; }

/* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
.page-purchase-form .field-name-field-price-table {
  padding: 0 1.5em; }

/* line 20, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
.page-purchase-form .field-name-commerce-price {
  font-size: 3em;
  text-align: center;
  color: #909090; }

/* line 25, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
.page-purchase-form .form-submit {
  margin-top: 0 !important; }

/* line 30, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
.page-purchase-form .others .commerce-add-to-cart {
  margin-top: 1.5em;
  margin-left: -15px;
  margin-right: -15px; }
  /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
  .page-purchase-form .others .commerce-add-to-cart:before, .page-purchase-form .others .commerce-add-to-cart:after {
    content: " ";
    display: table; }
  /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/bootstrap/mixins/_clearfix.scss */
  .page-purchase-form .others .commerce-add-to-cart:after {
    clear: both; }
  /* line 33, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
  .page-purchase-form .others .commerce-add-to-cart #edit-line-item-fields {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      /* line 33, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
      .page-purchase-form .others .commerce-add-to-cart #edit-line-item-fields {
        float: left;
        width: 75%; } }
    @media (min-width: 992px) {
      /* line 33, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
      .page-purchase-form .others .commerce-add-to-cart #edit-line-item-fields {
        float: right !important; } }
  /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
  .page-purchase-form .others .commerce-add-to-cart .form-item-quantity {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 992px) {
      /* line 39, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
      .page-purchase-form .others .commerce-add-to-cart .form-item-quantity {
        float: left;
        width: 25%; } }
  /* line 42, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
  .page-purchase-form .others .commerce-add-to-cart .form-submit {
    clear: both;
    display: block;
    margin-left: 15px; }

/* line 51, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_checkout.scss */
.pane-naac-commerce-take-resume-course {
  margin-bottom: 2em; }

/* line 2, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
.node-live-course .list-inline.sub-heading, .node-live-training .list-inline.sub-heading {
  margin-bottom: 1em; }
  /* line 4, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
  .node-live-course .list-inline.sub-heading div.list-item, .node-live-training .list-inline.sub-heading div.list-item {
    display: inline;
    font-family: "Cabin", sans-serif;
    color: #666;
    font-weight: 600; }
    /* line 9, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
    .node-live-course .list-inline.sub-heading div.list-item .date-display-range, .node-live-training .list-inline.sub-heading div.list-item .date-display-range {
      display: inline; }
    /* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
    .node-live-course .list-inline.sub-heading div.list-item .addtocal, .node-live-training .list-inline.sub-heading div.list-item .addtocal {
      float: none;
      display: inline;
      margin-left: 2em;
      vertical-align: bottom; }
      /* line 17, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
      .node-live-course .list-inline.sub-heading div.list-item .addtocal i, .node-live-training .list-inline.sub-heading div.list-item .addtocal i {
        font-size: 1em;
        color: #9FC6E8;
        font-weight: 300;
        margin: 0 .5em 0 0; }
    /* line 24, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
    .node-live-course .list-inline.sub-heading div.list-item i, .node-live-training .list-inline.sub-heading div.list-item i {
      margin: 0 .25em;
      font-size: 1.1em;
      color: #0F4788; }
    /* line 29, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
    .node-live-course .list-inline.sub-heading div.list-item .field, .node-live-training .list-inline.sub-heading div.list-item .field {
      display: inline-block;
      margin-right: 1em; }

/* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_node__live_course.scss */
.node-live-course .links, .node-live-training .links {
  margin-top: 2em;
  margin-bottom: 1em; }

@media (max-width: 767px) {
  /* line 3, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
  .breadcrumbs {
    text-align: center; }
    /* line 5, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
    .breadcrumbs .page-title {
      font-size: 1.2em;
      margin: 0; }
    /* line 9, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
    .breadcrumbs .breadcrumb {
      display: none !important; }
    /* line 12, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
    .breadcrumbs .pull-left, .breadcrumbs .pull-right {
      float: none !important; }
  /* line 16, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
  .radix-moscone-collapse {
    padding: 0 15px; }
  /* line 19, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
  #radix-collapse {
    margin-top: -4em; }
    /* line 21, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
    #radix-collapse .nav-wrapper {
      padding-left: 4em; }
  /* line 25, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
  .tab-v1 .tabs--primary {
    border-color: #0F4788;
    margin-top: -2.5em;
    text-align: center; }
    /* line 29, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_responsive.scss */
    .tab-v1 .tabs--primary li {
      max-width: 98%;
      float: none;
      display: block;
      margin: 0 auto; } }

@keyframes throb {
  0% {
    transform: scale(1.5) rotate(-10deg) translatex(-2px); }
  100% {
    transform: scale(1.3) rotate(-10deg) translatex(-2px); } }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0); }
  40%, 60% {
    transform: translate3d(3px, 0, 0); } }

@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes support {
  0%, 40% {
    transform: translateX(54%); }
  45% {
    transform: translateX(45%); }
  50% {
    transform: translateX(49%); }
  55% {
    transform: translateX(45%); }
  65%, 100% {
    transform: translateX(54%); } }

@keyframes support-hover {
  0% {
    transform: translateX(72%); }
  100% {
    transform: translateX(0); } }

/* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
#support {
  position: absolute;
  top: 40px;
  right: 0;
  transform: translatex(62%);
  font-weight: bold;
  z-index: 999;
  transition: all 1s ease-in; }
  /* line 10, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
  #support:hover {
    transform: translateX(0); }
  /* line 14, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
  #support:before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 46px 2.5em 47px 0;
    border-color: transparent #0F4788 transparent transparent;
    content: '';
    position: absolute;
    right: 100%;
    transform: rotate(360deg);
    transition: all .2s; }
  /* line 26, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
  #support .btn {
    font-size: 1.5em;
    line-height: .5em;
    border-color: #0F4788;
    background: #0F4788;
    color: #efefef;
    padding-left: 6px;
    line-height: 93px;
    height: 93px;
    padding-top: 0;
    padding-bottom: 0;
    transition: all .2s; }
    /* line 38, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
    #support .btn i {
      padding-right: 4px;
      padding-left: 4px;
      font-size: 2em;
      line-height: 94px;
      transition: all .15s; }
    /* line 45, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
    #support .btn span {
      vertical-align: top;
      text-transform: uppercase;
      font-size: 15px;
      vertical-align: top;
      line-height: 98px;
      display: inline-block;
      transition: all .2s; }
  @media (max-width: 1199px) {
    /* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
    #support {
      top: 0;
      animation: none !important;
      transform: none !important;
      margin-right: -1px; }
      /* line 60, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
      #support:before {
        border-width: 28px 1.5em 28px !important;
        margin-right: -1px; }
      /* line 64, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
      #support .btn {
        line-height: 56px !important;
        height: 56px !important; }
        /* line 68, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
        #support .btn i {
          line-height: 56px !important; }
        /* line 71, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
        #support .btn span {
          display: none !important; } }
  @media (min-width: 768px) and (max-width: 1199px) {
    /* line 1, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
    #support {
      position: relative !important;
      float: right;
      margin-right: -34px;
      margin-top: -10px;
      margin-bottom: -10px;
      margin-left: 2em; }
      /* line 84, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
      #support:before {
        border-width: 20px 1em 20px !important;
        margin-right: -1px; }
      /* line 88, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
      #support .btn {
        line-height: 40px !important;
        height: 40px !important; }
        /* line 92, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
        #support .btn i {
          line-height: 38px !important;
          font-size: 1.6em; } }

/* line 102, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
.header-fixed-shrink #support:before {
  border-width: 36px 1.5em 37px 0; }

/* line 106, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
.header-fixed-shrink #support .btn {
  line-height: 73px;
  height: 73px; }
  /* line 110, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
  .header-fixed-shrink #support .btn i {
    line-height: 74px; }
  /* line 113, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
  .header-fixed-shrink #support .btn span {
    line-height: 78px; }

/* line 121, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
body.front #support {
  animation: support 4s infinite;
  transition: all 1s ease-in; }
  /* line 124, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
  body.front #support:hover {
    transform: translateX(0);
    animation: support-hover .5s ease; }

@media (min-width: 1199px) and (max-width: 1400px) {
  /* line 132, /Users/asantiago/Sites/devdesktop/naac-dev/docroot/sites/all/themes/unify_bootstrap/sass/_support.scss */
  .header-v6 .navbar-collapse .navbar-nav {
    margin-right: 60px; } }
