body.page-redhen {

  .field.field-name-redhen-contact-email {
    display: table;
    width: 100%;
    border: solid 2px #eee;
    position: relative;
    outline: 1px solid #ddd;
    margin: 1.3em 0;
    &:after, &:before {
      top: 80%;
      left: 5px;
      width: 50%;
      z-index: -1;
      content: "";
      bottom: 15px;
      max-width: 300px;
      background: #999;
      position: absolute;
    }
    &:after {
      left: auto;
      right: 5px;
      transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -webkit-transform: rotate(3deg);
      box-shadow: 0 15px 10px #999;
      -moz-box-shadow: 0 15px 10px #999;
      -webkit-box-shadow: 0 15px 10px #999;
    }
    div {
      &.field-label {
        display: table-caption;
        padding: 5px 15px;
        color: #333333;
        background-color: #f5f5f5;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        border-bottom: 1px solid #ddd;
        &:before {
          font-family: 'fontawesome';
          content: "\f0e0";
          color: #ccc;
          margin-right: .5em;
        }
      }
      &.legend {
        display: table-header-group;
        div {
          &.email-address, &.email-label, &.bulk-label, &.hold-label, &.primary-label {
            display: table-cell;
            padding: 8px;
            line-height: 1.5;
            vertical-align: middle;
            margin: auto auto;
            font-weight: bold;
          }
        }
      }
      &.field-items {
        display: table-row-group;
        width: 100%;
        div.field-item {
          display: table-row;
          width: 100%;
          &:nth-of-type(odd) {
            background-color: #f9f9f9;
          }
          .email-address, .email-label, .bulk-label, .hold-label, .primary-label {
            display: table-cell;
            padding: 8px;
            line-height: 1.42857143;
            vertical-align: top;
            border-top: 1px solid #ddd;
            margin: auto auto;
          }
        }
      }
      .primary-label, .hold-label, .bulk-label {
        text-align: center;
      }
    }
  }
  div.redhen-fields-email-formatter-row {
    display: table-row;
    width: 100%;
  }

}

#redhen-filter-form {
  margin-top: 1em;
}
a[href^="mailto"] {
  color: $link-color;
  transition: all .1s;
  &:before {
    font-family: 'fontawesome';
    content: "\f0e0";
    margin: 0 .25em;
  }
  &:hover {
    border-bottom: 1px dotted;
    text-decoration: none;
    color: $link-hover-color;
  }
}
a[href^="tel:"] {
  color: $link-color;
  transition: all .1s;
  &:before {
    font-family: 'fontawesome';
    content: "\f095";
    margin: 0 .25em;
  }
  &:hover {
    border-bottom: 1px dotted;
    text-decoration: none;
    color: $link-hover-color;
  }
}