/*
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.7
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/

/*Parallax Slider (Main)
------------------------------------*/

.slider-inner {
  .da-slider {
    box-shadow: none;
    border-bottom: 2px solid #ddd;
  }
  .da-dots {
    z-index: 10;
  }
}

@media (max-width: 450px) {
  .da-slide p {
    display: none;
  }
}

@media (max-width: 900px) {
  /*Slider Parallax*/
  .da-slide .da-img {
    display: none;
  }
}

/*Revolution Slider
------------------------------------*/

.tp-dottedoverlay {
  z-index: 1 !important;
}

.tp-bullets.preview4 .bullet {
  border-radius: 10px !important;
}

.tp-banner-container {
  a {
    &.btn-u {
      color: #fff;
      border: solid 1px transparent;
      &:hover {
        color: #fff;
        border: solid 1px transparent;
      }
    }
    &.re-btn-brd {
      background: none;
      border: solid 1px #fff;
      &:hover {
        color: #555;
        background: #fff;
      }
    }
  }
  .btn-u.btn-brd {
    font-weight: 400;
  }
  position: relative;
}

/*Fullwidth*/

/*Fullscreen*/

.fullscreen-container {
  padding: 0;
  width: 100%;
  position: relative;
}

/*Slider Contents v1*/

.revolution-mch-1:after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: ' ';
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}

.revolution-ch1 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

.revolution-ch2 {
  color: #fff;
  font-size: 20px;
  max-width: 470px;
  line-height: 32px;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-family: "Open Sans", Arial, sans-serif;
  &:after {
    left: 50%;
    width: 70px;
    height: 2px;
    content: ' ';
    bottom: -20px;
    background: #fff;
    margin-left: -35px;
    position: absolute;
  }
}

/*Slider Contents v2*/

.re-title-v1 {
  color: #fff;
  font-size: 75px;
  font-weight: 600;
  line-height: 60px;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

.re-title-v2 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, sans-serif;
}

.re-text-v1 {
  color: #fff;
  font-size: 24px;
  text-align: center;
  font-family: "Open Sans", Arial, sans-serif;
}

.re-text-v2 {
  color: #fff;
  font-size: 24px;
  font-family: "Open Sans", Arial, sans-serif;
}

/*Slides*/

.rs-caption-1 {
  color: #fff;
  font-size: 32px;
  font-weight: 200;
  padding: 6px 75px;
  position: relative;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
  &:after, &:before {
    font-size: 16px;
    content: "\f005";
    position: absolute;
    font-family: FontAwesome;
  }
  &:after {
    right: 4%;
  }
  &:before {
    left: 4%;
  }
}

@media (max-width: 768px) {
  .rs-caption-1 {
    &:after, &:before {
      content: "";
    }
  }
}

.rs-caption-2 {
  color: #fff;
  position: relative;
  font-size: 26px;
  font-weight: 200;
  line-height: 40px;
  padding: 10px 50px;
  text-align: center;
  &:after {
    left: 50%;
    content: "";
    height: 1px;
    width: 100px;
    bottom: -10px;
    background: #fff;
    margin-left: -50px;
    position: absolute;
  }
}

.rs-caption-3 a {
  margin: 0 5px;
  padding: 7px 20px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .rs-caption-1, .rs-caption-2, .rs-caption-3 {
    font-weight: normal;
  }
}

/*Layer Slider
------------------------------------*/

.layer_slider {
  overflow: hidden;
}

.ls-ct-half, .ls-ct-center {
  border-radius: 100px !important;
}

/*Sequence Slider (Home Page)
------------------------------------*/
@media (max-width: 900px) {
  #sequence-theme .info {
    top: 80px !important;
    width: 60% !important;
    margin-right: 50px;
  }
}

@media (max-width: 450px) {
  #sequence-theme {
    .info {
      top: 70px !important;
      width: 70% !important;
      margin-right: 25px;
    }
    h2 {
      font-size: 30px !important;
    }
    .info p {
      font-size: 18px !important;
    }
  }
}

/*Clients Carousel
------------------------------------*/

.clients {
  border: 0;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow: none !important;
  margin-bottom: 0 !important;
  li {
    overflow: hidden;
    margin-left: 1px;
    position: relative;
    background: #fafafa;
    &:hover {
      background: #f6f6f6;
    }
    img.color-img {
      top: 0;
      left: 0;
      display: none;
      position: absolute;
    }
    &:hover img.color-img {
      display: block;
    }
    img {
      filter: gray;
      /* IE6-9 */
      float: none !important;
      margin: 0 auto !important;
      &:hover {
        filter: none;
      }
    }
  }
}

/*Pie Chart
------------------------------------*/

.pie-progress-charts {
  .inner-pchart {
    text-align: center;
  }
  .circle {
    color: #555;
    font-weight: 200;
    margin-bottom: 20px;
  }
  .circle-title {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .pie-progress-charts .inner-pchart {
    margin-bottom: 40px;
  }
}

/*Counter
------------------------------------*/

.counters {
  color: #555;
  text-align: center;
  span {
    font-size: 35px;
    &.counter-icon {
      display: block;
      margin-bottom: 10px;
      i {
        color: #fff;
        padding: 16px;
        min-width: 65px;
        font-size: 30px;
        position: relative;
        background: #72c02c;
        &:after {
          width: 0;
          height: 0;
          left: 38%;
          content: " ";
          bottom: -7px;
          display: block;
          position: absolute;
          border-top: 7px solid #72c02c;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-left-style: inset;
          /*FF fixes*/
          border-right-style: inset;
          /*FF fixes*/
        }
      }
    }
  }
  &:hover span.counter-icon i {
    border-color: #000;
  }
}

@media (max-width: 767px) {
  .counters {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: inherit;
    }
  }
}

/*Back To Top
------------------------------------*/

#topcontrol {
  color: #fff;
  z-index: 99;
  width: 30px;
  height: 30px;
  font-size: 20px;
  background: #222;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important;
  &:after {
    top: -2px;
    left: 8.5px;
    content: "\f106";
    position: absolute;
    text-align: center;
    font-family: FontAwesome;
  }
  &:hover {
    color: #fff;
    background: #72c02c;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

/*Owl Carousel
------------------------------------*/

.owl-btn {
  color: #777;
  cursor: pointer;
  font-size: 18px;
  padding: 2px 9px;
  text-align: center;
  background: #f5f5f5;
  &:hover {
    color: #fff;
    background: #72c02c;
  }
}

/*Owl Carousel v1*/

.owl-carousel-v1 {
  .owl-navigation {
    text-align: right;
    margin-bottom: 20px;
  }
  .item {
    margin: 0 5px;
    img {
      text-align: center;
    }
  }
  .owl-pagination {
    display: none;
  }
}

/*Owl Carousel v2*/

.owl-carousel-v2 {
  .owl-slider-v2 {
    margin-bottom: 20px;
  }
  .item {
    margin: 0 15px;
    img {
      text-align: center;
    }
  }
  .owl-navigation {
    text-align: center;
  }
  .owl-pagination {
    display: none;
  }
}

/*Owl Carousel v3*/

.owl-carousel-v3 {
  .item {
    margin: 0 15px;
    img {
      text-align: center;
    }
  }
  .owl-pagination {
    display: none;
  }
}

/*Owl Carousel v4*/

.owl-carousel-v4 {
  .owl-wrapper-outer {
    margin-bottom: 20px;
  }
  .item {
    margin: 0 3px;
    img {
      width: 100%;
    }
  }
}

/*Owl Carousel v5*/

.owl-carousel-v5 {
  .owl-wrapper-outer {
    margin-bottom: 20px;
  }
  .owl-controls {
    .owl-page.active span, &.clickable .owl-page:hover span {
      background: #72c02c;
    }
    .owl-page span {
      margin: 5px 3px !important;
    }
  }
}

/*Owl Slider v6*/

.owl-carousel-v6 {
  .owl-carousel-item {
    margin: 0 10px;
    padding: 7px 0;
    text-align: center;
    position: relative;
    border: 1px solid #e4e2e2;
    img {
      width: 150px;
      height: auto;
    }
  }
  .owl-wrapper-outer {
    margin-bottom: 20px;
  }
  .owl-controls .owl-page span {
    margin: 5px 3px !important;
  }
}

/*Owl Pagination*/

.owl-pagination {
  text-align: center !important;
}

.owl-theme .owl-controls {
  .owl-page.active span, &.clickable .owl-page:hover span {
    opacity: 1;
    filter: Alpha(Opacity = 100);
    /*IE7 fix*/
  }
  .owl-page {
    zoom: 1;
    display: inline-block;
    *display: inline;
    /*IE7 life-saver */
    span {
      width: 12px;
      height: 12px;
      opacity: 0.3;
      display: block;
      margin: 5px 7px;
      background: #bbb;
      filter: Alpha(Opacity = 50);
      /*IE7 fix*/
      -webkit-border-radius: 50% !important;
      -moz-border-radius: 50% !important;
      border-radius: 50% !important;
    }
  }
}

/*Owl Carousel Style v1*/

.owl-carousel-style-v1 img {
  width: 120px;
  height: auto;
}

/*Owl Carousel Style v2*/

.owl-carousel-style-v2 {
  .item {
    margin: 0 3px;
    background: #fafafa;
    text-align: center;
    border: solid 1px #eee;
    img {
      padding: 0 3px;
      display: inline-block;
    }
    &:hover {
      background: #f7f7f7;
      border-color: #e5e5e5;
      transition: all 0.4s ease-in-out;
    }
  }
  .owl-pagination {
    display: none;
  }
}

/*Owl Clients v2
------------------------------------*/

.owl-clients-v1 {
  img {
    width: 120px;
    height: auto;
  }
  .owl-pagination {
    display: none;
  }
}

/*Owl Carousel - Testimonials v3
------------------------------------*/
/*Testimonials Section*/

.testimonials-v3 {
  padding: 100px 0;
  font-family: "Open Sans", Arial, sans-serif;
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    position: relative;
  }
  li {
    img {
      width: 150px;
      height: 150px;
      margin-bottom: 10px;
    }
    > p {
      font-size: 14px;
    }
  }
  .testimonials-v3-title {
    font-size: 22px;
    font-weight: 200;
    margin-bottom: 20px;
    p {
      color: #72c02c;
      margin-bottom: 0;
    }
    span {
      color: #777;
      display: block;
      font-size: 14px;
    }
  }
  .owl-pagination {
    display: none;
  }
  .owl-buttons {
    .owl-prev, .owl-next {
      padding: 13px;
      position: relative;
      margin: 15px 2px 0;
      background: #f5f5f5;
      display: inline-block;
    }
    .owl-prev:after, .owl-next:after {
      top: 0;
      color: #bbb;
      font-size: 16px;
      position: absolute;
      font-weight: normal;
      display: inline-block;
      font-family: FontAwesome;
    }
    .owl-prev:after {
      left: 10px;
      content: "\f104";
    }
    .owl-next:after {
      right: 10px;
      content: "\f105";
    }
    .owl-prev:hover:after, .owl-next:hover:after {
      color: #fff;
    }
    .owl-prev:hover, .owl-next:hover {
      background: #72c02c;
      -webkit-transition: background 0.4s ease-in-out;
      -moz-transition: background 0.4s ease-in-out;
      -o-transition: background 0.4s ease-in-out;
      transition: background 0.4s ease-in-out;
    }
  }
}

/*Testimonials Owl Navigation*/

/*Recent Works (Owl Carousel)
------------------------------------*/

.owl-work-v1 {
  .owl-navigation {
    top: 3px;
    position: relative;
    margin-bottom: 10px;
  }
  .item {
    a {
      text-align: center;
      &:hover {
        text-decoration: none;
        strong {
          color: #555;
        }
      }
    }
    em.overflow-hidden {
      display: block;
    }
    a {
      span {
        display: block;
        padding: 10px;
        border-bottom: solid 2px #eee;
      }
      strong {
        color: #555;
        display: block;
      }
      i {
        color: #777;
      }
      img {
        -webkit-transition: all 0.8s ease-in-out;
        -moz-transition: all 0.8s ease-in-out;
        -o-transition: all 0.8s ease-in-out;
        -ms-transition: all 0.8s ease-in-out;
        transition: all 0.8s ease-in-out;
      }
      &:hover {
        img {
          opacity: 0.8;
          -webkit-transform: scale(1.2) rotate(3deg);
          -moz-transform: scale(1.2) rotate(3deg);
          -o-transform: scale(1) rotate(3deg);
          -ms-transform: scale(1.2) rotate(3deg);
          transform: scale(1.2) rotate(3deg);
        }
        span {
          border-bottom: solid 2px #72c02c;
          -webkit-transition: all 0.2s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.2s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}

/*Parallax Twitter (Owl Carousel)
------------------------------------*/

.parallax-twitter {
  color: #fff;
  padding: 100px 0;
  position: relative;
  text-align: center;
  background: #333 url(../img/bg/19.jpg) 50% 0 fixed;
  background-size: cover;
  background-position: center center;
  &:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: url(../img/patterns/gridtile.png) repeat;
  }
}

.parallax-twitter-in {
  z-index: 1;
  position: relative;
  ul {
    margin: 0;
  }
  li {
    color: #fff;
    p {
      color: #fff;
      font-size: 18px;
      font-weight: 200;
    }
    a {
      color: #eee;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      color: #ddd;
      display: block;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
  .owl-page {
    width: 18px;
  }
}

/*Twitter Block (owl-controls)*/

/*Clients Section (Owl Carousel)
------------------------------------*/

.clients-section {
  padding: 60px 0;
  position: relative;
  background: #333 url(../img/bg/7.jpg) 50% fixed;
  background-size: cover;
  background-position: center center;
  &:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
  }
  &:before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: " ";
    position: absolute;
    background: url(../img/patterns/gridtile.png) repeat;
  }
  .title-v1 {
    p, h2 {
      color: #fff;
    }
  }
  ul {
    padding: 0;
    z-index: 1;
    list-style: none;
  }
  li {
    margin: 0 3px;
    background: #fff;
    a {
      padding: 10px;
      display: block;
      overflow: hidden;
      text-align: center;
      transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -webkit-transition: all 0.3s ease-in-out;
      &:hover {
        -transform: scale(1.2);
        -o-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
      }
    }
  }
  img {
    width: 100px;
    height: 100px;
  }
  .owl-pagination {
    display: none;
  }
}

/*Owl Video
------------------------------------*/

.owl-video {
  img {
    position: relative;
    text-align: center;
    margin: 0 auto 20px;
    &.video-play {
      top: 50%;
      left: 50%;
      margin-top: -52px;
      margin-left: -52px;
      position: absolute;
    }
  }
  .owl-controls .owl-page span {
    margin: 5px 3px;
  }
}